<template>
  <div class="row-template-nv">
    <!--<div class="component_id">
      RowTemplateNV {{ this._uid }} - {{ this.template.id }}
    </div>-->
    <div v-bind:class="getClassForTemplate(template)">
      <div class="col-6 pr-0">
        <a :name="template.id" ref="anchor"></a>

        <div class="template-header pt-0 pb-0 pl-0">
          <div class="row template-row-header ml-0">
            <div class="col np-pd flex flex-separate">
							<div>
								<template v-if="available_template_version_statuses.length >= 1">
									<b-dropdown
										dropright
										no-caret
										class="template-id-number text-center mt-0"
										size="sm"
										:variant="template_status"
									>
										<template v-slot:button-content>
											{{ template.id }}
										</template>
										<template v-if="!readOnly">
											<div
												v-for="status in available_template_version_statuses"
												:key="status.id"
											>
												<template v-if="status.name != template_status">
													<a
														@click="
															updateTemplateVersionStatus(status.id, status.name)
														"
														style="text-transform: capitalize; cursor: pointer"
													>
														<span :class="'btn-' + status.name">&nbsp;</span>
														{{ status.name.replace("_", " ") }}
													</a>
												</template>
											</div>
										</template>
									</b-dropdown>
								</template>
								<template v-if="available_template_version_statuses.length < 1">
									<div
										:class="
											'template-id-number text-center mt-0 mr-0 btn-' +
											template_status
										"
									>
										{{ template.id }}
									</div>
								</template>

								<template v-if="readOnly">
									<a href="#" @click.prevent="showHistory">
										<b-badge
											class="template-updated-at m-0 p-0 mr-1"
											variant="light"
											v-text="updated_at"
										/>
									</a>
									<!-- <button style="background: transparent;" class="icon--xs" v-if="archivedTemplate" @click="opeRestoreTreeNode(template.id, template.versions[0])" v-b-tooltip.hover title="Restore elements">
										<i class="fas fa-trash-restore"></i>
									</button> -->
									<!-- <button style="background: transparent;" class="icon--xs" v-if="archivedTemplate" @click="openDeleteTreeNode(template.id, template.versions[0])" v-b-tooltip.hover title="Delete element">
										<i class="fas fa-trash"></i>
									</button> -->
								</template>
								<template v-else>
									<a href="#" @click.prevent="showHistory">
										<i class="fas fa-history template-circle-button"></i>
										<b-badge
											class="template-updated-at m-0 p-0 mr-1"
											variant="light"
											v-text="updated_at"
										/>
									</a>

									<ButtonCircle
										class="template-preview-icon"
										ref="ButtonEye"
										:template="template"
										v-on:click="update_regular_parts"
										icon="play"
										title="Run this segment with selected data file"
									/>
									<ButtonCircle
										class="template-preview-icon"
										ref="ButtonEyeComments"
										:template="template"
										@click="toggle_messages"
										icon="comments"
										title="Show comments"
									/>
									<b-dropdown
										id="rowTemplatesExtraOptions"
										size="sm"
										variant="link"
										class="row-template__extra-options"
										toggle-class="text-decoration-none m-0 p-0"
										no-caret
										title="Extra options"
									>
										<template v-slot:button-content>
											<span class="template-circle-button">
												<font-awesome-icon icon="ellipsis-v" size="sm" />
											</span>
										</template>

										<b-dropdown-item @click.prevent="showHistory"
											>History</b-dropdown-item
										>
										<hr />
										<b-dropdown-item
											ref="ButtonEye5X"
											@click="previewTemplate($event, 'ButtonEye', false, 5)"
											>Preview 5x</b-dropdown-item
										>
										<b-dropdown-item
											ref="ButtonEye20X"
											@click="previewTemplate($event, 'ButtonEye', false, 20)"
											>Preview 20x</b-dropdown-item
										>
										<b-dropdown-item
											ref="ButtonEye50X"
											@click="previewTemplate($event, 'ButtonEye', false, 50)"
											>Preview 50x</b-dropdown-item
										>
										<b-dropdown-item
											ref="RealPreview"
											@click="previewTemplate($event, 'ButtonEye', false, 1, 'false')"
											>Real Preview</b-dropdown-item
										>
										<hr />
										<!-- <b-dropdown-item
											ref="EntitiesExtractor"
											@click="entitiesExtractor"
											>Entities Extractor</b-dropdown-item
										> -->
										<b-dropdown-item
											@click.prevent="$emit('filterByTemplateID', template)"
											>Filter</b-dropdown-item
										>
										<!-- <b-dropdown-item @click.prevent="showSimilarity($event)"
											>Inspiration</b-dropdown-item
										> -->
										<b-dropdown-item @click.prevent="openPopupParaphrasing"
											>Paraphrasing</b-dropdown-item
										>

										<b-dropdown-item
											@click="translate"
											title="Translate segment"
											class="collapseControl"
											>Translate</b-dropdown-item
										>
										<hr />

										<b-dropdown-item
											ref="ButtonDuplicate"
											@click="$emit('duplicateTemplate', template)"
											><!--<i class="fa fa-files-o" aria-hidden="true"></i>-->
											Duplicate</b-dropdown-item
										>
										<b-dropdown-item @click.prevent="togglePlainView()"
											>Plain View</b-dropdown-item
										>
										<hr />
										<b-dropdown-item
											ref="ButtonDelete"
											@click="deleteTemplate($event)"
											><i
												class="fa fa-trash"
												aria-hidden="true"
												style="color: red"
											></i>
											<span style="color: red">Delete</span></b-dropdown-item
										>
									</b-dropdown>
								</template>
								<span
									class="template-cell-type"
									contenteditable
									spellcheck="false"
									@blur="updateVersion($event, 0, template.versions[0])"
									v-text="template.versions[0].type"
									v-if="templates_without_segements"
								>
										{{template.versions[0].type}}
								</span>
							</div>
            </div>
          </div>
          <div class="row">
            <div v-if="show_messages" class="col-12 message-list pt-2">
              <div
                v-for="message in template.messages"
                v-bind:key="message.id"
                class="col-12"
              >
                <div class="message-header">
                  <div class="message-name">
                    {{ message.first_name }} {{ message.last_name }}
                    <span class="message-date" style="margin-left: 2em">{{
                      format_date(message.post_datetime)
                    }}</span>
                  </div>
                </div>

                <div
                  class="message-body col-12"
                  :class="message.readed ? '' : 'message-unreaded'"
                >
                  <div class="row">
                    <div class="col-11">
                      {{ message.body }}
                    </div>
                    <div class="col-1 text-right">
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="delete_message(message.id, message.template_id)"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col pt-2">New message:</div>
              <div class="col-12">
                <div class="row">
                  <div class="col-11">
                    <atNV
                      :ats="['@']"
                      :members="users"
                      hideOnBlur
                      @at="autocompleteUser"
                      :filterMatch="filterMatch"
                      :filterMembers="filterUsers"
                    >
                      <div
                        contenteditable
                        class="new-message"
                        ref="new_message_body"
                      ></div>
                    </atNV>
                  </div>
                  <div class="col-1" style="margin: auto">
                    <button
                      class="btn btn-primary"
                      @click="create_message"
                      style="margin-bottom: 1em"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col ml-2">
              <div class="row">
                <b-col cols="12" class="m-0 p-0 pl-2 pt-1">
                  <div
                    class="segment_plain_edit_row row no-gutters"
                    v-show="plainView"
                  >
                    <div class="col">
                      <AtTextareaNV
                        :ats="triger_chars"
                        :members="autocomplete_members_in_templates"
                        hideOnBlur
                        :filterMatch="filterMatch"
                        :filterMembers="filterOnlyAliases"
                        :ref="'atta' + template.id"
                      >
                        <textarea
                          v-model="template.versions[0].text_for_editor"
                          placeholder="Plain view"
                          type="text"
                          style="width: 100%"
                          rows="1"
                          @blur="onNotifyValueChange"
                        />
                      </AtTextareaNV>
                    </div>
                  </div>
									<div class="row d-flex flex-wrap" v-if="oldModeEditActive">
										<div class="col">
											<at
												:ats="triger_chars"
												:members="autocomplete_members_in_templates"
												hideOnBlur
												:filterMatch="filterMatch"
												:filterMembers="filterOnlyAliases"
												:ref="'at' + template.id"
											>
												<Highlightable
													:ref="'Highlightable' + template.id"
													@onVariations="openPopupVariations"
													@onCreateTempByText="createTempByText"
													@onParaphrasing="openPopupParaphrasing"
												>
													<highlightable-input
														highlight-style="badge-data-template"
														highlight-enabled
														:highlight="highlight"
														:refreshHighlights="refreshHighlights"
														v-model="template.versions[0].text_for_editor"
														@keyup="handleTextKeyDown($event, template)"
														@blur="textTemplateLostFocus($event, template)"
														@focus="textTemplateGetFocus($event, template)"
														:ref="'text' + template.id"
														:category_id="category_id"
														canEdit
													/>
												</Highlightable>
											</at>
										</div>
									</div>
                  <div v-else class="segment_edit_row row">
                    <div class="col">
                      <atNV
                        :ats="triger_chars"
                        :members="autocomplete_members_in_templates"
                        hideOnBlur
                        :filterMatch="filterMatch"
                        :filterMembers="filterOnlyAliases"
                        :ref="'at' + template.id"
                      >
                        <HighlightableNV
                          :ref="'Highlightable' + template.id"
                          @onVariations="openPopupVariations"
                          @onCreateTempByText="createTempByText"
                        >
                          <Highlightable-inputNV
                            highlight-style="badge-data-template"
                            highlight-enabled
                            :highlight="highlight"
                            :refreshHighlights="refreshHighlights"
                            v-model="template.versions[0].text_for_editor"
                            :corrections="corrections"
                            @mouseup="selectionText($event, template.versions[0])"
                            @blur="textTemplateLostFocus($event, template)"
                            @focus="textTemplateGetFocus($event, template)"
                            @joinPartsrefreshContent="refreshContent"
                            @notifyValueChange="onNotifyValueChange"
                            :ref="'text' + template.id"
                            :category_id="category_id"
                            canEdit
                            :templateIdAncestors="template.id"
                            @duplicateTemplate="onDuplicateTemplate"
                            @deleteTemplate="onDeleteTemplate"
                            @addNewTemplate="onAddNewTemplate"
                            @updateTemplate="
                              previewTemplate($event, 'ButtonEye', false, 1)
                            "
                            @clickCorrections="onclickCorrections"
                            @clickSynonym="showSynonyms"
                            @clickTypo="showTypos"
                            @clickPlural="showPlural"
                          />
                        </HighlightableNV>
                      </atNV>
                    </div>
                  </div>
                </b-col>
              </div>
            </div>
          </div>

        </div>
      </div>
    <!--/div>

    <div
      v-bind:class="getClassForTemplate(template)"
      class="mt-1"
      v-if="previews && previews.length > 0"
    >
    <!-- preview -->
      <div class="col-6 preview-col" v-if="!readOnly">
        <div class="previewIcon m-1">
          <span><i class="fa fa-eye" aria-hidden="true"></i></span>
				</div>
				<div class="text-right pr-0 align-middle">
					<b-form-checkbox
              class="switch-render-html inline"
              v-model="renderHTML"
              name="check-renderHTML"
              size="sm"
              inline
              title="Render HTML toggle"
              >HTML</b-form-checkbox
            >
					<b-form-checkbox
						class="switch-render-html inline"
						@change="saveIsPrompt(template.is_prompt)"
						v-model="template.is_prompt"
						name="check-renderHTML"
						size="sm"
						inline
						title="Render HTML toggle"
					>Is Prompt</b-form-checkbox
					>
        </div>
        <div class="row segment_preview_row mt-0">
          <div class="col ml-3">
            <div class="template-col-previewnv">
              <p class="" v-if="variability">
                <span class="badge-template badge-data-template">
                  Variability:
                  <span class="variability-percent">{{ variability }}%</span>
                </span>
              </p>
              <div v-for="(preview, prevIndex) in previews" :key="prevIndex" class="template-row-preview-nv">
                <template v-if="!renderHTML">
                  <template v-if="!preview.error">
                    <div
                      v-if="preview.log.length > 0"
                      class="error-invalid-scenario"
                    >
                      <div v-for="(error, index) in preview.log" :key="index">
                        <div>{{ error.message }}:</div>
                        <div>- {{ error.details }}</div>

                        <br v-if="preview.log[index + 1]" />
                      </div>
                    </div>
                    <div
                      v-if="!preview.error"
                      class="template-cell-preview-result"
                    ><HTMLValidator :html_text="preview.result" > </HTMLValidator>
										</div>
                  </template>

                  <template v-else class="template-cell-preview-error">
                    <font-awesome-icon
                      icon="exclamation"
                      class="template-cell-preview-error-icon"
                    />
                    <span
                      v-html="preview.error"
                      class="template-cell-preview-error"
                    ></span>
                  </template>
                </template>
                <template v-else>
                  <!--div
                  v-if="preview.logs_formatted.length > 0"
                  class="error-invalid-scenario"
                  v-html="preview.logs_formatted"
                ></div-->

                  <template v-if="!preview.error">
                    <div v-if="preview.log.length > 0" class="previewErrorsIcon">
                      <i
                        class="fas fa-exclamation-triangle"
                        aria-hidden="true"
                        @click="show_preview_errors = !show_preview_errors"
                      ></i>
                    </div>
                    <div
                      v-if="show_preview_errors && preview.log.length > 0"
                      class="error-invalid-scenario"
                    >
                      <div v-for="(error, index) in preview.log" :key="index">
                        <div>{{ error.message }}:</div>
                        <div>- {{ error.details }}</div>

                        <br v-if="preview.log[index + 1]" />
                      </div>
                    </div>
                    <div
                      class="template-cell-preview-result"
                      v-html="preview.result"
                      v-if="!showTemplatesLinks"
                      @mouseup="selectionText($event, template.versions[0])"
                    ></div>
                    <div
                      class="template-cell-preview-result"
                      v-html="preview.result_with_sandbox_tags"
                      v-else
                      @mouseup="selectionText($event, template.versions[0])"
                    ></div>
                  </template>
                  <template v-else>
                    <font-awesome-icon
                      icon="exclamation"
                      class="template-cell-preview-error-icon"
                    />
                    <span
                      v-html="preview.error"
                      class="template-cell-preview-error"
                      @mouseup="selectionText($event, template.versions[0])"
                    ></span>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- translate -->
        <div v-if="translates.length > 0" class="row segment_preview_row mt-2">
          <div class="col">
            <div class="template-col-previewnv">
              <p class="template-row-preview-nv">
                <span
                  @click="translate"
                  title="Translate segment"
                  class="collapseControl"
                  ><i class="fas fa-language fa-2x"></i
                ></span>
              </p>
              <div
                v-for="(translate, translateIndex) in translates"
								:key="translateIndex"
                class="template-row-preview-nv"
              >
                <!--img src="@/assets/imgs/NarrativaLogoPNG_50h_black.png" style="height:20px;" />
                <div
                  v-if="translate.text_dest_narrativa"
                  class="template-cell-preview-result"
                  v-html="translate.text_dest_narrativa"
                ></div>
                <i class="fa fa-google"></i-->
                <div
                  v-if="translate.text_dest"
                  class="template-cell-preview-result"
                  v-html="translate.text_dest"
                ></div>
                <hr class="hr-template-cell-preview-result" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Tooltip-->
		<template v-if="!readOnly">
			<div class="row">
				<TooltipTemplate
					ref="tooltip"
					:template="template"
					@includeSynonyms="onIncludeSynonyms"
					@changeTypo="onChangeTypo"
					@changePlural="onChangePlural"
				>
				</TooltipTemplate>
			</div>

			<modal height="950" width="900" :name="modalSelecionTextName">
				<VariationsSelector
					:minerva_url="currentDomain.minerva_url"
					:minerva_server="currentDomain.minerva_server"
					:language="currentDocument.language"
					:modal_name="modalSelecionTextName"
					ref="VariationsSelector"
					@selectedItems="insertVariations"
				/>
			</modal>

			<modal height="950" width="900" :name="modalSimilarityName">
				<SimilaritySelector
					:minerva_url="currentDomain.minerva_url"
					:minerva_server="currentDomain.minerva_server"
					:language="currentDocument.language"
					:modal_name="modalSimilarityName"
					ref="VariationsSelector"
					@includeSimilariry="insertSimilarity"
				/>
			</modal>

			<modal
				height="700"
				width="1200"
				:name="modalEntitiesName"
				class="entities-modal"
			>
				<EntitiesSelector
					ref="EntitiesSelector"
					:language="currentDocument.language"
					:texts="textEntities"
					:modal_name="modalEntitiesName"
					:insert="entitiesNewTemplates"
					@includeEntities="insertEntities"
					@includeEntitiesTemplate="insertEntitiesTemplate"
				/>
			</modal>

			<modal height="950" width="900" :name="modalHistoryName">
				<TemplatesHistory
					:templateId="template.id"
					:modal_name="modalHistoryName"
					@setCurrentVersion="setCurrentVersion"
				/>
			</modal>

			<modal height="950" width="900" :name="modalParaphrasingName">
				<ParaphrasingSelector
					:domain_id="$store.getters.getCurrentDomain.id"
					:documenta_data_id="$store.getters.getDocumentDataSelected"
					:language="$store.getters.getCurrentDocument.language"
					:modal_name="modalParaphrasingName"
					ref="ParaphrasingSelector"
					@selectedItems="insertParaphrasing"
				/>
			</modal>

			<b-modal
				scrollable
				height="5000"
				width="60%"
				resizable
				draggable
				size="xl"
				:name="narraSelectorModalName"
				:id="narraSelectorModalName"
				centered
			>
				<template v-slot:modal-header="{ hide }"></template>

				<NarrasTreesViewerNV
					collapse_button
					tree_editor_button="false"
					select_name="true"
					:rows="rows"
					@selectNarra="selectNarra"
					:template_name_filter="template.versions[0].type"
				></NarrasTreesViewerNV>
				<template v-slot:modal-footer="{ ok, cancel, hide }">
					<b></b>
				</template>
			</b-modal>

			<b-modal
				height="5000"
				draggable
				size="lg"
				ref="modalGetTypeForNewVersion"
				centered
				hide-footer
				hide-header
				centered
			>
				<b-form-group
					id="input-group-2"
					label="New segment name:"
					label-for="type"
					label-align="left"
				>
					<b-form-input
						id="type"
						required
						v-model="typeTempByText"
						placeholder="Name"
					></b-form-input>
				</b-form-group>

				<b-form-group id="input-group-5" label-align="left">
					<b-form-checkbox id="active" value="true" unchecked-value="false"
						>Create associated default scenario</b-form-checkbox
					>
				</b-form-group>

				<b-button
					:disabled="!typeTempByText.length"
					variant="primary"
					v-on:click="createTempByTextWithType"
					>Save</b-button
				>

				<b-button
					type="reset"
					variant="danger"
					v-on:click="$refs['modalGetTypeForNewVersion'].hide()"
					>Cancel</b-button
				>
			</b-modal>
		</template>
  </div>
</template>

<script>
import template_version_api from "../../api/template_version.js";
import template_message_api from "../../api/template_messages.js";
import templates_api from "../../api/template.js";
import narras_templates_api from "../../api/narras_tree_templates";
import preview_api from "../../api/preview";
import tree_nodes_api from "../../api/narras_tree_nodes.js";
import tree_nodes_versions_api from "../../api/narras_tree_nodes_versions.js";
import scenario_categories_api from "../../api/scenario_categories.js";
import scenario_api from "../../api/scenario.js";
import translate_api from "../../api/translate.js";
import minerva_api from "../../api/minerva.js";
import { htmlDecode, getPosEL } from "../../utils/common.js";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import { autocompleteMixins } from "@/mixins/autocompleteMixins.js";
import { templateVersionsMixins } from "@/mixins/templateVersionsMixins.js";
import AtNV from "@/components/UI/AutoComplete/AtNV.vue";
import At from "@/components/UI/AutoComplete/At.vue";
import AtTextareaNV from "@/components/UI/AutoComplete/AtTextareaNV.vue";
import VariationsSelector from "@/components/UI/Variations/VariationsSelector.vue";
import SimilaritySelector from "@/components/UI/Variations/Similarity.vue";
import EntitiesSelector from "@/components/Entities/Entities.vue";
import Highlightable from "@/components/UI/Highlightable/Highlightable.vue";
import HighlightableNV from "@/components/UI/Highlightable/HighlightableNV.vue";
import HighlightableInputNV from "@/components/UI/Highlightable/HighlightableInputNV";
import HighlightableInput from "@/components/UI/Highlightable/HighlightableInput";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import NarrasTreesViewerNV from "@/components/narras/NarrasTreesViewerNV";
import TemplatesHistory from "@/components/templates/TemplatesHistory";
import ParaphrasingSelector from "@/components/UI/Variations/ParaphrasingSelector.vue";
import TooltipTemplate from "@/components/templates/TooltipTemplate.vue";
import HTMLValidator from "@/components/templates/HTMLValidator";

import { mapGetters, mapMutations } from 'vuex';

export default {
	name: "RowTemplateNV",
	mixins: [
		alertMixins,
		templatesMixins,
		narrasMixins,
		autocompleteMixins,
		templateVersionsMixins
	],
	components: {
		AtNV,
		VariationsSelector,
		SimilaritySelector,
		HighlightableNV,
		HighlightableInputNV,
		ButtonCircle,
		NarrasTreesViewerNV,
		EntitiesSelector,
		AtTextareaNV,
		TemplatesHistory,
		ParaphrasingSelector,
		TooltipTemplate,
		At,
		Highlightable,
		HighlightableInput,
		HTMLValidator
	},
	props: {
		template: { type: Object },
		template_root: { type: Object, default: null },
		currentDocument: { type: Object },
		currentDomain: { type: Object },
		currentRoute: { type: String, default: "" },
		document_data_selected: { type: Number, default: 0 },
		level: { type: Number, default: 0 },
		category_id: {},
		active_autocomplete: { type: Boolean, default: true },
		active_preview: { type: Boolean, default: true },
		refreshHighlights: { type: Boolean, default: true },
		collapseID: { type: String },
		parent: { type: Boolean, default: false },
		autoHighlights: { type: Boolean, default: true },
		templates_without_segements: {default: false},
		readOnly: { default: false },
		archivedTemplate: { default: false }
	},
	created () {
		this.weigth = this.template.versions[0].weigth;
		if (this.template.versions[0].status) {
			this.template_status = this.template.versions[0].status;
		}
		this.has_unread = this.template.messages ? this.template.messages.some((msg) => !msg.readed) : false;

		let self = this;
		this.$store.watch(
			(state, getters) => getters.getSegmentIdFilter,
			(segment_id, oldValue) => {
				/*console.debug(
					"RowTemplate change segment_id",
					segment_id,
					this.template.id
				);*/
				if (segment_id == this.template.id) {
					console.debug("scrollToThisTemplate", segment_id);
					this.scrollToThisTemplate();
				}
			}
		);
	},
	mounted () {
		/*console.debug(
			"RowTemplate mounted",
			this.$store.getters.getSegmentIdFilter,
			this.template.id
		);*/
		if (
			("segment_id" in this.$route.query &&
        this.$route.query.segment_id == this.template.id) ||
      this.$store.getters.getSegmentIdFilter == this.template.id
		) {
			console.debug("scrollToThisTemplate", this.template.id);
			this.scrollToThisTemplate();
		}
	},
	data () {
		return {
			highlight: [
				{ text: /{(.*?.*?)}/g, style: "badge-template badge-synonym-template" }
			],
			triger_chars: ["[", "(", "|", "--", ".", "__sort__", "__len__"],
			renderHTML: true,
			showTemplatesLinks: false,
			timeout: null,
			timeout_preview: null,
			template_versions_variation: "",
			selectionWordText: {},
			navigate_here: false,
			typeTempByText: "",
			associateDefaultByText: "true",
			newTemplateByType: {},
			previews: [],
			translates: [],
			variability: false,
			weigth_options: [
				{ value: 30, text: "High" },
				{ value: 20, text: "Normal" },
				{ value: 10, text: "Low" }
			],
			weigth: 20,
			textEntities: [],
			entitiesNewTemplates: false,
			editMode: true,
			plainView: false,
			showMenu: false,
			available_template_version_statuses: JSON.parse(
				localStorage.getItem("template_versions_permissions")
			),
			template_status: "not_completed",
			show_messages: false,
			has_unread: false,
			users: JSON.parse(localStorage.getItem("users")),
			corrections: {},
			show_preview_errors: false,
			regular_parts_updated: false,
		};
	},
	watch: {
		active_autocomplete: function (val) {
			//this.active_autocomplete = !this.active_autocomplete;
			this.triger_chars = val
				? ["[", "(", "|", "--", ".", "__sort__", "__len__"]
				: [];
		},
		template () {
			this.previews = [];
		},
		triggerPreview(newVal, oldVal) {
			if (newVal) {
				this.previewFromPlayAllButton();
			}
		}
	},
	computed: {
		...mapGetters({
			triggerPreview: "getTriggerTemplatesPreview",
			templatesToPreview: "getTemplatesToPreview",
			oldModeEditActive: "getOldEditModeActive"
		}),
		updated_at () {
			const localDate = new Date(this.template.versions[0].updated_at);
			return localDate.toLocaleString("es", {
				day: "numeric", //numeric, 2-digit
				month: "numeric", //numeric, 2-digit, long, short, narrow
				year: "numeric", //numeric, 2-digit
				hour: "2-digit", //numeric, 2-digit
				minute: "numeric", //numeric, 2-digit
				//second: 'numeric', // numeric, 2-digit
				hour12: false
			});
		},
		rows () {
			return this.$store.getters.getRowsNarras;
		},
		narraSelectorModalName () {
			return "NarraSelectorModal" + this.$_uid;
		},
		modalSelecionTextName () {
			return "modalSelecionText" + this.$_uid;
		},
		modalSimilarityName () {
			return "modalSimilarityName" + this.$_uid;
		},
		modalEntitiesName () {
			return "modalEntitiesName" + this.$_uid;
		},
		modalParaphrasingName () {
			return "modalParaphrasing" + this.$_uid;
		},
		modalHistoryName () {
			return "modalHistoryName" + this.$_uid;
		},
		//esta funcion obtiene la ruta de completa del arbol de scenarios del template actual
		treePath () {
			try {
				if (
					this.template.narra_tree_nodes_version != undefined &&
          this.template.narra_tree_nodes_version.length > 0
				) {
					this.template.narra_tree_nodes_version[0].name = this.getScenarioByID(
						this.template.narra_tree_nodes_version[0].scenario_id,
						this.template.narra_tree_nodes_version[0].name
					);

					if (this.template.narra_tree_nodes_version[0].parent_id) {
						let parents = this.getTreeNarraParent(
							this.template.narra_tree_nodes_version[0].parent_id
						);
						return parents.reverse();
					}
				}
			} catch (err) {
				console.log(err);
			}
			return "";
		}
	},
	methods: {
		...mapMutations({
			triggerTemplatesPreview: "SET_TRIGGER_TEMPLATES_PREVIEW",
			setTemplatePreviewStatus: "SET_UPDATE_TEMPLATE_PREVIEW_STATUS"
		}),
		opeRestoreTreeNode (id, isRoot) {
			const isRootElement = "parent_id" in isRoot && isRoot.parent_id === null;
			this.$store.commit("SET_TREE_NODE_TO_RESTORE", {id: id, isRoot: isRootElement});
			this.$bvModal.show("restore-parent-segment");
		},
		openDeleteTreeNode (id, isRoot) {
			console.log(id)
			const isRootElement = "parent_id" in isRoot && isRoot.parent_id === null;
			this.$store.commit("SET_TREE_NODE_TO_DELETE", {id: id, isRoot: isRootElement});
			this.$bvModal.show("delete-parent-segment");
		},
		async previewFromPlayAllButton () {
			if (this.templatesToPreview.find(item => item.id === this.template.id && item.previewed === false)) {
					await this.previewTemplate(null, "ButtonEye", "false")
					this.setTemplatePreviewStatus({ id: this.template.id, previewed: true });
			}
			this.triggerTemplatesPreview(false);
		},
		scrollToThisTemplate () {

			//this.show_messages = true;
			let self = this;
			setTimeout(() => {
				const element = self.$el;
				// const xscroll = element.offsetTop - 100;
				// document.body.scrollTop = xscroll; //For Safari
				// document.documentElement.scrollTop = xscroll; //For Chrome, Firefox, IE and Opera
				// console.debug("scrollToThisTemplate", element, xscroll);
				element.scrollIntoView({
					behavior: "auto",
					block: "center",
					inline: "center"
				});
				this.navigate_here = true;
			}, 1500);
		},
		format_date (date) {
			const options = {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit"
			};
			const parsed_date = new Date(date);
			return parsed_date.toLocaleTimeString("en-US", options);
		},
		async create_message ($event) {
			const [response, error] = await template_message_api.createMessage(
				this.template.id,
				this.$refs.new_message_body.innerText,
				this.currentDomain.id,
				this.currentDocument.id,
				this.currentRoute
			);
			if (!error) {
				this.template.messages = response;
				this.$refs.new_message_body.innerText = "";
				this.local_mark_read();
			}
		},
		async delete_message (message_id, template_id) {
			const [response, error] = await template_message_api.deleteMessage(
				message_id,
				template_id
			);
			if (!error) {
				this.template.messages = response;
			}
		},
		async updateVersion (event, field_num, version) {
			version = this.getTempalteVersionObjChanged(version, field_num, event);

			this.updateTemplate(version);
			/*if (this.autoHighlights)
				this.$refs["text" + this.template.id].processHighlights();*/
			//
		},
		autocompleteUser (index, chunk) {
			console.log(`Autocomplete User \n index: ${index}; chunk: ${chunk}`);
		},
		async saveIsPrompt (isPrompt) {
			console.log("saveIsPrompt", isPrompt, "template", this.template);
			const [response, error] = await templates_api.updateTemplate(
				this.template,
			);
			// if (!error) {
			// 	this.template.is_prompt = response.is_prompt;
			// }
		},
		async updateTemplate (version) {
			console.debug(
				"[" + this._uid + "] RowTemplateNV.updateTemplate: ",
				version
			);

			if (version) {
				console.log("POST TEMPLATE VERSION", version);
				this.isLoading = false;
				//this.template.versions[0].original_text = version.text_for_editor;

				/*console.debug(
          "[" +
            this._uid +
            "] RowTemplateNV.updateTemplate.template_version_api.createVersion"
        );*/

				const [data, err] = await template_version_api.createVersionWithAlias(
					version,
					this.$store.getters.getCurrentDomain.id
				);

				if (!err) {
					console.log("UPDATE", data);
					this.template.versions[0].updated_at = data.updated_at;
					//this.template.versions[0].text_for_editor = data.text_for_editor;
					//this.template.versions[0].synonyms = data.synonyms;
					//this.template.versions[0].typos = data.typos;
					this.template.versions[0].id = data.id;
					/*console.debug(
            "[" +
              this._uid +
              "] RowTemplateNV.updateTemplate. $emit('updateTemplate')"
          );*/
					//this.updateChildsTemplates(data.subtemplates);
					this.$emit("updateTemplate");
				} else {
					return this.showApiRequestErrorAlert(err);
				}

				await this.previewTemplate(null, "ButtonEye", "false");

				this.isLoading = false;

				return data;
			}
		},
		async update_regular_parts () {
			await this.previewTemplate(null, "ButtonEye", "false");

			if (this.previews.length == 0) {
				return;
			}

			this.$refs["ButtonEye"].loading = true;
			//console.log("TEST_UPDATE_REGULAR_PARTS", this.previews);


			/* ******************************************************************************************* */
			/* ******************************************************************************************* */
			/*COMENTED UNITIL MAKE SYNONYMS FEATURE*/

			// const [synonyms_data, synonyms_error] =
      //   await minerva_api.synonyms_tokens(
      //   	this.currentDomain.id,
      //   	this.currentDocument.language,
      //   	this.previews[0].result
      //   );

			// this.template.versions[0].synonyms = synonyms_data;

			// const [grammar_data, grammar_error] = await minerva_api.grammar(
			// 	this.currentDomain.id,
			// 	this.currentDocument.language,
			// 	this.previews[0].result
			// );

			// this.template.versions[0].typos = grammar_data;

			// const [plural_data, plural_error] = await minerva_api.plural(
			// 	this.currentDomain.id,
			// 	this.currentDocument.language,
			// 	this.previews[0].result,
			// 	this.previews[0].data_used
			// );

			// //console.log("DEBUG_MINERVA_plural", plural_data, plural_error);
			// this.template.versions[0].plural = Array.isArray(plural_data)
			// 	? plural_data.flat()
			// 	: [];

			// this.corrections = {
			// 	typos: this.template.versions[0].typos,
			// 	synonyms: this.template.versions[0].synonyms,
			// 	plural: this.template.versions[0].plural
			// };
			/* ******************************************************************************************* */
			/* ******************************************************************************************* */

			this.$refs["ButtonEye"].loading = false;
			this.regular_parts_updated = true;
		},
		setCurrentVersion (template) {
			template.versions[0].current_version = true;
			this.template.versions[0].text_for_editor =
        template.versions[0].text_for_editor;

			this.updateTemplate(template.versions[0]);
			this.$modal.hide(this.modalHistoryName);
		},
		onDuplicateTemplate (tmpl) {
			console.debug("[" + this._uid + "] RowTemplateNV.onDuplicateTemplate: ");
			this.$emit("duplicateTemplate", tmpl);
		},
		onAddNewTemplate (tmplateType) {
			/*console.debug(
        "[" + this._uid + "] RowTemplateNV.addNewTemplate.value: " + tmplateType
      );*/
			this.$emit("addNewTemplate", tmplateType);
		},
		onDeleteTemplate (tmpl) {
			console.debug("[" + this._uid + "] RowTemplateNV.onDeleteTemplate: ");
			this.$emit("deleteTemplate", tmpl);
		},
		async onclickCorrections (
			word,
			corrected,
			plural_list,
			token_idx,
			isTypo,
			isSynonym,
			isPlural,
			offset
		) {
			this.$refs.tooltip.getCorrections(
				word,
				corrected,
				plural_list,
				token_idx,
				isTypo,
				isSynonym,
				isPlural,
				offset
			);
		},
		async showSynonyms (word) {
			this.$refs.tooltip.getSynonyms(word);
		},
		async showTypos (word, corrected) {
			this.$refs.tooltip.getTypos(word, corrected);
		},
		async showPlural (word, token_idx) {
			//console.log("showPlural from RowTemplateNV");
			this.$refs.tooltip.getPlural(
				word,
				this.template.versions[0].plural,
				token_idx
			);
		},
		onNotifyValueChange (value) {
			console.debug(
				"[" +
          this._uid +
          "] RowTemplateNV.onNotifyValueChange.value: " +
          value
			);
			console.log("RowTemplateNV onNotifyValueChange", value);
			if (typeof value == "object") {
				this.template.versions[0].text_for_editor = value.target.value;
			} else {
				this.template.versions[0].text_for_editor = value;
			}
			// this.template.versions[0].text_for_editor = this.template.versions[0].text_for_editor.replace(/·/g, " ");
			this.updateTemplate(this.template.versions[0]);
			this.template_status = "not_completed";
		},
		confirmDeleteNarraFromTemplate ($event, template, narra_version) {
			this.showDeleteModal(
				[template, narra_version],
				this.deleteNarraFromTemplateFromTemplate,
				this.deleteNarraFromTemplateFromTemplateCancel
			);
		},
		async deleteNarraFromTemplateFromTemplate (data_list) {
			this.isLoading = true;

			let template = data_list[0];
			let narra_version = data_list[1];

			const [data, err] = await narras_templates_api.remove(
				narra_version.narra_tree_node_id,
				template.id
			);
			if (err) {
				console.log("Delete ", err);
				return this.showApiRequestErrorAlert(err);
			}
			template.narra_tree_nodes_version =
        template.narra_tree_nodes_version.filter(function (obj) {
        	return obj.narra_tree_node_id !== narra_version.narra_tree_node_id;
        });

			//recorremos las rows hasta encontrar el ID del narra y le quitamos el template
			removeTemplateFromNarrasRows(template, narra_version, this.rows);

			this.isLoading = false;
		},
		deleteNarraFromTemplateCancel (data) {
			this.isLoading = false;
		},
		async openPopupParaphrasing () {
			console.info("[" + this._uid + "] openPopupParaphrasing: ");

			this.isLoading = true;
			const [data, err] = await this.make_preview_request(false);
			this.isLoading = false;
			if (!err) {
				//guardamos la respuesta en Vuex para usar en el componente de VariationsSelector
				this.$store.commit("SET_PREVIEW_PARAPHRASING", data);
				this.$modal.show(this.modalParaphrasingName);
			}
		},
		async openPopupVariations (selectedText) {
			if (this.document_data_selected != 0) {
				//hacemos preview de la plantilla para obtener un texto válido para pasar a los modelos de IA
				const [data, err] = await this.make_preview_request(false);
				if (!err) {
					//guardamos la respuesta en Vuex para usar en el componente de VariationsSelector
					this.$store.commit("SET_PREVIEW_VARIATIONS", data);
					this.$modal.show(this.modalSelecionTextName);
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
		},
		createTempByText (selectedText) {
			//this.$emit("createTempByText", this.template, selectedText);
			const version = {
				type: "",
				narras: "",
				weigth: 0,
				text: selectedText,
				template_id: this.template.id,
				active: true
			};
			this.typeTempByText = "";
			this.associateDefaultByText = "true";
			this.newTemplateByType = { versions: [version] };
			this.$refs["modalGetTypeForNewVersion"].show();
		},
		expandAllCollapsed () {
			this.$refs["text" + this.template.id].setCollapsed(false);
		},
		contractAllCollapsed () {
			this.$refs["text" + this.template.id].setCollapsed(true);
		},
		togglePlainView () {
			this.plainView = !this.plainView;
		},
		async translate ($event) {
			this.translates = [];

			await this.previewTemplate($event, "ButtonEye", "false");
			console.log("preview translate ", this.previews);
			for (let i = 0; i < this.previews.length; i++) {
				const [data, err] = await translate_api.translate(
					this.previews[0].result,
					this.currentDocument.language
				);
				console.log("translate", data, err);
				this.translates.push(data);
			}
		},
		async createDefaultScenarioIfNotExists (domain_id) {
			let categories = this.$store.getters.getScenarios;
			const default_scenario = categories.filter(function (scenario) {
				return scenario.name == "DEFAULT";
			});

			console.log("default_scenario", default_scenario);

			if (default_scenario.length == 0) {
				//se crea si no existe
				let cat = {
					name: "DEFAULT",
					domain_id: domain_id
				};

				let [category, err] = await scenario_categories_api.createCategory(cat);
				console.log(category, err);
				console.log("category.id", category.id);
				if (!err) {
					const scenario = {
						active: true,
						domain_id: domain_id,
						scenario_category: category.id,
						versions: [
							{
								name: "DEFAULT",
								narra: "",
								description: "DEFAULT",
								active: true,
								current_version: true
							}
						]
					};

					const [data_scenario, err_scenario] =
            await scenario_api.createScenario(scenario);
					console.log(data_scenario, err_scenario);
					if (!err_scenario) {
						category.scenarios = [];
						category.scenarios.push(data_scenario);

						categories.push(category);
						console.log("categories", categories);
						this.$store.commit("SET_SCENARIOS", categories);

						return category;
					}
				}
			} else {
				return default_scenario[0];
			}
		},
		async createRootNarraForTemplateIfNotExists (template_name) {
			let root_narras = this.$store.getters.getRootNarras;

			const narras = root_narras.nodes.filter(function (node) {
				return node.versions[0].name == template_name;
			});

			if (narras.length == 0) {
				let root_narra = await this.createNarraTreeNode(template_name);
				let row_narras = this.$store.getters.getRowsNarras;

				console.log("row_narras", row_narras);
				row_narras[root_narra.id] = [];
				this.$store.commit("SET_ROWS_NARRAS", row_narras);

				return root_narra;
			} else {
				return narras[0];
			}
		},
		async createChildNarraForDefaultScenarioIfNotExists (
			root_narra_tree,
			scenario_default
		) {
			let row_narras = this.$store.getters.getRowsNarras;

			let root = row_narras[root_narra_tree.id];

			if (root.length > 0) {
				for (let i = 0; i < root.length; i++) {
					if (root[i].name == "DEFAULT") {
						return root[i].data_node;
					}
				}
			}

			//Hay que crear el nodo del árbol para default
			console.log("Add node");

			let id_tree_node = root_narra_tree.id;
			let data_node_tree;
			let data, err;

			let node = {
				domain_id: this.currentDomain.id,
				active: true
			};

			console.log("New node");

			[data, err] = await tree_nodes_api.create(node);
			if (!err) {
				data_node_tree = data;
				id_tree_node = data_node_tree.id;

				let version = {
					narra_tree_node_id: id_tree_node,
					parent_id: root_narra_tree.id,
					name: "DEFAULT",
					scenario_id: scenario_default.scenarios[0].id,
					description: "",
					current_version: true,
					active: true
				};

				console.log(version);

				[data, err] = await tree_nodes_versions_api.create(version);

				if (!err) {
					data_node_tree.versions = [data];

					let tree_row = {
						_children: [],
						narras: null,
						scenario_id: version.scenario_id,
						name: "DEFAULT",
						data_node: data_node_tree,
						_showChildren: true
					};

					root.push(tree_row);
					this.$store.commit("SET_ROWS_NARRAS", row_narras);

					return data_node_tree;
				}
			}

			return null;
		},
		async createTempByTextWithType () {
			//this.addRow(template.order, newTemplate);
			this.$refs["modalGetTypeForNewVersion"].hide();

			if (this.associateDefaultByText == "true") {
				const scenario_default = await this.createDefaultScenarioIfNotExists(
					this.currentDomain.id
				);
				console.log("scenario_default", scenario_default);

				const root_narra_tree =
          await this.createRootNarraForTemplateIfNotExists(this.typeTempByText);
				console.log("root_narra_tree", root_narra_tree);

				const child_narra_tree =
          await this.createChildNarraForDefaultScenarioIfNotExists(
          	root_narra_tree,
          	scenario_default
          );
				console.log("child_narra_tree", child_narra_tree);

				this.loadNarras(this.currentDomain.id, this.currentDocument.id);

				this.newTemplateByType.narra_tree_nodes_version =
          child_narra_tree.versions;
			}

			this.newTemplateByType.versions[0].type = this.typeTempByText;

			const text = "[" + this.typeTempByText + "]";
			console.error(
				"createTempByTextWithType",
				text,
				this.selectionWordText,
				this.selectionObject
			);

			const r = this.selectionObject.range;
			r.deleteContents();
			let node = r.endContainer;

			if (node.data) {
				const cut = r.endOffset;
				node.data = node.data.slice(0, cut) + text + node.data.slice(cut);
				r.setEnd(node, cut + text.length);
			} else {
				try {
					let newNode = document.createElement("span");
					newNode.innerHTML = text;
					r.insertNode(newNode);
				} catch (e) {
					this.showErrorAlert("Error: " + e);
				}
			}

			r.collapse(false);
			const selection = window.getSelection();
			if (selection) {
				selection.removeAllRanges();
				selection.addRange(r);
			}

			let innerText = node.innerText;
			if (node.className != "template-cell-text-input p-1") {
				while (node.parentNode.className != "template-cell-text-input p-1") {
					node = node.parentNode;
				}
				innerText = node.parentNode.innerText;
			}

			this.$refs["text" + this.template.id].internalValue = innerText.replace(
				/\|/g,
				"|"
			);

			this.updateVersion(this.selectionObject, 3, this.template.versions[0]);

			//this.newTemplateByType;
			this.$emit("createTempByText", this.newTemplateByType);

			//metemos un retardo para procesar el resaltado para esperar que se produzca la openración en el back y en el store
			self = this;
			setTimeout(function () {
				if (this.autoHighlights) { self.$refs["text" + self.template.id].processHighlights(); }
			}, 500);

			this.typeTempByText = "";
		},
		enableEditing () {
			/*console.log("enableEditing");
			this.editMode = true;

			let self = this;
			setTimeout(function () {
				console.log("focus");
				self.$refs["text" + self.template.id].focus();
			}, 100);*/
		},
		refreshContent (new_content) {
			console.log("refreshContent:" + new_content);
			this.template.versions[0].text_for_editor = new_content;
		},

		textTemplateGetFocus ($event, template_obj) {
			console.log("textTemplateGetFocus");
			console.log("textTemplateGetFocus", template_obj.versions[0]);
			//template_obj.versions[0].text_for_editor = template_obj.versions[0].original_text;
		},
		textTemplateLostFocus ($event, template_obj) {
			//console.debug("[" + this._uid + "] RowTemplateNV.textTemplateLostFocus");

			const template = JSON.parse(JSON.stringify(template_obj));

			let panelRef = this.$refs["at" + template.id];
			if (Array.isArray(panelRef)) {
				panelRef = panelRef[0];
			}
			panelRef.closePanel();

			let self = this;
			clearTimeout(this.timeout_preview);
			self.updateVersion($event, 3, self.template.versions[0]);

			if (self.document_data_selected != 0 && self.active_preview) {
				self.previewTemplate($event, "ButtonEye", "false");
			}
		},

		insertParaphrasing (texts) {
			this.$modal.hide(this.modalParaphrasingName);
			console.info(texts);

			let texts_ids = [];
			let id = 1;
			[...texts].forEach((text) => {
				const obj = {
					id: id,
					text: text
				};
				texts_ids.push(obj);
				id++;
			});

			this.textEntities = texts_ids;
			this.showEntities(true);
		},

		insertVariations (text) {
			this.$modal.hide(this.modalSelecionTextName);

			const r = this.selectionObject.range;
			r.deleteContents();
			const node = r.endContainer;

			const cut = r.endOffset;
			node.data = node.data.slice(0, cut) + text + node.data.slice(cut);
			r.setEnd(node, cut + text.length);

			r.collapse(false);
			const selection = window.getSelection();
			if (selection) {
				selection.removeAllRanges();
				selection.addRange(r);
			}

			this.$refs["text" + this.template.id].internalValue =
        node.parentNode.innerText.replace(/\|/g, "|");

			self = this;
			setTimeout(function () {
				//if (this.autoHighlights)
				//console.log("insertVariations");
				self.$refs["text" + self.template.id].processHighlights();
			}, 500);

			this.updateVersion(
				this.selectionObject,
				3,
				this.template_versions_variation
			);
		},
		handleTextKeyDown ($event, template) {
			let template_version = template.versions[0];
			//if ($event.keyCode >= 37 && $event.keyCode <= 40) {
			//// son las flechas. Puede que se esté seleccionando texto
			//const selection = window.getSelection();
			//let r;
			//if (selection && selection.rangeCount > 0) {
			//this.template_versions_variation = template_version;
			//const posEL = getPosEL($event.target);

			//const r = selection.getRangeAt(0);
			//const range = r.cloneRange();
			//range.setStart(range.endContainer, range.endOffset);
			//range.collapse(true);

			//const rect = range.getClientRects()[0];
			//console.log("RECTS", rect);

			//$event.layerX = rect.left - posEL.x;
			//$event.layerY = rect.top - posEL.y + 100;

			//this.selectionText($event, template_version);
			//}
			//}

			if ($event.keyCode == 32) {
				//si es espacio procesamos los tags html
				/*if (this.autoHighlights)
					this.$refs["text" + this.template.id].processHighlights();*/
			}

			if (window.getSelection().toString().length == 0) {
				this.hideLabelVariations();
			}

			let self = this;
			//clear timeout variable
			/*clearTimeout(this.timeout);
			this.timeout = setTimeout(function () {
				self.$refs["text" + self.template.id].processHighlights();
			}, 1000);*/

			clearTimeout(this.timeout_preview);
			this.timeout_preview = setTimeout(function () {
				//enter this block of code after 1 second
				//handle stuff, call search API etc.
				self.updateVersion($event, 3, self.template.versions[0]);

				if (self.document_data_selected != 0 && self.active_preview) {
					self.previewTemplate($event, "ButtonEye", "false");
				}
			}, 2000);
		},
		async previewTemplate (
			$event,
			button,
			cached = "false",
			times = 1,
			test_mode = "true"
		) {
			if ($event) {
				$event.preventDefault();
				$event.stopPropagation();
			}
			this.$refs[button].loading = true;

			if (this.document_data_selected != 0) {
				const [data, err] = await this.make_preview_request(
					cached,
					times,
					test_mode
				);

				if (!err) {
					if (Array.isArray(data.previews)) {
						[...data.previews].forEach((preview) => {
							if (preview.result_with_sandbox_tags) {
								//buscamos los posibles enlaces a las plantillas que componen la preview
								let regex = RegExp(/\(#(.*?.*?)\)/g);
								let indices = [];
								let match = null;
								while (
									(match = regex.exec(preview.result_with_sandbox_tags)) != null
								) {
									indices.push({
										start: match.index,
										end: match.index + match[0].length - 1,
										template_id: match[1]
									});
								}

								//recorremos las equiquetas encontradas y ponemos enlaces
								let result = "";
								let startingPosition = 0;
								[...indices].forEach((position) => {
									result += preview.result_with_sandbox_tags.substring(
										startingPosition,
										position.start
									);

									const text_tag = preview.result_with_sandbox_tags.substring(
										position.start,
										position.end + 1
									);

									if (position.template_id != 99999) {
										result +=
                      "<a class='' href='#" +
                      position.template_id +
                      "'>" +
                      text_tag +
                      "</a>";
									} else {
										result += text_tag;
									}
									startingPosition = position.end + 1;
								});
								preview.result_with_sandbox_tags = result;
							}
						});

						this.previews = data.previews;
						this.variability = data.variability;
					} else {
						//el json no es valido. Lo ponemos como error en la preview
						this.previews = [{ error: "Document is not a valid JSON" }];
					}
				} else if (!cached) {
					console.log(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
			this.$refs[button].loading = false;
		},
		async make_preview_request (cached, times = 1, test_mode = "true") {
			return await preview_api.getPreview(
				this.currentDomain.id,
				this.currentDocument.id,
				this.document_data_selected,
				cached,
				times,
				this.template.id,
				test_mode
			);
		},
		deleteTemplate ($event, templateDelete) {
			this.showDeleteModal(
				this.template,
				this.delete_template_OK,
				this.delete_template_cancel
			);
		},
		async delete_template_OK (template) {
			this.$refs["ButtonDelete"].loading = true;
			// const [data, err] = await templates_api.patch(this.template.id, {
			// 	active: false
			// });
			const [data, err] = await templates_api.delete(this.template.id, {
				active: false
			});

			if (!err) {
				this.$emit("templateDeleted", this.template);
			} else {
				this.showErrorAlert("Error: " + err.data.error);
			}
			this.$refs["ButtonDelete"].loading = false;
		},
		delete_template_cancel (template) {
			console.log("dont delete");
		},

		selectionText ($event, template_version) {
			const selection = window.getSelection();
			const selectionText = selection.toString();
			if (selectionText.length > 0) {
				this.template_versions_variation = template_version;

				if ($event.target.nodeName == "SPAN") {
					console.error("ES SPAN");
					const textStart =
            $event.currentTarget.innerText.indexOf(selectionText);
					const textStop = textStart + selectionText.length;
					this.selectionObject = {
						target: $event.target,
						selectionText: selectionText,
						textStart: textStart,
						textStop: textStop,
						textComplete: $event.currentTarget.innerText,
						range: selection.getRangeAt(0).cloneRange()
					};
				} else {
					this.selectionObject = {
						target: $event.target,
						selectionText: selectionText,
						textStart: selection.anchorOffset,
						textStop: selection.focusOffset,
						textComplete: selection.anchorNode.textContent,
						range: selection.getRangeAt(0).cloneRange()
					};
				}

				this.$store.commit("SET_SELECTION_ON_TEMPLATE", this.selectionObject);
				//this.$bvModal.show("modalSelecionText");
				//this.$modal.show("modalSelecionText");
				this.$refs["Highlightable" + template_version.template_id].onMouseup(
					$event
				);
			} else if ($event.target.tagName == "A") {
				let hash = $event.target.hash;

				//obtenemos el type de la nueva template. this
				//el hash en este caso es algo así
				//#new_[type]
				const type = hash.substring("#new_[".length, hash.length - 1);

				if (isNaN(hash.substring(1))) {
					if (this.category_id == "2") {
						//hay que crear el template padre y el árbol de la plantilla

						this.confirmCreateTreeNoExits(type);
					} else if (this.category_id == "1") {
						/*this.$bvModal
							.msgBoxConfirm(
								"The _subtemplate does not exist. Create a new one?",
								{
									title: "Create",
									size: "sm",
									buttonSize: "sm",
									okVariant: "danger",
									okTitle: "YES",
									cancelTitle: "NO",
									footerClass: "p-2",
									hideHeaderClose: true,
									centered: true
								}
							)
							.then(value => {
								const tmpl = {
									versions: [
										{
											type: type,
											narras: "",
											weigth: 0,
											text: ""
										}
									]
								};
								this.$emit("newTemplateByType", -1, tmpl, 1);
								/*let self = this;
								setTimeout(function() {
									self.updateVersion($event, 3, self.template.versions[0]);
									self.$store.commit("SET_NAVIGATE_ROOT", self.template.id);
								}, 200);*/
						//});
					}
				} else {
					//hay que comprobar si es una subplantilla
					//en ese caso hay que desplegar la plantilla padre que la contenga para que se
					const template_id = hash.substring(1);

					const template_filter = this.$store.getters.getTemplates.filter(
						function (template) {
							return template.id == template_id;
						}
					);

					//buscamos la plantilla pladre para expandirla y que se muestre el subtemplate
					if (template_filter.length > 0) {
						console.error("SET_NAVIGATE_TEMPLATE2", hash.substring(1));
						this.$store.commit("SET_NAVIGATE_TEMPLATE", template_filter[0]);
					}
				}
			}
		},
		confirmCreateTreeNoExits (type) {
			//originalmente esta parte creaba el template del mismo nombre
			//pero hemos acordado que sea un tree lo que se cree
			//Lo templates se generaran posteriormente a partir de los nodos de los árboles

			this.$bvModal
				.msgBoxConfirm("The tree does not exist. Create a new one?", {
					title: "Create",
					size: "sm",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "YES",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: true,
					centered: true
				})
				.then((value) => {
					this.isLoading = true;
					if (value) {
						let node = {
							domain_id: this.currentDomain.id,
							active: true
						};

						tree_nodes_api.create(node).then((value_return) => {
							const [data, err] = value_return;

							console.error("tree_nodes_api.create", data);

							if (!err) {
								let data_node_tree = data;

								let version = {
									narra_tree_node_id: data_node_tree.id,
									parent_id: null,
									name: type,
									narras: "",
									description: "",
									current_version: true,
									active: true
								};

								tree_nodes_versions_api.create(version).then((value_return) => {
									const [data, err] = value_return;
									console.error("tree_nodes_versions_api.create", data);
									if (!err) {
										data_node_tree.versions = [data];

										let root_narras = this.$store.getters.getRootNarras;
										root_narras.nodes.push(data_node_tree);

										this.$bvToast.toast("Tree created", {
											title: "Success ",
											variant: "success",
											solid: true,
											"auto-hide-delay": "2000",
											appendToast: true
										});
									} else {
										this.showErrorAlert("Error: " + err.data.error);
									}

									this.isLoading = false;
								});
							} else {
								this.showErrorAlert("Error: " + err.data.error);
								this.isLoading = false;
							}
						});
					} else {
						console.log("responde NO");
					}
				})
				.catch((err) => {
					//An error occurred
					console.error("ERROR", err);
					this.isLoading = false;
				});
		},
		openSelectNarra ($event, template_versions) {
			this.$bvModal.show(this.narraSelectorModalName);
		},

		async selectNarra (tree_node) {
			this.isLoading = true;

			this.$bvModal.hide(this.narraSelectorModalName);
			const [data, err] = await narras_templates_api.create(
				tree_node.data_node.id,
				{
					template_id: this.template.id
				}
			);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}
			this.template.narra_tree_nodes_version.push(
				tree_node.data_node.versions[0]
			);

			if (tree_node.template) {
				tree_node.template =
          tree_node.template + ", " + this.template.versions[0].type;
			} else tree_node.template = this.template.versions[0].type;

			this.isLoading = false;
		},
		getFocus () {
			this.$refs.lblType.focus();
		},
		isComposition (type) {
			return type[0] != "_" && type[0] != "*" && this.category_id == 1;
		},
		isComponent (type) {
			return type[0] == "*";
		},
		getClassForTemplate (template) {
			let result = "row template_full_row";
			if (this.isComposition(template.versions[0].type)) {
				result += " template_border_root_template";
			} else if (this.isComponent(template.versions[0].type)) {
				result += " template_border_component_template";
			} else {
				result += " template_border_sub_template";
			}

			if (
				this.template.versions[0].type == "" &&
        this.template.versions[0].text_for_editor == ""
			) { result += " empty-template"; }
			if (this.navigate_here) result += " blink-template";

			return result;
		},
		showSimilarity ($event) {
			this.$modal.show(this.modalSimilarityName);
		},
		showEntities (insert) {
			this.entitiesNewTemplates = insert;
			this.$modal.show(this.modalEntitiesName);
		},
		showHistory ($event) {
			this.$modal.show(this.modalHistoryName);
		},
		insertSimilarity (texts) {
			this.$modal.hide(this.modalSimilarityName);
			let texts_ids = [];
			let id = 1;
			[...texts].forEach((text) => {
				const obj = {
					id: id,
					text: text
				};
				texts_ids.push(obj);
				id++;
			});

			this.textEntities = texts_ids;
			this.showEntities(true);
		},
		duplicateThisTemplate () {
			console.log("Duplicate this template");

			let tmpl = JSON.parse(JSON.stringify(this.template));
			tmpl.versions[0].text_for_editor = text;

			//console.error("New template", tmpl);
			this.$emit("duplicateTemplate", tmpl);
		},

		async insertEntities (texts) {
			this.$modal.hide(this.modalEntitiesName);

			for (let i = 0; i < texts.length; i++) {
				const text = texts[i];
				let tmpl = JSON.parse(JSON.stringify(this.template));
				tmpl.versions[0].text_for_editor = text;

				console.error("New template", tmpl);
				await this.$emit("duplicateTemplate", tmpl);
			}
		},
		insertEntitiesTemplate (texts) {
			this.$modal.hide(this.modalEntitiesName);

			this.template.versions[0].text_for_editor = texts[0];
			this.updateTemplate(this.template.versions[0]);
		},
		entitiesExtractor () {
			let texts_ids = [];
			let id = 1;
			const obj = {
				id: 1,
				text: this.template.versions[0].text_for_editor
				// text: this.template.versions[0].text_for_editor.replace(/·/g, " ")
			};
			texts_ids.push(obj);

			this.textEntities = texts_ids;
			this.showEntities(false);
		},

		updateChildsTemplates (subtemplates) {
			//return;
			if (subtemplates) {
				this.template_root.subtemplates_objs = [];

				let templates_remove = [];
				this.template_root.subtemplates = subtemplates;

				this.template_root.subtemplates_objs = this.addSubtemplatesToTemplate(
					this.template_root,
					this.all_templates,
					templates_remove
				);
				this.template_root.subtemplates_objs_render =
          this.template_root.subtemplates_objs;

				this.findTemplatesToRemove(
					this.templates,
					this.template_root,
					templates_remove
				);

				//console.error("Templates a eliminar", templates_remove);
				this.templates = this.filteTemplatesToRemove(
					this.templates,
					templates_remove
				);
			}
		},
		onIncludeSynonyms (word, text) {
			this.template.versions[0].synonyms = null;
			this.template.versions[0].typos = null;
			this.template.versions[0].plural = null;

			this.template.versions[0].text_for_editor =
        this.template.versions[0].text_for_editor.replace(word, text);
			this.updateTemplate(this.template.versions[0]);
		},
		onChangeTypo (word, corrected) {
			this.template.versions[0].synonyms = null;
			this.template.versions[0].typos = null;
			this.template.versions[0].plural = null;

			this.template.versions[0].text_for_editor =
        this.template.versions[0].text_for_editor.replace(word, corrected);
			this.updateTemplate(this.template.versions[0]);
		},
		onChangePlural (word, corrected, token_idx, offset) {
			console.log(
				"onChangePlural_on_RowTemplateNV",
				word,
				corrected,
				token_idx,
				offset
			);
			this.template.versions[0].synonyms = null;
			this.template.versions[0].typos = null;
			this.template.versions[0].plural = null;

			const left_part = this.template.versions[0].text_for_editor.slice(
				0,
				offset
			);
			const right_part = this.template.versions[0].text_for_editor.slice(
				offset + word.length
			);

			console.log(
				"onChangePlural_on_RowTemplateVN",
				`${left_part} ${corrected} ${right_part}`
			);

			//this.template.versions[0].text_for_editor = this.template.versions[0].text_for_editor.replace(word, corrected);
			this.template.versions[0].text_for_editor = `${left_part} ${corrected} ${right_part}`;
			this.updateTemplate(this.template.versions[0]);
		},

		async updateTemplateVersionStatus (status_id, status_name) {
			console.log("updateTemplateVersionStatus");
			const template_version_id = this.template.versions[0].id;

			const [response, error] =
        await template_version_api.update_template_versions_status(
        	template_version_id,
        	status_id
        );

			if (!error) {
				this.template_status = status_name;
				this.template.versions[0].status_id = status_id;
				this.template.versions[0].status = status_name;
			}
		},
		local_mark_read () {
			this.template.messages.forEach((message) => {
				message.readed = true;
			});
		},
		toggle_messages () {
			this.show_messages = !this.show_messages;
			this.mark_read();
			setTimeout(() => {
				this.local_mark_read();
				this.has_unread = false;
			}, 3000);
		},
		async mark_read () {
			const [response, error] = await template_message_api.markRead(
				this.template.id
			);
		},
		async mark_read () {
			const [response, error] = await template_message_api.markRead(
				this.template.id
			);
		},
		completeUsers (index, chunk) {
			console.log(`completeUsers ${index} ${chunk}`);
		}
	}
};

function removeTemplateFromNarrasRows (template, narra_version, rows) {
	//recorremos el data.rows que tiene los narras para quitar el template eliminado y que no salga en la tablabundleRenderer.renderToStream
	for (let i = 0; i < rows.length; i++) {}
}
</script>

<style lang="scss" scoped>
.row-template {
	&__extra-options {
		z-index: 9999 !important;
	}
}

.v--modal-overlay {
  z-index: 1030;
}

.template_full_row {
  /*width: fit-content !important;*/
  min-width: 100%;
  background-color: white;
  /*border:1px solid red;*/
  /*overflow-x: scroll;*/
  /*font-size: 1.2em;*/
  margin-top: 10px;
}

.segment_edit_row {
  /*width: max-content;*/
  /*margin-top: 0.5em;*/
  /*min-width: 100%;
  display:contents;*/
  padding-left: 5px;
  padding-right: 5px;
}

.segment_preview_row {
  width: 100%;
}

.template-col-previewnv {
  /* border-top: 1px solid #ccc; */
  width: 100%;
}
.template-row-preview-nv {
  margin-top: 0.5em;
  padding-left: 0.25em;
  margin-bottom: 0.5em;
}

.component_id {
  position: relative;
  /*float: right;*/
  font-size: 0.7em;
  font-weight: bold;
}

.segment_plain_edit_row {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 0.5em;
  padding-left: 7px;
  border-bottom: 1px dotted #bbb;
  margin-left: 0px;
}
.collapseControl {
  cursor: pointer;
}

.template-header {
  /*min-width: 65em;*/
}
.template-row-header {
  /*padding-bottom: 2em;*/
}
.menuToggle {
  position: absolute;
  z-index: 9999;
  margin-top: -8px;
  margin-left: -7px;
  cursor: pointer;
}

.menuToggle a {
  color: #157ef3 !important;
  font-size: 0.7em;
}

.previewIcon {
  color: #157ef3 !important;
  font-size: 0.7em;
  position: absolute;
  z-index: 5;
  margin-top: -3px;
  margin-left: 1px;
}

.previewErrorsIcon {
  color: #ee0000 !important;
  font-size: 0.7em;
  position: absolute;
  z-index: 5;
  margin-top: -3px;
  margin-left: 0px;
}

.preview-col {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.template-id-number {
  z-index: 4;
  margin-right: 1rem !important;
}

.template-id-number ul {
  z-index: 5;
}

</style>
