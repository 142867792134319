var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "light" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Entities selector"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-div-container" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "ml-1" }, [
                _c(
                  "div",
                  { staticClass: "card entities-card-columns variations-card" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Texts")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "table",
                        { staticClass: "table table-hover table-bordered" },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.texts, function (text) {
                              return _c("tr", { key: text.id }, [
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.selectText(text.id)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(text.text))]
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "mr-1" }, [
                _c(
                  "div",
                  { staticClass: "card entities-card-columns variations-card" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Entities")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("EntitiesFormater", {
                          attrs: { text_entities: _vm.text_entities_selected },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right mt-2 mr-1" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "info" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.includeEntities.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Create segment")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }