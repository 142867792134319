var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show && _vm.regular_parts_updated()
    ? _c(
        "div",
        { staticClass: "container template_full_row container-border" },
        [
          _vm.showSynonyms
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "row mt-1 ml-1 title" }, [
                    _vm._v("* SYNONYMS"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3 ml-2 word" }, [
                    _vm._v("\n          " + _vm._s(_vm.word) + "\n        "),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 pr-0" },
                  [
                    _vm.loadingSynonyms
                      ? [
                          _c("b-spinner", {
                            staticClass: "mt-4",
                            attrs: { variant: "primary" },
                          }),
                        ]
                      : _vm.synonyms_list.length > 0
                      ? _c("div", { staticClass: "card card-column" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _vm._v("Select the synonyms to use"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass:
                                      "overflow-auto synonyms-check-group-ml",
                                  },
                                  _vm._l(_vm.synonyms_list, function (option) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: option.value,
                                        attrs: {
                                          value: option.value,
                                          stacked: "",
                                        },
                                        model: {
                                          value: _vm.synonyms_check,
                                          callback: function ($$v) {
                                            _vm.synonyms_check = $$v
                                          },
                                          expression: "synonyms_check",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(option.text) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "margin-right": ".5em" },
                                      attrs: { variant: "info" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.includeSelectedSynonyms()
                                        },
                                      },
                                    },
                                    [_vm._v("Include")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "danger" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancel()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showPlural
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "row mt-1 ml-1 title" }, [
                    _vm._v("* SINGULAR/PLURAL"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3 ml-2 word" }, [
                    _vm._v("\n          " + _vm._s(_vm.word) + "\n        "),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 pr-0" },
                  [
                    _vm.loadingPlural
                      ? [
                          _c("b-spinner", {
                            staticClass: "mt-4",
                            attrs: { variant: "primary" },
                          }),
                        ]
                      : _c("div", { staticClass: "card card-column" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _vm._v("Press Include to transform into a plural"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass:
                                      "overflow-auto synonyms-check-group-ml",
                                  },
                                  _vm._l(_vm.partsSegment, function (segment) {
                                    return _c(
                                      "b-form-radio",
                                      {
                                        key: segment.id,
                                        attrs: {
                                          value: segment.text,
                                          stacked: "",
                                        },
                                        model: {
                                          value: _vm.plural_check,
                                          callback: function ($$v) {
                                            _vm.plural_check = $$v
                                          },
                                          expression: "plural_check",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(segment.text) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("br"),
                                _c("br"),
                                _c("br"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "margin-right": ".5em" },
                                      attrs: { variant: "info" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.includeSelectedPlural()
                                        },
                                      },
                                    },
                                    [_vm._v("Include")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "danger" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancel()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                  ],
                  2
                ),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "row mt-1 ml-1 title" }, [
                    _vm._v("* SINGULAR/PLURAL"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3 ml-2 word" }, [
                    _vm._v("\n          " + _vm._s(_vm.word) + "\n        "),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 pr-0" }, [
                  _c("div", { staticClass: "card card-column" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _vm._v("Press Accept to generate a plural expression"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "input-group flex-nowrap" }, [
                          _c(
                            "span",
                            {
                              staticClass: "input-group-text col-1",
                              attrs: { id: "label-singular" },
                            },
                            [_vm._v("Singular")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.manual_singular,
                                expression: "manual_singular",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "Singular: I.E: goal",
                              "aria-label": "singular",
                              "aria-describedby": "label-singular",
                            },
                            domProps: { value: _vm.manual_singular },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.manual_singular = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.empty_singular
                          ? _c("div", { staticClass: "no-valid col-12" }, [
                              _vm._v("Please, enter the singular alternative"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-group flex-nowrap mt-2" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "input-group-text col-1",
                                attrs: { id: "label-plural" },
                              },
                              [_vm._v("Plural")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.manual_plural,
                                  expression: "manual_plural",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Singular: I.E: goals",
                                "aria-label": "singular",
                                "aria-describedby": "label-plural",
                              },
                              domProps: { value: _vm.manual_plural },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.manual_plural = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.empty_plural
                          ? _c("div", { staticClass: "no-valid col-12" }, [
                              _vm._v("Please, enter the plural alternative"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-100 mt-4 ml-4" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\tSelect associated data:\n\t\t\t\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.empty_plural_expression
                              ? _c(
                                  "div",
                                  { staticClass: "no-valid col-12 w-100 ml-2" },
                                  [_vm._v("Please, select associated data")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass:
                                  "overflow-auto synonyms-check-group-ml mt-2",
                              },
                              _vm._l(_vm.partsSegment, function (segment) {
                                return _c(
                                  "b-form-radio",
                                  {
                                    key: segment.id,
                                    attrs: { value: segment.text, stacked: "" },
                                    model: {
                                      value: _vm.manual_associated_data,
                                      callback: function ($$v) {
                                        _vm.manual_associated_data = $$v
                                      },
                                      expression: "manual_associated_data",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(segment.text) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { "margin-right": ".5em" },
                                attrs: { variant: "info" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.includeSelectedManualPlural()
                                  },
                                },
                              },
                              [_vm._v("Accept")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.cancel()
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
          _vm._v(" "),
          _vm.showTypos
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "row mt-1 ml-1 title" }, [
                    _vm._v("* TYPOS"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3 ml-2 word" }, [
                    _vm._v("\n          " + _vm._s(_vm.word) + "\n        "),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 pr-0" }, [
                  _c("div", { staticClass: "card card-column" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _vm._v('Press "Change" to correct typo'),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _c("p", [_vm._v(_vm._s(_vm.typo_corrected))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { "margin-right": ".5em" },
                                attrs: { variant: "info" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.changeTypo()
                                  },
                                },
                              },
                              [_vm._v("Change")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.cancel()
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }