import domains_api from "../api/domain.js";

export const nschemaMixins = {

	methods: {
		putNSchemaAutocomplete (nschema, extra_functions) {
			//console.log("nschema ", nschema);

			let objNSchema = JSON.parse(
				nschema.replace(/False/g, "false").replace(/True/g, "true") //Los boleanos vienen en mayusculas. Cosas de python. Hacemos la sutitución antes de pasarlo a Json
			);

			let entities = ["r"];
			let relations = {};
			let properties = {};

			properties["r"] = [];

			//console.log(objNSchema["@graph"]);
			for (let i = 0; i < objNSchema["@graph"].length; i++) {
				let graphItem = objNSchema["@graph"][i];
				//console.log(graphItem);

				let id = graphItem["@id"].split("#");
				//console.log(id[0]);

				if (id[0] == "nrt:class") {
					let entity = id[1];
					entities.push(entity);
					properties["r"].push(entity);

					properties[entity] = [];
					for (let j = 0; j < graphItem.properties.length; j++) {
						properties[entity].push(graphItem.properties[j].name);
					}
					if (extra_functions) {
						properties[entity].push("__sort__");
						properties[entity].push("__len__");
					}
				} else if (id[0] == "nrt:relationship") {
					let from = graphItem.from.split("#")[1];
					let to = graphItem.to.split("#")[1];

					if (!relations[from]) {
						relations[from] = [];
					}
					relations[from].push(id[1] + "->" + to);
				}
			}

			return {
				"entities": entities,
				"relations": relations,
				"properties": properties
			};
		},
		async loadNSchema () {
			let nschema = {};
			let self = this;
			domains_api.getNSchema(this.domainId).then(function ([data, err]) {
				if (!err) {
					//console.log(data);
					nschema = data.nschema
						.replace(/\'/g, "\"")
						.replace(/True/g, "\"True\"")
						.replace(/False/g, "\"False\"");

					self.$store.commit("SET_NSCHEMA", nschema);

					self.$store.commit(
						"SET_AUTOCOMPLETE_NSCHEMA_MEMBERS",
						self.putNSchemaAutocomplete(nschema, true)
					);
				} else {
					self.$store.commit("SET_NSCHEMA", {});
					self.$store.commit("SET_AUTOCOMPLETE_NSCHEMA_MEMBERS", {
						"entities": []
					});
				}
			});
		}
	}
};
