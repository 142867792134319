<template>
	<div id="tree-as-table">
		<table
			class="vue-ads-w-full vue-ads-font-sans vue-ads-border vue-ads-w-full"
			width="100%"
		>
			<thead class>
				<tr class="vue-ads-border-b">
					<th scope="col" width="20%">Name</th>
					<!--th v-if="!only_name_column" scope="col" width="50%">Narras</th-->
					<th v-if="!only_name_column" scope="col" width="65%">
						{{ this.$templatesName }}
					</th>
				</tr>
			</thead>
			<tbody>
				<TableTreeRow
					v-for="node in tree_array"
					:node="node"
					:key="node.id"
					:select_name="select_name"
					:only_name_column="only_name_column"
					@selectNarra="selectNarra"
				/>
			</tbody>
		</table>
	</div>
</template>

<script>
import TableTreeRow from "./TableTreeRow";
export default {
	name: "NrtTreeTable",
	components: { TableTreeRow },
	data () {
		return {};
	},
	props: {
		tree: Array,
		select_name: String,
		searchString: String,
		only_name_column: Boolean
	},
	computed: {
		tree_array () {
			return this.normalizedTree(this.tree, 0);
		},
		scenarios () {
			const categories = this.$store.getters.getScenarios;
			let options = [];
			options.push({ value: null, text: "Select a scenario" });
			for (let i = 0; i < categories.length; i++) {
				for (let j = 0; j < categories[i].scenarios.length; j++) {
					const scenario = categories[i].scenarios[j];
					options.push({ value: scenario.id, text: scenario.versions[0].name });
				}
			}

			let options_sorted = options.sort((x, y) => {
				if (x.text == "Select a scenario") return 1;
				if (y.text == "Select a scenario") return 1;

				if (x.text < y.text) return -1;
				if (x.text < y.text) return 1;

				return 0;
			});

			return options_sorted;
		}
	},
	methods: {
		selectNarra (node) {
			//console.log(node);
			this.$emit("selectNarra", node);
		},
		getScenarioByID (scenario_id, name) {
			const scenarios = this.scenarios.filter((s) => {
				return s.value == scenario_id && scenario_id != null;
			});
			if (scenarios.length > 0) {
				return scenarios[0].text;
			}
			return name;
		},
		normalizedTree (nodes, level) {
			let _this = this;
			let array_fragment = [];
			//console.log(level, nodes);
			for (let j = 0; j < nodes.length; j++) {
				let node = nodes[j];

				let name = this.getScenarioByID(node.scenario_id, node.name);

				let normal_element = {
					id: node.data_node.id,
					name: name,
					narras: node.narras,
					template: node.template,
					level: level,
					data: node
				};

				array_fragment.push(normal_element);

				if (node._children && node._children.length > 0) {
					for (let i = 0; i < node._children.length; i++) {
						array_fragment = array_fragment.concat(
							_this.normalizedTree([node._children[i]], level + 1)
						);
					}
				}
			}
			const searchString = this.searchString;
			return array_fragment.filter((v) => {
				//console.log(v.name, v.name.indexOf(searchString));
				return v.name.indexOf(searchString) > -1;
			});
		}
	}
};
</script>
<style scoped></style>
