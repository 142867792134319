var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "table-templates-v52",
      class: _vm.cssClass,
      attrs: { id: "templates_back_ground" },
    },
    [
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar navbar-fixed-top",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      staticClass: "num-templates",
                      attrs: { href: "#", title: "# of templates" },
                    },
                    [_vm._v(_vm._s(_vm.filterTemplates.length))]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { href: "#", title: "Add a new template" },
                      on: {
                        click: function ($event) {
                          return _vm.addRow(-1)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" Add\n          "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { href: "#", title: "Find & replace" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$refs["findReplace"].show()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-search" }),
                      _vm._v(" Find & replace\n          "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        href: "#",
                        title: "Import parent segments from another domain",
                      },
                      on: { click: _vm.showImportModal },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "file-import" },
                      }),
                      _vm._v(" Import\n          "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        href: "#",
                        title: "Force reload all information from Database",
                      },
                      on: { click: _vm.refreshTemplates },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "sync-alt" } }),
                      _vm._v(" Refresh\n          "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.category_id == 1
                    ? _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            href: "#",
                            title: "Expand all templates (might take a while)",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.expandAll()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-expand",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" Expand all\n          "),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.category_id == 1
                    ? _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: { href: "#", title: "Collapse all templates" },
                          on: {
                            click: function ($event) {
                              return _vm.collapseAll()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-compress",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" Collapse all\n          "),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        href: "#",
                        title: "Deploy these templates in production",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("migrate")
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "database" } }),
                      _vm._v(" Copy to production\n          "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.filterSelected.name.length > 0
                    ? _c("span", { staticClass: "badge badge-filter" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.filterSelected.name) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-form",
                    [
                      _c("b-form-select", {
                        staticClass: "input-select-document",
                        attrs: {
                          id: "engine",
                          options: _vm.document_data,
                          size: "sm",
                        },
                        model: {
                          value: _vm.document_data_selected,
                          callback: function ($$v) {
                            _vm.document_data_selected = $$v
                          },
                          expression: "document_data_selected",
                        },
                      }),
                      _vm._v(" \n            "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            title: "Run ALL templates with selected data file",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.previewAll($event)
                            },
                          },
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "play" } })],
                        1
                      ),
                      _vm._v(" \n            "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "warning",
                            title: "Manage data files",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.dataModal($event)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "paperclip" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("   \n            "),
                      _c("b-form-input", {
                        ref: "inputSearch",
                        staticClass: "mr-sm-2 input-search-template",
                        attrs: { size: "sm", placeholder: "Search" },
                        on: {
                          keyup: function ($event) {
                            return _vm.makeFilterTemplates($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "switch-autocomplete",
                          attrs: {
                            checked: "",
                            name: "switch_autocomplete",
                            switch: "",
                          },
                          model: {
                            value: _vm.active_autocomplete,
                            callback: function ($$v) {
                              _vm.active_autocomplete = $$v
                            },
                            expression: "active_autocomplete",
                          },
                        },
                        [_vm._v("Autocomplete")]
                      ),
                      _vm._v(" \n            "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "switch-autocomplete",
                          attrs: {
                            checked: "",
                            name: "switch_preview",
                            switch: "",
                          },
                          model: {
                            value: _vm.active_preview,
                            callback: function ($$v) {
                              _vm.active_preview = $$v
                            },
                            expression: "active_preview",
                          },
                        },
                        [_vm._v("Preview")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-nav-item",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.topFunction },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "arrow-up" },
                          }),
                          _vm._v(" Top\n            "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.filterTemplates.length > 0
        ? _c(
            "div",
            { staticClass: "row-templates-container" },
            _vm._l(_vm.filterTemplates, function (template, templateIndex) {
              return _c(
                "div",
                { key: templateIndex, staticClass: "template-root" },
                [
                  _c("RowTemplate", {
                    ref: "RowTemplate" + template.id,
                    refInFor: true,
                    attrs: {
                      template: template,
                      template_root: template,
                      currentDocument: _vm.currentDocument,
                      currentDomain: _vm.currentDomain,
                      document_data_selected: _vm.document_data_selected,
                      category_id: _vm.category_id,
                      active_autocomplete: _vm.active_autocomplete,
                      active_preview: _vm.active_preview,
                      refreshHighlights: _vm.refreshHighlights,
                      collapseID: "collapse-" + template.id,
                      parent:
                        template.subtemplates &&
                        template.subtemplates.length > 0,
                    },
                    on: {
                      templateDeleted: _vm.templateDeleted,
                      duplicateTemplate: _vm.templateDuplicated,
                      createTempByText: _vm.createTempByText,
                      updateTemplate: _vm.updateTemplatesStore,
                      filterByTemplateID: _vm.filterByTemplateID,
                      newTemplateByType: _vm.addRow,
                      clickTemplate: _vm.selectTemplate,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      ref: "collapse-" + template.id,
                      refInFor: true,
                      attrs: { id: "collapse-" + template.id },
                    },
                    _vm._l(
                      template.subtemplates_objs_render,
                      function (subtemplate, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "template-child",
                            style:
                              "margin-left: " + 15 * subtemplate.level + "px;",
                          },
                          [
                            _c("RowTemplate", {
                              ref: "RowTemplate" + template.id,
                              refInFor: true,
                              attrs: {
                                template: subtemplate.template,
                                template_root: template,
                                level: subtemplate.level,
                                currentDocument: _vm.currentDocument,
                                currentDomain: _vm.currentDomain,
                                document_data_selected:
                                  _vm.document_data_selected,
                                category_id: _vm.category_id,
                                active_autocomplete: _vm.active_autocomplete,
                                active_preview: _vm.active_preview,
                                refreshHighlights: _vm.refreshHighlights,
                                collapseID:
                                  "collapse-" +
                                  template.id +
                                  "-" +
                                  (subtemplate.level + 1),
                              },
                              on: {
                                templateDeleted: _vm.templateDeleted,
                                duplicateTemplate: _vm.templateDuplicated,
                                createTempByText: _vm.createTempByText,
                                updateTemplate: _vm.updateTemplatesStore,
                                filterByTemplateID: _vm.filterByTemplateID,
                                clickTemplate: _vm.selectTemplate,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm.category_id == 1
        ? _c(
            "div",
            {
              staticClass: "alert alert-secondary m-1",
              attrs: { role: "alert" },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
      _vm._v(" "),
      _vm.category_id == 1
        ? _c(
            "a",
            {
              staticClass: "template-float-button",
              attrs: {
                href: "#",
                id: "template-float-button",
                "data-toggle": "tooltip",
                title: "Filter by narra",
              },
              on: {
                click: function ($event) {
                  return _vm.openFilter($event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "template-float-button-filter" },
                [_c("font-awesome-icon", { attrs: { icon: "filter" } })],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "SideMenu",
        { ref: "sideMenu", attrs: { width: "700px" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", fill: "", "content-class": "mt-3" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Scenarios", active: "" } },
                    [
                      _c("NarrasTreesViewer", {
                        attrs: {
                          collapse_button: "",
                          tree_editor_button: "false",
                          select_name: "true",
                          only_name_column: "",
                          rows: _vm.rows,
                        },
                        on: { selectNarra: _vm.selectNarra },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$templatesName } },
                    [
                      _c("TemplatesSelector", {
                        attrs: { templates: _vm.all_templates },
                        on: { selectTemplate: _vm.selectTemplate },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            height: "auto",
            width: "900",
            scrollable: "",
            resizable: "",
            name: "documentsDataListModal" + _vm.category_id,
          },
        },
        [
          _c("DocumentsDataList", {
            attrs: {
              documentID: _vm.documentId,
              ref_file_input: "file_input_templates" + _vm.category_id,
              modalName: "documentsDataListModal" + _vm.category_id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            height: "auto",
            width: "900",
            scrollable: "",
            resizable: "",
            name: "importModal" + _vm.category_id,
          },
        },
        [
          _c("ImportTemplatesModal", {
            attrs: { modalName: "importModal" + _vm.category_id },
            on: { importSegments: _vm.importSegments },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("FindReplace", {
        ref: "findReplace",
        attrs: { documentId: _vm.documentId },
        on: { save: _vm.saveTemplatesFindReplace },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(
        "There aren't any Semgment selected. Select a segment in\n        "
      ),
      _c("b", [_vm._v("Compositions tab")]),
      _vm._v(" or with the "),
      _c("b", [_vm._v("filter button")]),
      _vm._v(" in the lower\n        right corner"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }