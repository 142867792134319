<template>
  <div>
    <modal height="auto" width="700" name="modalUploadDataFile">
      <b-navbar toggleable="lg" type="light" variant="info">
        <b-navbar-brand href="#">Documents data</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-nav class="ml-auto">
          <a
            href="#"
            class="close-dialog"
            @click="$modal.hide('modalUploadDataFile')"
          >
            <font-awesome-icon icon="times" />
          </a>
        </b-navbar-nav>
      </b-navbar>
      <div class="container">
        <UploadFile
          ref_file_input="file_input_data"
          @uploadFiles="upload"
          :multiple="uploadMultipleFiles"
        />
        <br />
      </div>
    </modal>
    <b-navbar
      sticky
      toggleable="lg"
      type="light"
      variant="light"
      class="navigation_sticky_bar"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="updateNSchema()">
            <font-awesome-icon icon="save" />&nbsp;Save
          </b-nav-item>
          <b-nav-item href="#" @click="showModalUpload()">
            <font-awesome-icon icon="file-import" />&nbsp;Import data file
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!--TreeView
			class="mx-4"
			:data="nschema_data"
			:options="{ maxDepth: 6, rootObjectKey: '*' }"
		></TreeView-->
    <div class="container-flex">
      <div class="row no-gutters">
        <v-jsoneditor
          v-model="nschema_data"
          :options="optionsJsonEditor"
          @error="onError"
          height="760px"
          class="col-5"
        />
        <graph-viewer class="col-7"></graph-viewer>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
//import TreeView from "@/components/UI/JSONEditor/TreeView";
import VJsoneditor from "@/components/UI/VueJsoneditor/VueJsoneditor.vue";
import UploadFile from "@/components/UI/UploadFile/UploadFile";
import Loading from "@/components/UI/Loading";
import domains_api from "../../api/domain.js";
import { nschemaMixins } from "@/mixins/nschemaMixins.js";
import GraphViewer from "./NSchemaGraphViewer.vue";

export default {
	name: "NSchemaViewer",
	props: {
		domainId: {}
	},
	mixins: [nschemaMixins],
	data () {
		return {
			timeout: null,
			optionsJsonEditor: {
				mode: "tree",
				modes: ["tree", "code"],
				mainMenuBar: true,
				navigationBar: true,
				statusBar: true,
				templates: [
					{
						text: "nrt:class",
						title: "Insert a NSchema class",
						className: "nschema-class",
						field: "ClassTemplate",
						value: {
							"@id": "nrt:class#",
							"@type": "nrt:class",
							properties: []
						}
					},
					{
						text: "property",
						title: "Insert a NSchema Class property",
						className: "nschema-property",
						field: "PropertyTemplate",
						value: {
							name: "",
							typeOf: "xsd:",
							mandatory: true,
							container: "list"
						}
					},
					{
						text: "relation",
						title: "Insert a NSchema relation",
						className: "nschema-relation",
						field: "RelationTemplate",
						value: {
							to: "nrt:class#",
							"@id": "nrt:relationship#",
							from: "nrt:class#",
							"@type": "nrt:relationship",
							cardinality: "1-to-n"
						}
					}
				]
			},
			uploadMultipleFiles: false,
			isLoading: false
		};
	},
	components: {
		//TreeView,
		VJsoneditor,
		UploadFile,
		Loading,
		GraphViewer
	},
	computed: {
		nschema_data: {
			get () {
				try {
					const nschemaONB = JSON.parse(this.$store.getters.getNSchema);
					return nschemaONB;
				} catch (e) {
					return this.$store.getters.getNSchema;
				}
			},
			set (value) {
				this.$store.commit("SET_NSCHEMA", value);
				//this.nschema_data = value;
				//console.log("value", value);
				//console.log("ns", this.nschema_data);
				/*clearTimeout(this.timeout);

				var self = this;
				this.timeout = setTimeout(function () {
					console.log("set", value);
					self.updateNSchema(value);
				}, 1000);*/
			}
		}
	},
	methods: {
		onError () {},
		async updateNSchema () {
			this.isLoading = true;
			const [data, err] = await domains_api.setNSchema(this.domainId, {
				domain_id: this.domainId,
				nschema: JSON.stringify(this.nschema_data)
			});
			if (!err) {
				this.$bvToast.toast("NSchema updated", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
				this.loadNSchema();
			} else {
				this.$bvToast.toast("Error saving NSchema", {
					title: "Error ",
					variant: "warning",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
			}
			this.isLoading = false;
		},
		async upload (files) {
			this.isLoading = true;
			this.$modal.hide("modalUploadDataFile");

			const [data, err] = await domains_api.uploadNSchemaDataFile(
				this.domainId,
				{ data: files[0].data }
			);
			if (!err) {
				this.$bvToast.toast("NSchema updated", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
				this.loadNSchema();
			} else {
				this.$bvToast.toast("Error importing NSchema", {
					title: "Error ",
					variant: "warning",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
			}
			this.isLoading = false;
		},
		showModalUpload ($event) {
			this.$modal.show("modalUploadDataFile");
		}
	}
};
</script>
