import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Documents extends BaseClient {
	constructor () {
		super(httpClient, "/previews/");
	}

	getPreview (
		domainId,
		documentId,
		documentDataId,
		cached,
		times,
		templateId,
		test_mode = "true"
	) {
		console.log("Preview ", cached);
		if (templateId == undefined) {
			return this.get(
				`${domainId}/${documentId}/${documentDataId}?cached=${cached}&times=${times}&test_mode=${test_mode}`
			);
		} else {
			return this.get(
				`${domainId}/${documentId}/${documentDataId}/${templateId}?cached=${cached}&times=${times}&test_mode=${test_mode}`
			);
		}
	}

	getPreviewDataExpression (
		domainId,
		documentId,
		documentDataId,
		cached,
		times,
		templateId,
		test_mode = "true",
		dataExpression
	) {
		console.debug("Preview ", cached);

		let url = `${domainId}/${documentId}/${documentDataId}/${templateId}?cached=${cached}&times=${times}&test_mode=${test_mode}`;

		if (templateId == undefined) {
			url = `${domainId}/${documentId}/${documentDataId}?cached=${cached}&times=${times}&test_mode=${test_mode}`;
		}

		if (dataExpression) {
			let encodedDataExpression = encodeURIComponent(dataExpression);
			url = url + `&data_expression=${encodedDataExpression}`;
		}

		return this.get(url);
	}
}

export default new Documents();
