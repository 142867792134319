<template>
	<label :class="cssClass">
		<slot></slot>
		<span class="v-checkbox__label">{{label}}</span>
	</label>
</template>

<script>
export default {
	name: "Checkbox",
	data() {
		return {}
	},
	computed: {
		cssClass () {
			return {
				"v-checkbox": true,
				"v-checkbox--checked": this.checked,
				"v-checkbox--small": this.small
			}
		}
	},
	props: {
		label: String,
		checked: Boolean,
		small: Boolean
	}
}
</script>

<style lang="scss" scoped>
.v-checkbox {
	border: 2px solid var(--n-blue);
	border-radius: 16px;
	color: var(--n-blue);
	cursor: pointer;
	font-weight: 500;
	padding: 5px 12px;
	margin-bottom: 0 !important;

	&--checked {
		background-color: var(--n-blue);
		color: var(--white);
	}

	&--small {
		border-width: 1px;
		cursor: initial;
		font-size: 13px;
		font-weight: 300;
		padding: 2px 6px;
	}


	input[type="checkbox"] {
		display: none;
	}
}
</style>
