/* eslint-disable */
<template>
  <div class="table-templates-v52" id="templates_back_ground" :class="cssClass">
    <b-navbar
      sticky
      toggleable="lg"
      type="light"
      variant="light"
      class="navigation_sticky_bar navbar-fixed-top"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" class="num-templates" title="# of templates">{{
            filterTemplates.length
          }}</b-nav-item>
          <b-nav-item href="#" @click="addRow(-1)" title="Add a new template">
            <font-awesome-icon icon="plus" />&nbsp;Add
          </b-nav-item>

          <b-nav-item
            href="#"
            @click.prevent="$refs['findReplace'].show()"
            title="Find & replace"
          >
            <i class="fas fa-search"></i>&nbsp;Find & replace
          </b-nav-item>

          <b-nav-item
            href="#"
            @click="showImportModal"
            title="Import parent segments from another domain"
          >
            <font-awesome-icon icon="file-import" />&nbsp;Import
          </b-nav-item>
          <b-nav-item
            href="#"
            @click="refreshTemplates"
            title="Force reload all information from Database"
          >
            <font-awesome-icon icon="sync-alt" />&nbsp;Refresh
          </b-nav-item>

          <b-nav-item
            v-if="category_id == 1"
            href="#"
            @click="expandAll()"
            title="Expand all templates (might take a while)"
						v-b-tooltip.hover
          >
            <i class="fa fa-expand" aria-hidden="true"></i>&nbsp;Expand all
          </b-nav-item>
          <b-nav-item
            v-if="category_id == 1"
            href="#"
            @click="collapseAll()"
            title="Collapse all templates"
						v-b-tooltip.hover
          >
            <i class="fa fa-compress" aria-hidden="true"></i>&nbsp;Collapse all
          </b-nav-item>

          <b-nav-item
            href="#"
            @click="$emit('migrate')"
            title="Deploy these templates in production"
          >
            <font-awesome-icon icon="database" />&nbsp;Copy to production
          </b-nav-item>
          <span
            v-if="filterSelected.name.length > 0"
            class="badge badge-filter"
          >
            {{ filterSelected.name }}
            <!--a
							href="#"
							class="times-erase-filter"
							@click.prevent="eraseFilter"
							title="Remove filter"
						>
							<font-awesome-icon icon="times" />
						</a-->
          </span>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-select
              class="input-select-document"
              id="engine"
              v-model="document_data_selected"
              :options="document_data"
              size="sm"
            ></b-form-select
            >&nbsp;
            <b-button
              variant="success"
              v-on:click="previewAll($event)"
              title="Run ALL templates with selected data file"
            >
              <font-awesome-icon icon="play" /> </b-button
            >&nbsp;
            <b-button
              variant="warning"
              v-on:click="dataModal($event)"
              title="Manage data files"
            >
              <font-awesome-icon icon="paperclip" /> </b-button
            >&nbsp; &nbsp;
            <b-form-input
              size="sm"
              class="mr-sm-2 input-search-template"
              placeholder="Search"
              @keyup="makeFilterTemplates($event)"
              ref="inputSearch"
            ></b-form-input>
            <b-form-checkbox
              class="switch-autocomplete"
              checked
              v-model="active_autocomplete"
              name="switch_autocomplete"
              switch
              >Autocomplete</b-form-checkbox
            >&nbsp;
            <b-form-checkbox
              class="switch-autocomplete"
							checked
							v-model="active_preview"
              name="switch_preview"
              switch
              >Preview</b-form-checkbox
            >
            <b-nav-item href="#" @click="topFunction">
              <font-awesome-icon icon="arrow-up" />&nbsp;Top
            </b-nav-item>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="row-templates-container" v-if="filterTemplates.length > 0">
      <div v-for="(template, templateIndex) in filterTemplates" :key="templateIndex" class="template-root">
        <RowTemplate
          :template="template"
          :template_root="template"
          :currentDocument="currentDocument"
          :currentDomain="currentDomain"
          :document_data_selected="document_data_selected"
          :category_id="category_id"
          :active_autocomplete="active_autocomplete"
          :active_preview="active_preview"
          :ref="'RowTemplate' + template.id"
          :refreshHighlights="refreshHighlights"
          :collapseID="'collapse-' + template.id"
          :parent="template.subtemplates && template.subtemplates.length > 0"
          @templateDeleted="templateDeleted"
          @duplicateTemplate="templateDuplicated"
          @createTempByText="createTempByText"
          @updateTemplate="updateTemplatesStore"
          @filterByTemplateID="filterByTemplateID"
          @newTemplateByType="addRow"
          @clickTemplate="selectTemplate"
        />
        <b-collapse
          :id="'collapse-' + template.id"
          :ref="'collapse-' + template.id"
        >
          <div
            v-for="(subtemplate, index) in template.subtemplates_objs_render"
						:key="index"
            class="template-child"
            :style="'margin-left: ' + 15 * subtemplate.level + 'px;'"
          >
            <RowTemplate
              :template="subtemplate.template"
              :template_root="template"
              :level="subtemplate.level"
              :currentDocument="currentDocument"
              :currentDomain="currentDomain"
              :document_data_selected="document_data_selected"
              :category_id="category_id"
              :active_autocomplete="active_autocomplete"
              :active_preview="active_preview"
              :ref="'RowTemplate' + template.id"
              :refreshHighlights="refreshHighlights"
              :collapseID="
                'collapse-' + template.id + '-' + (subtemplate.level + 1)
              "
              @templateDeleted="templateDeleted"
              @duplicateTemplate="templateDuplicated"
              @createTempByText="createTempByText"
              @updateTemplate="updateTemplatesStore"
              @filterByTemplateID="filterByTemplateID"
              @clickTemplate="selectTemplate"
            />
          </div>
        </b-collapse>
      </div>
    </div>

    <div
      v-else-if="category_id == 1"
      class="alert alert-secondary m-1"
      role="alert"
    >
      <span
        >There aren't any Semgment selected. Select a segment in
        <b>Compositions tab</b> or with the <b>filter button</b> in the lower
        right corner</span
      >
    </div>

    <Loading v-if="isLoading"></Loading>
    <a
      href="#"
      @click="openFilter($event)"
      class="template-float-button"
      id="template-float-button"
      data-toggle="tooltip"
      title="Filter by narra"
      v-if="category_id == 1"
    >
      <div class="template-float-button-filter">
        <font-awesome-icon icon="filter" />
      </div>
    </a>

    <SideMenu ref="sideMenu" width="700px">
      <b-card no-body>
        <b-tabs card fill content-class="mt-3">
          <b-tab title="Scenarios" active>
            <NarrasTreesViewer
              collapse_button
              tree_editor_button="false"
              select_name="true"
              only_name_column
              :rows="rows"
              @selectNarra="selectNarra"
            ></NarrasTreesViewer>
          </b-tab>
          <b-tab :title="$templatesName">
            <TemplatesSelector
              :templates="all_templates"
              @selectTemplate="selectTemplate"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </SideMenu>

    <modal
      height="auto"
      width="900"
      scrollable
      resizable
      :name="'documentsDataListModal' + category_id"
    >
      <DocumentsDataList
        :documentID="documentId"
        :ref_file_input="'file_input_templates' + category_id"
        :modalName="'documentsDataListModal' + category_id"
      />
    </modal>

    <modal
      height="auto"
      width="900"
      scrollable
      resizable
      :name="'importModal' + category_id"
    >
      <ImportTemplatesModal
        :modalName="'importModal' + category_id"
        @importSegments="importSegments"
      />
    </modal>
    <FindReplace
      :documentId="documentId"
      ref="findReplace"
      @save="saveTemplatesFindReplace"
    >
    </FindReplace>
  </div>
</template>

<script>
import document_templates_api from "../../api/document_templates.js";
import template_version_api from "../../api/template_version.js";
import templates_api from "../../api/template.js";
import narras_templates_api from "../../api/narras_tree_templates";
import preview_api from "../../api/preview";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import "../../utils/common.js";
//import contextMenu from "vue-context-menu";
import Loading from "@/components/UI/Loading";
import NarrasTreesViewer from "@/components/narras/NarrasTreesViewer";
import RowTemplate from "@/components/templates/RowTemplate";
import LevelRowTemplate from "@/components/templates/LevelRowTemplate";
import SideMenu from "@/components/UI/SideMenu/SideMenu";
import DocumentsDataList from "@/components/documents/DocumentsDataList";
import TemplatesSelector from "@/components/templates/TemplatesSelector";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import ImportTemplatesModal from "@/components/templates/ImportTemplatesModal";
import FindReplace from "@/components/UI/FindReplace/FindReplace";
import document_templates from "../../api/document_templates.js";

export default {
	name: "TableTemplatesV5",
	mixins: [alertMixins, templatesMixins],
	components: {
		//contextMenu,
		NarrasTreesViewer,
		Loading,
		SideMenu,
		DocumentsDataList,
		RowTemplate,
		LevelRowTemplate,
		TemplatesSelector,
		ButtonCircle,
		ImportTemplatesModal,
		FindReplace
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getNavigateRoot,
			(template, oldValue) => {
				console.log("NAVIGATE Root", template.id);
				this.$refs["collapse-" + template.id][0].show = true;
			}
		);
		this.$store.watch(
			(state, getters) => getters.getNavigateTemplate,
			(template, oldValue) => {
				//console.log("NAVIGATE EditorV5", template_id);
				if (this.category_id == 1) {
					//console.log("clickTemplate", template);
					//this.$emit("clickTemplate", template.versions[0].type);
					this.selectTemplate(template);
				}
			}
		);
		let self = this;
		window.addEventListener("keydown", function (e) {
			if (e.ctrlKey && e.key === "f") {
				e.preventDefault();
				self.$refs["findReplace"].show();
			}
		});

		if (localStorage.getItem("active_preview") == "true" || localStorage.getItem("active_preview") == null) {
			console.log("activar preview");
			this.active_preview = true;
		} else {
			console.log("desactivar preview");
			this.active_preview = false;
		}

	},
	watch: {
		active_preview(value) {
			console.log(value)
			localStorage.setItem("active_preview", value);
		}
	},
	data () {
		return {
			menuData: {},
			rowCopied: 0,
			active_autocomplete: true,
			active_preview: true,
			searchTemplate: "",
			currentSort: "type",
			currentSortDir: "asc",
			template_versions_selected: {},
			mousePosition: [],
			isLoading: false,
			filterSelected: { name: "" },
			refreshHighlights: true
		};
	},
	computed: {
		cssClass () {
			return {
				"compositions-tab": this.category_id === "2"
			}
		},
		document_data_selected: {
			get () {
				return this.$store.getters.getDocumentDataSelected;
			},
			set (value) {
				this.$store.commit("SET_DOCUMENT_DATA_SELECTED", value);
			}
		},
		paste_menu_enabled () {
			if (this.rowCopied == 0) {
				return "ctx-item disabled";
			} else {
				return "ctx-item";
			}
		},

		enabled_drag () {
			console.log("computed");
			return this.searchTemplate.length === 0;
		},
		rows () {
			return this.$store.getters.getRowsNarras;
		},
		root_narras () {
			return this.$store.getters.getRootNarras;
		},
		document_data () {
			let data = this.$store.getters.getDocumentData;

			let options = [];
			options.push({ value: 0, text: "Select a data file" });
			for (let index = 0; index < data.length; index++) {
				let document_data = data[index];

				options.push({
					value: document_data.id,
					text: document_data.name.trunc(50)
				});
			}
			return options;
		},
		filterTemplates: {
			get () {
				let templates =  this.$store.getters.getTemplatesByFilter(
					this.searchTemplate,
					this.category_id
				);
				return templates.filter(function (template) {
					return template.active;
				});
			},
			set (value) {}
		},
		narraSelectorModalName () {
			return "NarraSelectorModal" + this.category_id;
		},
		sortedTemplates: function () {
			//return this.filterTemplates;
			return this.filterTemplates.sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === "desc") modifier = -1;

				//console.log("sort", a, b);

				let field = this.currentSort;
				if (!a.versions) {
					a.versions = [{ field: "" }];
				}
				if (!b.versions) {
					b.versions = [{ field: "" }];
				}

				if (this.currentSort == "narras") {
					if (!a.narra_tree_nodes_version[0]) {
						a.narra_tree_nodes_version[0] = { name: "" };
					}
					if (!b.narra_tree_nodes_version[0]) {
						b.narra_tree_nodes_version[0] = { name: "" };
					}

					if (
						a.narra_tree_nodes_version[0].name.toString().toLowerCase() <
            b.narra_tree_nodes_version[0].name.toString().toLowerCase()
					) { return -1 * modifier; }
					if (
						a.narra_tree_nodes_version[0].name.toString().toLowerCase() >
            b.narra_tree_nodes_version[0].name.toString().toLowerCase()
					) { return 1 * modifier; }
				} else {
					if (
						a.versions[0][this.currentSort].toString().toLowerCase() <
            b.versions[0][this.currentSort].toString().toLowerCase()
					) { return -1 * modifier; }
					if (
						a.versions[0][this.currentSort].toString().toLowerCase() >
            b.versions[0][this.currentSort].toString().toLowerCase()
					) { return 1 * modifier; }
				}
				return 0;
			});
		}
	},
	methods: {
		async addRow (order, template = 0, category_id = this.category_id) {
			console.log("Add ", category_id);
			let new_template_obj = {};
			this.isLoading = true;
			//creamos el template
			let [data, err] = await templates_api.createTemplate({
				active: true,
				category_id: category_id
			});
			if (!err) {
				let template_order = this.templates.length + 1;
				if (order != -1) template_order = order;

				//si se crea vien lo asociamos al documento
				const [data_dt, err_dt] = await document_templates_api.create(
					this.documentId,
					{
						template_id: data.id,
						order: template_order
					}
				);

				if (!err_dt) {
					let new_version = {
						template_id: data.id,
						current_version: true,
						active: true
					};

					if (template == 0) {
						if (category_id == 2) new_version.type = "composition";
						else new_version.type = "";
						new_version.narras = "";
						new_version.weigth = 20;
						new_version.text_for_editor = "";
					} else {
						new_version.type = template.versions[0].type;
						new_version.narras = template.versions[0].narras;
						new_version.weigth = template.versions[0].weigth;
						new_version.text_for_editor = template.versions[0].text_for_editor;
						// new_version.text_for_editor =
            //   template.versions[0].text_for_editor.replace(/·/g, " ");
					}

					const [datav, errv] =
            await template_version_api.createVersionWithAlias(
            	new_version,
            	this.currentDomain.id
            );

					if (!errv) {
						console.log(datav);
						new_version.id = datav.id;

						new_template_obj = {
							id: new_version.template_id,
							active: true,
							category: { id: category_id },
							order: template_order,
							versions: [new_version],
							narra_tree_nodes_version: []
						};

						//this.templates.splice(3, 0, new_template_obj);
						if (order == -1) {
							console.log("Category", category_id, "type", new_version.type);
							let tmpls;
							if (category_id == 2) {
								tmpls = this.$store.getters.getTemplatesComposition;
							} else {
								tmpls = this.$store.getters.getTemplatesRegular;
							}
							tmpls.push(new_template_obj);
							this.$store.getters.getTemplates.push(new_template_obj);
						} else {
							//hay que insertar el template en la posicion del array que indique el orden
							//desplazar en el orden de las filas posteriores
							console.log("order " + order);
							this.templates.splice(order - 1, 0, new_template_obj);

							let tempaltes_update = [];

							for (let i = order; i < this.templates.length; i++) {
								/*console.log(
									"Old " + this.templates[i].order + " new " + (order + 1)
								);*/
								this.templates[i].order = order + 1;
								order++;

								tempaltes_update.push({
									document_id: this.documentId,
									template_id: this.templates[i].id,
									order: this.templates[i].order
								});
							}

							if (tempaltes_update.length > 0) {
								[data, err] = await document_templates_api.order_update(
									this.documentId,
									tempaltes_update
								);

								console.log(data, err);
							}
						}

						//Ponemos el foco en la nueva fila
						setTimeout(() => {
							//retardamos el coger el foco para que de tiempo a que se cree el componente
							this.$refs["RowTemplate" + new_template_obj.id][0].getFocus();
						}, 100);
					} else {
						console.error(errv);
						this.showErrorAlert("Error: " + errv.data.error);
					}
				} else {
					console.error(err_dt);
					this.showErrorAlert("Error: " + err_dt.data.error);
				}
			} else {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			}
			/*console.log("scroll to bottom");
			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight
			);*/

			this.updateTemplatesStore();

			this.isLoading = false;
			return new_template_obj;
		},
		updateTemplatesStore () {
			console.log("updateTemplatesStore");
			if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", this.templates); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates); }
		},
		async templateDuplicated (template) {
			console.log("templateDuplicated", template);

			//insertamos el template
			let template_new = await this.addRow(template.order, template);
			console.log(
				"template.narra_tree_nodes_version",
				template.narra_tree_nodes_version
			);
			if (
				template.narra_tree_nodes_version &&
        template.narra_tree_nodes_version.length > 0
			) {
				this.template_versions_selected = template_new;

				let tree_node = {
					data_node: {
						id: template.narra_tree_nodes_version[0].narra_tree_node_id,
						versions: [template.narra_tree_nodes_version[0]]
					}
				};
				this.associateNarra(tree_node);
			}
			if (this.category_id == 1) {
				this.$store.commit("SET_NAVIGATE_TEMPLATE", template_new);
			}
		},
		async associateNarra (tree_node) {
			console.log("------------ selectNarra", tree_node);
			console.log(this.template_versions_selected);

			this.isLoading = true;

			//this.$modal.hide(this.narraSelectorModalName);
			this.$bvModal.hide(this.narraSelectorModalName);
			const [data, err] = await narras_templates_api.create(
				tree_node.data_node.id,
				{
					template_id: this.template_versions_selected.id
				}
			);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}
			this.template_versions_selected.narra_tree_nodes_version.push(
				tree_node.data_node.versions[0]
			);

			if (tree_node.template) {
				tree_node.template =
          tree_node.template +
          ", " +
          this.template_versions_selected.versions[0].type;
			} else { tree_node.template = this.template_versions_selected.versions[0].type; }
			this.isLoading = false;
		},
		templateDeleted (template) {
			const template_id = template.id;
			this.templates = this.templates.filter(function (template) {
				return template.id != template_id;
			});

			if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", this.templates); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates); }
		},
		async createTempByText (template) {
			let template_new = await this.addRow(-1, template, 1);

			console.log(
				"template.narra_tree_nodes_version",
				template.narra_tree_nodes_version
			);

			if (
				template.narra_tree_nodes_version &&
        template.narra_tree_nodes_version.length > 0
			) {
				this.template_versions_selected = template_new;

				let tree_node = {
					data_node: {
						id: template.narra_tree_nodes_version[0].narra_tree_node_id,
						versions: [template.narra_tree_nodes_version[0]]
					}
				};
				this.associateNarra(tree_node);
			}
			if (this.category_id == 1) {
				this.$store.commit("SET_NAVIGATE_TEMPLATE", template_new);
			}
		},
		openFilter ($event) {
			this.template_versions_selected = null;
			//this.$bvModal.show(this.narraSelectorModalName);

			this.$refs.sideMenu.open();
		},
		async selectNarra (tree_node) {
			console.log("+++------------ selectNarra", tree_node);
			console.log(this.template_versions_selected);

			this.isLoading = true;

			//this.$modal.hide(this.narraSelectorModalName);
			//console.log("ref", this.$refs);
			this.$refs.sideMenu.close();

			console.log("get temapltes by narra");
			const tree_id = tree_node.data_node
				? tree_node.data_node.id
				: tree_node.narra_tree_node_id;
			console.log("tree_id ", this.documentId, tree_id);

			const [data, err] = await document_templates_api.getTemplatesByNarraId(
				this.documentId,
				tree_id
			);

			console.log("templates", data, err);

			if (err) {
				if (err.status == 404) {
					this.showErrorAlert(
						"There aren't segments associated with the scenario"
					);
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				//this.$store.commit("SET_TEMPLATES_REGULAR", data);
				this.replaceSpacesWithPoints(data);

				this.filterSelected = {
					name: tree_node.name,
					obj: tree_node,
					type: "narras"
				};

				this.templates = this.putSubtemplatesObjs(data);
				/*[...data].forEach(template => {
					template.subtemplates_objs = [];
					if (template.subtemplates) {
						[...template.subtemplates].forEach(subtemplate => {
							console.log("subtemplate", subtemplate);
							let tmpl = this.getTemplateObjByType(data, subtemplate);
							template.subtemplates_objs = template.subtemplates_objs.concat(
								tmpl
							);
						});
					}
				});*/
				//this.templates = data;
			}

			this.isLoading = false;
		},
		async selectTemplate (template) {
			this.isLoading = true;

			this.$refs.sideMenu.close();

			const [data, err] = await document_templates_api.getTemplatesByType(
				this.documentId,
				template
			);

			console.log("templates", data, err);

			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.replaceSpacesWithPoints(data);
				this.filterSelected = {
					name: template,
					obj: template,
					type: "template"
				};
				this.templates = data;
				//this.templates = this.putSubtemplatesObjs(data);
			}

			this.isLoading = false;
		},
		async eraseFilter () {
			this.filterSelected = { name: "" };
			this.refreshTemplates();

			/*this.isLoading = true;

			this.filterSelected = { "name": "" };

			function pEraseFilter(self) {
				return new Promise(resolve => {
					setTimeout(() => {
						let templates = self.all_templates.filter(function(template) {
							return template.category != null
								? template.category.id == self.category_id
								: false;
						});
						//self.templates = templates_regular;
						self.templates = self.putSubtemplatesObjs(templates);
						resolve();
					}, 10);
				});
			}

			console.log("2");
			self = this;
			const r = await pEraseFilter(this);
			console.log("3");
			self.isLoading = false;*/
		},
		popMenu (e, template) {
			let self = this;
			e.preventDefault();
			this.template_menu = template;

			if (e.button === 2) {
				let x = e.layerX;
				let y = e.layerY;
				self.mousePosition = [x, y];
			} else if (e.button === 0) {
				self.mousePosition = ["close"];
			}
		},
		async list_item_click (it) {
			console.log(this.template_menu);
			switch (it) {
			case 0:
				alert("Color");
				break;
			case 1:
				/*let template_deactivate = {
            id: this.template_menu.id,
            active: !template_menu.active
          }
          const [ datav, errv ] = await templates_api.updateTemplate(template_deactivate)

          if (!errv) {
            //this.templates.pop(this.template_menu)
            alert("Modificado")
          } else {
            console.error(errv)
          }
          break;*/
				alert("Modificado");
			}
		},
		sort: function (s) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
			}
			this.currentSort = s;
		},
		async moveTemplate (e) {
			console.log(e.oldIndex, e.newIndex);
			//array_move(this.templates, e.oldIndex, e.newIndex);
			console.log("Movido", e, this.templates);

			let tempaltes_update = reorderTemplates(this.templates, this.documentId);
			console.log("reordenado", tempaltes_update);
			const [data, err] = await document_templates_api.order_update(
				this.documentId,
				tempaltes_update
			);

			console.log(data, err);
			//return false;
		},
		onCtxOpen (locals) {
			console.log("open", locals);
			this.menuData = locals;
		},
		insert_template_below ($event, node) {
			//insertamos una plantilla debajo de la actual
			console.log(node);
			this.addRow(node.order + 1);
		},
		insert_template_over ($event, node) {
			//insertamos una plantilla debajo de la actual
			console.log(node);
			this.addRow(node.order);
		},
		copy_template ($event, menuData) {
			console.log("copy", menuData);
			this.rowCopied = menuData;
		},
		async paste_template_over ($event, templatePaste) {
			console.log("Paste over", this.rowCopied, templatePaste);

			this.paste_template(templatePaste, templatePaste.order);
		},
		async paste_template_below ($event, templatePaste) {
			console.log("Paste below", this.rowCopied, templatePaste);

			this.paste_template(templatePaste, templatePaste.order + 1);
		},
		async paste_template (templatePaste, order) {
			//insertamos el template
			let template_new = await this.addRow(
				templatePaste.order + 1,
				this.rowCopied
			);

			if (this.rowCopied.narra_tree_nodes_version.length > 0) {
				this.template_versions_selected = template_new;

				let tree_node = {
					data_node: {
						id: this.rowCopied.narra_tree_nodes_version[0].narra_tree_node_id,
						versions: [this.rowCopied.narra_tree_nodes_version[0]]
					}
				};
				this.selectNarra(tree_node);
			}
		},
		dataModal ($event) {
			this.$modal.show("documentsDataListModal" + this.category_id);
		},
		previewAll ($event) {
			if (this.document_data_selected != 0) {
				console.log("preview ref ", this.$refs, this.filterTemplates);
				for (let i = 0; i < this.filterTemplates.length; i++) {
					console.log(
						"ID",
						this.filterTemplates[i].id,
						this.$refs["RowTemplate" + this.filterTemplates[i].id]
					);
					if (this.$refs["RowTemplate" + this.filterTemplates[i].id]) {
						this.$refs[
							"RowTemplate" + this.filterTemplates[i].id
						][0].previewTemplate($event, "ButtonEye", "true");
					}
					//this.previewTemplate($event, this.filterTemplates[i]);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
		},
		makeFilterTemplates ($event) {
			console.log("makeFilterTemplates", $event);
			//desactivamos el refresco de los higthligth
			this.refreshHighlights = false;

			let self = this;
			setTimeout(() => {
				this.searchTemplate = $event.target.value;
			}, 100);
			setTimeout(() => {
				self.refreshHighlights = true;
			}, 500);
		},
		async filterByTemplateID (template) {
			console.log("filterByTemplateID");
			this.isLoading = true;

			const [data, err] = await document_templates_api.getById(
				this.documentId,
				template.id
			);

			if (!err) {
				this.replaceSpacesWithPoints(data.templates);
				this.templates = this.putSubtemplatesObjs(data.templates);
				//this.templates = data.templates;
				console.log("filter");
				this.filterSelected = {
					name: template.versions[0].type + " (" + template.id + ")",
					obj: template,
					type: "row"
				};
			}

			this.isLoading = false;
		},
		async refreshTemplates () {
			this.isLoading = true;
			if (this.filterSelected.name.length > 0) {
				if (this.filterSelected.type == "row") {
					await this.filterByTemplateID(this.filterSelected.obj);
				} else if (this.filterSelected.type == "narras") {
					await this.selectNarra(this.filterSelected.obj);
				} else if (this.filterSelected.type == "template") {
					await this.selectTemplate(this.filterSelected.obj);
				}
			} else {
				await this.loadTemplates();
			}
			this.isLoading = false;
		},
		expandAll () {
			this.Loading = true;
			[...this.filterTemplates].forEach((template) => {
				if (this.$refs["collapse-" + template.id]) {
					template.subtemplates_objs_render = template.subtemplates_objs;
					for (
						let i = 0;
						i < this.$refs["collapse-" + template.id].length;
						i++
					) {
						this.$refs["collapse-" + template.id][i].show = true;
						console.log("expand");
					}
				}
			});
			this.Loading = false;
			console.log("End of expandAll function");
		},
		collapseAll () {
			this.Loading = true;
			[...this.filterTemplates].forEach((template) => {
				if (this.$refs["collapse-" + template.id]) {
					for (
						let i = 0;
						i < this.$refs["collapse-" + template.id].length;
						i++
					) {
						this.$refs["collapse-" + template.id][i].show = false;
						console.log("collapse");
					}
				}
			});
			this.Loading = false;

			console.log("End of collapseAll function");
		},
		topFunction () {
			document.body.scrollTop = 0; //For Safari
			document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera
		},
		showImportModal () {
			//importModal
			console.log("showImportModal " + "importModal" + this.category_id);
			this.$modal.show("importModal" + this.category_id);
		},
		importSegments (domain, document, selectedItems) {
			console.log("selectedItems", selectedItems);
			this.$emit("importSegments", domain, document, selectedItems);
		},
		async saveTemplatesFindReplace (templates) {
			this.isLoading = true;

			for (let i = 0; i < templates.length; i++) {
				for (let template of this.$store.getters.getTemplatesRegular) {
					if (template.id == templates[i].id) {
						console.log("template", template);
						let version = templates[i].versions[0];
						template.versions[0].text_for_editor = templates[
							i
						].versions[0].text_for_editor
						// template.versions[0].text_for_editor = templates[
						// 	i
						// 	].versions[0].text_for_editor.replace(/·/g, " ");
						//this.updateTemplate(this.template.versions[0]);

						if (version) {
							console.log("POST TEMPLATE VERSION", version);
							const [data, err] =
                await template_version_api.createVersionWithAlias(
                	version,
                	this.$store.getters.getCurrentDomain.id
                );

							if (!err) {
								console.log("UPDATE", data);
								template.versions[0].updated_at = data.updated_at;
							} else {
								this.isLoading = false;
								return this.showApiRequestErrorAlert(err);
							}
						}
						break;
					}
				}
			}

			this.isLoading = false;
		}
	},
	props: {
		documentId: {},
		category_id: {},
		title_navbar: {},
		currentDocument: { type: Object },
		currentDomain: { type: Object },
		contentType: String
	}
};

function reorderTemplates (templates, documentId) {
	let order_template = 1;
	let tempaltes_update = [];

	console.log("reorderTemplates");
	for (let index = 0; index < templates.length; index++) {
		console.log(templates[index].order, order_template);
		templates[index].order = order_template;

		tempaltes_update.push({
			document_id: documentId,
			template_id: templates[index].id,
			order: order_template
		});

		order_template += 1;
	}
	console.log(tempaltes_update);
	return tempaltes_update;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.table-templates-v52 {

	td {
		font-family: "Lucida Console", Monaco, monospace, Helvetica, Arial, sans-serif;
		/*-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;*/
		color: #000000;
		margin-top: 0px;
	}

	#navbar {
		overflow: hidden;
		background-color: #333;
	}

	/* Navbar links */
	#navbar a {
		float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		padding: 14px;
		text-decoration: none;
	}

	/* The sticky class is added to the navbar with JS when it reaches its scroll position */
	.sticky {
		position: fixed;
		top: 0;
		width: 100%;
	}

	/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
	.sticky + .content {
		padding-top: 60px;
	}

	.v--modal-overlay {
		z-index: 1030;
	}

	.row-templates-container {
		/*font-family: "Menlo Regular", "Avenir", Helvetica, Arial, sans-serif;*/
		scroll-behavior: auto;
	}

	.num-templates {
		background-color: #157ef3;
	}

	.num-templates a {
		color: #fff !important;
	}
}
</style>
