<template>
	<div
		class="card mt-1 nschema-slied-selector-card"
		:style="{ left: `${x}px`, top: `${y}px` }"
		v-if="show"
	>
		<div class="card-header">
			<b-form-input v-model="filterTree" placeholder="Filter Nschema" />
		</div>
		<div class="card-body">
			<!--div v-for="property in nschema_properties">
					<{{ property }}
				</div-->

			<tree
				class="tree-container nschema-slied-selector-tree"
				:data="nschema"
				:options="options"
				:filter="filterTree"
				@node:selected="selectNode"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "NschemaFieldSelector",
	props: {
		old_text: String
	},
	data () {
		return {
			x: 0,
			y: 0,
			show: false,
			filterTree: "",
			options: {
				checkbox: false,
				deletion: false,
				dnd: true,
				filterTree: "",
				filter: {
					emptyText: "Nothing found!",
					matcher (query, node) {
						return new RegExp(query, "i").test(node.text);
					},
					plainList: true,
					showChildren: false
				}
			},
			nschema: []
		};
	},
	mounted () {
		//cargamos los datos con el nschema
		const nschema_entities = this.$store.getters.getAutocompleteNSchemaMembers
			.entities;
		const nschema_props = this.$store.getters.getAutocompleteNSchemaMembers
			.properties;

		let root = {
			"text": "r",
			"state": { selectable: false, expanded: false },
			"children": []
		};
		this.nschema.push(root);

		let entities = [];
		[...nschema_entities].forEach((entity) => {
			if (entity != "r") {
				let children = [];
				let children_r = [];
				[...nschema_props[entity]].forEach((child) => {
					children.push({
						"text": child,
						"state": { "selectable": true },
						"data": { "field": "[" + entity + "." + child + "]" }
					});

					children_r.push({
						"text": child,
						"state": { "selectable": true },
						"data": { "field": "[r." + entity + "." + child + "]" }
					});
				});

				root.children.push({
					"text": entity,
					"children": children_r,
					"state": { selectable: false, expanded: false }
				});
				this.nschema.push({
					"text": entity,
					"children": children,
					"state": { selectable: false, expanded: false }
				});
			}
		});
		console.log(root);
		console.log(entities);

		this.nschema.push(entities);
	},
	methods: {
		selectNode (node) {
			this.$emit("selectNode", node.data.field);
			this.show = false;
		},
		showTree ($event) {
			this.show = true;
			this.x = $event.target.offsetLeft + $event.target.offsetWidth; //rect.left;
			this.y = $event.target.offsetTop + $event.target.offsetHeight;
			this.filterTree = "";
		},
		hideTree ($event) {
			this.show = false;
		}
	}
};
</script>

<style>
</style>
