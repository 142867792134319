<template>
  <div>
    <b-navbar
      sticky
      toggleable="lg"
      type="light"
      variant="light"
      class="navigation_sticky_bar"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="newModal()" v-b-tooltip.hover title="Add new Scenario Tree">
            <font-awesome-icon icon="plus" />&nbsp;
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="expandAll()" v-b-tooltip.hover title="Expand">
            <i class="fa fa-expand" aria-hidden="true"></i>&nbsp;
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="collapseAll()" v-b-tooltip.hover title="Collapse">
            <i class="fa fa-compress" aria-hidden="true"></i>&nbsp;
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-input
              v-model="searchTree"
              placeholder="Filter Tree"
              class="form-control-sm"
            />
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="container">
      <h1 class="h4 scenario_main_title">Scenarios Trees</h1>

      <div align-h="start" class="ml-0" v-for="(root_node, index) in filterTrees" :key="index">
        <div class="categoryscenario-header">
          <span
            contenteditable
            spellcheck="false"
            @blur="editNode($event, root_node)"
						v-bind:class="{ 'node-text-inactive': !root_node.active }"
          >
            {{ root_node.versions[0].name }}
          </span>
          <button
            @click.prevent.stop="addNode(root_node)"
						v-b-tooltip.hover title="Add new node"
            class="template-circle-button"
          >
            <font-awesome-icon icon="plus" size="sm" />
          </button>
          <button
            @click.prevent.stop="addNodeByCategory(root_node)"
						v-b-tooltip.hover title="Add node by category"
            class="template-circle-button"
          >
            <font-awesome-icon icon="code-branch" size="sm" />
          </button>
          <!-- <button
            @click.prevent.stop="requestRemoveNode(root_node)"
						v-b-tooltip.hover title="Remove node"
            class="template-circle-button categoryscenario-header__delete-btn"
          >
            <font-awesome-icon icon="trash-alt" size="sm" />
          </button> -->
					<button
						ref="openBranchGeneratorBtn"
						v-if="enableCubes"
            @click.prevent.stop="openBranchGenerator(root_node.id)"
						v-b-tooltip.hover title="Open branch generator"
            class="template-circle-button categoryscenario-header__generator-btn"
          >
            <font-awesome-icon icon="project-diagram" size="sm" />
          </button>
          <ButtonCircle
            :ref="'ButtonCollapse' + root_node.id"
            :template="root_node"
            icon="angle-down"
            icon_second="angle-up"
            @click="toogleTree(root_node)"
						v-b-tooltip.hover title="Expand / Collapse"
          />
        </div>

				<b-collapse
          :id="'collapse-' + root_node.id"
          :ref="'collapse-' + root_node.id"
          class="mt-2"
        ><!-- Recover for Cubes
					<scenarios-branch-visual v-if="enableCubes" :isLoading="loadingCubes" :branches="filterCubes(root_node.id)"></scenarios-branch-visual>
					-->
          <ScenariosTree
            :root_node="root_node"
            :domainId="domainId"
            :documentId="documentId"
            :ref="'tree' + root_node.id"
        /></b-collapse>

        <br />
        <br />
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>

    <b-modal
      hide-footer
      id="modal-new"
      title="New root narras tree"
      ref="modal-new"
      :no-close-on-backdrop="true"
			centered
    >
      <b-form @submit.stop.prevent>
        <b-form-group
          id="input-group-2"
          label="Name:"
          label-for="name"
          label-align="left"
        >
          <b-form-input
						id="name"
						:state="validationName"
						v-model="form.name"
						required
						placeholder="Name"
					></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Description:"
          label-for="description"
          label-align="left"
        >
          <b-form-textarea
            id="description"
            v-model="form.description"
            placeholder="Description"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group id="input-group-5" label-align="left">
          <b-form-checkbox
            id="active"
            value="true"
            v-model="form.active"
            unchecked-value="false"
            >Active</b-form-checkbox
          >
        </b-form-group>

        <b-button
          :disabled="!validationName"
          variant="primary"
          v-on:click="updateNarra"
          >Save</b-button
        >
        <b-button type="reset" variant="danger" v-on:click="cancel">Cancel</b-button>
      </b-form>
    </b-modal>
		<scenarios-branch-generator v-if="enableCubes" @reloadTree="reloadTree" :branches="scenariosBranches"></scenarios-branch-generator>
		<b-toast id="branch-generator-toast" variant="success" solid>
			<template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Branches generator</strong>
        </div>
      </template>
      <span>Branches generated succefully.</span>
    </b-toast>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import Loading from "@/components/UI/Loading";
import ScenariosTree from "@/components/scenarios/ScenariosTree";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import vSelect from "vue-select";
import { alertMixins } from "@/mixins/alertMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import ScenariosBranchGenerator from "@/components/scenarios/ScenariosBranchGenerator";
import ScenariosBranchVisual from "@/components/scenarios/ScenariosBranchVisual";
import { mapMutations, mapGetters } from "vuex";
import cubes from "../../api/cubes";

export default {
	name: "NarrasTreeEditor",
	components: {
		Loading,
		ScenariosTree,
		ButtonCircle,
		vSelect,
		ScenariosBranchGenerator,
		ScenariosBranchVisual
	},
	mixins: [alertMixins, narrasMixins],
	data () {
		return {
			//root_nodes: [],
			searchTree: "",
			isLoading: false,
			firstLoad: true,
			form: {
				id: "",
				name: "",
				narras: "",
				scenario_id: 0,
				description: "",
				active: "1",
				domain_id: "",
				parent_id: "",
				parent_node: {},
				parent_narras: [],
				title: ""
			},
			enableCubes: true,
			loadingCubes: false
		};
	},
	methods: {
		...mapMutations({
			setNodeTreeId: "SET_NODE_TREE_ID",
			setScenariosBranchesToEdit: "SET_SCENARIOS_BRANCHES_TO_EDIT"
		}),
		addNode (root_node) {
			this.$refs["tree" + root_node.id][0].addChildNode(root_node);
			this.$refs["collapse-" + root_node.id][0].show = true;
		},
		addNodeByCategory (root_node) {
			this.$refs["tree" + root_node.id][0].showAddByCategory(root_node);
			this.$refs["collapse-" + root_node.id][0].show = true;
		},
		newModal () {
			this.form.active = true;
			this.form.id = "";
			this.form.name = "";
			this.form.narras = "";
			this.form.description = "";
			this.form.narra_obj = {};
			this.$refs["modal-new"].show();
		},
		editNode ($event, node) {
			if (node.versions[0].name.trim()==$event.target.innerText.trim()){
				console.debug("No changes detected for node: " + node.id + ". Exiting")
				return
			}
			this.form.active = node.active;
			this.form.id = node.id;
			this.form.name = $event.target.innerText;
			this.form.domain_id = node.domain_id;
			this.form.parent_id = null;
			this.form.narra_obj = node;
			console.log(this.form);

			this.updateNarra();
		},
		cancel (event) {
			this.$refs["modal-new"].hide();
		},
		toogleTree (root_node) {
			this.$root.$emit("bv::toggle::collapse", "collapse-" + root_node.id);
			this.fetchScenariosBranches(root_node.id);
			this.$refs["ButtonCollapse" + root_node.id][0].changeState();
		},
		expandAll () {
			[...this.root_nodes].forEach((root_node) => {
				this.$refs["collapse-" + root_node.id][0].show = true;
				if (!this.cubes.find(item => item.id === root_node.id)) {
					this.fetchScenariosBranches(root_node.id);
				}
			});
		},
		collapseAll () {
			[...this.root_nodes].forEach((root_node) => {
				this.$refs["collapse-" + root_node.id][0].show = false;
			});
		},
		topFunction () {
			document.body.scrollTop = 0; //For Safari
			document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera
		},
		openBranchGenerator (nodeTreeId) {
			this.setNodeTreeId(nodeTreeId);
			this.fetchScenariosBranches(nodeTreeId, true);
			this.$bvModal.show("branch-generator");
		},
		async fetchScenariosBranches (nodeTreeId, forceupdate = false) {
			if (!forceupdate && this.scenariosBranches.branches && this.scenariosBranches.branches.find(item => item.treeId === nodeTreeId)) {
				return false;
			}

			this.loadingCubes = true;
			const [data, err] = await cubes.getByTreeId(nodeTreeId);

			if (err) {
				this.loadingCubes = false;
				return false;
			}

			const branches = data && data.length ? data : [];
			branches.forEach(item => { item.selectedCategory = ""; item.selectedScenario = ""; });

			this.setScenariosBranchesToEdit({ treeId: nodeTreeId, branches: branches });
			this.$store.commit("SET_CUBES", { id: nodeTreeId, branches: branches })

			this.loadingCubes = false;
		},
		reloadTree () {
			this.$bvToast.show("branch-generator-toast");
			this.loadNarras(this.domainId, this.documentId);
			this.fetchScenariosBranches(this.openedNodeTreeId, true);
		}
	},
	props: {
		navbar: {
			type: [String, Boolean],
			default: true
		},
		domainId: {},
		documentId: {}
	},
	computed: {
		...mapGetters({
			scenariosBranches: "getScenariosBranchesToEdit",
			openedNodeTreeId: "getNodeTreeId",
			cubes: "getCubes"
		}),
		validationName () {
			return this.form.name.length > 0;
		},
		filterTrees () {
			const filter = this.searchTree;
			return this.root_nodes.filter((r) => {
				return (
					r.versions[0].name.toLowerCase().indexOf(filter.toLowerCase()) > -1
				);
			});
		},
		root_nodes: {
			get () {
				const root_narras = this.$store.getters.getRootNarras;
				let root_nodes = [];
				if (root_narras.nodes) {
					for (let i = 0; i < root_narras.nodes.length; i++) {
						const root = root_narras.nodes[i];
						root.cubes = {}
						//console.log("ROOT", root);
						//console.log("child nodes  ", child_nodes[root.id]);
						//root.child_nodes = child_nodes[root.id];
						root_nodes.push(root);
					}
				}
				return root_nodes;
			},
			set (value) {
				this.$store.commit("SET_ROOT_NARRAS", value);
			}
		},
		templates_options () {
			const templates = this.$store.getters.getTemplates;
			let options = [];
			//options.push({ value: null, text: "Select a template" });
			for (let i = 0; i < templates.length; i++) {
				try {
					const template = templates[i];
					if (template.versions[0].type[0] != "_") {
						//options.push({ value: template.id, text: template.versions[0].type });
						const roots = this.root_nodes.filter(function (tree) {
							return tree.versions[0].name == template.versions[0].type;
						});

						if (roots.length == 0) options.push(template.versions[0].type);
					}
				} catch (err) {
					console.debug("templates_options - ", templates[i].id, templates[i]);
				}
			}

			let options_sorted = options.sort((x, y) => {
				//if (x.text == "Select a template") return 1;
				//if (y.text == "Select a template") return 1;

				if (x.toLowerCase() < y.toLowerCase()) return -1;
				if (x.toLowerCase() > y.toLowerCase()) return 1;

				return 0;
			});

			options_sorted = [...new Set(options_sorted)];

			return options_sorted;
		},
		scenariosBranchesByTreeId () {
			return (treeId) => {
				const branches = this.scenariosBranches.treeId && this.scenariosBranches.treeId === treeId ? this.scenariosBranches.branches : [];
				if (branches.length) {
					return branches;
				} else {
					return [];
				}
			};
		},
		filterCubes () {
			return (id) => {
				const cubes = this.cubes.find(item => item.id === id);
				return cubes && cubes.branches ? cubes.branches : [];
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.categoryscenario-header {

	& > * {
		margin-right: .5rem;
	}

	&__generator-btn {
		font-size: 16px;
		margin-right: .8rem;
	}

	&__delete-btn {
		font-size: 19px;
	}

	button:focus {
		outline: none;
	}
}
</style>
