var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scenarios-branch-visual",
      attrs: { "data-test-id": "scenarios-branch-visual" },
    },
    [
      _vm.isLoading
        ? [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-center align-items-center",
                staticStyle: { height: "40px" },
              },
              [
                _c("b-spinner", {
                  attrs: { variant: "primary", label: "Loading branches" },
                }),
              ],
              1
            ),
          ]
        : [
            _vm.hasBranches
              ? [
                  _c(
                    "ul",
                    {
                      staticClass: "scenarios-branch-visual__categories no-pd",
                    },
                    _vm._l(
                      _vm.addedCategories(_vm.branches),
                      function (category, categoryIndex) {
                        return _c(
                          "li",
                          {
                            key: categoryIndex,
                            staticClass:
                              "scenarios-branch-visual__category-item",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "scenarios-branch-visual__category",
                              },
                              [_vm._v(_vm._s(category.scenario_category_name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "scenarios-branch-visual__scenarios flex",
                              },
                              _vm._l(
                                _vm.addedScenarios(category.scenarios),
                                function (scenario, indexScenario) {
                                  return _c(
                                    "li",
                                    {
                                      key: indexScenario,
                                      staticClass:
                                        "scenarios-branch-visual__scenario",
                                    },
                                    [
                                      _c(
                                        "v-checkbox",
                                        {
                                          attrs: {
                                            label: scenario.name,
                                            checked: scenario.enabled,
                                            small: true,
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              disabled: "",
                                            },
                                            domProps: {
                                              checked: scenario.enabled,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              : [
                  _c(
                    "p",
                    { staticClass: "scenarios-branch-visual__no-data d-none" },
                    [_vm._v("No cubes available")]
                  ),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }