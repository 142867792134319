import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Aliases extends BaseClient {
	constructor () {
		super(httpClient, "");
	}

	getById (alias_id) {
		return this.get(`/alias/${alias_id}`);
	}

	getAliasesForDomain (domain_id) {
		return this.get(`/domains/${domain_id}/alias`);
	}

	createalias (alias) {
		return this.post("/alias/", alias);
	}

	deletealias (alias_id) {
		return this.delete(`/alias/${alias_id}`);
	}

	updatealias (alias) {
		return this.patch(`/alias/${alias.id}`, alias);
	}
}

export default new Aliases();
