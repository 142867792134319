<template>
  <section class="login content p-0 d-flex align-items-center justify-content-center">
    <div class="login__wrap ">
			<div class="login__form">
				<b-alert class="mb-4" v-if="showError" show variant="danger">Authentication failed</b-alert>
				<b-card header="Sign In">
					<b-form @submit.prevent="login">
						<b-form-group id="input-group-1" label-for="input-1">
							<b-form-input
								id="user"
								v-model="credentials.username"
								type="text"
								required
								placeholder="User"
							></b-form-input>
						</b-form-group>

						<b-form-group id="input-group-2" label-for="input-2">
							<b-form-input
								id="input-2"
								v-model="credentials.password"
								required
								placeholder="Password"
								type="password"
							></b-form-input>
						</b-form-group>

						<b-button type="submit" variant="primary">Submit</b-button>
					</b-form>
				</b-card>
			</div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </section>
</template>
<script>
import httpClient from "@/api/config/httpClient";
import router from "@/router";
import Loading from "@/components/UI/Loading";
import template_version_api from "../api/template_version";
import users_api from "../api/users";
import { mapGetters } from "vuex";

export default {
	name: "Login",
	components: {
		Loading
	},
	data () {
		return {
			credentials: {},
			showError: false,
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			jwtToken: "getJwtToken"
		})
	},
	methods: {
		async login () {
			this.isLoading = true;
			await httpClient
				.post(`${process.env.AUTH_URL}`, this.credentials, {
					headers: { "Content-Type": "application/json" }
				})
				.then(async (res) => {
					let [response] = res;
					this.showError = false;
					this.$store.commit("SET_JWT_TOKEN", response.token);
					const payload = this.parseJwt(this.jwtToken);
					localStorage.setItem("username", payload.username);
					this.$store.commit("SET_USERNAME", payload.username);

					localStorage.setItem("jwt_token", response.token);
					httpClient.defaults.headers.common["Authorization"] = "JWT " + localStorage.getItem("jwt_token");

					try {
						const [permissions, permissions_errors] = await template_version_api.available_statuses();
						if (permissions) {
							localStorage.setItem("template_versions_permissions", JSON.stringify(permissions));
						}

						const [is_stuff, stuff_errors] = await users_api.isStuff(payload.username);

						if(is_stuff){
							localStorage.setItem("is_stuff", is_stuff.is_stuff);
							console.log("is_stuff", is_stuff.is_stuff);
						}else{
							localStorage.setItem("is_stuff", false);
						}

						const [remote_users, errors] = await users_api.getUsers();
						if (remote_users) {
							let users = [];
							for (const index in remote_users) {
								users.push(remote_users[index].full_name);
							}
							localStorage.setItem("users", JSON.stringify(users));
						}
					} catch (err) {
						console.error(err);
					}

					router.push({ name: "Home" });
				})
				.catch(e => {
					console.error("Error login " + e);
					this.showError = true;
					this.isLoading = false;
				});
		},
		parseJwt (token) {
			let base64Url = token.split(".")[1];
			let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			let jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(""));

			return JSON.parse(jsonPayload);
		}
	}
};
</script>
<style lang="scss" scoped>
.login {
	height: 100%;

	&__wrap {
		flex: 0 0 25%;
    max-width: 25%;
	}
}

/*! CSS Used from: Embedded */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button {
  text-transform: none;
}
button,
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
h2 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h2 {
  font-size: 2rem;
}
.small {
  font-size: 80%;
  font-weight: 400;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-group {
  margin-bottom: 1rem;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
  opacity: 0.65;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
h2 {
  font-weight: normal;
}
*,
:after,
:before {
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}
/*! CSS Used from: Embedded */
.fh[data-v-ef68022e] {
  height: 100%;
}
h2[data-v-ef68022e] {
  margin: 10px 0;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 300;
  color: #2c2d2f;
}
h2[data-v-ef68022e] {
  font-size: 31px;
  line-height: 40px;
}
p[data-v-ef68022e] {
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}
.small[data-v-ef68022e] {
  line-height: 18px;
  font-size: 85%;
}
.semi-bold[data-v-ef68022e] {
  font-weight: 400 !important;
}
.bg-white[data-v-ef68022e] {
  background-color: #fff;
}
.text-white[data-v-ef68022e] {
  color: #fff !important;
}
@media (max-width: 1400px) {
  p[data-v-ef68022e] {
    font-size: 13px;
    line-height: 20px;
  }
  h2[data-v-ef68022e] {
    font-size: 28px;
    line-height: 40px;
  }
  .small[data-v-ef68022e] {
    font-size: 89%;
    line-height: 17px;
  }
}
.btn[data-v-ef68022e] {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #e8e9ea;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background-image: none !important;
  color: #575a5d;
  background-color: #fff;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 21px;
  position: relative;
  -webkit-transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
  padding: 6px 17px;
}
.btn[data-v-ef68022e]:hover {
  background-color: #fafafa;
  border: 1px solid rgba(87, 90, 93, 0.27);
  color: #333;
}
.btn[data-v-ef68022e]:focus,
.btn[data-v-ef68022e]:active:focus {
  outline: none !important;
  outline-style: none;
  color: #333;
}
.btn[data-v-ef68022e]:hover,
.btn[data-v-ef68022e]:focus,
.btn[data-v-ef68022e]:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
button[data-v-ef68022e]:focus {
  outline: none !important;
}
.btn-primary[data-v-ef68022e],
.btn-primary[data-v-ef68022e]:focus {
  color: #fff;
  background-color: #157ef3;
  border-color: #157ef3;
}
.btn-primary[data-v-ef68022e]:active,
.btn-primary[data-v-ef68022e]:active:focus,
.btn-primary[data-v-ef68022e]:active:hover {
  background-color: #069d4d;
  border-color: #069d4d;
  color: #fff;
}
.btn-primary[data-v-ef68022e]:hover {
  background-color: #39ce7f;
  border-color: #39ce7f;
  color: #fff;
}
input[data-v-ef68022e],
button[data-v-ef68022e] {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
input[data-v-ef68022e]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[data-v-ef68022e] {
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2d2f;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}
.form-control[data-v-ef68022e]:focus {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #e8e9ea;
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[data-v-ef68022e]:focus::-moz-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control[data-v-ef68022e]:focus:-ms-input-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control[data-v-ef68022e]:focus::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control[data-v-ef68022e]::-moz-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control[data-v-ef68022e]:-ms-input-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control[data-v-ef68022e]::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-group[data-v-ef68022e] {
  margin-bottom: 10px;
}
input[data-v-ef68022e],
input[data-v-ef68022e]:focus {
  -webkit-transition: none !important;
}
input[data-v-ef68022e]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
input[data-v-ef68022e]:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #e8e9ea inset !important;
}
.login-wrapper[data-v-ef68022e] {
  height: 100%;
  background-color: #157ef3;
}
.login-wrapper > *[data-v-ef68022e] {
  height: 100%;
}
.login-wrapper .bg-pic[data-v-ef68022e] {
  position: absolute;
  width: 100%;
  overflow: hidden;
}
.login-wrapper .bg-pic > img[data-v-ef68022e] {
  height: 100%;
  opacity: 0.6;
}
.login-wrapper .login-container[data-v-ef68022e] {
  width: 700px;
  height: 1000px;
  display: block;
  position: relative;
  float: right;
}
.login-wrapper .bg-caption[data-v-ef68022e] {
  width: 500px;
}
@media (max-width: 768px) {
  .login-wrapper .login-container[data-v-ef68022e] {
    width: 100%;
  }
}
@media only screen and (max-width: 321px) {
  .login-wrapper .login-container[data-v-ef68022e] {
    width: 100%;
  }
}
.pull-bottom[data-v-ef68022e] {
  position: absolute !important;
  bottom: 0;
}
.p-t-15[data-v-ef68022e] {
  padding-top: 15px !important;
}
.p-l-20[data-v-ef68022e] {
  padding-left: 20px !important;
}
.p-t-35[data-v-ef68022e] {
  padding-top: 35px !important;
}
.p-t-50[data-v-ef68022e] {
  padding-top: 50px !important;
}
.p-r-50[data-v-ef68022e] {
  padding-right: 50px !important;
}
.p-l-50[data-v-ef68022e] {
  padding-left: 50px !important;
}
.m-r-20[data-v-ef68022e] {
  margin-right: 20px;
}
.m-l-20[data-v-ef68022e] {
  margin-left: 20px;
}
.m-b-20[data-v-ef68022e] {
  margin-bottom: 20px;
}
.m-t-30[data-v-ef68022e] {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .sm-pull-bottom[data-v-ef68022e] {
    position: relative !important;
  }
  .sm-p-r-15[data-v-ef68022e] {
    padding-right: 15px !important;
  }
  .sm-p-l-15[data-v-ef68022e] {
    padding-left: 15px !important;
  }
}
</style>
