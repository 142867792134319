<template>
<div class="wrap-restore-parent-segment">
	<b-modal data-test-id="restore-parent-segment" class="restore-parent-segment" id="restore-parent-segment" ref="restore-parent-segment" size="lg" title="Restore items" no-close-on-backdrop centered>
		<b-overlay no-wrap :show="waitingResponse" rounded="true"></b-overlay>
		<div class="archive-parernt-segment__body">
			<div v-bind:class="feedbackCssClass" v-show="showRequestError">
				<b-alert v-model="showRequestError" variant="danger" dismissible @dismissed="toggleRequestErrorVisibility">Error</b-alert>
			</div>
			<form action="">
				<b-form-group label="Elements to restore:">
					<div class="d-flex flex-row pl-2">
						<b-form-radio v-model="includeChildren" name="some-radios" value="true" class="mr-4" :checked="treeNodeToRestore.isRoot">Include all children</b-form-radio>
						<b-form-radio v-model="includeChildren" name="some-radios" value="false" :disabled="treeNodeToRestore.isRoot">Just the current one</b-form-radio>
					</div>
				</b-form-group>
				<!-- <b-form-checkbox
					id="include-segments"
					v-model="includeSegments"
					:disabled="treeNodeToRestore.isRoot"
					name="include-segments"
					unchecked-value="false"
					class="pl-3"
				>
					<span>Include all children segments.</span>
				</b-form-checkbox> -->
			</form>
		</div>
		<template #modal-footer>
			<b-button @click="closeMainModal" variant="secondary">Cancel</b-button>
			<b-button @click="restore" variant="primary">Restore</b-button>
		</template>
	</b-modal>
</div>
</template>

<script>
import narrasTreeNodesApi from "@/api/narras_tree_nodes";
import { mapGetters } from "vuex";
export default {
	name: "ModalRestoreTreeNode",
	data () {
		return {
			includeChildren: false,
			includeSegments: true,
			waitingResponse: false,
			showRequestError: false
		}
	},
	mounted () {
		this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
			console.log("si", this.treeNodeToRestore.isRoot)
      this.includeSegments = this.treeNodeToRestore.isRoot;
			this.includeChildren = this.treeNodeToRestore.isRoot;
			this.showRequestError = false;
    })
	},
	computed: {
		...mapGetters({
			treeNodeToRestore: "getTreeNodeToRestore"
		}),
		feedbackCssClass: function () {
			return {
				"branch-generator__feedback": true,
				"visible": this.showRequestError
			}
		}
	},
	methods: {
		closeMainModal () {
			this.showRequestError = false;
			this.waitingResponse = false;
			this.includeSegments = true;
			this.includeChildren = false;
			this.$refs["restore-parent-segment"].hide();
		},
		async restore () {
			console.log( this.includeChildren, this.includeSegments)
			this.waitingResponse = true;
			const [data, err] = await narrasTreeNodesApi.restoreSegments(this.treeNodeToRestore.id, this.includeChildren, this.includeSegments);

			if (err) {
				this.showRequestError = true;
				this.waitingResponse = false;
				return false;
			}

			this.waitingResponse = false;
			this.$emit("refreshData");
			this.closeMainModal();
			this.$bvToast.show("restore-segments-toast");
		},
		toggleRequestErrorVisibility () {
			this.showRequestError = !this.showRequestError;
		}
	}
}
</script>

<style lang="scss" scoped>
.restore-parent-segment {
	&__feedback {
		display: none;
		height: 60px;
		text-align: center;
	}
}
</style>
