import axios from 'axios';

class HtmlValidatorW3C  {

	validate_w3c (data) {

		const options = {
			headers: {"Content-Type": "text/html; charset=utf-8"},
		};

		return axios.post('https://validator.w3.org/nu/?out=json', data, options);
	}
}

export default new HtmlValidatorW3C();
