<template>
  <div>
		<b-navbar toggleable="lg" type="light" variant="light">
			<b-navbar-brand href="#">Import segments from another domain</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click="closeModal">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>

    <div class="modal-div-container mb-5">
			<b-row class="mb-5">
				<b-col cols="4">
          <div class="card variations-card-columns variations-card">
            <div class="card-header variations-header">Domains</div>
						<div class="card-body scroll" >
							<b-row>
                <b-form-input
                  size="sm"
                  class="mr-sm-2 input-search-template"
                  placeholder="Search"
                  v-model="filterDomain"
                ></b-form-input>
              </b-row>
              <br>
              <p v-for="domain in domains_filter" v-bind:key="domain.id">
                <a href="#" @click.prevent.stop="selectDomain(domain.id)">{{ domain.name }}</a>
              </p>
            </div>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="card variations-card-columns variations-card">
            <div class="card-header variations-header">Documents</div>
            <div class="card-body scroll">
							<p v-for="document in documents" v-bind:key="document.id">
                <a href="#" @click.prevent.stop="selectDocument(document.id)">{{ document.name }}</a>
              </p>
            </div>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="card variations-card-columns variations-card">
            <div class="card-header variations-header">Segments</div>
            <div class="card-body scroll">
              <b-form-group v-if="segments.length > 0"	label="Select segments to import">
                <b-form-checkbox
                  v-for="segment in segments" v-bind:key="segment.id"
                  v-model="segments_check"
                  :value="segment.versions[0].type"
                  >
                  {{ segment.versions[0].type }}
                </b-form-checkbox>
              </b-form-group>

            </div>
            <div v-if="segments.length > 0" class="text-right m-1">
              <b-button
                variant="info"
                :disabled="segments_check.length === 0"
                @click.prevent="includeSelected(segments_check)">Import segments</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import domains_api from "../../api/domain.js";
import document_templates_api from "../../api/document_templates.js";

export default {
	name: "ImportTemplatesModal",
	props: {
		modalName: {type: String}
	},
	data () {
		return {
			filterDomain: "",
			domains: [],
			documents: [],
			segments: [],
			segments_check: [],
			domain_selected: 0,
			document_selected: 0
		};
	},
	created () {
		this.getDomains();
	},
	methods: {
		async getDomains () {
			//const [data, err] = await domains_api.getAll();
			let self = this;
			domains_api.getAll().then(function ([data, err]) {
				self.domains = data;
			});
		},
		async selectDomain (domain_id) {
			this.domain_selected = domain_id;
			const [data, err] = await domains_api.getDocuments(domain_id);

			if (data.documents != undefined) this.documents = data.documents;
			else this.documents = [];

			this.segments = [];
		},
		async selectDocument (document_id) {
			this.document_selected = document_id;

			const [data, err] = await document_templates_api.getParents(document_id);
			if (!err) {
				this.segments = data.templates;
			} else {
				this.segments = [];
			}
		},
		closeModal () {
			console.log("closeModal", this.modal_name);
			this.$modal.hide(this.modalName);
		},
		includeSelected (selectedItems) {
			console.log("selectedItems", selectedItems);
			this.$emit(
				"importSegments",
				this.domain_selected,
				this.document_selected,
				selectedItems
			);
			this.$modal.hide(this.modalName);
		}
	},
	computed: {
		domains_filter () {
			let regex = new RegExp("(" + this.filterDomain + ")", "i");
			return this.domains.filter(function (domain) {
				return domain.name.match(regex);
			});
		}
	}
};
</script>
