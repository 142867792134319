var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-template-nv" },
    [
      _c("div", { class: _vm.getClassForTemplate(_vm.template) }, [
        _c("div", { staticClass: "col-6 pr-0" }, [
          _c("a", { ref: "anchor", attrs: { name: _vm.template.id } }),
          _vm._v(" "),
          _c("div", { staticClass: "template-header pt-0 pb-0 pl-0" }, [
            _c("div", { staticClass: "row template-row-header ml-0" }, [
              _c("div", { staticClass: "col np-pd flex flex-separate" }, [
                _c(
                  "div",
                  [
                    _vm.available_template_version_statuses.length >= 1
                      ? [
                          _c(
                            "b-dropdown",
                            {
                              staticClass:
                                "template-id-number text-center mt-0",
                              attrs: {
                                dropright: "",
                                "no-caret": "",
                                size: "sm",
                                variant: _vm.template_status,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.template.id) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2479376633
                              ),
                            },
                            [
                              _vm._v(" "),
                              !_vm.readOnly
                                ? _vm._l(
                                    _vm.available_template_version_statuses,
                                    function (status) {
                                      return _c(
                                        "div",
                                        { key: status.id },
                                        [
                                          status.name != _vm.template_status
                                            ? [
                                                _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      "text-transform":
                                                        "capitalize",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateTemplateVersionStatus(
                                                          status.id,
                                                          status.name
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          "btn-" + status.name,
                                                      },
                                                      [_vm._v(" ")]
                                                    ),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          status.name.replace(
                                                            "_",
                                                            " "
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.available_template_version_statuses.length < 1
                      ? [
                          _c(
                            "div",
                            {
                              class:
                                "template-id-number text-center mt-0 mr-0 btn-" +
                                _vm.template_status,
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.template.id) +
                                  "\n\t\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.readOnly
                      ? [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showHistory.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("b-badge", {
                                staticClass: "template-updated-at m-0 p-0 mr-1",
                                attrs: { variant: "light" },
                                domProps: {
                                  textContent: _vm._s(_vm.updated_at),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showHistory.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-history template-circle-button",
                              }),
                              _vm._v(" "),
                              _c("b-badge", {
                                staticClass: "template-updated-at m-0 p-0 mr-1",
                                attrs: { variant: "light" },
                                domProps: {
                                  textContent: _vm._s(_vm.updated_at),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("ButtonCircle", {
                            ref: "ButtonEye",
                            staticClass: "template-preview-icon",
                            attrs: {
                              template: _vm.template,
                              icon: "play",
                              title: "Run this segment with selected data file",
                            },
                            on: { click: _vm.update_regular_parts },
                          }),
                          _vm._v(" "),
                          _c("ButtonCircle", {
                            ref: "ButtonEyeComments",
                            staticClass: "template-preview-icon",
                            attrs: {
                              template: _vm.template,
                              icon: "comments",
                              title: "Show comments",
                            },
                            on: { click: _vm.toggle_messages },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "row-template__extra-options",
                              attrs: {
                                id: "rowTemplatesExtraOptions",
                                size: "sm",
                                variant: "link",
                                "toggle-class": "text-decoration-none m-0 p-0",
                                "no-caret": "",
                                title: "Extra options",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "template-circle-button",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "ellipsis-v",
                                              size: "sm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.showHistory.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("History")]
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  ref: "ButtonEye5X",
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewTemplate(
                                        $event,
                                        "ButtonEye",
                                        false,
                                        5
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Preview 5x")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  ref: "ButtonEye20X",
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewTemplate(
                                        $event,
                                        "ButtonEye",
                                        false,
                                        20
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Preview 20x")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  ref: "ButtonEye50X",
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewTemplate(
                                        $event,
                                        "ButtonEye",
                                        false,
                                        50
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Preview 50x")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  ref: "RealPreview",
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewTemplate(
                                        $event,
                                        "ButtonEye",
                                        false,
                                        1,
                                        "false"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Real Preview")]
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.$emit(
                                        "filterByTemplateID",
                                        _vm.template
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Filter")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openPopupParaphrasing.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Paraphrasing")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "collapseControl",
                                  attrs: { title: "Translate segment" },
                                  on: { click: _vm.translate },
                                },
                                [_vm._v("Translate")]
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  ref: "ButtonDuplicate",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "duplicateTemplate",
                                        _vm.template
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n\t\t\t\t\t\t\t\t\t\t\tDuplicate")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.togglePlainView()
                                    },
                                  },
                                },
                                [_vm._v("Plain View")]
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  ref: "ButtonDelete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTemplate($event)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash",
                                    staticStyle: { color: "red" },
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("Delete")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                    _vm._v(" "),
                    _vm.templates_without_segements
                      ? _c(
                          "span",
                          {
                            staticClass: "template-cell-type",
                            attrs: { contenteditable: "", spellcheck: "false" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.template.versions[0].type
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.updateVersion(
                                  $event,
                                  0,
                                  _vm.template.versions[0]
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.template.versions[0].type) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.show_messages
                ? _c(
                    "div",
                    { staticClass: "col-12 message-list pt-2" },
                    [
                      _vm._l(_vm.template.messages, function (message) {
                        return _c(
                          "div",
                          { key: message.id, staticClass: "col-12" },
                          [
                            _c("div", { staticClass: "message-header" }, [
                              _c("div", { staticClass: "message-name" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(message.first_name) +
                                    " " +
                                    _vm._s(message.last_name) +
                                    "\n                    "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "message-date",
                                    staticStyle: { "margin-left": "2em" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.format_date(message.post_datetime)
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "message-body col-12",
                                class: message.readed ? "" : "message-unreaded",
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-11" }, [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(message.body) +
                                        "\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-1 text-right" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delete_message(
                                                message.id,
                                                message.template_id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "trash-alt" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col pt-2" }, [
                        _vm._v("New message:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-11" },
                            [
                              _c(
                                "atNV",
                                {
                                  attrs: {
                                    ats: ["@"],
                                    members: _vm.users,
                                    hideOnBlur: "",
                                    filterMatch: _vm.filterMatch,
                                    filterMembers: _vm.filterUsers,
                                  },
                                  on: { at: _vm.autocompleteUser },
                                },
                                [
                                  _c("div", {
                                    ref: "new_message_body",
                                    staticClass: "new-message",
                                    attrs: { contenteditable: "" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-1",
                              staticStyle: { margin: "auto" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  staticStyle: { "margin-bottom": "1em" },
                                  on: { click: _vm.create_message },
                                },
                                [
                                  _vm._v(
                                    "\n                      Send\n                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col ml-2" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "m-0 p-0 pl-2 pt-1",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.plainView,
                                expression: "plainView",
                              },
                            ],
                            staticClass:
                              "segment_plain_edit_row row no-gutters",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "AtTextareaNV",
                                  {
                                    ref: "atta" + _vm.template.id,
                                    attrs: {
                                      ats: _vm.triger_chars,
                                      members:
                                        _vm.autocomplete_members_in_templates,
                                      hideOnBlur: "",
                                      filterMatch: _vm.filterMatch,
                                      filterMembers: _vm.filterOnlyAliases,
                                    },
                                  },
                                  [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.template.versions[0]
                                              .text_for_editor,
                                          expression:
                                            "template.versions[0].text_for_editor",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "Plain view",
                                        type: "text",
                                        rows: "1",
                                      },
                                      domProps: {
                                        value:
                                          _vm.template.versions[0]
                                            .text_for_editor,
                                      },
                                      on: {
                                        blur: _vm.onNotifyValueChange,
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.template.versions[0],
                                            "text_for_editor",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.oldModeEditActive
                          ? _c("div", { staticClass: "row d-flex flex-wrap" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "at",
                                    {
                                      ref: "at" + _vm.template.id,
                                      attrs: {
                                        ats: _vm.triger_chars,
                                        members:
                                          _vm.autocomplete_members_in_templates,
                                        hideOnBlur: "",
                                        filterMatch: _vm.filterMatch,
                                        filterMembers: _vm.filterOnlyAliases,
                                      },
                                    },
                                    [
                                      _c(
                                        "Highlightable",
                                        {
                                          ref:
                                            "Highlightable" + _vm.template.id,
                                          on: {
                                            onVariations:
                                              _vm.openPopupVariations,
                                            onCreateTempByText:
                                              _vm.createTempByText,
                                            onParaphrasing:
                                              _vm.openPopupParaphrasing,
                                          },
                                        },
                                        [
                                          _c("highlightable-input", {
                                            ref: "text" + _vm.template.id,
                                            attrs: {
                                              "highlight-style":
                                                "badge-data-template",
                                              "highlight-enabled": "",
                                              highlight: _vm.highlight,
                                              refreshHighlights:
                                                _vm.refreshHighlights,
                                              category_id: _vm.category_id,
                                              canEdit: "",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                return _vm.handleTextKeyDown(
                                                  $event,
                                                  _vm.template
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.textTemplateLostFocus(
                                                  $event,
                                                  _vm.template
                                                )
                                              },
                                              focus: function ($event) {
                                                return _vm.textTemplateGetFocus(
                                                  $event,
                                                  _vm.template
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.template.versions[0]
                                                  .text_for_editor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.template.versions[0],
                                                  "text_for_editor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "template.versions[0].text_for_editor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "segment_edit_row row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "atNV",
                                    {
                                      ref: "at" + _vm.template.id,
                                      attrs: {
                                        ats: _vm.triger_chars,
                                        members:
                                          _vm.autocomplete_members_in_templates,
                                        hideOnBlur: "",
                                        filterMatch: _vm.filterMatch,
                                        filterMembers: _vm.filterOnlyAliases,
                                      },
                                    },
                                    [
                                      _c(
                                        "HighlightableNV",
                                        {
                                          ref:
                                            "Highlightable" + _vm.template.id,
                                          on: {
                                            onVariations:
                                              _vm.openPopupVariations,
                                            onCreateTempByText:
                                              _vm.createTempByText,
                                          },
                                        },
                                        [
                                          _c("Highlightable-inputNV", {
                                            ref: "text" + _vm.template.id,
                                            attrs: {
                                              "highlight-style":
                                                "badge-data-template",
                                              "highlight-enabled": "",
                                              highlight: _vm.highlight,
                                              refreshHighlights:
                                                _vm.refreshHighlights,
                                              corrections: _vm.corrections,
                                              category_id: _vm.category_id,
                                              canEdit: "",
                                              templateIdAncestors:
                                                _vm.template.id,
                                            },
                                            on: {
                                              mouseup: function ($event) {
                                                return _vm.selectionText(
                                                  $event,
                                                  _vm.template.versions[0]
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.textTemplateLostFocus(
                                                  $event,
                                                  _vm.template
                                                )
                                              },
                                              focus: function ($event) {
                                                return _vm.textTemplateGetFocus(
                                                  $event,
                                                  _vm.template
                                                )
                                              },
                                              joinPartsrefreshContent:
                                                _vm.refreshContent,
                                              notifyValueChange:
                                                _vm.onNotifyValueChange,
                                              duplicateTemplate:
                                                _vm.onDuplicateTemplate,
                                              deleteTemplate:
                                                _vm.onDeleteTemplate,
                                              addNewTemplate:
                                                _vm.onAddNewTemplate,
                                              updateTemplate: function (
                                                $event
                                              ) {
                                                return _vm.previewTemplate(
                                                  $event,
                                                  "ButtonEye",
                                                  false,
                                                  1
                                                )
                                              },
                                              clickCorrections:
                                                _vm.onclickCorrections,
                                              clickSynonym: _vm.showSynonyms,
                                              clickTypo: _vm.showTypos,
                                              clickPlural: _vm.showPlural,
                                            },
                                            model: {
                                              value:
                                                _vm.template.versions[0]
                                                  .text_for_editor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.template.versions[0],
                                                  "text_for_editor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "template.versions[0].text_for_editor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        !_vm.readOnly
          ? _c("div", { staticClass: "col-6 preview-col" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right pr-0 align-middle" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "switch-render-html inline",
                      attrs: {
                        name: "check-renderHTML",
                        size: "sm",
                        inline: "",
                        title: "Render HTML toggle",
                      },
                      model: {
                        value: _vm.renderHTML,
                        callback: function ($$v) {
                          _vm.renderHTML = $$v
                        },
                        expression: "renderHTML",
                      },
                    },
                    [_vm._v("HTML")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "switch-render-html inline",
                      attrs: {
                        name: "check-renderHTML",
                        size: "sm",
                        inline: "",
                        title: "Render HTML toggle",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.saveIsPrompt(_vm.template.is_prompt)
                        },
                      },
                      model: {
                        value: _vm.template.is_prompt,
                        callback: function ($$v) {
                          _vm.$set(_vm.template, "is_prompt", $$v)
                        },
                        expression: "template.is_prompt",
                      },
                    },
                    [_vm._v("Is Prompt")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row segment_preview_row mt-0" }, [
                _c("div", { staticClass: "col ml-3" }, [
                  _c(
                    "div",
                    { staticClass: "template-col-previewnv" },
                    [
                      _vm.variability
                        ? _c("p", {}, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge-template badge-data-template",
                              },
                              [
                                _vm._v(
                                  "\n                  Variability:\n                  "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "variability-percent" },
                                  [_vm._v(_vm._s(_vm.variability) + "%")]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.previews, function (preview, prevIndex) {
                        return _c(
                          "div",
                          {
                            key: prevIndex,
                            staticClass: "template-row-preview-nv",
                          },
                          [
                            !_vm.renderHTML
                              ? [
                                  !preview.error
                                    ? [
                                        preview.log.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "error-invalid-scenario",
                                              },
                                              _vm._l(
                                                preview.log,
                                                function (error, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            error.message
                                                          ) + ":"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              error.details
                                                            )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      preview.log[index + 1]
                                                        ? _c("br")
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !preview.error
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "template-cell-preview-result",
                                              },
                                              [
                                                _c("HTMLValidator", {
                                                  attrs: {
                                                    html_text: preview.result,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "template-cell-preview-error-icon",
                                          attrs: { icon: "exclamation" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "template-cell-preview-error",
                                          domProps: {
                                            innerHTML: _vm._s(preview.error),
                                          },
                                        }),
                                      ],
                                ]
                              : [
                                  !preview.error
                                    ? [
                                        preview.log.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "previewErrorsIcon",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-exclamation-triangle",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.show_preview_errors =
                                                        !_vm.show_preview_errors
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.show_preview_errors &&
                                        preview.log.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "error-invalid-scenario",
                                              },
                                              _vm._l(
                                                preview.log,
                                                function (error, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            error.message
                                                          ) + ":"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              error.details
                                                            )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      preview.log[index + 1]
                                                        ? _c("br")
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.showTemplatesLinks
                                          ? _c("div", {
                                              staticClass:
                                                "template-cell-preview-result",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  preview.result
                                                ),
                                              },
                                              on: {
                                                mouseup: function ($event) {
                                                  return _vm.selectionText(
                                                    $event,
                                                    _vm.template.versions[0]
                                                  )
                                                },
                                              },
                                            })
                                          : _c("div", {
                                              staticClass:
                                                "template-cell-preview-result",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  preview.result_with_sandbox_tags
                                                ),
                                              },
                                              on: {
                                                mouseup: function ($event) {
                                                  return _vm.selectionText(
                                                    $event,
                                                    _vm.template.versions[0]
                                                  )
                                                },
                                              },
                                            }),
                                      ]
                                    : [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "template-cell-preview-error-icon",
                                          attrs: { icon: "exclamation" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "template-cell-preview-error",
                                          domProps: {
                                            innerHTML: _vm._s(preview.error),
                                          },
                                          on: {
                                            mouseup: function ($event) {
                                              return _vm.selectionText(
                                                $event,
                                                _vm.template.versions[0]
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.translates.length > 0
                ? _c("div", { staticClass: "row segment_preview_row mt-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "template-col-previewnv" },
                        [
                          _c("p", { staticClass: "template-row-preview-nv" }, [
                            _c(
                              "span",
                              {
                                staticClass: "collapseControl",
                                attrs: { title: "Translate segment" },
                                on: { click: _vm.translate },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-language fa-2x",
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.translates,
                            function (translate, translateIndex) {
                              return _c(
                                "div",
                                {
                                  key: translateIndex,
                                  staticClass: "template-row-preview-nv",
                                },
                                [
                                  translate.text_dest
                                    ? _c("div", {
                                        staticClass:
                                          "template-cell-preview-result",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            translate.text_dest
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("hr", {
                                    staticClass:
                                      "hr-template-cell-preview-result",
                                  }),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.readOnly
        ? [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("TooltipTemplate", {
                  ref: "tooltip",
                  attrs: { template: _vm.template },
                  on: {
                    includeSynonyms: _vm.onIncludeSynonyms,
                    changeTypo: _vm.onChangeTypo,
                    changePlural: _vm.onChangePlural,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "modal",
              {
                attrs: {
                  height: "950",
                  width: "900",
                  name: _vm.modalSelecionTextName,
                },
              },
              [
                _c("VariationsSelector", {
                  ref: "VariationsSelector",
                  attrs: {
                    minerva_url: _vm.currentDomain.minerva_url,
                    minerva_server: _vm.currentDomain.minerva_server,
                    language: _vm.currentDocument.language,
                    modal_name: _vm.modalSelecionTextName,
                  },
                  on: { selectedItems: _vm.insertVariations },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "modal",
              {
                attrs: {
                  height: "950",
                  width: "900",
                  name: _vm.modalSimilarityName,
                },
              },
              [
                _c("SimilaritySelector", {
                  ref: "VariationsSelector",
                  attrs: {
                    minerva_url: _vm.currentDomain.minerva_url,
                    minerva_server: _vm.currentDomain.minerva_server,
                    language: _vm.currentDocument.language,
                    modal_name: _vm.modalSimilarityName,
                  },
                  on: { includeSimilariry: _vm.insertSimilarity },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "modal",
              {
                staticClass: "entities-modal",
                attrs: {
                  height: "700",
                  width: "1200",
                  name: _vm.modalEntitiesName,
                },
              },
              [
                _c("EntitiesSelector", {
                  ref: "EntitiesSelector",
                  attrs: {
                    language: _vm.currentDocument.language,
                    texts: _vm.textEntities,
                    modal_name: _vm.modalEntitiesName,
                    insert: _vm.entitiesNewTemplates,
                  },
                  on: {
                    includeEntities: _vm.insertEntities,
                    includeEntitiesTemplate: _vm.insertEntitiesTemplate,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "modal",
              {
                attrs: {
                  height: "950",
                  width: "900",
                  name: _vm.modalHistoryName,
                },
              },
              [
                _c("TemplatesHistory", {
                  attrs: {
                    templateId: _vm.template.id,
                    modal_name: _vm.modalHistoryName,
                  },
                  on: { setCurrentVersion: _vm.setCurrentVersion },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "modal",
              {
                attrs: {
                  height: "950",
                  width: "900",
                  name: _vm.modalParaphrasingName,
                },
              },
              [
                _c("ParaphrasingSelector", {
                  ref: "ParaphrasingSelector",
                  attrs: {
                    domain_id: _vm.$store.getters.getCurrentDomain.id,
                    documenta_data_id:
                      _vm.$store.getters.getDocumentDataSelected,
                    language: _vm.$store.getters.getCurrentDocument.language,
                    modal_name: _vm.modalParaphrasingName,
                  },
                  on: { selectedItems: _vm.insertParaphrasing },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-modal",
              {
                attrs: {
                  scrollable: "",
                  height: "5000",
                  width: "60%",
                  resizable: "",
                  draggable: "",
                  size: "xl",
                  name: _vm.narraSelectorModalName,
                  id: _vm.narraSelectorModalName,
                  centered: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modal-header",
                      fn: function (ref) {
                        var hide = ref.hide
                        return undefined
                      },
                    },
                    {
                      key: "modal-footer",
                      fn: function (ref) {
                        var ok = ref.ok
                        var cancel = ref.cancel
                        var hide = ref.hide
                        return [_c("b")]
                      },
                    },
                  ],
                  null,
                  false,
                  4064066194
                ),
              },
              [
                _vm._v(" "),
                _c("NarrasTreesViewerNV", {
                  attrs: {
                    collapse_button: "",
                    tree_editor_button: "false",
                    select_name: "true",
                    rows: _vm.rows,
                    template_name_filter: _vm.template.versions[0].type,
                  },
                  on: { selectNarra: _vm.selectNarra },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-modal",
              {
                ref: "modalGetTypeForNewVersion",
                attrs: {
                  height: "5000",
                  draggable: "",
                  size: "lg",
                  centered: "",
                  "hide-footer": "",
                  "hide-header": "",
                  centered: "",
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "New segment name:",
                      "label-for": "type",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "type", required: "", placeholder: "Name" },
                      model: {
                        value: _vm.typeTempByText,
                        callback: function ($$v) {
                          _vm.typeTempByText = $$v
                        },
                        expression: "typeTempByText",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "input-group-5", "label-align": "left" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "active",
                          value: "true",
                          "unchecked-value": "false",
                        },
                      },
                      [_vm._v("Create associated default scenario")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: !_vm.typeTempByText.length,
                      variant: "primary",
                    },
                    on: { click: _vm.createTempByTextWithType },
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { type: "reset", variant: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs["modalGetTypeForNewVersion"].hide()
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "previewIcon m-1" }, [
      _c("span", [
        _c("i", { staticClass: "fa fa-eye", attrs: { "aria-hidden": "true" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }