<template>
  <b-row class="tree-row">
    <b-col cols="12">
      <b-card>
        <!--<b-row>
          <input
            type="text"
            placeholder="Type to filter..."
            v-model="filterTree"
            class
          />
        </b-row>-->
        <b-row>
          <tree
            class="tree-container"
            :data="Narras"
            :options="options"
            :filter="filterTree"
            @node:dragging:finish="nodeDragFinish"
            ref="tree"
          >
            <div slot-scope="{ node }" class="node-container">
              <div
                v-bind:class="{ 'node-text-inactive': !node.data.active }"
                @click.stop="editNode(node)"
              >
                •&nbsp;
                <span @contextmenu.prevent="$refs.ctxMenu.open($event, node)">{{
                  node.text
                }}</span
                >
                <button href="#" @click.prevent.stop="addChildNode(node)" title="Add a child node to this node">
                  <font-awesome-icon icon="plus" /> </button
                >
                <button href="#" @click.prevent.stop="showAddByCategory(node)" title="Add a tree of a Category to this node">
                  <font-awesome-icon icon="code-branch" /> </button
                >
                <button href="#" @click.prevent.stop="createNodeTemplates(node)"  title="Create a Segment for this node and its descendants" >
                  <font-awesome-icon icon="stream" />
                </button>
                <button href="#" @click.prevent.stop="requestRemoveNode(node)" title="Delete this node and its subnodes"  >
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </div>
            </div>
          </tree>

          <b-col></b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-modal
      size="sm"
      ref="modalGetScenariosCategory"
      centered
      hide-footer
      hide-header
			centered
    >
      <b-form-group
        id="input-group-2"
        label="Select category:"
        label-for="type"
        label-align="left"
      >
        <b-form-select
          class="input-select-document"
          id="engine"
          v-model="categorySelected"
          :options="categoriesScenarios"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <b-button
        :disabled="categorySelected == null"
        variant="primary"
        v-on:click="addNodesByCategory"
        >Create</b-button
      >

      <b-button
        type="reset"
        variant="danger"
        v-on:click="$refs['modalGetScenariosCategory'].hide()"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal
      size="lg"
      ref="modalGetScenario"
      centered
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-form-group
        id="input-group-2"
        label="Select scenario:"
        label-for="type"
        label-align="left"
      >
        <!--b-form-select
					class="input-select-document"
					id="engine"
					v-model="scenarioSelected"
					:options="scenarios"
					size="sm"
				></b-form-select-->
        <v-select :options="scenarios" label="text" v-model="scenarioSelected">
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              <em>Sorry, no matching option or was already defined</em>.
            </template>
            <em v-else>Sorry, no matching option or was already defined</em>
          </template>
        </v-select>
      </b-form-group>
      <b-button
        :disabled="scenarioSelected == null"
        variant="primary"
        v-on:click="addNode(true)"
        >OK</b-button
      >

      <b-button
        type="reset"
        variant="danger"
        v-on:click="$refs['modalGetScenario'].hide()"
        >Cancel</b-button
      >
    </b-modal>

    <context-menu id="context-menu" ref="ctxMenu" @ctx-open="onCtxOpen">
      <li class="ctx-item" @click="copy_node($event, menuData)">Copy</li>
      <li class="ctx-item" @click="paste_node($event, menuData)">Paste</li>
    </context-menu>
    <Loading v-if="isLoading"></Loading>
  </b-row>
</template>

<script>
import tree_nodes_api from "../../api/narras_tree_nodes.js";
import tree_nodes_versions_api from "../../api/narras_tree_nodes_versions.js";
import templates_api from "../../api/template.js";
import narras_templates_api from "../../api/narras_tree_templates";
import document_templates_api from "../../api/document_templates.js";
import template_version_api from "../../api/template_version.js";
import Loading from "@/components/UI/Loading";
import At from "@/components/UI/AutoComplete/At.vue";
import contextMenu from "vue-context-menu";
import vSelect from "vue-select";
import { alertMixins } from "@/mixins/alertMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";

export default {
	name: "ScenariosTree",
	components: {
		Loading,
		At,
		contextMenu,
		vSelect
	},
	mixins: [alertMixins, narrasMixins],
	data () {
		return {
			isLoading: false,
			categorySelected: null,
			scenarioSelected: null,
			nodeCreateNodesByCategory: {},
			filterTree: "",
			copiedNode: null,
			trigerChars: ["CALC", ".", "'", "CALC_NARRA_", "--"],
			options: {
				checkbox: false,
				deletion: false,
				dnd: true,
				filter: {
					emptyText: "Nothing found!",
					matcher (query, node) {
						return new RegExp(query, "i").test(node.text);
					},
					plainList: true,
					showChildren: false
				}
			},
			node_data: {
				id: "",
				name: "",
				scenario_id: 0,
				active: "1",
				domain_id: "",
				parent_id: "",
				parent_node: {}
			},
			Narras: []
		};
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getRowsNarras,
			(document_id, oldValue) => {
				this.loadTree();
			}
		);
	},

	mounted () {
		//retardamos la carga de los nodos para que de tiempo a que se creeen los componenetes
		this.loadTree();
	},
	methods: {
		loadTree () {
			//retardamos la carga de los nodos para que de tiempo a que se creeen los componenetes
			let self = this;
			setTimeout(function () {
				let selection;
				if (self.$refs["tree"]) {
					selection = self.$refs["tree"].findAll({
						state: { disabled: false }
					});
					//console.log("selection", selection);
					selection.remove();
				}

				//console.log("create");
				let root_narras = self.$store.getters.getRootNarras;

				if (root_narras.nodes) {
					//this.firstLoad = false;
					let child_nodes = self.$store.getters.getRowsNarras;

					for (let i = 0; i < root_narras.nodes.length; i++) {
						const root = root_narras.nodes[i];
						//console.log("ROOT", root);
						if (root.id == self.root_node.id) {
							//console.log("putChildNodes");
							//console.log("child nodes  ", child_nodes[root.id]);

							self.putChildNodes(child_nodes[root.id], root, null);
						}
					}
				}
			}, 1000);
		},
		filterMatch (text, name, chunk, at) {
			//console.log("filterMatch vue ", text, name, chunk, at);
			return name.indexOf(chunk) > -1;
		},

		cancel () {
			this.$refs["modal-new"].hide();
		},

		filterMembersAutocomplete (text, chunk, at, index) {
			//console.log("filterMembersAutocomplete", text, chunk, at, index);
			if (at == "CALC" || at == "'" || at == "CALC_NARRA_") {
				let items = [];
				if (at == "CALC") {
					items = ["CALC_NARRA_", "CALC_NARRA_EXP(data_row['"];
					//.concat(this.autocomplete_members);
				} else {
					items = this.autocomplete_members;
				}

				return items.filter((v) => {
					return v.indexOf(chunk) > -1;
				});
			} else {
				//es un campo
				//hay que obtener de que entidad se trata
				//Puede ser algo:
				//- [Entity.field
				//- [Entity[0].field
				//- [Entity--relation->Entity2.field
				//- [Entity--relation->Entity2[1].field
				//__len__ y __sort__ estos son bastante especiales

				//hay que buscar la primera aparición hacia atras desde el index de at de los caracteres "[", "(" o "->"
				function searchOperand (text, index) {
					let lastCALC_NARRA = text
						.substring(0, index)
						.lastIndexOf("CALC_NARRA_");
					let lastQuote = text.substring(0, index).lastIndexOf("'");
					let lastBracket = text.substring(0, index).lastIndexOf("[");
					let lastUnderscore = text.substring(0, index).lastIndexOf("_");
					let lastOperand = text.substring(0, index).lastIndexOf("->");
					let lastPoint = text.substring(0, index).lastIndexOf(".");
					console.log(
						"last ",
						lastCALC_NARRA,
						lastQuote,
						lastBracket,
						lastUnderscore,
						lastOperand,
						lastPoint
					);

					let fromChar =
            Math.max(
            	...[
            		lastCALC_NARRA,
            		lastQuote,
            		lastBracket,
            		lastUnderscore,
            		lastOperand,
            		lastPoint
            	]
            ) + 1;
					//console.log("fromChar1 " + fromChar + ":" + text.substring(fromChar));

					return fromChar;
				}
				let fromChar = searchOperand(text, index);

				let isEndBracket = -1;
				if (text[fromChar - 1] == "[") {
					//puede que si hay un corchete sea por acceder a un elemento [0]
					isEndBracket = text.substring(fromChar - 1, index).lastIndexOf("]");
					console.log("isEndBracket " + isEndBracket);
					if (isEndBracket > -1) {
						index = fromChar - 1;
						fromChar = searchOperand(text, fromChar - 2);
					}
				}
				console.log("isEndBracket " + isEndBracket);
				if (
					text.substring(fromChar).lastIndexOf("ALC_NARRA_") > -1 &&
          isEndBracket < 0
				) {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					console.log("Tiene ALC_NARRA_");
					fromChar += "ALC_NARRA_".length;
				}

				if (text[fromChar] == ">") {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					fromChar++;
				}
				//console.log("fromChar2 " + fromChar + ":" + text.substring(fromChar));

				if (at == "__sort__") index--;
				let entity = text.substring(fromChar, index);
				console.log("Entity " + entity);
				if (entity.indexOf(".") > -1) {
					//La entidad no puede tener un punto como nombre ya que es un operador. Si viene un punto es que tenemos alguna firigrana
					//del tipo Index.__sort__region_name__desc[0]. La entidad es hasta el primer punto
					entity = entity.substring(0, entity.indexOf("."));
				}
				console.log("Entity " + entity);
				console.log("at " + at);

				if (at == "." || at == "__sort__") {
					console.log("properties");
					//

					return this.$store.getters.getAutocompleteNSchemaMembers.properties[
						entity
					].filter((v) => {
						return v.indexOf(chunk) > -1;
					});
				} else if (at == "--") {
					return this.$store.getters.getAutocompleteNSchemaMembers.relations[
						entity
					].filter((v) => {
						return v.indexOf(chunk) > -1;
					});
				}
			}
		},
		autocompletAt (index, chunk) {
			console.log("evento at " + index + " --- " + chunk);
		},
		addChildNode (node) {
			console.log("addChild", node);

			this.node_data.active = true;
			this.node_data.id = "";
			this.node_data.name = "";
			this.node_data.scenario_id = null;

			this.node_data.domain_id = this.domainId;
			if (node.data) {
				this.node_data.parent_id = node.data.id;
				this.node_data.parent_node = node;
			} else {
				this.node_data.parent_id = node.id;
				this.node_data.parent_node = null;
			}

			this.scenarioSelected = null;

			this.$refs["modalGetScenario"].show();
		},
		editNode (node, e) {
			if (node.enabled()) {
				console.log("editChild");
				console.log(node.data.versions[0]);

				//node.append("New Node");
				this.node_data.active = node.data.active;
				this.node_data.id = node.data.id;

				//check if scenario id exits
				const scenario_id = node.data.versions[0].scenario_id;
				const scenarios = this.scenarios.filter((s) => {
					return s.value == scenario_id;
				});

				if (scenarios.length > 0) {
					this.node_data.scenario_id = node.data.versions[0].scenario_id;
					this.scenarioSelected.value = node.data.versions[0].scenario_id;
				} else {
					this.node_data.scenario_id = null;
					this.scenarioSelected = null;
				}

				this.node_data.domain_id = node.data.domain_id;
				this.node_data.parent_id = node.data.versions[0].parent_id;
				this.node_data.parent_node = node;

				//this.scrollToForm();
				this.$refs["modalGetScenario"].show();
			}
		},
		showAddByCategory (node) {
			this.categorySelected = null;
			this.nodeCreateNodesByCategory = node;
			this.$refs["modalGetScenariosCategory"].show();
		},
		async addNodesByCategory () {
			this.$refs["modalGetScenariosCategory"].hide();

			const scenarios = this.getScenariosByCategory(this.categorySelected);
			console.log(scenarios);
			let self = this;
			for (let i = 0; i < scenarios.length; i++) {
				const s = scenarios[i];
				if (s.versions[0].name.trim().length > 0) {
					this.node_data.active = true;
					this.node_data.id = "";
					this.node_data.name = s.versions[0].name;
					this.node_data.scenario_id = s.id;

					this.node_data.domain_id = this.domainId;
					console.log(
						"nodeCreateNodesByCategory",
						this.nodeCreateNodesByCategory
					);
					if (this.nodeCreateNodesByCategory.data) {
						this.node_data.parent_node = this.nodeCreateNodesByCategory;
						this.node_data.parent_id =
              this.nodeCreateNodesByCategory.data.versions[0].narra_tree_node_id;
					} else {
						//Cuelga del nodo raiz, osea es de primer nivel del árbol
						this.node_data.parent_id =
              this.nodeCreateNodesByCategory.versions[0].narra_tree_node_id;
						this.node_data.parent_node = this.null;
					}

					await this.addNode(false);
				}
			}
		},
		getScenariosByCategory (category_id) {
			const categories = this.$store.getters.getScenarios;
			let scenarios = [];
			[...categories].forEach((c) => {
				console.log("compara", c.id, category_id);
				if (c.id == category_id) {
					console.log("retorna", c.scenarios);
					scenarios = c.scenarios;
				}
			});

			return scenarios;
		},
		scrollToForm () {
			setTimeout(() => {
				const element = this.$refs["form" + this.root_node.id];
				const elementRect = element.getBoundingClientRect();
				const absoluteElementTop = elementRect.top + window.pageYOffset;
				const middle = absoluteElementTop - window.innerHeight / 5;
				window.scrollTo(0, middle);
			}, 100);
		},
		async addNode (form = false) {
			console.log("Add node");
			this.isLoading = true;

			this.$refs["modalGetScenario"].hide();

			let id_tree_node = this.node_data.id;
			let data_node_tree;
			let data, err;
			let add_node = false;

			let node = {
				domain_id: this.node_data.domain_id,
				active: this.node_data.active
			};

			if (id_tree_node == "") {
				console.log("New node");
				add_node = true;
				[data, err] = await tree_nodes_api.create(node);
				if (err) {
					this.showErrorAlert("Error: " + err.data.error);
					this.isLoading = false;
					return;
				}
				data_node_tree = data;
				id_tree_node = data_node_tree.id;
			} else {
				console.log("Update node node");
				node.id = id_tree_node;
				[data, err] = await tree_nodes_api.update(id_tree_node, node);
				if (err) {
					this.showErrorAlert("Error: " + err.data.error);
					this.isLoading = false;
					return;
				}
				data_node_tree = node;
			}

			if (form) {
				this.node_data.scenario_id = this.scenarioSelected.value;
			}

			//si se crea bien el nodo creamos la versión
			let name = this.getScenarioByID(
				this.node_data.scenario_id,
				this.node_data.name
			);

			let version = {
				narra_tree_node_id: id_tree_node,
				parent_id: this.node_data.parent_id,
				name: name,
				scenario_id: this.node_data.scenario_id,
				description: "",
				current_version: true,
				active: this.node_data.active
			};

			if (this.$refs.narras_text) { version.narras = this.$refs.narras_text.innerHTML; }

			console.log(version);

			[data, err] = await tree_nodes_versions_api.create(version);

			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}

			console.log("this.node_data.id", this.node_data.id);
			if (add_node) {
				data_node_tree.versions = [data];
				//creamos un nodo nuevo
				if (this.node_data.parent_node) {
					this.node_data.parent_node.append({
						text: name,
						data: data_node_tree,
						state: { expanded: true, selectable: false }
					});
				} else {
					this.$refs["tree"].append({
						text: name,
						data: data_node_tree,
						state: { expanded: true, selectable: false }
					});
				}

				//hay que añadir un row_narra al store
			} else {
				//actualizamos el nodo existente
				this.node_data.parent_node.text = version.name;
				this.node_data.parent_node.data.active = JSON.parse(
					this.node_data.active
				);
				this.node_data.parent_node.data.versions[0] = data;
			}

			this.loadNarras(this.domainId, this.documentId);
			this.isLoading = false;
		},
		async createTemplate (node) {
			console.log("Add row", node);
			let new_template_obj = {};
			let templates = this.$store.getters.getTemplatesRegular;

			this.isLoading = true;
			//creamos el template
			let [data, err] = await templates_api.createTemplate({
				active: true,
				category_id: 1 //siempre temoplate. Nunca composición
			});

			if (!err) {
				let template_order = templates.length + 1;

				//si se crea vien lo asociamos al documento
				const [data_dt, err_dt] = await document_templates_api.create(
					this.documentId,
					{
						template_id: data.id,
						order: template_order
					}
				);

				if (!err_dt) {
					let new_version = {
						template_id: data.id,
						current_version: true,
						active: true
					};

					new_version.type = this.root_node.versions[0].name;
					new_version.narras = "";
					new_version.weigth = 20;
					new_version.text = "";

					const [datav, errv] =
            await template_version_api.createVersionWithAlias(
            	new_version,
            	this.$store.getters.getCurrentDomain.id
            );

					if (!errv) {
						console.log(datav);
						new_version.id = datav.id;

						//asociamos el scenario al template recien creado
						new_template_obj = {
							id: new_version.template_id,
							category: { id: this.category_id },
							order: template_order,
							versions: [new_version],
							narra_tree_nodes_version: [node.data.versions[0]]
						};
						templates.push(new_template_obj);

						[data, err] = await narras_templates_api.create(node.data.id, {
							template_id: data.id
						});
						if (err) {
							this.showErrorAlert("Error: " + err.data.error);
							this.isLoading = false;
							return;
						}
					} else {
						console.error(errv);
						this.showErrorAlert("Error: " + errv.data.error);
					}
				} else {
					console.error(err_dt);
					this.showErrorAlert("Error: " + err_dt.data.error);
				}
			} else {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			}
			/*console.log("scroll to bottom");
			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight
			);*/

			this.$store.commit("SET_TEMPLATES_REGULAR", templates);

			this.isLoading = false;
			return new_template_obj;
		},
		async nodeDragFinish (targetNode, distinationNode) {
			console.log("End drag", targetNode, distinationNode);

			let node_version = targetNode.data.versions[0];
			node_version.parent_id = targetNode.parent.data.id;

			console.log("New version", node_version);

			const [data, err] = await tree_nodes_versions_api.create(node_version);

			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}

			targetNode.data.versions[0] = node_version;
		},

		putChildNodes (branches, root, rootNode) {
			//console.log("putChildNodes", root);

			//hacemos el arbol
			for (let index = 0; index < branches.length; index++) {
				let branch = branches[index];

				//console.log("branch id " + branch.data_node.versions[0].parent_id);
				let name = this.getScenarioByID(
					branch.data_node.versions[0].scenario_id,
					branch.data_node.versions[0].name
				);

				let newNode;
				if (rootNode) {
					newNode = rootNode.append({
						text: name,
						data: branch.data_node,
						state: { expanded: true, selectable: false }
					});
				} else {
					if (this.$refs["tree"]) {
						newNode = this.$refs["tree"].append({
							text: name,
							data: branch.data_node,
							state: { expanded: true, selectable: false }
						});
					}
				}

				this.putChildNodes(branch._children, branch, newNode);
			}
		},
		async createNodeTemplates (node, parent, count) {
			this.isLoading = true;
			if (count == null){
				count = 1
			}

			console.log("createNodeTemplates", node);
			await this.createTemplate(node);


			for (let i = 0; i < node.children.length; i++) {
				this.isLoading = true;
				count+=await this.createNodeTemplates(node.children[i], node, count);
			}

			this.isLoading = false;

			if (parent==null){
				let plural = count>1?"s":""
				let plural_it_them = count>1?"them":"it"
				let plural_title = count>1?"Segments Added":"Segment Added"
				this.showAlert("Added " + count + " new Segment"+plural+" for the choosen node. You can see " + plural_it_them +" in the Segments tab", plural_title)
			}

			return 1; /*for recursion purpouses*/
		},
		onCtxOpen (locals) {
			console.log("open", locals);
			this.menuData = locals;
		},
		copy_node ($event, node) {
			console.log("copy", node);
			this.copiedNode = node;
		},
		paste_node ($event, node) {
			console.log("Paste", this.copiedNode, node);
		}
	},
	props: {
		domainId: {},
		documentId: {},
		root_node: { type: Object }
	},
	computed: {
		autocomplete_members () {
			return this.$store.getters.getAutocompleteNSchemaMembers.entities;
		},
		validationName () {
			return (
				this.node_data.name.length > 0 || this.node_data.scenario_id != null
			);
		},
		categoriesScenarios () {
			const categories = this.$store.getters.getScenarios;
			let options = [];
			options.push({ value: null, text: "Select a category" });
			for (let i = 0; i < categories.length; i++) {
				const category = categories[i];
				options.push({ value: category.id, text: category.name });
			}

			return options;
		},
		scenariosForSelect () {
			const scenarios = this.scenarios;
			let options = [];
			//options.push({ value: null, text: "Select a scenario" });
			for (let i = 0; i < scenarios.length; i++) {
				const scenario = scenarios[i];
				options.push({ value: scenario.id, text: scenario.versions[0].name });
			}

			let options_sorted = options.sort((x, y) => {
				/*if (x.text == "Select a scenario") return 1;
        if (y.text == "Select a scenario") return 1;*/

				if (x.text.toLowerCase() < y.text.toLowerCase()) return -1;
				if (x.text.toLowerCase() > y.text.toLowerCase()) return 1;

				return 0;
			});

			return options_sorted;
		}
	}
};
</script>

<style lang="scss" scoped>
.tree-row .card {
	border: 0 !important;
}
</style>
