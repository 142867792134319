import { render, staticRenderFns } from "./NarrasTreesViewerNV.vue?vue&type=template&id=5735aff0&scoped=true&"
import script from "./NarrasTreesViewerNV.vue?vue&type=script&lang=js&"
export * from "./NarrasTreesViewerNV.vue?vue&type=script&lang=js&"
import style0 from "./NarrasTreesViewerNV.vue?vue&type=style&index=0&id=5735aff0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5735aff0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ivan/Narrativa/Editor/nrt_editor_frontend/nrt-editor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5735aff0')) {
      api.createRecord('5735aff0', component.options)
    } else {
      api.reload('5735aff0', component.options)
    }
    module.hot.accept("./NarrasTreesViewerNV.vue?vue&type=template&id=5735aff0&scoped=true&", function () {
      api.rerender('5735aff0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/narras/NarrasTreesViewerNV.vue"
export default component.exports