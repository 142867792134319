<template>
  <div>
    <b-navbar
      sticky
      toggleable="lg"
      type="light"
      variant="light"
      class="navigation_sticky_bar"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="addNewAlias()">
            <font-awesome-icon icon="plus" />&nbsp;Add New
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>


    <div class="container" v-if="aliases && aliases.length > 0">
			<b-row class="mt-4">
				<b-col cols="12">
					<b-form-input
						class="mr-sm-2 input-search-template"
						placeholder="Search"
						v-model="filter"
					></b-form-input>
				</b-col>
			</b-row>
      <div id="tableOfAliases" class="mt-4">
        <div class="row pr-3 pl-3">
          <div class="col-3">
            <h2 class="h5">Name</h2>
          </div>
          <div class="col-5">
            <h2 class="h5">Value</h2>
          </div>
          <div class="col-3">
            <h2 class="h5">Description</h2>
          </div>
          <div class="col-1"></div>
        </div>

        <div v-for="alias in aliases_filter" v-bind:key="alias.id">
          <AliasItem
            :aliasItem="alias"
            @onDelete="deleteAlias"
            @onUpdate="updateAlias"
          ></AliasItem>
        </div>
      </div>

      <Loading v-if="isLoading"></Loading>
    </div>
    <p v-else class="mt-4 text-center">
      No aliases available for this domain. Please, click on "Add New" button to
      create one
    </p>
    <br /><br />
  </div>
</template>

<script>
import aliases_api from "../../api/aliases.js";
import Loading from "@/components/UI/Loading";
import AliasItem from "@/components/aliases/AliasItem";
import { alertMixins } from "@/mixins/alertMixins.js";

export default {
	name: "AliasesEditor",
	mixins: [alertMixins],
	props: {
		domainId: {},
		documentId: {}
	},
	components: {
		Loading,
		AliasItem
	},
	mounted () {
		this.loadAliases();
	},
	data () {
		return {
			isLoading: false,
			aliases: [],
			alias: {
				alias: "",
				value: "",
				description: ""
			},
			filter: ""
		};
	},
	computed: {
		aliases_filter () {
			let regex = new RegExp("(" + this.filter + ")", "i");
			return this.aliases.filter(function (alias) {
				return alias.alias.match(regex);
			});
		}
	},
	methods: {
		async loadAliases () {
			this.isLoading = true;

			const [data, err] = await aliases_api.getAliasesForDomain(this.domainId);
			if (err) {
				console.error(err);
				this.isLoading = false;
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				let aliases_sorted = data.sort((x, y) => {
					/*if (x.text == "Select a scenario") return 1;
					if (y.text == "Select a scenario") return 1;*/

					if (x.alias.toLowerCase() < y.alias.toLowerCase()) return -1;
					if (x.alias.toLowerCase() > y.alias.toLowerCase()) return 1;

					return 0;
				});

				this.aliases = aliases_sorted;
				this.$store.commit("SET_ALIASES", data);
			}
			this.isLoading = false;
		},

		async addNewAlias () {
			this.isLoading = true;
			let newAlias = {
				alias: "New",
				value: "Value",
				description: "Description",
				domain_id: this.domainId
			};

			const [data, err] = await aliases_api.createalias(newAlias);
			if (err) {
				console.error(err);
				this.isLoading = false;
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.isLoading = false;
				this.loadAliases();
			}
		},
		async deleteAlias (alias) {
			this.isLoading = true;
			let aliasId = alias.id;

			const [data, err] = await aliases_api.deletealias(aliasId);
			if (err) {
				console.error(err);
				this.isLoading = false;
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.isLoading = false;
				this.loadAliases();
			}
		},

		async updateAlias (alias) {
			this.isLoading = true;
			//Removed all # from the string, because they are not permitted
			console.debug("UpdateAlias called");
			alias.alias = alias.alias.replace(/#/g, "");

			let aliasFoundInAliases = null;
			for (let i = 0; i++; i < this.aliases.length) {
				if (this.aliases[i].id = alias.id) {
					aliasFoundInAliases = this.aliases[i];
				}
			}

			aliasFoundInAliases = JSON.parse(JSON.stringify(alias));

			const [data, err] = await aliases_api.updatealias(aliasFoundInAliases);
			if (err) {
				console.error(err);
				this.isLoading = false;
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.isLoading = false;
				this.loadAliases();
			}
		}
	}
};
</script>
