<template>
  <div class="aliasRow" :class="{ hoverable: !this.showEdit , aliasRowUnsaved: unsaved_changes}">
    <div class="col">
      <div
        class="row"
        @click="
          (data) => {
            return (this.showEdit = true);
          }
        "
      >
        <div class="col-3">
          <strong>#{{ this.aliasItemCopy.alias }}</strong>
        </div>
        <div class="col-5">{{ this.aliasItemCopy.value }}</div>
        <div class="col-3">
          {{ this.aliasItemCopy.description }}
        </div>
        <div class="col-1 text-right text-danger">
          <a variant="danger" @click="deleteAlias()">
            <font-awesome-icon icon="trash-alt" />
          </a>
        </div>
      </div>
      <div class="row">
        <!-- editor -->
        <div v-if="showEdit" class="col-12 mt-2 mb-2">
          <div class="form-group row">
            <label
              :for="'alias_' + aliasItem.id"
              class="col-sm-2 col-form-label"
              >Name:</label
            >
            <div class="col-sm-10">
              <input
                type="text"
                v-model="aliasItemCopy.alias"
                :id="'alias_' + aliasItem.id"
                placeholder="AliasIdentifier"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              :for="'value_' + aliasItem.id"
              class="col-sm-2 col-form-label"
              >Value:</label
            >
            <div class="col-sm-10">
							<AtTextareaNV
                        :ats="triger_chars"
                        :members="autocomplete_members_in_templates"
                        hideOnBlur
                        :filterMatch="filterMatch"
                        :filterMembers="filterNotAliases"
                      >
											<textarea
												:id="'value_' + aliasItem.id"
												v-model="aliasItemCopy.value"
												class="form-control"
											></textarea>
                      </AtTextareaNV>

            </div>
          </div>
          <div class="form-group row">
            <label
              :for="'description_' + aliasItem.id"
              class="col-sm-2 col-form-label"
              >Description:</label
            >
            <div class="col-sm-10">
              <input
                type="description"
                :id="'description_' + aliasItem.id"
                class="form-control"
                v-model="aliasItemCopy.description"
              />
            </div>
          </div>

          <div class="form-group row text-right">
            <div class="col">
              <button class="btn btn-sm btn-secondary" @click="cancel()">
                Cancel
              </button>
              <button class="btn btn-sm btn-success" @click="updateAlias()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/UI/Loading";
import { alertMixins } from "@/mixins/alertMixins.js";
import { autocompleteMixins } from "@/mixins/autocompleteMixins.js";
import AtTextareaNV from "@/components/UI/AutoComplete/AtTextareaNV.vue";

export default {
	props: {
		aliasItem: {
			type: Object
		}
	},
	mixins: [
		alertMixins,
		autocompleteMixins,
		],
	components: {
		Loading,
		AtTextareaNV,
	},
	data () {
		return {
			showEdit: false,
			aliasItemCopy: null,
			triger_chars: ["[", "(", "|", "--", ".", "__sort__", "__len__"],
			all_templates: this.$store.getters.getTemplates,
		};
	},
	computed: {
		unsaved_changes() {
			return this.aliasItemCopy.value !== this.aliasItem.value ||
				this.aliasItemCopy.description !== this.aliasItem.description ||
				this.aliasItemCopy.alias !== this.aliasItem.alias;
		},

	},
	beforeMount () {
		this.aliasItemCopy = JSON.parse(JSON.stringify(this.aliasItem));
	},
	methods: {
		deleteAlias () {
			this.showDeleteModal(null, this.deleteConfirmed, this.deleteCanceled);
			//console.log("deleteAlias");
		},
		updateAlias () {
			this.aliasItem = JSON.parse(JSON.stringify(this.aliasItemCopy));
			this.$emit("onUpdate", this.aliasItem);
			console.log("UpdateAlias");
			this.showEdit = false;
		},
		cancel () {
			this.aliasItemCopy = JSON.parse(JSON.stringify(this.aliasItem));
			this.showEdit = !this.showEdit;
		},
		deleteConfirmed () {
			this.$emit("onDelete", this.aliasItem);
		},
		deleteCanceled () {
			console.log("dont delete");
		}
	}
};
</script>
<style scoped>
.hoverable {
}

.hoverable:hover {
  background-color: #ddd;
}

.hoverable.disabled {
  background-color: green;
}

.aliasRow {
  margin-bottom: 1em;
  cursor: pointer;
  border: 1px solid #157ef3;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #fff;
}

.aliasRowUnsaved {
	border: 2px solid #f31515;
}
</style>
