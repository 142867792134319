<template>
<b-modal scrollable body-class="branch-generator-modal-body" data-test-id="branch-generator" class="branch-generator" id="branch-generator" ref="branch-generator" size="lg" no-close-on-backdrop centered :title="modalTitle" @close="resetGenerator" centered>
	<b-overlay no-wrap :show="waitingResponse" rounded="true"></b-overlay>
	<div data-test-id="branch-generator" class="branch-generator__body">
		<div v-bind:class="feedbackCssClass" v-show="showRequestError">
			<b-alert v-model="showRequestError" variant="danger" dismissible @dismissed="toggleRequestErrorVisibility">{{requestErrorText}}</b-alert>
		</div>
		<div class="branch-generator__errors">
		</div>
		<div class="branch-generator__categories row">
			<div class="col-12 branch-generator__field-wrap flex-base flex-separate">
				<div :class="categoryFieldCssClass">
					<select @change="selectCategoryChanged" v-model="selectedCategory" class="form-control branch-generator__select-category" aria-label="Select category">
						<option disabled value="">Select category</option>
						<option
							v-for="(category, index) in categories"
							:key="index"
							:value="category"
							:disabled="checkCategoryDisabledOption(category.scenario_category_id)"
						>
							{{ category.scenario_category_name }}
						</option>
					</select>
					<span class="branch-generator__error-msg error">{{ selectCategoryError }}</span>
				</div>
				<b-button @click="addCategory" class="btn btn-default branch-generator__add-category" variant="primary" :aria-label="addCategoryBtnText" v-b-tooltip.hover :title="addCategoryBtnText">
					<font-awesome-icon icon="plus-square" size="lg" />
				</b-button>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul
					class="branch-generator__list no-pd"
					v-if="hasAddedCategories"
				>
					<li
						class="branch-generator__category"
						v-for="(category, categoryIndex) in scenarios.scenarios_categories" :key="categoryIndex"
					>
						<p>
							<span class="badge badge-primary mr-1" v-b-tooltip.hover :title="categotoryOrderTooltip">{{ categoryIndex + 1 }}</span>
							<span class="branch-generator__category-name">{{ category.scenario_category_name }}</span>
						</p>
						<div class="branch-generator__scenarios flex" v-if="category.scenarios.length">
							<div class="col-4 no-pd-l">
								<div class="flex flex-base branch-generator__field-wrap">
									<div :class="scenarioField">
										<select @change="selectScenarioChanged" v-model="category.selectedScenario" class="form-control branch-generator__select-scenario" aria-label="Select scenario">
											<option disabled value="">Select scenario</option>
											<option
												v-for="(scenario, i) in category.scenarios"
												:key="i"
												:value="scenario"
												:disabled="checkScenarioDisabledOption(scenario)"
											>{{ scenarioName(scenario) }}</option>
										</select>
										<span class="branch-generator__error-msg error">{{selectScenarioError}}</span>
									</div>
									<b-button @click="addScenario(category.selectedScenario, true, false)" type="button" variant="primary" class="btn btn-default branch-generator__add-scenario" :aria-label="addScenarioBtnText"  v-b-tooltip.hover :title="addScenarioBtnText">
										<font-awesome-icon icon="plus-square" size="lg" />
									</b-button>
								</div>
							</div>
							<div class="col-8 flex flex-base" v-if="hasAddedScenarios">
								<ul class="flex flex-center-y flex-wrap flex-justify-start branch-generator__scenarios-list">
									<li class="branch-generator__scenario-item" v-for="(scenario, scenarioIndex) in scenariosAddedToBranchGenerator(category.scenarios)" :key="scenarioIndex">
										<div>
											<v-checkbox :label="scenarioName(scenario)" :checked="scenario.enabled">
												<input
													@change="updateScenario(scenario, true, scenario.enabled)"
													v-model="scenario.enabled"
													class="form-check-input"
													type="checkbox"
												>
											</v-checkbox>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<template #modal-footer>
		<b-button @click="closeModal" variant="secondary">Cancel</b-button>
		<b-button @click="generateBranches" variant="primary">Generate Branches</b-button>
	</template>
</b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import cubes from "@/api/cubes";
import Checkbox from "@/components/UI/Checkbox.vue";
import scrollTo from "@/utils/scrollTo";

export default {
	name: "ScenariosBranchGenerator",
	components: {
		"v-checkbox": Checkbox
	},
	data () {
		return {
			cubeId: null,
			waitingResponse: false,
			modalTitle: "Branches generator",
			selectedCategory: "",
			scenarios: {
				scenarios_categories: []
			},
			selectCategoryError: "You have to select a category.",
			selectScenarioError: "You have to select an scenario.",
			addCategoryBtnText: "Add category",
			addScenarioBtnText: "Add scenario",
			categotoryOrderTooltip: "Category order",
			showCategoryError: false,
			showScenarioError: false,
			showRequestError: false,
			requestErrorText: "There an error during the request. Please, try again."
		};
	},
	props: {
		branches: {
			type: Object
		}
	},
	watch: {
		branches (newVal) {
			if (newVal && newVal.branches) {
				this.scenarios.scenarios_categories = [...newVal.branches]
			}
		}
	},
	computed: {
		...mapGetters({
			categories: "categoriesBranchGenerator",
			nodeTreeId: "getNodeTreeId",
			username: "getUsername"
		}),
		hasAddedCategories: function () {
			return this.scenarios.scenarios_categories.length;
		},
		hasAddedScenarios: function () {
			return this.hasAddedCategories && this.scenarios.scenarios_categories.some(item => item.scenarios.some(el => el.visible));
		},
		checkScenarioDisabledOption () {
			return scenario => scenario.visible;
		},
		scenariosAddedToBranchGenerator () {
			return scenarios => scenarios.filter(scenario => scenario.visible);
		},
		feedbackCssClass: function () {
			return {
				"branch-generator__feedback": true,
				"visible": this.showRequestError
			}
		},
		categoryFieldCssClass: function () {
			return {
				"branch-generator__field": true,
				"branch-generator__field-category": true,
				"error": this.showCategoryError
			}
		},
		scenarioField: function () {
			return {
				"branch-generator__field": true,
				"error": this.showScenarioError
			}
		},
		scenarioName () {
			return (scenario) => scenario.versions ? scenario.versions[0].name : scenario.name
		}
	},
	methods: {
		async addCategory () {
			if (!this.selectedCategory) {
				this.showCategoryError = true;
				return false;
			}

			this.waitingResponse = true;

			const categoryObj = {
				narra_tree_nodes_id: this.nodeTreeId,
				scenario_category_id: this.selectedCategory.scenario_category_id,
				order: !this.scenarios.scenarios_categories.length ? 1 : this.scenarios.scenarios_categories.length + 1
			};

			const [data, error] = await cubes.postCategory(categoryObj);

			if (error) {
				console.error(error);
				this.showRequestError = true;
				this.waitingResponse = false;
				return false;
			}

			this.selectedCategory.id = data.id;
			this.scenarios.scenarios_categories.push(this.selectedCategory);
			this.resetSelectedCategory();
			this.waitingResponse = false;

			const elementHeight = document.querySelector(".branch-generator-modal-body").scrollHeight;
			scrollTo({ elementSelector: ".branch-generator-modal-body", timer: 250, top: elementHeight});
		},
		async addScenario (scenarioSelected, updateVisibility, enabledStatus) {
			if (!scenarioSelected) {
				this.showScenarioError = true;
				return false;
			}

			this.waitingResponse = true;

			if (scenarioSelected) {
				const scenarioId = scenarioSelected.scenario_id ? scenarioSelected.scenario_id : scenarioSelected.versions[0].scenario_id;
				const parentCategory = this.scenarios.scenarios_categories.find(item => {
					return item.scenarios.find(scenario => {
						const id = scenario.scenario_id ? scenario.scenario_id : scenario.versions[0].scenario_id;
						if (id === scenarioId) {
							return true;
						} else {
							return false;
						}
					})
				});

				const scenarioObj = {
					scenario_id: scenarioId,
					cube_id: parentCategory.id,
					visible: updateVisibility,
					enabled: enabledStatus
				}

				const [data, error] = await cubes.postScenario(scenarioObj);

				if (error) {
					console.error(error);
					this.showRequestError = true;
					this.waitingResponse = false;
					return false;
				}

				scenarioSelected.visible = true;
				scenarioSelected.enabled = enabledStatus;
				this.resetSelectedScenario();
				this.waitingResponse = false;

			} else {
				this.waitingResponse = false;
			}
		},
		async updateScenario (scenarioSelected, visibility, enabledStatus) {
			if (scenarioSelected) {
				const scenarioId = scenarioSelected.scenario_id ? scenarioSelected.scenario_id : scenarioSelected.versions[0].scenario_id;
				const parentCategory = this.scenarios.scenarios_categories.find(item => {
					return item.scenarios.find(scenario => {
						const id = scenario.scenario_id ? scenario.scenario_id : scenario.versions[0].scenario_id;
						if (id === scenarioId) {
							return true;
						} else {
							return false;
						}
					})
				});

				const scenarioObj = {
					scenario_id: scenarioId,
					cube_id: parentCategory.id,
					visible: visibility,
					enabled: enabledStatus
				}

				this.waitingResponse = true;

				const [data, error] = await cubes.postScenario(scenarioObj);

				if (error) {
					console.error(error);
					this.showRequestError = true;
					this.waitingResponse = false;
					return false;
				}

				scenarioSelected.visible = visibility;
				scenarioSelected.enabled = enabledStatus;
				this.waitingResponse = false;
			}
		},
		resetSelectedCategory () {
			this.selectedCategory = "";
		},
		resetSelectedScenario () {
			this.scenarios.scenarios_categories.forEach(item => (item.selectedScenario = ""));
		},
		resetCategories () {
			this.scenarios.scenarios_categories = [];
		},
		resetGenerator () {
			this.resetSelectedCategory();
			this.resetSelectedScenario();
			//this.resetCategories();
			this.showCategoryError = false;
			this.showRequestError = false;
			this.showScenarioError = false;
			this.waitingResponse = false;
			this.scenarios.scenarios_categories = [];
		},
		checkCategoryDisabledOption (id) {
			return this.scenarios.scenarios_categories.some(cat => cat.scenario_category_id === id);
		},
		closeModal () {
			this.resetGenerator();
			this.$refs["branch-generator"].hide();
		},
		selectCategoryChanged () {
			if (this.showCategoryError) {
				this.showCategoryError = false;
			}
		},
		selectScenarioChanged () {
			if (this.showScenarioError) {
				this.showScenarioError = false;
			}
		},
		toggleRequestErrorVisibility () {
			this.showRequestError = !this.showRequestError;
		},
		async generateBranches () {
			this.waitingResponse = true;
			const [data, error] = await cubes.generateCubes(this.nodeTreeId);

			if (error) {
				this.showRequestError = true;
				this.waitingResponse = false;
				return false;
			}
			this.$emit("reloadTree");
			this.waitingResponse = false;
			this.closeModal();
		}
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.branch-generator {

	&__feedback {
		display: none;
		height: 60px;
		text-align: center;
	}

	&__select-category,
	&__select-scenario {
		min-width: pixeltoRem(200);
	}

	&__categories {
		margin-bottom: 2rem;
	}

	&__category {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__category-name {
		font-weight: 500;
	}

	&__scenarios-list {
		padding: 0 !important;
	}

	&__scenario-item {
		margin-bottom: .5rem;
		margin-right: 1rem;
	}

	&__add-category,
	&__add-scenario {
		background-color: transparent !important;
		border: 0 !important;
		color: var(--blue) !important;
		padding: 0 !important;
	}

	&__error-msg {
		display: none;
		margin-top: .2rem;
	}

	&__field {
		margin-right: pixeltoRem(20);

		&-wrap {
			display: flex;
			height: 63px;
		}

		&-category {
			width: 95% !important;
		}

		&.error {
			.branch-generator__select {
				border-color: var(--red);
				margin-bottom: .5rem;
			}

			.branch-generator__error-msg {
				display: block;
			}
		}
	}

	label {
		margin: 0;
	}

	&__scenarios {
		padding-left: 1.5rem;
	}
}

.branch-generator-modal-body {
	position: static !important;
}
</style>
