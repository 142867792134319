import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Translate extends BaseClient {
	constructor () {
		super(httpClient, "/translate/");
	}

	translate (text, language) {
		return this.post("", {"language": language, "text_src": text});
	}
}

export default new Translate();
