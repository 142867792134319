import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class NarrasTreeTemplates extends BaseClient {
	constructor () {
		super(httpClient, "/narra_tree_templates/");
	}

	create (id, data) {
		return this.post(`${id}`, data);
	}

	remove (id, id_template) {
		return this.delete(`${id}/${id_template}`);
	}
}
export default new NarrasTreeTemplates();
