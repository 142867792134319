export default class {
	constructor (httpClient, baseUrl) {
		this.http = httpClient;
		this.baseUrl = baseUrl;
	}

	get (url) {
		return this.http.get(`${url ? this.baseUrl + url : this.baseUrl}`)
	}

	post(url, data) {
		return this.http.post(`${url ? this.baseUrl + url : this.baseUrl}`, data)
	}

	put(url, data) {
		return this.http.put(`${url ? this.baseUrl + url : this.baseUrl}`, data)
	}

	patch(url, data) {
		return this.http.patch(`${url ? this.baseUrl + url : this.baseUrl}`, data)
	}

	delete(url, data) {
		return this.http.delete(`${url ? this.baseUrl + url : this.baseUrl}`)
	}
}
