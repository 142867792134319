var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap-restore-parent-segment" },
    [
      _c(
        "b-modal",
        {
          ref: "restore-parent-segment",
          staticClass: "restore-parent-segment",
          attrs: {
            "data-test-id": "restore-parent-segment",
            id: "restore-parent-segment",
            size: "lg",
            title: "Restore items",
            "no-close-on-backdrop": "",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeMainModal },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.restore },
                    },
                    [_vm._v("Restore")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-overlay", {
            attrs: {
              "no-wrap": "",
              show: _vm.waitingResponse,
              rounded: "true",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "archive-parernt-segment__body" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRequestError,
                    expression: "showRequestError",
                  },
                ],
                class: _vm.feedbackCssClass,
              },
              [
                _c(
                  "b-alert",
                  {
                    attrs: { variant: "danger", dismissible: "" },
                    on: { dismissed: _vm.toggleRequestErrorVisibility },
                    model: {
                      value: _vm.showRequestError,
                      callback: function ($$v) {
                        _vm.showRequestError = $$v
                      },
                      expression: "showRequestError",
                    },
                  },
                  [_vm._v("Error")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "form",
              { attrs: { action: "" } },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Elements to restore:" } },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row pl-2" },
                      [
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              name: "some-radios",
                              value: "true",
                              checked: _vm.treeNodeToRestore.isRoot,
                            },
                            model: {
                              value: _vm.includeChildren,
                              callback: function ($$v) {
                                _vm.includeChildren = $$v
                              },
                              expression: "includeChildren",
                            },
                          },
                          [_vm._v("Include all children")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              name: "some-radios",
                              value: "false",
                              disabled: _vm.treeNodeToRestore.isRoot,
                            },
                            model: {
                              value: _vm.includeChildren,
                              callback: function ($$v) {
                                _vm.includeChildren = $$v
                              },
                              expression: "includeChildren",
                            },
                          },
                          [_vm._v("Just the current one")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }