var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-card-body",
            [
              _c("b-col", { attrs: { cols: "1" } }),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    { attrs: { "bg-variant": "default" } },
                    [
                      _c("b-form-input", {
                        staticClass: "mr-sm-2",
                        attrs: { size: "sm", placeholder: "Search scenario" },
                        model: {
                          value: _vm.searchNarras,
                          callback: function ($$v) {
                            _vm.searchNarras = $$v
                          },
                          expression: "searchNarras",
                        },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.root_narras_filter, function (narra) {
                        return [
                          _c(
                            "b-card",
                            {
                              attrs: { "no-body": "", "bg-variant": "default" },
                            },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: {
                                    "header-tag": "header",
                                    role: "tab",
                                  },
                                },
                                [
                                  _c(
                                    "h2",
                                    [
                                      _vm.select_name == "true"
                                        ? _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "selectNarra",
                                                    narra.versions[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-badge",
                                                { attrs: { variant: "info" } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        narra.versions[0].name
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "b-badge",
                                            { attrs: { variant: "info" } },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    narra.versions[0].name
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _vm.collapse_button != "false"
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "text-rigth",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggle_root(
                                                    narra.versions[0].name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-popover",
                                                      rawName:
                                                        "v-b-popover.hover",
                                                      value: "Collapse tree",
                                                      expression:
                                                        "'Collapse tree'",
                                                      modifiers: {
                                                        hover: true,
                                                      },
                                                    },
                                                  ],
                                                  attrs: {
                                                    pill: "",
                                                    variant: "success",
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "angle-up" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.tree_editor_button != "false"
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "text-rigth",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDialog(narra)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-popover",
                                                      rawName:
                                                        "v-b-popover.hover",
                                                      value: "Open tree editor",
                                                      expression:
                                                        "'Open tree editor'",
                                                      modifiers: {
                                                        hover: true,
                                                      },
                                                    },
                                                  ],
                                                  attrs: {
                                                    pill: "",
                                                    variant: "info",
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: "code-branch",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: narra.versions[0].name,
                                    visible: "",
                                  },
                                },
                                [
                                  _c("NrtTreeTable", {
                                    attrs: {
                                      tree: _vm.rows[narra.id],
                                      select_name: _vm.select_name,
                                      only_name_column: _vm.only_name_column,
                                      searchString: _vm.searchNarras,
                                    },
                                    on: { selectNarra: _vm.selectNarra },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            {
              attrs: {
                height: "auto",
                width: "100%",
                scrollable: "",
                resizable: "",
                name: "NarraTreeEditorModal",
              },
              on: {
                "before-close": function ($event) {
                  return _vm.$emit("updateTrees")
                },
              },
            },
            [_c("NarrasTreeEditor", { attrs: { narraId: _vm.narraIdEditor } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }