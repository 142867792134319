var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.templates_without_tree.length > 0
    ? _c("div", { staticClass: "row-templates-container" }, [
        _c(
          "div",
          {
            staticClass: "root_narra_title",
            on: {
              click: function ($event) {
                return _vm.toggleCollapsed()
              },
            },
          },
          [
            _c("div", { staticClass: "toggleControlNarras" }, [
              !_vm.collapsed
                ? _c("span", [
                    _c("i", {
                      staticClass: "fa fa-caret-down",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ])
                : _c("span", [
                    _c("i", {
                      staticClass: "fa fa-caret-right",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "root_narra_name_title" }, [
              _vm._v("Segments without tree"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "template-root", attrs: { v_if: "collapsed" } },
          [
            !_vm.collapsed
              ? _c("TableTemplatesForRowNarraNV", {
                  attrs: {
                    templates_without_segements: "true",
                    category_id: _vm.category_id,
                    documentId: _vm.$store.getters.getCurrentDocument.id,
                    currentDomain: _vm.$store.getters.getCurrentDomain,
                    currentDocument: _vm.$store.getters.getCurrentDocument,
                    currentRoute: "",
                  },
                  on: {
                    deleteTemplate: _vm.onDeleteTemplate,
                    templateDeleted: _vm.onTemplateDeleted,
                    templateDuplicated: _vm.onTemplateDuplicated,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }