export const autocompleteMixins = {
	computed: {
		//esta funcion obtiene las entidades y plantillas que aparecen en el autocompletado
		autocomplete_members_in_templates() {
			let members_templates = [];

			for (let i = 0; i < this.all_templates.length; i++) {
				if (
					this.all_templates[i].versions[0] &&
					members_templates.indexOf(this.all_templates[i].versions[0].type) < 0
				) {
					members_templates.push(this.all_templates[i].versions[0].type);
				}
			}
			members_templates.push("__EMPTY__");
			let nschemaMembers = this.$store.getters.getAutocompleteNSchemaMembers.entities;
			let aliases = this.$store.getters.getAliases.map((alias) => "#" + alias.alias);
			return members_templates.concat(nschemaMembers).concat(aliases).sort();
		}
	},
	methods: {
		filterMatch(text, name, chunk, at) {
			return name.indexOf(chunk) > -1;
		},
		filterUsers(text, chunk, at, index) {
			const users = JSON.parse(localStorage.getItem("users"));
			return users.filter(v => {
				return v.toLowerCase().indexOf(chunk.toLowerCase()) > -1;
			});
		},
		filterOnlyAliases(text, chunk, at, index) {
			const members = this.filterMembersAutocompleteInTemplates(text, chunk, at, index);
			let members_filtered = [];
			if (members) {
				members_filtered = members.filter(member => member.startsWith('#') || member.startsWith('*'));
			}

			// console.log("Filter_alias", members_filtered);
			return members_filtered;
		},
		filterNotAliases(text, chunk, at, index) {
			const members = this.filterMembersAutocompleteInTemplates(text, chunk, at, index);
			let members_filtered = [];
			if (members) {
				members_filtered = members.filter(member => !member.startsWith('#'));
			}
			// console.log("Filter_alias", members_filtered);
			return members_filtered;
		},
		filterMembersAutocompleteInTemplates(text, chunk, at, index) {
			if (at == "[" || at == "(" || at == "|") {
				return this.autocomplete_members_in_templates.filter((v) => {
					return v.indexOf(chunk) > -1;
				});
			} else {
				//es un campo
				//hay que obtener de que entidad se trata
				//Puede ser algo:
				//- [Entity.field
				//- [Entity[0].field
				//- [Entity--relation->Entity2.field
				//- [Entity--relation->Entity2[1].field
				//__len__ y __sort__ estos son bastante especiales

				//hay que buscar la primera aparición hacia atras desde el index de at de los caracteres "[", "(" o "->"
				function searchOperand(text, index) {
					let lastBracket = text.substring(0, index).lastIndexOf("[");
					let lastParenthesis = text.substring(0, index).lastIndexOf("(");
					let lastOperand = text.substring(0, index).lastIndexOf("->");
					let lastModified = text.substring(0, index).lastIndexOf("|");

					let fromChar =
						Math.max(
							...[lastBracket, lastParenthesis, lastOperand, lastModified]
						) + 1;

					return fromChar;
				}
				let fromChar = searchOperand(text, index);

				if (text[fromChar - 1] == "[") {
					//puede que si hay un corchete sea por acceder a un elemento [0]
					let isEndBracket = text
						.substring(fromChar - 1, index)
						.lastIndexOf("]");
					if (isEndBracket > -1) {
						index = fromChar - 1;
						fromChar = searchOperand(text, fromChar - 2);
					}
				}

				if (text[fromChar] == ">") {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					fromChar++;
				}

				if (at == "__sort__") index--;
				let entity = text.substring(fromChar, index);
				const index_point = entity.indexOf(".");
				if (index_point > -1) {
					//La entidad no puede tener un punto como nombre ya que es un operador. Si viene un punto es que tenemos alguna firigrana
					//del tipo Index.__sort__region_name__desc[0]. La entidad es hasta el primer punto

					if (entity.substring(0, index_point) != "r") { entity = entity.substring(0, index_point); } else entity = entity.substring(index_point + 1);
				}

				if (at == "." || at == "__sort__") {
					//
					if (this.$store.getters.getAutocompleteNSchemaMembers.properties[entity]) {
						return this.$store.getters.getAutocompleteNSchemaMembers.properties[entity].filter((v) => {
							return v.indexOf(chunk) > -1;
						});
					}
				} else if (at == "--") {
					if (this.$store.getters.getAutocompleteNSchemaMembers.relations[entity]) {
						return this.$store.getters.getAutocompleteNSchemaMembers.relations[entity].filter((v) => {
							return v.indexOf(chunk) > -1;
						});
					}
				}
			}
		}
	}
};
