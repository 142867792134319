import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Documents extends BaseClient {
	constructor () {
		super(httpClient, "/documents/");
	}

	getAll (domainId) {
		return this.get(`?domain_id=${domainId}`);
	}

	getDocument (id) {
		return this.get(id);
	}

	deleteDocument (id) {
		return this.delete(id);
	}

	create (domain) {
		return this.post("", domain);
	}
}

export default new Documents();
