var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "light" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Segment history"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-div-container-history" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                _vm._l(_vm.templates, function (template) {
                  return _c("div", { key: template.versions[0].id }, [
                    _c(
                      "div",
                      { class: _vm.getClassForTemplate(template) },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-header template-header pt-0 pb-0 pl-2",
                          },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass: "template-row-header",
                                attrs: { "align-v": "center" },
                              },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "m-0 p-0 pl-2 pt-1",
                                    attrs: { cols: "8" },
                                  },
                                  [
                                    _c("label", {
                                      staticClass: "template-history-date",
                                      attrs: { spellcheck: "false" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.updated_at(template)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      staticClass: "template-history-date",
                                      attrs: { spellcheck: "false" },
                                      domProps: {
                                        textContent: _vm._s(
                                          template.versions[0].user
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right pr-0 align-middle",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    !template.versions[0].current_version
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "template-preview-icon template-circle-button",
                                            attrs: {
                                              href: "#",
                                              title: "Set current version",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.$emit(
                                                  "setCurrentVersion",
                                                  template
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("div", {}, [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-arrow-alt-circle-right fa-lg",
                                              }),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          { staticClass: "template-row-text" },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "template-cell-text card-body",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "Highlightable",
                                  {
                                    ref: "Highlightable" + template.id,
                                    refInFor: true,
                                  },
                                  [
                                    _c("highlightable-input", {
                                      attrs: {
                                        "highlight-style":
                                          "badge-data-template",
                                        "highlight-enabled": "",
                                        highlight: _vm.highlight,
                                        refreshHighlights:
                                          _vm.refreshHighlights,
                                        category_id:
                                          template.category.id.toString(),
                                      },
                                      model: {
                                        value:
                                          template.versions[0].text_for_editor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            template.versions[0],
                                            "text_for_editor",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "template.versions[0].text_for_editor",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }