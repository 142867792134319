var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "template-child", style: "margin-left: 15px;" },
      [
        _c(
          "b-collapse",
          {
            ref: "collapse-" + _vm.template_id + "-" + _vm.level,
            staticClass: "mt-2",
            attrs: {
              id: "collapse-" + _vm.template_id + "-" + _vm.level,
              visible: "",
            },
          },
          _vm._l(_vm.subtemplates[_vm.level], function (subtemplate) {
            return _c(
              "div",
              [
                _c("RowTemplate", {
                  ref: "RowTemplate" + _vm.template_id,
                  refInFor: true,
                  attrs: {
                    template: subtemplate.template,
                    currentDocument: _vm.currentDocument,
                    currentDomain: _vm.currentDomain,
                    document_data_selected: _vm.document_data_selected,
                    category_id: _vm.category_id,
                    active_autocomplete: _vm.active_autocomplete,
                    refreshHighlights: _vm.refreshHighlights,
                    level: _vm.level + 1,
                  },
                  on: {
                    templateDeleted: _vm.templateDeletedEmit,
                    duplicateTemplate: _vm.duplicateTemplateEmit,
                    createTempByText: _vm.createTempByTextEmit,
                    updateTemplate: function ($event) {
                      return _vm.$emit("updateTemplatesStore")
                    },
                    filterByTemplateID: _vm.filterByTemplateIDEmit,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }