import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=06a89544&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ivan/Narrativa/Editor/nrt_editor_frontend/nrt-editor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06a89544')) {
      api.createRecord('06a89544', component.options)
    } else {
      api.reload('06a89544', component.options)
    }
    module.hot.accept("./SideMenu.vue?vue&type=template&id=06a89544&", function () {
      api.rerender('06a89544', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UI/SideMenu/SideMenu.vue"
export default component.exports