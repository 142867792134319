<template>
	<div>
		<div
			v-show="showMenu"
			class="menu-highlightable"
			:style="{ left: `${x}px`, top: `${y}px` }"
			@mousedown.prevent=""
		>
			<span
				class="item-highlightable"
				@mousedown.prevent="handleAction('onVariations')"
			>
				Variations
			</span>
			<br />
			<span
				class="item-highlightable"
				@mousedown.prevent="handleAction('onParaphrasing')"
			>
				Paraphrasing
			</span>
			<br />
			<span
				class="item-highlightable"
				@mousedown.prevent="handleAction('onCreateTempByText')"
			>
				Create segment
			</span>

			<!-- You can add more buttons here -->
		</div>
		<!-- The insterted text should be displayed here -->
		<slot /></div
></template>

<script>
import getCaretCoordinates from "textarea-caret";
export default {
	name: "Highlightable",
	data () {
		return { x: 0, y: 0, showMenu: false, selectedText: "" };
	},
	computed: {
		highlightableEl () {
			return this.$slots.default[0].elm;
		}
	},
	methods: {
		onMouseup ($event) {
			const selection = window.getSelection();
			console.log("HIGTH", $event, selection);
			const selectionRange = selection.getRangeAt(0);

			/*
			// startNode is the element that the selection starts in
			const startNode = selectionRange.startContainer.parentNode;

			// endNode is the element that the selection ends in
			const endNode = selectionRange.endContainer.parentNode;

			// if the selected text is not part of the highlightableEl (i.e. <p>)
			// OR
			// if startNode !== endNode (i.e. the user selected multiple paragraphs)
			// Then
			// Don't show the menu (this selection is invalid)
			console.log("startNode", startNode);
			console.log("endNode", endNode);
			if (
				!startNode.isSameNode(this.highlightableEl) ||
				!startNode.isSameNode(endNode)
			) {
				this.showMenu = false;
				return;
			}*/

			//Get the x, y, and width of the selection
			const { x, y, width } = selectionRange.getBoundingClientRect();
			console.log("WIDTH", width);

			//If width === 0 (i.e. no selection)    // Then, hide the menu
			if (!width) {
				this.showMenu = false;
				return;
			}

			//Finally, if the selection is valid,
			//set the position of the menu element,
			//set selectedText to content of the selection
			//then, show the menu
			this.x = $event.layerX; //rect.left;
			this.y = $event.layerY - 20;
			this.selectedText = selection.toString();
			//this.showMenu = true;
		},
		handleAction (action) {
			this.$emit(action, this.selectedText);
			this.showMenu = false;
		}
	}
};
</script>

<style scoped>
</style>
