var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm._l(_vm.text_entities.fragments, function (fragment) {
            return [
              fragment.type == 1 && fragment.text != "r"
                ? _c(
                    "a",
                    {
                      staticClass: "badge-template badge-data-template",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.showNschemaTree($event, fragment.text)
                        },
                      },
                    },
                    [_vm._v(_vm._s(fragment.text))]
                  )
                : _c("span", [_vm._v(_vm._s(fragment.text))]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("NschemaFieldSelector", {
        ref: "NschemaFieldSelector",
        attrs: { old_text: _vm.old_text },
        on: { selectNode: _vm.selectNode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }