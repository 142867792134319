<template>
	<div>
		<div id="filedrag" v-cloak @drop.prevent="addFile" @dragover.prevent>
			<h1><font-awesome-icon icon="cloud-upload-alt" /></h1>
			<h2>Drop files here</h2>
			or
			<br />
			<input
				class="file-upload-input"
				id="file-input"
				:ref="ref_file_input"
				type="file"
				:multiple="multiple"
				v-on:change="handleBrowseFiles($event)"
			/>
			<b-table-simple hover small caption-top sticky-header>
				<b-tbody>
					<b-tr v-for="file in files" v-bind:key="file.id">
						<b-td class="text-left align-middle"
							>{{ file.name }} ({{ file.size }} bytes)</b-td
						>
						<b-td class="text-right align-middle">
							<b-button @click="removeFile(file)"
								><font-awesome-icon icon="times"
							/></b-button>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<b-button
				variant="success"
				onclick="document.getElementById('file-input').click();"
				>Browse files</b-button
			>
		</div>
		<div class="text-center">
			<b-button variant="info" :disabled="uploadDisabled" @click="upload"
				>Upload</b-button
			>
		</div>
	</div>
</template>

<script>
export default {
	"name": "UploadFile",
	props: {
		ref_file_input: { type: String },
		multiple: { type: Boolean, default: true }
	},
	data () {
		return {
			files: []
		};
	},

	computed: {
		uploadDisabled () {
			return this.files.length === 0;
		}
	},
	methods: {
		addFile (e) {
			let droppedFiles = e.dataTransfer.files;
			console.log("Add file", droppedFiles);
			if (!droppedFiles) return;
			//this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
			[...droppedFiles].forEach((f) => {
				//this.files.push(f);
				this.readFile(f);
			});
		},
		removeFile (file) {
			this.files = this.files.filter((f) => {
				return f != file;
			});
		},
		handleBrowseFiles ($event) {
			console.log("handleBrowseFiles", this.$refs[this.ref_file_input].files);
			if (!this.multiple) {
				this.files = [];
			}
			[...this.$refs[this.ref_file_input].files].forEach((f) => {
				this.readFile(f);
				//this.files.push(f);
			});
		},
		upload () {
			if (this.files.length > 0) this.$emit("uploadFiles", this.files);
			this.files = [];
		},
		readFile (f) {
			console.log("File", f);

			const self = this;
			let reader = new FileReader();

			reader.onloadend = function () {
				self.files.push({
					"name": f.name,
					"size": f.size,
					"data": event.target.result
				});
			};
			reader.readAsDataURL(f);
		}
	}
};
</script>

<style>
.file-upload-input {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 1%;
	height: 1%;
	outline: none;
	opacity: 0;
	cursor: pointer;
}
</style>
