import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class NarrasTreeNodes extends BaseClient {
	constructor () {
		super(httpClient, "/narra_tree_nodes/");
	}

	getAll (id) {
		let data = this.get(`${id}/branches`);

		return data;
	}

	getAllWithTemplates (id) {
		let data = this.get(`${id}/branches/templates`);

		return data;
	}

	create (node) {
		return this.post("", node);
	}

	update (id, node) {
		return this.patch(`${id}`, node);
	}

	remove (id, node) {
		console.log("DELETE");
		return this.delete(`${id}/branches`, node);
	}

	archiveSegments (id, includeChildren, includeSegments) {
		return this.delete(`${id}/branches/archive?include_children=${includeChildren}&include_segments=${includeSegments}`);
	}

	restoreSegments (id, includeChildren, includeSegments) {
		return this.delete(`${id}/branches/restore?include_children=${includeChildren}&include_segments=${includeSegments}`);
	}

	deleteSegments (id, includeChildren, includeSegments) {
		return this.delete(`${id}/branches?include_children=${includeChildren}&include_segments=${includeSegments}`);
	}
}

export default new NarrasTreeNodes();
