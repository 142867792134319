var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.row_narras.length > 0
    ? _c(
        "div",
        { staticClass: "table-root-narras-nv" },
        [
          _c(
            "div",
            {
              staticClass: "root_narra_title",
              on: {
                click: function ($event) {
                  return _vm.toggleCollapsed()
                },
              },
            },
            [
              _c("div", { staticClass: "toggleControlNarras" }, [
                !_vm.collapsed
                  ? _c("span", [
                      _c("i", {
                        staticClass: "fa fa-caret-down",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ])
                  : _c("span", [
                      _c("i", {
                        staticClass: "fa fa-caret-right",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "root_narra_name_title",
                  attrs: { title: _vm.root_narra.id },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.root_narra.versions[0].name))]),
                  _vm._v(" "),
                  _vm.archived == "true" && !_vm.root_narra.active
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "icon--xs",
                          staticStyle: { background: "transparent" },
                          attrs: { title: "Restore elements" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.opeRestoreTreeNode(_vm.root_narra.id)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-trash-restore" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.archived == "false" && _vm.root_narra.active
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "icon--xs",
                          attrs: { title: "Archive elements" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openArchiveParentSegment(
                                _vm.root_narra.id
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-trash" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.archived == "true" && !_vm.root_narra.active
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "icon--xs",
                          attrs: { title: "Delete elements" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openDeleteParentSegment(
                                _vm.root_narra.id
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-trash" })]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._l(this.row_narras, function (narra) {
            return _c(
              "div",
              { key: narra.key },
              [
                !_vm.collapsed
                  ? _c("TableRowNarrasNV", {
                      attrs: {
                        row_narra: narra,
                        category_id: _vm.category_id,
                        documentId: _vm.documentId,
                        currentDomain: _vm.currentDomain,
                        currentDocument: _vm.currentDocument,
                        prev_row_narra_name: _vm.root_narra.versions[0].name,
                        search_segment: _vm.search_segment,
                        archived: _vm.archived,
                      },
                      on: {
                        addNewSegmentForRowNarra: _vm.addNewSegmentForRowNarra,
                        deleteTemplate: _vm.onDeleteTemplate,
                        templateDeleted: _vm.onTemplateDeleted,
                        templateDuplicated: _vm.onTemplateDuplicated,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }