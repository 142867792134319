var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap" },
    _vm._l(this.partsSegmentBackup, function (segment, index) {
      return _c("HTEditorNV", {
        key: segment.id,
        staticClass: "HighlightableInputNV",
        attrs: {
          collapseId: String(segment.collapseId),
          templateIdAncestors: _vm.templateIdAncestorsVal,
          parentCollapseId: "unknown",
          value_type: segment.type,
          id: "input_" + index,
          offset: segment.offset,
          corrections: _vm.corrections,
        },
        on: {
          blur: function ($event) {
            return _vm.onBlur($event, index)
          },
          notifyValueChange: function ($event) {
            return _vm.onNotifyValueChange($event, index)
          },
          duplicateTemplate: _vm.onDuplicateTemplate,
          addNewTemplate: _vm.onAddNewTemplate,
          deleteTemplate: _vm.onDeleteTemplate,
          updateTemplate: _vm.onupdateTemplate,
          clickCorrections: _vm.onclickCorrections,
          clickSynonym: _vm.onClickSynonym,
          clickTypo: _vm.onClickTypo,
          clickPlural: _vm.onClickPlural,
        },
        model: {
          value: segment.text,
          callback: function ($$v) {
            _vm.$set(segment, "text", $$v)
          },
          expression: "segment.text",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }