<template>
  <div
    v-if="show && regular_parts_updated()"
    class="container template_full_row container-border"
  >
    <div class="row" v-if="showSynonyms">
      <div class="col-2">
        <div class="row mt-1 ml-1 title">* SYNONYMS</div>
        <div class="row mt-3 ml-2 word">
          {{ word }}
        </div>
      </div>
      <div class="col-10 pr-0">
        <template v-if="loadingSynonyms">
          <b-spinner variant="primary" class="mt-4"></b-spinner>
        </template>
        <div class="card card-column" v-else-if="synonyms_list.length > 0">
          <div class="card-header">Select the synonyms to use</div>
          <div class="card-body">
            <div class="row">
              <b-form-group class="overflow-auto synonyms-check-group-ml">
                <b-form-checkbox
                  v-for="option in synonyms_list"
                  v-model="synonyms_check"
                  :key="option.value"
                  :value="option.value"
                  stacked
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="row">
              <div>
                <b-button
                  variant="info"
                  @click.prevent="includeSelectedSynonyms()"
                  style="margin-right: .5em;"
                  >Include</b-button
                >
              </div>
              <div>
                <b-button variant="danger" @click.prevent="cancel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showPlural">
      <div class="col-2">
        <div class="row mt-1 ml-1 title">* SINGULAR/PLURAL</div>
        <div class="row mt-3 ml-2 word">
          {{ word }}
        </div>
      </div>
      <div class="col-10 pr-0">
        <template v-if="loadingPlural">
          <b-spinner variant="primary" class="mt-4"></b-spinner>
        </template>
        <div class="card card-column" v-else>
          <div class="card-header">Press Include to transform into a plural</div>
          <div class="card-body">
            <div class="row">
              <b-form-group class="overflow-auto synonyms-check-group-ml">
                <b-form-radio
                  v-for="segment in partsSegment"
                  v-model="plural_check"
                  :key="segment.id"
                  :value="segment.text"
                  stacked
                >
                  {{ segment.text }}
                </b-form-radio>
              </b-form-group>
              <br/><br/><br/>
            </div>
            <div class="row">
              <div>
                <b-button
                  variant="info"
                  @click.prevent="includeSelectedPlural()"
                  style="margin-right: .5em;"
                  >Include</b-button
                >
              </div>
              <div>
                <b-button variant="danger" @click.prevent="cancel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<div class="row" v-else>
      <div class="col-2">
        <div class="row mt-1 ml-1 title">* SINGULAR/PLURAL</div>
        <div class="row mt-3 ml-2 word">
          {{ word }}
        </div>
      </div>
      <div class="col-10 pr-0">
        <div class="card card-column">
          <div class="card-header">Press Accept to generate a plural expression</div>
          <div class="card-body">
            <div class="row">
							<div class="input-group flex-nowrap">
								<span class="input-group-text col-1" id="label-singular">Singular</span>
								<input type="text" class="form-control" placeholder="Singular: I.E: goal" aria-label="singular" aria-describedby="label-singular" v-model="manual_singular">
							</div>
							<div class="no-valid col-12" v-if="empty_singular">Please, enter the singular alternative</div>
							<div class="input-group flex-nowrap mt-2">
								<span class="input-group-text col-1" id="label-plural">Plural</span>
								<input type="text" class="form-control" placeholder="Singular: I.E: goals" aria-label="singular" aria-describedby="label-plural" v-model="manual_plural">
							</div>
							<div class="no-valid col-12" v-if="empty_plural">Please, enter the plural alternative</div>
								<div class="w-100 mt-4 ml-4">
									Select associated data:
								</div>
							<div>
								<div class="no-valid col-12 w-100 ml-2" v-if="empty_plural_expression">Please, select associated data</div>
								<b-form-group class="overflow-auto synonyms-check-group-ml mt-2">
									<b-form-radio
										v-for="segment in partsSegment"
										v-model="manual_associated_data"
										:key="segment.id"
										:value="segment.text"
										stacked
									>
										{{ segment.text }}
									</b-form-radio>
								</b-form-group>
							</div>
            </div>
            <div class="row mt-3">
              <div>
                <b-button
                  variant="info"
                  @click.prevent="includeSelectedManualPlural()"
                  style="margin-right: .5em;"
                  >Accept</b-button
                >
              </div>
              <div>
                <b-button variant="danger" @click.prevent="cancel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showTypos">
      <div class="col-2">
        <div class="row mt-1 ml-1 title">* TYPOS</div>
        <div class="row mt-3 ml-2 word">
          {{ word }}
        </div>
      </div>
      <div class="col-10 pr-0">
        <div class="card card-column">
          <div class="card-header">Press "Change" to correct typo</div>
          <div class="card-body">
            <div class="row">
              <div class="col-2">
                <p>{{ typo_corrected }}</p>
              </div>
            </div>
            <div class="row">
              <div>
                <b-button variant="info" @click.prevent="changeTypo()"
                  style="margin-right: .5em;"
                  >Change</b-button
                >
              </div>
              <div>
                <b-button variant="danger" @click.prevent="cancel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import minerva_api from "../../api/minerva.js";
import preview_api from "../../api/preview";

import { alertMixins } from "@/mixins/alertMixins.js";

export default {
	name: "TooltipTemplate",
	mixins: [alertMixins],
	props: {
		template: {}
	},
	data () {
		return {
			synonyms_list: [],
			plural_list: [],
			token_idx: -1,
			loadingSynonyms: false,
			loadingPlural: false,
			synonyms_check: [],
			plural_check: [],
			typo_corrected: "",
			word: "",
			show: false,
			showSynonyms: false,
			showTypos: false,
			showPlural: false,
			offset: -1,
			manual_plural: "",
			manual_singular: "",
			manual_associated_data: "",
			empty_singular: false,
			empty_plural: false,
			empty_plural_expression: false,
		};
	},
	computed:{
		partsSegment(){
			const highlightableinput = this.$parent.$refs[`text${this.template.id}`];
			const parts = highlightableinput.partsSegment.filter(part => part.type==="data");
			let minimum = null;
			for (const part of parts){
				const left = Math.abs(part.offset + part.text.length - this.offset);
				const right = Math.abs(this.offset + this.word.length - part.offset);
				const distance = Math.min(left, right);
				if(minimum == null || minimum > distance){
					minimum = distance;
					this.plural_check = part.text;
				}
			}
			return parts;
		},
	},
	created () {},
	methods: {
		regular_parts_updated(){
				return this.$parent.regular_parts_updated;
		},
		async getCorrections (word, corrected, plural_list, token_idx, isTypo, isSynonym, isPlural, offset) {
			this.show = true && this.regular_parts_updated();
			this.showTypos = isTypo;
			this.showSynonyms = isSynonym;
			this.showPlural = isPlural;
			this.loadingSynonyms = true;
			this.loadingPlural = true;
			// this.word = word.replaceAll("·", " ");
			this.manual_plural = this.word;
			this.manual_singular = this.word;
			if (isTypo) {
				await this.getTypos(word, corrected);
			}
			if (isSynonym) {
				await this.getSynonyms(word);
			}
			if (isPlural) {
				this.plural_list = plural_list;
				await this.getPlural(word, plural_list, token_idx, offset);
			}else{
				this.token_idx = token_idx;
				this.offset = offset;
			}
		},
		async getTypos (word, corrected) {
			// console.log("getTypos", word, corrected);
			this.typo_corrected = corrected;
		},
		async getPlural (word, plural_list, token_idx, offset) {
			//console.log("getPlural", word, plural_list, token_idx, offset);

			for (const [key, plural] of Object.entries(plural_list)) {
				//console.log("TEST_plural_getplural", `=${this.word.trim()}=`, `=${plural.singular.trim()}=`, plural.plural, token_idx, plural.token_idx, offset);

				if (token_idx == plural.token_idx) {
					this.token_idx = plural.token_idx;
					this.offset = offset;
				}
			}

			this.loadingPlural = false;
		},
		async getSynonyms (word) {
			// console.log("getSynonyms", word);
			this.show = true;
			this.showSynonyms = true;

			this.synonyms_list = [];
			this.synonyms_check = [];

			const [preview, err_preview] = await preview_api.getPreview(
				this.$store.getters.getCurrentDomain.id,
				this.$store.getters.getCurrentDocument.id,
				this.$store.getters.getDocumentDataSelected,
				false,
				1,
				this.template.id,
				true
			);

			if (!err_preview) {
				let text = "";
				for (let i = 0; i < preview.previews.length; i++) {
					if (!preview.previews[i].error) {
						text = preview.previews[i].result.replace("(invalid scenario)", "");
						break;
					}
				}

				let sentence = text.replace(this.word, "[MASK] ");

				const [data, err] = await minerva_api.variations_bert(
					this.$store.getters.getCurrentDomain.id,
					this.$store.getters.getCurrentDocument.language,
					"[CLS] " + sentence + " [SEP]"
				);

				if (!err) {
					let self = this;
					const lst = data.filter(function (value, index, arr) {
						return value.token != self.word;
					});
					lst.length = 5;
					for (let i = 0; i < lst.length; i++) {
						this.synonyms_list.push({
							text: lst[i].token,
							value: lst[i].token
						});
					}
				}
			} else {
			}
			this.loadingSynonyms = false;
		},
		includeSelectedSynonyms () {
			this.show = false;
			this.showSynonyms = false;
			this.showPlural = false;
			this.showTypos = false;
			if (this.synonyms_check.length > 0) {
				let synonym =
          "{" + this.word + "|" + this.synonyms_check.join("|") + "} ";
				this.$emit("includeSynonyms", this.word, synonym);
			}
		},
		changeTypo () {
			this.show = false;
			this.showSynonyms = false;
			this.showPlural = false;
			this.showTypos = false;
			this.$emit("changeTypo", this.word, this.typo_corrected);
		},
		includeSelectedManualPlural () {

			let check_error = false;

			if(this.manual_singular.trim() === ""){
				check_error = true;
				this.empty_singular = true;
			}

			if(this.manual_plural.trim() === ""){
				check_error = true;
				this.empty_plural = true;
			}

			if(this.manual_associated_data.trim() === ""){
				check_error = true;
				this.empty_plural_expression = true;
			}

			if(check_error){
				return;
			}

			this.show = false;
			this.showSynonyms = false;
			this.showPlural = false;
			this.showTypos = false;

			const field = this.manual_associated_data.substring(1, this.manual_associated_data.length-1);
			const data = `[plurals(=one:${this.manual_singular},=other:${this.manual_plural})|${field}] `;

			const word = this.word;
			const token_idx = this.token_idx;
			const offset = this.offset;

			this.word = "";
			this.token_idx = -1;
			this.offset = -1;
			this.manual_plural = "",
			this.manual_singular = "",
			this.manual_associated_data = "",

			this.$emit("changePlural", word, data, token_idx, offset);
		},
		 includeSelectedPlural() {
			this.show = false;
			this.showSynonyms = false;
			this.showPlural = false;
			this.showTypos = false;

			let data = this.word;

			for (const plural_opt of JSON.parse(JSON.stringify(this.plural_list))){
				if([plural_opt.plural.toLowerCase(), plural_opt.singular.toLowerCase()].includes(this.word.toLowerCase())){
					const field = this.plural_check.substring(1, this.plural_check.length-1);
					data = `[plurals(=one:${plural_opt.singular},=other:${plural_opt.plural})|${field}]`;
					break;
				}
			}

			// console.log("TEST_PLURAL data", data);
			this.$emit("changePlural", this.word, data, this.token_idx, this.offset);
		},
		cancel () {
			this.show = false;
			this.showSynonyms = false;
			this.showPlural = false;
			this.showTypos = false;
		}
	}
};
</script>

<style>
.title {
  color: #8e9490;
}

.word {
  font-weight: bold;
  font-size: 20px;
}

.container-border {
  border: 1px solid #bfc7c1;
}

.no-valid{
    font-size: 80%;
    color: #dc3545;
		clear: both;
}
</style>
