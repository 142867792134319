var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "info" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Documents data"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.$modal.hide(_vm.modalName)
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("UploadFile", {
            attrs: { ref_file_input: _vm.ref_file_input },
            on: { uploadFiles: _vm.upload },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-table-simple",
                { attrs: { hover: "", small: "", "caption-top": "" } },
                [
                  _c(
                    "b-thead",
                    [
                      _c(
                        "b-th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "80%" },
                        },
                        [_vm._v("File")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "20%" },
                        },
                        [_vm._v("Controls")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    _vm._l(_vm.documents, function (document) {
                      return _c(
                        "b-tr",
                        { key: document.id },
                        [
                          _c(
                            "b-td",
                            { staticClass: "text-left align-middle" },
                            [_vm._v(_vm._s(document.name.trunc(70)))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-td",
                            { staticClass: "text-right align-middle" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadDataFile(
                                        $event,
                                        document
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "file-download" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteData($event, document)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "trash-alt" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }