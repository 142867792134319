var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.addNewAlias()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" Add New\n          "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.aliases && _vm.aliases.length > 0
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("b-form-input", {
                        staticClass: "mr-sm-2 input-search-template",
                        attrs: { placeholder: "Search" },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4", attrs: { id: "tableOfAliases" } },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.aliases_filter, function (alias) {
                    return _c(
                      "div",
                      { key: alias.id },
                      [
                        _c("AliasItem", {
                          attrs: { aliasItem: alias },
                          on: {
                            onDelete: _vm.deleteAlias,
                            onUpdate: _vm.updateAlias,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.isLoading ? _c("Loading") : _vm._e(),
            ],
            1
          )
        : _c("p", { staticClass: "mt-4 text-center" }, [
            _vm._v(
              '\n      No aliases available for this domain. Please, click on "Add New" button to\n      create one\n    '
            ),
          ]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row pr-3 pl-3" }, [
      _c("div", { staticClass: "col-3" }, [
        _c("h2", { staticClass: "h5" }, [_vm._v("Name")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c("h2", { staticClass: "h5" }, [_vm._v("Value")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c("h2", { staticClass: "h5" }, [_vm._v("Description")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }