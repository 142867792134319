var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jsoneditor-container",
      class: { "max-box": _vm.max, "min-box": !_vm.max },
      style: _vm.getHeight,
    },
    [
      _c("div", { ref: "jsoneditor", staticClass: "jsoneditor-box" }),
      _vm._v(" "),
      _vm.options.mode == "code" && _vm.plus
        ? _c("button", {
            staticClass: "max-btn",
            attrs: { type: "button", size: "mini" },
            on: {
              click: function ($event) {
                _vm.max = !_vm.max
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }