import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

const replace_data = (string, data_used) => {
	data_used.forEach(data => {
		if (parseInt(data[1]) == data[1] || parseFloat(data[1]) == data[1]) {
			string = string.replace(data[1], "[number]");
		}
	});
	return string;
};

class Minerva extends BaseClient {
	constructor() {
		super(httpClient, "/minerva/");
	}

	similarity(domain_id, language, word) {
		console.log("similariry", language, word);
		return this.post("", { "domain_id": domain_id, "operation": "similarity", "language": language, "data": word });
	}

	paraphrasing(domain_id, language, documenta_data_id, sentence) {
		console.log("paraphrasing", language, sentence);
		return this.post("", { "domain_id": domain_id, "operation": "paraphrasing", "language": language, "documenta_data_id": documenta_data_id, "data": sentence });
	}

	variations_bert(domain_id, language, sentence) {
		console.log("variations", language, sentence);

		return this.post("", { "domain_id": domain_id, "operation": "variations_bert", "language": language, "data": sentence });
	}

	variations_context2vec(domain_id, language, sentence) {
		console.log("variations", language, sentence);
		return this.post("", { "domain_id": domain_id, "operation": "variations_context2vec", "language": language, "data": sentence });
	}

	async synonyms_tokens(domain_id, language, sentence) {
		console.log("synonyms_tokens", language, sentence);
		return await this.post("", { "domain_id": domain_id, "operation": "synonyms_tokens", "language": language, "data": sentence });
	}

	async grammar(domain_id, language, sentence) {
		console.log("grammar", language, sentence);
		return await this.post("", { "domain_id": domain_id, "operation": "grammar", "language": language, "data": sentence });
	}

	async plural(domain_id, language, sentence, data_used) {
		console.log("singular/plural", language, sentence, data_used);
		const sentence_fix = sentence.replace("’", "'").replace("·", " ");
		const data = replace_data(sentence_fix, data_used);
		return await this.post("", { "domain_id": domain_id, "operation": "plural", "language": language, "data": data });
	}
}

export default new Minerva();
