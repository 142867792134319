<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="info">
			<b-navbar-brand href="#">Documents data</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click="$modal.hide(modalName)">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>
		<div class="container">
			<UploadFile :ref_file_input="ref_file_input" @uploadFiles="upload" />
			<br />
			<b-row>
				<b-table-simple hover small caption-top>
					<b-thead>
						<b-th class="text-center" style="width: 80%">File</b-th>
						<b-th class="text-center" style="width: 20%">Controls</b-th>
					</b-thead>
					<b-tbody>
						<b-tr v-for="document in documents" v-bind:key="document.id">
							<b-td class="text-left align-middle">{{
								document.name.trunc(70)
							}}</b-td>

							<b-td class="text-right align-middle">
								<b-button
									variant="primary"
									@click="downloadDataFile($event, document)"
								>
									<font-awesome-icon icon="file-download" />
								</b-button>

								<b-button
									variant="danger"
									@click="deleteData($event, document)"
								>
									<font-awesome-icon icon="trash-alt" />
								</b-button>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-row>
		</div>
		<Loading v-if="isLoading"></Loading>
	</div>
</template>

<script>
import document_data_api from "../../api/document_data.js";
import { alertMixins } from "@/mixins/alertMixins.js";
import "../../utils/common.js";
import Loading from "@/components/UI/Loading";
import UploadFile from "@/components/UI/UploadFile/UploadFile";

export default {
	name: "DocumentsDataList",
	mixins: [alertMixins],
	props: ["documentID", "ref_file_input", "modalName"],
	components: {
		Loading,
		UploadFile
	},
	data () {
		return {
			isLoading: true,

			documents: []
		};
	},
	created () {
		this.getdocumentData();
	},

	methods: {
		async getdocumentData () {
			this.isLoading = true;
			const [data, err] = await document_data_api.getByDocumentId(
				this.documentID
			);
			console.log(data);
			if (!err) {
				this.documents = data;
				this.$store.commit("SET_DOCUMENT_DATA", data);
			} else {
				this.showErrorAlert("Error: " + err.data.error);
			}
			this.isLoading = false;
		},
		upload (files) {
			let self = this;
			files.forEach(async (file) => {
				const [data, err] = await document_data_api.create(self.documentID, {
					"name": file.name,
					"data": file.data
				});

				console.log(data);
				if (!err) {
					self.documents.push(data);
					self.$store.commit("SET_DOCUMENT_DATA", self.documents);
				} else self.showErrorAlert("Error: " + err.data.error);
			});
		},
		deleteData ($event, document) {
			this.showDeleteModal(document, this.deleteDataOK, this.deleteDataCancel);
		},
		async deleteDataOK (document) {
			this.isLoading = true;
			console.log("deleteData", document);

			const [data, err] = await document_data_api.deleteDocumentData(
				document.document_id,
				document.id
			);

			console.log(data, err);
			if (err) this.showErrorAlert("Error: " + err.data.error);

			this.getdocumentData();
			this.isLoading = false;
		},
		deleteDataCancel () {},
		async downloadDataFile ($event, doc) {
			const blob = new Blob([doc.data], { type: "text/plain" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = doc.name;
			link.click();
			URL.revokeObjectURL(link.href);
		}
	}
};
</script>

<style scoped>
.file-upload-input {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 1%;
	height: 1%;
	outline: none;
	opacity: 0;
	cursor: pointer;
}

.v--modal-overlay {
	z-index: 1030;
}
</style>
