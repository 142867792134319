<template>
	<div
		class="template-cell-text-input p-1 highlight-input"
		:contenteditable="canEdit"
		spellcheck="false"
		@blur.prevent="handleAction('blur', $event)"
		@mouseup="handleAction('mouseup', $event)"
		@keyup.prevent="handleAction('keyup', $event)"
		@focus.prevent="handleAction('focus', $event)"
		ref="hight"
	></div>
</template>

<script>
import IntervalTree from "node-interval-tree";
import debounce from "lodash/debounce";
import isUndefined from "lodash/isUndefined";

let tagsToReplace = {
	"&": "&amp;",
	"<": "&lt;",
	">": "&gt;"
};

export default {
	name: "HighlightableInput",
	props: {
		highlight: Array,
		value: String,
		highlightStyle: {
			type: [String, Object],
			default: "background-color:yellow"
		},
		highlightEnabled: {
			type: Boolean,
			default: true
		},
		highlightDelay: {
			type: Number,
			default: 500 //This is milliseconds
		},
		caseSensitive: {
			type: Boolean,
			default: false
		},
		fireOn: {
			type: String,
			default: "keydown"
		},
		fireOnEnabled: {
			type: Boolean,
			default: true
		},
		refreshHighlights: {
			type: Boolean,
			default: true
		},
		category_id: {
			type: String,
			default: 1
		},
		canEdit: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			internalValue: "",
			htmlOutput: "",
			debouncedHandler: null,
			focused: false
		};
	},
	mounted () {
		if (this.fireOnEnabled) { this.$el.addEventListener(this.fireOn, this.handleChange); }
		this.internalValue = this.value;
		this.processHighlights();
	},

	watch: {
		highlightStyle () {
			//console.error("highlightStyle");
			//this.processHighlights();
		},

		highlight () {
			//console.error("highlight");
			//this.processHighlights();
		},

		value () {
			//console.error("value ", this.value);
			if (this.internalValue != this.value) {
				this.internalValue = this.value;
				this.processHighlights();
			}
		},

		highlightEnabled () {
			//console.error("highlightEnabled");
			//this.processHighlights();
		},

		caseSensitive () {
			//console.error("caseSensitive");
			//this.processHighlights();
		},
		templates_types () {
			//console.error("templates_types");
			//this.processHighlights();
		},
		htmlOutput () {
			//console.error("htmlOutput");
			let selection = this.saveSelection(this.$el);
			this.$el.innerHTML = this.htmlOutput;
			this.restoreSelection(this.$el, selection);
		}
	},
	computed: {
		all_templates () {
			return this.$store.getters.getTemplates;
		},
		templates_types () {
			let members_templates = [];

			for (let i = 0; i < this.all_templates.length; i++) {
				const template = this.all_templates[i];
				if (
					template.versions[0] &&
					members_templates.indexOf(template.versions[0].type) < 0
				) {
					members_templates.push(template.versions[0]);
				}
				if (template.subtemplates_objs) {
					//template.subtemplates_objs = {};
					[...template.subtemplates_objs].forEach((subtemplate) => {
						//console.log("subtemplate", subtemplate);
						members_templates.push(subtemplate.template.versions[0]);
					});
				}
			}
			//console.error("push members_templates", members_templates);
			return members_templates;
		},
		trees () {
			let trees = [];
			let root_narras = this.$store.getters.getRootNarras;

			for (let i = 0; i < root_narras.nodes.length; i++) {
				const tree = root_narras.nodes[i];
				if (tree.versions[0] && trees.indexOf(tree.versions[0].name) < 0) {
					trees.push(tree.versions[0].name);
				}
			}
			//console.error("push members_templates", members_templates);
			return trees;
		}
	},
	methods: {
		focus () {
			let domElement = this.$el;
			domElement.focus();
			console.log("focus H", domElement);
		},
		handleAction (action, $event) {
			if (action == "focus") {
				this.focused = true;
			} else if (action == "blur") {
				this.focused = false;
				//this.processHighlights();
			}
			this.$emit(action, $event);
		},
		handleChange () {
			this.debouncedHandler = debounce(function () {
				if (this.internalValue !== this.$el.textContent) {
					this.internalValue = this.$el.textContent;
					//console.error("handleChange");
					//this.processHighlights();
				}
			}, this.highlightDelay);
			this.debouncedHandler();
		},

		processHighlights () {
			//console.log("processHighlights", this.internalValue);
			if (!this.refreshHighlights) {
				//return;
				console.log("no refres");
			}

			if (!this.highlightEnabled) {
				console.log("no enabled");
				this.htmlOutput = this.internalValue;
				this.$emit("input", this.internalValue);
				return;
			}

			let intervalTree = new IntervalTree();
			//Find the position ranges of the text to highlight
			let highlightPositions = [];
			let sortedHighlights = this.normalizedHighlights();
			if (!sortedHighlights) return;

			for (var i = 0; i < sortedHighlights.length; i++) {
				var highlightObj = sortedHighlights[i];

				var indices = [];
				if (highlightObj.text) {
					if (typeof highlightObj.text === "string") {
						indices = this.getIndicesOf(
							highlightObj.text,
							this.internalValue,
							isUndefined(highlightObj.caseSensitive)
								? this.caseSensitive
								: highlightObj.caseSensitive
						);
						indices.forEach((start) => {
							let end = start + highlightObj.text.length - 1;
							this.insertRange(start, end, highlightObj, intervalTree);
						});
					} else if (
						Object.prototype.toString.call(highlightObj.text) ===
						"[object RegExp]"
					) {
						indices = this.getRegexIndices(
							highlightObj.text,
							this.internalValue
						);
						indices.forEach((pair) => {
							this.insertRange(
								pair.start,
								pair.end,
								highlightObj,
								intervalTree
							);
						});
					}
				}

				if (
					highlightObj.start != undefined &&
					highlightObj.end != undefined &&
					highlightObj.start < highlightObj.end
				) {
					let start = highlightObj.start;
					let end = highlightObj.end - 1;
					this.insertRange(start, end, highlightObj, intervalTree);
				}
			}

			//Esto está hecho adhoc para el editor.
			//Como la búsqueda de corchetes con RegExp no funciona del todo bien voy ha hacer una búsqueda manual
			indices = [];
			let inicio = false;
			let chrInicio = 0;
			let apertura = 0;
			let isTemplate = false;
			// this.internalValue = this.internalValue.replace(
			// 	/\s+(?=[^<>]*(?:<|$))/g,
			// 	"\u00B7"
			// );

			for (var i = 0; i < this.internalValue.length; i++) {
				if (this.internalValue.charAt(i) == "[") {
					if (!inicio) {
						chrInicio = i;
						inicio = true;
						if (
							this.internalValue.charAt(i + 1) == "_" ||
							this.internalValue.charAt(i + 1) == "*"
						) {
							isTemplate = true;
						} else {
							isTemplate = false;
						}
					}
					apertura++;
				} else if (this.internalValue.charAt(i) == "]") {
					apertura--;
					if (apertura == 0) {
						let highlightO;
						if (isTemplate) {
							highlightO = {
								text: /\[_(.*?.*?)\]/g,
								style: "badge-template badge-subtemplate-template"
							};
						} else {
							highlightO = {
								text: /\[(.*?.*?)\]/g,
								style: "badge-template badge-data-template"
							};
						}
						//console.log("highlightO", highlightO);
						this.insertRange(chrInicio, i, highlightO, intervalTree);

						inicio = false;
						chrInicio = 0;
						apertura = 0;
						isTemplate = false;
					}
				}
				//console.log("fin process");
			}

			highlightPositions = intervalTree.search(0, this.internalValue.length);
			highlightPositions = highlightPositions.sort((a, b) => a.start - b.start);

			//Construct the output with styled spans around the highlight text
			let result = "";
			let startingPosition = 0;
			for (let k = 0; k < highlightPositions.length; k++) {
				let position = highlightPositions[k];
				result += this.safe_tags_replace(
					this.internalValue.substring(startingPosition, position.start)
				);

				const text_tag = this.safe_tags_replace(
					this.internalValue.substring(position.start, position.end + 1)
				);

				const text_synonims_formated = this.putBRinSyunonims(text_tag);

				//hay que buscar si hay referencias a subtemplates para tranformalos en links
				const final_text = this.replaceWithLink(text_synonims_formated[1]);
				//console.log("final_text", final_text);

				if (text_synonims_formated[0]) {
					result +=
						"<br><span class='" +
						highlightPositions[k].style +
						"'>" +
						final_text +
						"</span><br>";
				} else {
					result +=
						"<span class='" +
						highlightPositions[k].style +
						"'>" +
						final_text +
						"</span>";
				}
				startingPosition = position.end + 1;
			}

			//In case we exited the loop early
			if (startingPosition < this.internalValue.length) {
				result += this.safe_tags_replace(
					this.internalValue.substring(
						startingPosition,
						this.internalValue.length
					)
				);
			}

			//Stupid firefox bug
			if (result[result.length - 1] == " ") {
				result = result.substring(0, result.length - 1);
				result += "&nbsp;";
			}

			result = this.htmlFormatter(result);

			this.htmlOutput = result;
			this.$emit("input", this.internalValue);
		},
		htmlFormatter (result) {
			//si detectamos un </p> o un <br> metemos un salto de línea
			//console.error("result", result);
			//<br> <br/>
			result = result.replace(/&lt;br&gt;/g, "&lt;br&gt;<br>");
			result = result.replace(/&lt;br\/&gt;/g, "&lt;br/&gt;<br>");

			//<p>
			result = result.replace(/&lt;p&gt;/g, "<br>&lt;p&gt;");
			result = result.replace(/&lt;\/p&gt;/g, "&lt;/p&gt;<br>");

			//<ul>
			result = result.replace(/&lt;ul&gt;/g, "<ul>&lt;ul&gt;");
			result = result.replace(/&lt;\/ul&gt;/g, "&lt;/ul&gt;</ul>");

			//<il>
			result = result.replace(/&lt;li&gt;/g, "<li>&lt;li&gt;");
			result = result.replace(/&lt;\/li&gt;/g, "&lt;/li&gt;</li>");

			//<b> </b>
			result = result.replace(/&lt;b&gt;/g, "<b>&lt;b&gt;");
			result = result.replace(/&lt;\/b&gt;/g, "&lt;/b&gt;</b>");

			//<strong> </strong>
			result = result.replace(/&lt;strong&gt;/g, "<strong>&lt;strong&gt;");
			result = result.replace(/&lt;\/strong&gt;/g, "&lt;/strong&gt;</strong>");

			//<div> </div>
			result = result.replace(/&lt;div&gt;/g, "<div>&lt;div&gt;");
			result = result.replace(/&lt;\/div&gt;/g, "&lt;/div&gt;</div>");

			return result;
		},
		putBRinSyunonims (text_tag) {
			let text_formated = text_tag;
			let isSynonim = false;

			if (text_formated[0] == "{") {
				isSynonim = true;
				text_formated = "{" + text_formated.substring(1);
				let ind = text_formated.indexOf("|");
				while (ind > -1) {
					text_formated =
						text_formated.substring(0, ind) +
						"|<br>" +
						text_formated.substring(ind + 1);
					ind = text_formated.indexOf("|", ind + 1);
				}
			}

			return [isSynonim, text_formated];
		},
		replaceWithLink (text_tag) {
			/*console.log("replaceWithLink", text_tag);*/

			if (text_tag[0] == "{" || text_tag.indexOf(".") > -1) {
				return text_tag;
			}

			const template = text_tag.substr(1, text_tag.length - 2);
			//const template = text_tag.replace(/[\[\]']+/g,'')

			const text_link =
				"<a class='subtemplate-link' href='#" + template + "'>" +
				template +
				"</a>";

			const begin = text_tag.substring(0, 1);
			const end = text_tag.substring(text_tag.length - 1);
			text_tag = begin + text_link + end;

			return text_tag;

			const types_sorted = this.templates_types.sort(
				(x, y) => y.type.length - x.type.length
			);
			console.log("types_sorted", types_sorted);
			let findIt = false;
			for (let i = 0; i < types_sorted.length; i++) {
				const version = types_sorted[i];
				let ind = text_tag.indexOf(version.type);

				if (ind < 0) ind = text_tag.indexOf("(" + version.type);

				if (version.type.length > 0 && ind > -1) {
					const last = text_tag[ind + version.type.length];
					const first = ind > 0 ? text_tag[ind - 1] : text_tag[ind];
					if (
						(first == "[" || first == "(" || first == "|") &&
						(last == "]" || last == "|" || last == "(" || last == ")")
					) {
						findIt = true;

						const text_link =
							"<a class='subtemplate-link' href='#" +
							version.template_id +
							"'>" +
							version.type +
							"</a>";

						const begin = text_tag.substring(0, ind);
						const end = text_tag.substring(ind + version.type.length);
						text_tag = begin + text_link + end;
					}
				}
			}
			//console.log("findIt", findIt, text_tag);
			if (!findIt && text_tag[0] == "[" && this.category_id == "2") {
				//Si no existe el template y es composicion comprobamos si existe un tree con el mismo nombre
				for (let i = 0; i < this.trees.length; i++) {
					const tree = this.trees[i];

					if ("[" + tree + "]" == text_tag) {
						findIt = true;
					}
				}

				if (!findIt) {
					//console.log("const tree = this.trees[i]", text_tag);
					//console.error("char ", text_tag, tree, first, last);
					text_tag =
						"<a class='subtemplate-link-new' href='#new_" +
						text_tag +
						"'>" +
						text_tag +
						"</a>";
				}
			}

			return text_tag;
		},
		insertRange (start, end, highlightObj, intervalTree) {
			let overlap = intervalTree.search(start, end);
			let maxLengthOverlap = overlap.reduce((max, o) => {
				return Math.max(o.end - o.start, max);
			}, 0);
			if (overlap.length == 0) {
				intervalTree.insert(start, end, {
					start: start,
					end: end,
					style: highlightObj.style
				});
			} else if (end - start > maxLengthOverlap) {
				overlap.forEach((o) => {
					intervalTree.remove(o.start, o.end, o);
				});
				intervalTree.insert(start, end, {
					start: start,
					end: end,
					style: highlightObj.style
				});
			}
		},

		normalizedHighlights () {
			if (this.highlight == null) return null;

			if (
				Object.prototype.toString.call(this.highlight) === "[object RegExp]" ||
				typeof this.highlight === "string"
			) { return [{ text: this.highlight }]; }

			if (
				Object.prototype.toString.call(this.highlight) === "[object Array]" &&
				this.highlight.length > 0
			) {
				let globalDefaultStyle =
					typeof this.highlightStyle === "string"
						? this.highlightStyle
						: Object.keys(this.highlightStyle)
							.map((key) => key + ":" + this.highlightStyle[key])
							.join(";") + ";";

				let regExpHighlights = this.highlight.filter(
					(x) => (x == Object.prototype.toString.call(x)) === "[object RegExp]"
				);
				let nonRegExpHighlights = this.highlight.filter(
					(x) => (x == Object.prototype.toString.call(x)) !== "[object RegExp]"
				);
				return nonRegExpHighlights
					.map((h) => {
						if (h.text || typeof h === "string") {
							return {
								text: h.text || h,
								style: h.style || globalDefaultStyle,
								caseSensitive: h.caseSensitive
							};
						} else if (h.start != undefined && h.end != undefined) {
							return {
								style: h.style || globalDefaultStyle,
								start: h.start,
								end: h.end,
								caseSensitive: h.caseSensitive
							};
						} else {
							console.error(
								"Please provide a valid highlight object or string"
							);
						}
					})
					.sort((a, b) =>
						a.text && b.text
							? a.text > b.text
							: a.start == b.start
								? a.end < b.end
								: a.start < b.start
					)
					.concat(regExpHighlights);
				//We sort here in ascending order because we want to find highlights for the smaller strings first
				//and then override them later with any overlapping larger strings. So for example:
				//if we have highlights: g and gg and the string "sup gg" should have only "gg" highlighted.
				//RegExp highlights are not sorted and simply concated (this could be done better  in the future)
			}

			console.error("Expected a string or an array of strings");
			return null;
		},

		//Copied from: https://stackoverflow.com/questions/5499078/fastest-method-to-escape-html-tags-as-html-entities
		safe_tags_replace (str) {
			return str.replace(/[&<>]/g, this.replaceTag);
		},

		replaceTag (tag) {
			return tagsToReplace[tag] || tag;
		},

		getRegexIndices (regex, str) {
			if (!regex.global) {
				console.error("Expected " + regex + " to be global");
				return [];
			}

			regex = RegExp(regex);
			let indices = [];
			let match = null;
			while ((match = regex.exec(str)) != null) {
				indices.push({
					start: match.index,
					end: match.index + match[0].length - 1
				});
			}
			return indices;
		},

		//Copied verbatim because I'm lazy:
		//https://stackoverflow.com/questions/3410464/how-to-find-indices-of-all-occurrences-of-one-string-in-another-in-javascript
		getIndicesOf (searchStr, str, caseSensitive) {
			let searchStrLen = searchStr.length;
			if (searchStrLen == 0) {
				return [];
			}
			let startIndex = 0,
				index,
				indices = [];
			if (!caseSensitive) {
				str = str.toLowerCase();
				searchStr = searchStr.toLowerCase();
			}
			while ((index = str.indexOf(searchStr, startIndex)) > -1) {
				indices.push(index);
				startIndex = index + searchStrLen;
			}
			return indices;
		},

		//Copied but modifed slightly from: https://stackoverflow.com/questions/14636218/jquery-convert-text-url-to-link-as-typing/14637351#14637351
		saveSelection (containerEl) {
			let start;
			if (window.getSelection && document.createRange) {
				let selection = window.getSelection();
				if (!selection || selection.rangeCount == 0) return;
				let range = selection.getRangeAt(0);
				let preSelectionRange = range.cloneRange();
				preSelectionRange.selectNodeContents(containerEl);
				preSelectionRange.setEnd(range.startContainer, range.startOffset);
				start = preSelectionRange.toString().length;

				return {
					start: start,
					end: start + range.toString().length
				};
			} else if (document.selection) {
				let selectedTextRange = document.selection.createRange();
				let preSelectionTextRange = document.body.createTextRange();
				preSelectionTextRange.moveToElementText(containerEl);
				preSelectionTextRange.setEndPoint("EndToStart", selectedTextRange);
				start = preSelectionTextRange.text.length;

				return {
					start: start,
					end: start + selectedTextRange.text.length
				};
			}
		},

		//Copied but modifed slightly from: https://stackoverflow.com/questions/14636218/jquery-convert-text-url-to-link-as-typing/14637351#14637351
		restoreSelection (containerEl, savedSel) {
			//console.error("restoreSelection", this.refreshHighlights, this.focused);
			if (!savedSel) return;

			if (!this.refreshHighlights || !this.focused) {
				return;
			}

			if (window.getSelection && document.createRange) {
				let charIndex = 0,
					range = document.createRange();
				range.setStart(containerEl, 0);
				range.collapse(true);
				let nodeStack = [containerEl],
					node,
					foundStart = false,
					stop = false;

				while (!stop && (node = nodeStack.pop())) {
					if (node.nodeType == 3) {
						let nextCharIndex = charIndex + node.length;
						if (
							!foundStart &&
							savedSel.start >= charIndex &&
							savedSel.start <= nextCharIndex
						) {
							range.setStart(node, savedSel.start - charIndex);
							foundStart = true;
						}
						if (
							foundStart &&
							savedSel.end >= charIndex &&
							savedSel.end <= nextCharIndex
						) {
							range.setEnd(node, savedSel.end - charIndex);
							stop = true;
						}
						charIndex = nextCharIndex;
					} else {
						let i = node.childNodes.length;
						while (i--) {
							nodeStack.push(node.childNodes[i]);
						}
					}
				}

				let sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange(range);
			} else if (document.selection) {
				let textRange = document.body.createTextRange();
				textRange.moveToElementText(containerEl);
				textRange.collapse(true);
				textRange.moveEnd("character", savedSel.end);
				textRange.moveStart("character", savedSel.start);
				textRange.select();
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.highlight-input {
	word-break: break-all;
	word-wrap: break-word;

	.compositions-tab & {
		display: block;
	}
}
</style>
