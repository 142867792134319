import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Entities extends BaseClient {
	constructor () {
		super(httpClient, "/entities/");
	}

	getAll (data) {
		console.log("Entities ", data);

		return this.post("", data);
	}
}

export default new Entities();
