var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "tree-row" },
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c("tree", {
                    ref: "tree",
                    staticClass: "tree-container",
                    attrs: {
                      data: _vm.Narras,
                      options: _vm.options,
                      filter: _vm.filterTree,
                    },
                    on: { "node:dragging:finish": _vm.nodeDragFinish },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          return _c("div", { staticClass: "node-container" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "node-text-inactive": !node.data.active,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editNode(node)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                • \n                "
                                ),
                                _c(
                                  "span",
                                  {
                                    on: {
                                      contextmenu: function ($event) {
                                        $event.preventDefault()
                                        return _vm.$refs.ctxMenu.open(
                                          $event,
                                          node
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(node.text))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      href: "#",
                                      title: "Add a child node to this node",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.addChildNode(node)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "plus" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      href: "#",
                                      title:
                                        "Add a tree of a Category to this node",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.showAddByCategory(node)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "code-branch" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      href: "#",
                                      title:
                                        "Create a Segment for this node and its descendants",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.createNodeTemplates(node)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "stream" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      href: "#",
                                      title:
                                        "Delete this node and its subnodes",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.requestRemoveNode(node)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "trash-alt" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("b-col"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalGetScenariosCategory",
          attrs: {
            size: "sm",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-2",
                label: "Select category:",
                "label-for": "type",
                "label-align": "left",
              },
            },
            [
              _c("b-form-select", {
                staticClass: "input-select-document",
                attrs: {
                  id: "engine",
                  options: _vm.categoriesScenarios,
                  size: "sm",
                },
                model: {
                  value: _vm.categorySelected,
                  callback: function ($$v) {
                    _vm.categorySelected = $$v
                  },
                  expression: "categorySelected",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.categorySelected == null,
                variant: "primary",
              },
              on: { click: _vm.addNodesByCategory },
            },
            [_vm._v("Create")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { type: "reset", variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$refs["modalGetScenariosCategory"].hide()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalGetScenario",
          attrs: {
            size: "lg",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": true,
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-2",
                label: "Select scenario:",
                "label-for": "type",
                "label-align": "left",
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.scenarios, label: "text" },
                scopedSlots: _vm._u([
                  {
                    key: "no-options",
                    fn: function (ref) {
                      var search = ref.search
                      var searching = ref.searching
                      return [
                        searching
                          ? [
                              _c("em", [
                                _vm._v(
                                  "Sorry, no matching option or was already defined"
                                ),
                              ]),
                              _vm._v(".\n            "),
                            ]
                          : _c("em", [
                              _vm._v(
                                "Sorry, no matching option or was already defined"
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.scenarioSelected,
                  callback: function ($$v) {
                    _vm.scenarioSelected = $$v
                  },
                  expression: "scenarioSelected",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.scenarioSelected == null,
                variant: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.addNode(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { type: "reset", variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$refs["modalGetScenario"].hide()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "context-menu",
        {
          ref: "ctxMenu",
          attrs: { id: "context-menu" },
          on: { "ctx-open": _vm.onCtxOpen },
        },
        [
          _c(
            "li",
            {
              staticClass: "ctx-item",
              on: {
                click: function ($event) {
                  return _vm.copy_node($event, _vm.menuData)
                },
              },
            },
            [_vm._v("Copy")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "ctx-item",
              on: {
                click: function ($event) {
                  return _vm.paste_node($event, _vm.menuData)
                },
              },
            },
            [_vm._v("Paste")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }