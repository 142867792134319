var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "row text-light pt-2" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "footer__top row" }, [
            _c("div", { staticClass: "col-md-4 pl-3" }, [
              _c("h3", [
                _c(
                  "a",
                  {
                    staticClass: "footer__logo",
                    attrs: {
                      href: "https://www.narrativa.com",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "100px",
                        height: "16px",
                        src: "https://covid19tracking.narrativa.com/img/NarrativaLogoBlanco.png",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer__bottom row mb-1 mt-0" }, [
            _c("div", { staticClass: "col-md-10" }, [
              _c("p", { staticClass: "footer__copy" }, [
                _vm._v(
                  "© Copyright All Rights Reserved 2015-2021 by Narrativa"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass:
                  "footer__social col-md-2 mb-0 d-flex justify-content-end",
              },
              [
                _c("li", { staticClass: "footer__social-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "footer__link footer__social-link",
                      attrs: {
                        href: "https://www.linkedin.com/company/9329868",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-linkedin",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none" }, [
                        _vm._v("linkedin"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "footer__social-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "footer__link footer__social-link",
                      attrs: {
                        href: "https://www.twitter.com/NarrativaAI",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-twitter",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none" }, [
                        _vm._v("twitter"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "footer__social-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "footer__link footer__social-link",
                      attrs: {
                        href: "https://www.facebook.com/narrativaai/",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-facebook",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none" }, [
                        _vm._v("facebook"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "footer__social-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "footer__link footer__social-link",
                      attrs: {
                        href: "mailto:info@narrativa.com",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-envelope",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none" }, [_vm._v("email")]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }