var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("br"),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success" },
                    on: { click: _vm.newModal },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                    _vm._v(" New\n          "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c("b-col", [
              _c("h3", { staticClass: "text-center" }, [_vm._v("Structure")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v("Select a Section to start working"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text-center" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Home",
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        { attrs: { variant: "secondary" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "backward" },
                          }),
                          _vm._v(" Back\n              "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { cols: "2" } }),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { cols: "8" } },
              [
                _c("br"),
                _vm._v(" "),
                _c(
                  "b-table-simple",
                  { attrs: { hover: "", small: "", "caption-top": "" } },
                  [
                    _c(
                      "b-thead",
                      [
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "65%" },
                          },
                          [_vm._v("Section")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "20%" },
                          },
                          [_vm._v("Controls")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tbody",
                      _vm._l(_vm.narras.nodes, function (narra) {
                        return _c(
                          "b-tr",
                          { key: narra.id, attrs: { scope: "row" } },
                          [
                            _c(
                              "b-td",
                              { staticClass: "text-left" },
                              [
                                narra.versions[0].active ||
                                narra.versions[0].active == "true"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "NarrasEditorView",
                                            params: {
                                              domainId: _vm.domainId,
                                              narraId: narra.id,
                                              narraName: narra.versions[0].name,
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(narra.versions[0].name))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(narra.versions[0].name)),
                                    ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-td",
                              { staticClass: "text-right" },
                              [
                                narra.versions[0].active ||
                                narra.versions[0].active == "true"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "NarrasEditorView",
                                            params: {
                                              domainId: _vm.domainId,
                                              narraId: narra.id,
                                              narraName: narra.versions[0].name,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          { attrs: { variant: "info" } },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "play" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "light" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateModal($event, narra)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "cog" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteModal($event, narra.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "trash-alt" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "modal-new",
            attrs: {
              "hide-footer": "",
              id: "modal-new",
              title: "New root narras tree",
              "no-close-on-backdrop": true,
              centered: "",
            },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Name:",
                      "label-for": "name",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "name",
                        state: _vm.validationName,
                        required: "",
                        placeholder: "Name",
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-4",
                      label: "Description:",
                      "label-for": "description",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-textarea", {
                      attrs: { id: "description", placeholder: "Description" },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "input-group-5", "label-align": "left" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "active",
                          value: "true",
                          "unchecked-value": "false",
                        },
                        model: {
                          value: _vm.form.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "active", $$v)
                          },
                          expression: "form.active",
                        },
                      },
                      [_vm._v("Active")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: !_vm.validationName,
                      variant: "primary",
                    },
                    on: { click: _vm.updateNarra },
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: !_vm.validationName,
                      variant: "primary",
                    },
                    on: { click: _vm.updateNarraAndEdit },
                  },
                  [_vm._v("Save and add childs")]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { type: "reset", variant: "danger" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isLoading ? _c("Loading") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }