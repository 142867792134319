import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import TableTemplatesV4 from "@/components/templates/TableTemplatesV4.vue";
import EditorV5 from "@/components/editor/EditorV5";
import EditorNV from "@/components/editor/EditorNV";
import DocumentsList from "@/components/documents/DocumentsList.vue";
import Login from "@/components/Login.vue";
import NarrasEditorView from "@/components/narras/NarrasEditorView.vue";
import NarrasList from "@/components/narras/NarrasList.vue";

Vue.use(Router);

let router = new Router({
	mode: "history",
	routes: [
		{
			path: process.env.ROOT_PATH,
			name: "Home",
			component: Home,
      meta: {
        requiresAuth: true
      }
		},
		{
			path: process.env.ROOT_PATH + "/document/:domainId",
			name: "DocumentsList",
			component: DocumentsList,
			props: true,
      meta: {
        requiresAuth: true
      }
		},
		{
			path: process.env.ROOT_PATH + "/document/templates/v4/:documentId",
			name: "TableTemplatesV4",
			component: TableTemplatesV4,
			props: true,
      meta: {
        requiresAuth: true
      }
		},
		{
			path:
        process.env.ROOT_PATH + "/document/templates/v5/:domainId/:documentId",
			name: "EditorV5",
			component: EditorV5,
			props: true,
      meta: {
        requiresAuth: true
      }
		},
		{
			path:
        process.env.ROOT_PATH + "/document/templates/nv/:domainId/:documentId",
			name: "EditorNV",
			component: EditorNV,
			props: true
		},
		{
			path: process.env.ROOT_PATH + "/narras/:domainId",
			name: "NarrasList",
			component: NarrasList,
			props: true,
      meta: {
        requiresAuth: true
      }
		},
		{
			path:
        process.env.ROOT_PATH + "/narras/tree/:domainId/:narraId/:narraName",
			name: "NarrasEditorView",
			component: NarrasEditorView,
			props: true,
      meta: {
        requiresAuth: true
      }
		},
		{
			path: process.env.ROOT_PATH + "/login",
			name: "login",
			component: Login
		}
	]
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt_token') == null) {
      next({
        path: process.env.ROOT_PATH + "/login",
        params: { nextUrl: to.fullPath }
      })
    } else {
			next()
		}
  } else {
		next()
	}
})

export default router;
