import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Users extends BaseClient {
	constructor() {
		super(httpClient, "/users");
	}

	getUsers() {
		return this.get("");
	}

	isStuff(username) {
		return this.get(`/${username}/is_stuff`);
	}
}

export default new Users();
