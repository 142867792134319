<template>
	<div>
		<div>
			<template v-for="fragment in text_entities.fragments">
				<a
					v-if="fragment.type == 1 && fragment.text != 'r'"
					class="badge-template badge-data-template"
					href="#"
					@click.prevent.stop="showNschemaTree($event, fragment.text)"
					>{{ fragment.text }}</a
				>
				<span v-else>{{ fragment.text }}</span>
			</template>
		</div>
		<NschemaFieldSelector
			:old_text="old_text"
			ref="NschemaFieldSelector"
			@selectNode="selectNode"
		/>
	</div>
</template>

<script>
import NschemaFieldSelector from "@/components/Entities/NschemaFieldSelector.vue";
export default {
	name: "EntitiesFormater",
	components: { NschemaFieldSelector },
	data () {
		return {
			old_text: ""
		};
	},
	props: {
		text_entities: Object
	},
	watch: {
		text_entities () {
			this.$refs["NschemaFieldSelector"].hideTree();
		}
	},
	created () {},
	computed: {},
	methods: {
		showNschemaTree ($event, entity) {
			this.old_text = entity;

			this.$refs["NschemaFieldSelector"].showTree($event);
		},
		selectNode (new_text) {
			[...this.text_entities.fragments].forEach((fragment) => {
				if (fragment.text == this.old_text) {
					fragment.text = new_text;
				}
			});
		}
	}
};
</script>

<style>
</style>
