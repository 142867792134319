export const htmlDecode = input => {
	let doc = new DOMParser().parseFromString(input, "text/html");
	return doc.documentElement.textContent;
};

export const getPosEL = el => {
	//yay readability
	for (
		var lx = 0, ly = 0;
		el != null;
		lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
	);
	return { x: lx, y: ly };
};

export const getRandomStringId = () => {
	return Math.random().toString(36).substring(7);
};

String.prototype.trunc =
  String.prototype.trunc ||
  function (n) {
  	return this.length > n ? this.substr(0, n - 1) + "..." : this;
  };
