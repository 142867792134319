<template>
	<div>
		<modal height="auto" width="700" name="documentsDataListModalData">
			<DocumentsDataList
				:documentID="documentId"
				ref_file_input="file_input_data"
				modalName="documentsDataListModalData"
			/>
		</modal>
		<b-navbar
			sticky
			toggleable="lg"
			type="light"
			variant="light"
			class="navigation_sticky_bar"
		>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<b-nav-item href="#" @click="updateDataFile()">
						<font-awesome-icon icon="save" />&nbsp;Save
					</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav class="ml-auto">
					<b-nav-form>
						<b-form-select
							class="input-select-document"
							id="engine"
							v-model="document_data_selected"
							:options="document_data"
							size="sm"
						></b-form-select>
						&nbsp;
						<b-button variant="warning" v-on:click="dataModal($event)">
							<font-awesome-icon icon="paperclip" />
						</b-button>
					</b-nav-form>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		<!--TreeView
			class="mx-5"
			:data="file_data"
			:options="{ maxDepth: 8, modifiable: false, rootObjectKey: '*' }"
			@change-data="onChangeData"
		></TreeView!-->
		<v-jsoneditor
			v-model="file_data"
			:options="optionsJsonEditor"
			@error="onError"
			height="760px"
		/>
	</div>
</template>

<script>
import DocumentsDataList from "@/components/documents/DocumentsDataList";
import VJsoneditor from "@/components/UI/VueJsoneditor/VueJsoneditor.vue";
import document_data_api from "../../api/document_data.js";
//import TreeView from "@/components/UI/JSONEditor/TreeView";
import "../../utils/common.js";

export default {
	name: "DataViewer",
	components: {
		//TreeView,
		DocumentsDataList,
		VJsoneditor
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getDocumentDataSelected,
			(document_id, oldValue) => {
				console.log("watch");
				let data = this.$store.getters.getDocumentData;
				const document = data.filter((v) => {
					//console.log(v.name, v.name.indexOf(searchString));
					return v.id == document_id;
				});
				if (document.length > 0) {
					this.file_data = JSON.parse(document[0].data);
					this.document_obj = document[0];

					//extract templates from the nschema
					const nschemaOBJ = JSON.parse(this.$store.getters.getNSchema);
					this.optionsJsonEditor.templates = [];
					[...nschemaOBJ["@graph"]].forEach((data) => {
						if (data["@id"].toLowerCase().startsWith("nrt:class#")) {
							const className =
								"_class_" + data["@id"].substring(data["@id"].indexOf("#"));
							let obj = {
								"text": className
							};
						}

						/*
{
						text: "nrt:class",
						title: "Insert a NSchema class",
						className: "nschema-class",
						field: "ClassTemplate",
						value: {
							"@id": "nrt:class#",
							"@type": "nrt:class",
							"properties": [],
						},
					},
						*/
					});
				}
			}
		);
	},
	computed: {
		document_data_selected: {
			get () {
				return this.$store.getters.getDocumentDataSelected;
			},
			set (value) {
				this.$store.commit("SET_DOCUMENT_DATA_SELECTED", value);
			}
		},
		document_data () {
			let data = this.$store.getters.getDocumentData;

			let options = [];
			options.push({ value: 0, text: "Select a data file" });
			for (let index = 0; index < data.length; index++) {
				let document_data = data[index];

				options.push({
					value: document_data.id,
					text: document_data.name.trunc(50)
				});
			}
			return options;
		}
	},
	props: {
		documentId: {}
	},
	data () {
		return {
			file_data: {},
			document_obj: {},
			optionsJsonEditor: {
				mode: "tree",
				modes: ["tree", "code"],
				mainMenuBar: true,
				navigationBar: true,
				statusBar: true,
				templates: []
				/*templates: [
          {
            text: "Person",
            title: "Insert a Person Node",
            className: "jsoneditor-type-object",
            field: "PersonTemplate",
            value: {
              "firstName": "John",
              "lastName": "Do",
              "age": 28
            }
          },
          {
            text: "Address",
            title: "Insert a Address Node",
            field: "AddressTemplate",
            value: {
              "street": "",
              "city": "",
              "state": "",
              "ZIP code": ""
            }
          }
        ]*/
			}
		};
	},
	methods: {
		onError () {},
		onChangeData (data) {
			console.log(JSON.stringify(data));
		},
		dataModal ($event) {
			this.$modal.show("documentsDataListModalData");
		},
		async updateDataFile () {
			const [data, err] = await document_data_api.update(
				this.documentId,
				this.document_data_selected,
				{
					"document_id": this.documentId,
					"id": this.document_data_selected,
					"name": this.document_obj.name,
					"data": JSON.stringify(this.file_data)
				}
			);
			console.log("data", data);
			console.log("err", err);
			if (!err) {
				this.$bvToast.toast("Data file updated", {
					"title": "Success ",
					"variant": "success",
					"solid": true,
					"auto-hide-delay": "2000",
					"appendToast": true
				});
				this.loadNSchema();
			} else {
				this.$bvToast.toast("Error saving data file", {
					"title": "Error ",
					"variant": "danger",
					"solid": true,
					"auto-hide-delay": "2000",
					"appendToast": true
				});
			}
		}
	}
};
</script>

<style scoped>
.v--modal-overlay {
	z-index: 1030;
}
</style>
