/* eslint-disable */
<template>
	<section class="content">
		<b-navbar toggleable="lg" type="light" variant="info">
			<b-navbar-brand href="#">Document ID {{ documentId }}</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<b-nav-item href="#" @click="addRow">
						<img src="@/assets/imgs/plus_icon.png" />New Template
					</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>

		<table class="table table-bordered table-hover">
			<thead class>
				<tr>
					<th scope="col">Name</th>
					<th scope="col">Narras</th>
					<th scope="col">Weigth</th>
					<th scope="col">Text</th>
				</tr>
			</thead>
			<tbody>
				<tr scope="row" v-for="template in templates">
					<td
						class="text-left"
						contenteditable
						spellcheck="false"
						@blur="updateVersion($event, 0, template.versions[0])"
						v-text="template.versions[0].type"
					></td>
					<td
						class="text-left"
						contenteditable
						spellcheck="false"
						@blur="updateVersion($event, 1, template.versions[0])"
						v-text="template.versions[0].narras"
					></td>
					<td
						class="text-left"
						contenteditable
						spellcheck="false"
						@blur="updateVersion($event, 2, template.versions[0])"
						v-text="template.versions[0].weigth"
					></td>
					<td
						class="text-left"
						contenteditable
						spellcheck="false"
						@blur="updateVersion($event, 3, template.versions[0])"
						v-text="template.versions[0].text_for_editor"
					></td>
				</tr>
			</tbody>
		</table>
		<Loading v-if="isLoading"></Loading>
	</section>
</template>

<script>
import document_templates_api from "../../api/document_templates.js";
import template_version_api from "../../api/template_version.js";
import templates_api from "../../api/template.js";
import { htmlDecode } from "../../utils/common.js";
import Loading from "@/components/UI/Loading";

export default {
	name: "TableTemplatesV4",
	components: {
		Loading
	},
	data () {
		return {
			templates: null,
			template_menu: null,
			isLoading: true
		};
	},
	created () {
		this.getTemplates();
		//templates = document_templates_api.getAll(documentId)
	},
	methods: {
		async getTemplates () {
			const [data, err] = await document_templates_api.getAllCurrent(
				this.documentId
			);

			console.log(data);
			if (!err) {
				this.templates = data.templates;
			}
			this.isLoading = false;
		},
		updateVersion (event, field, version) {
			let field_value = htmlDecode(event.target.innerHTML);
			console.log("updateVersion " + field_value + " field " + field);
			//TODO hay que hacer limpieza de <br> y otros tags html
			switch (field) {
			case 0:
				if (version.type == field_value) return;
				version.type = field_value;
				break;
			case 1:
				if (version.narras == field_value) return;
				version.narras = field_value;
				break;
			case 2:
				if (version.weigth == field_value) return;
				version.weigth = field_value;
				break;
			case 3:
				if (version.text_for_editor == field_value) return;
				version.text_for_editor = field_value;
				break;
			}
			this.updateTemplate(version);
			console.log(version);
		},
		updateTemplate (version) {
			console.log("POST TEMPLATE");
			this.isLoading = true;
			version.current_version = true;
			template_version_api.createVersionWithAlias(version, this.$store.getters.getCurrentDomain.id);
			this.isLoading = false;
			//const [ data, err ] = await document_templates.put()
		},
		async addRow () {
			console.log("Add row");
			this.isLoading = true;
			//creamos el template
			const [data, err] = await templates_api.createTemplate({
				active: true
			});
			if (!err) {
				//si se crea vien lo asociamos al documento
				const [data_dt, err_dt] = await document_templates_api.create(
					this.documentId,
					data.id
				);

				if (!err_dt) {
					let new_version = {
						template_id: data.id,
						type: "",
						narras: "",
						weigth: 0,
						text: "",
						text_for_editor: "",
						current_version: true,
						active: true
					};

					const [datav, errv] = await template_version_api.createVersion(
						new_version
					);

					if (!errv) {
						console.log(datav);
						new_version.id = datav.id;
						this.templates.push({
							id: new_version.template_id,
							versions: [new_version]
						});
					} else {
						console.error(errv);
					}
				} else {
					console.error(err_dt);
				}
			} else {
				console.error(err);
			}
			this.isLoading = false;
		}
	},
	props: {
		documentId: {}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Style the navbar */
#navbar {
	overflow: hidden;
	background-color: #333;
}

/* Navbar links */
#navbar a {
	float: left;
	display: block;
	color: #f2f2f2;
	text-align: center;
	padding: 14px;
	text-decoration: none;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
	position: fixed;
	top: 0;
	width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
	padding-top: 60px;
}
</style>
