var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        attrs: { id: "filedrag" },
        on: {
          drop: function ($event) {
            $event.preventDefault()
            return _vm.addFile.apply(null, arguments)
          },
          dragover: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c(
          "h1",
          [_c("font-awesome-icon", { attrs: { icon: "cloud-upload-alt" } })],
          1
        ),
        _vm._v(" "),
        _c("h2", [_vm._v("Drop files here")]),
        _vm._v("\n\t\tor\n\t\t"),
        _c("br"),
        _vm._v(" "),
        _c("input", {
          ref: _vm.ref_file_input,
          staticClass: "file-upload-input",
          attrs: { id: "file-input", type: "file", multiple: _vm.multiple },
          on: {
            change: function ($event) {
              return _vm.handleBrowseFiles($event)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "b-table-simple",
          {
            attrs: {
              hover: "",
              small: "",
              "caption-top": "",
              "sticky-header": "",
            },
          },
          [
            _c(
              "b-tbody",
              _vm._l(_vm.files, function (file) {
                return _c(
                  "b-tr",
                  { key: file.id },
                  [
                    _c("b-td", { staticClass: "text-left align-middle" }, [
                      _vm._v(
                        _vm._s(file.name) + " (" + _vm._s(file.size) + " bytes)"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-td",
                      { staticClass: "text-right align-middle" },
                      [
                        _c(
                          "b-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.removeFile(file)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "times" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-button",
          {
            attrs: {
              variant: "success",
              onclick: "document.getElementById('file-input').click();",
            },
          },
          [_vm._v("Browse files")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c(
          "b-button",
          {
            attrs: { variant: "info", disabled: _vm.uploadDisabled },
            on: { click: _vm.upload },
          },
          [_vm._v("Upload")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }