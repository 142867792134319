<template>
  <div id="app" class="app">
    <app-header></app-header>
		<main id="content" class="main">
			<router-view />
		</main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import { mapGetters } from "vuex";
export default {
	name: "App",
	components: {
		AppHeader,
		AppFooter
	},
	computed: {
		...mapGetters({
			redirectTo: "getRedirectTo"
		})
	},
	watch: {
		redirectTo: function (newVal, oldVal) {
			if (newVal) {
				this.$router.push(this.redirectTo);
			}
		}
	}
};
</script>
