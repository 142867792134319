var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "light" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Import segments from another domain"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: { click: _vm.closeModal },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-div-container mb-5" },
        [
          _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  {
                    staticClass: "card variations-card-columns variations-card",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Domains")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body scroll" },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-form-input", {
                              staticClass: "mr-sm-2 input-search-template",
                              attrs: { size: "sm", placeholder: "Search" },
                              model: {
                                value: _vm.filterDomain,
                                callback: function ($$v) {
                                  _vm.filterDomain = $$v
                                },
                                expression: "filterDomain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm._l(_vm.domains_filter, function (domain) {
                          return _c("p", { key: domain.id }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.selectDomain(domain.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(domain.name))]
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  {
                    staticClass: "card variations-card-columns variations-card",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Documents")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body scroll" },
                      _vm._l(_vm.documents, function (document) {
                        return _c("p", { key: document.id }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.selectDocument(document.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(document.name))]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  {
                    staticClass: "card variations-card-columns variations-card",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Segments")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body scroll" },
                      [
                        _vm.segments.length > 0
                          ? _c(
                              "b-form-group",
                              { attrs: { label: "Select segments to import" } },
                              _vm._l(_vm.segments, function (segment) {
                                return _c(
                                  "b-form-checkbox",
                                  {
                                    key: segment.id,
                                    attrs: { value: segment.versions[0].type },
                                    model: {
                                      value: _vm.segments_check,
                                      callback: function ($$v) {
                                        _vm.segments_check = $$v
                                      },
                                      expression: "segments_check",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(segment.versions[0].type) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.segments.length > 0
                      ? _c(
                          "div",
                          { staticClass: "text-right m-1" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "info",
                                  disabled: _vm.segments_check.length === 0,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.includeSelected(
                                      _vm.segments_check
                                    )
                                  },
                                },
                              },
                              [_vm._v("Import segments")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }