var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "content no-mw no-pd",
      on: {
        keydown: function ($event) {
          if (!$event.type.indexOf("key") && $event.keyCode !== 90) {
            return null
          }
          if (!$event.ctrlKey) {
            return null
          }
          return _vm.handleKeyDown($event)
        },
      },
    },
    [
      _c(
        "b-tabs",
        {
          attrs: { "content-class": "mt-0 ", "nav-class": "sticky-top" },
          model: {
            value: _vm.tabSelected,
            callback: function ($$v) {
              _vm.tabSelected = $$v
            },
            expression: "tabSelected",
          },
        },
        [
          _vm.is_stuff ||
          _vm.username === "raico.acosta" ||
          _vm.username === "anayeli.tapia" ||
          _vm.username === "rodrigo.gutierrez" ||
          _vm.username === "juliana.fregoso2"
            ? _c(
                "b-tab",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "title-nav-item" }, [
                              _c("i", {
                                staticClass: "fa fa-sitemap",
                                attrs: {
                                  "aria-hidden": "true",
                                  title: "1. NScheme",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "title-nav-item-text" },
                                [_vm._v("NScheme")]
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2764287428
                  ),
                },
                [
                  _vm._v(" "),
                  _c("NSchemaViewer", { attrs: { domainId: _vm.domainId } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "title-nav-item" }, [
                        _c("i", {
                          staticClass: "fa fa-database",
                          attrs: { "aria-hidden": "true", title: "2. Data" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "title-nav-item-text" }, [
                          _vm._v("Data"),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("DataViewer", { attrs: { documentId: _vm.documentId } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.is_stuff ||
          _vm.username === "raico.acosta" ||
          _vm.username === "anayeli.tapia" ||
          _vm.username === "rodrigo.gutierrez" ||
          _vm.username === "juliana.fregoso2"
            ? _c(
                "b-tab",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "title-nav-item" }, [
                              _c("i", {
                                staticClass: "fa fa-cubes",
                                attrs: {
                                  "aria-hidden": "true",
                                  title: "3. Scenarios",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "title-nav-item-text" },
                                [_vm._v("Scenarios")]
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2861486419
                  ),
                },
                [
                  _vm._v(" "),
                  _c("div", { staticClass: "container-fluid no-pd" }, [
                    _c("div", { staticClass: "row no-gutters" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("NarrasTreeEditor", {
                            ref: "NarrasTreeEditor",
                            attrs: {
                              navbar: "false",
                              domainId: _vm.domainId,
                              documentId: _vm.documentId,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-8",
                          attrs: { id: "scenarios_editor" },
                        },
                        [
                          _c("ScenariosEditor", {
                            ref: "ScenariosEditor",
                            attrs: {
                              domainId: _vm.domainId,
                              documentId: _vm.documentId,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "title-nav-item" }, [
                        _c("i", {
                          staticClass: "fa fa-puzzle-piece",
                          attrs: {
                            "aria-hidden": "true",
                            title: "4. Compositions",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "title-nav-item-text" }, [
                          _vm._v("Compositions"),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("TableTemplatesV5", {
                attrs: {
                  documentId: _vm.documentId,
                  category_id: "2",
                  title_navbar: "Compositions",
                  currentDocument: _vm.currentDocument,
                  currentDomain: _vm.currentDomain,
                },
                on: {
                  refresh: function ($event) {
                    return _vm.getEditorData()
                  },
                  migrate: function ($event) {
                    return _vm.migrate_domain()
                  },
                  importSegments: _vm.importSegments,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "title-nav-item" }, [
                        _c("i", {
                          staticClass: "fa fa-pencil",
                          attrs: {
                            "aria-hidden": "true",
                            title: "'5. ' + $templatesName",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "title-nav-item-text" }, [
                          _vm._v("Segments"),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("TableTemplatesNV", {
                attrs: {
                  documentId: _vm.documentId,
                  category_id: "1",
                  title_navbar: _vm.$templatesName,
                  currentDocument: _vm.currentDocument,
                  currentDomain: _vm.currentDomain,
                },
                on: {
                  refresh: function ($event) {
                    return _vm.getEditorData()
                  },
                  migrate: function ($event) {
                    return _vm.migrate_domain()
                  },
                  importSegments: _vm.importSegments,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "title-nav-item" }, [
                        _c("i", {
                          staticClass: "fa fa-star",
                          attrs: { "aria-hidden": "true", title: "Aliases" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "title-nav-item-text" }, [
                          _vm._v("Aliases"),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("AliasesEditor", {
                ref: "AliasesEditor",
                attrs: { domainId: _vm.domainId, documentId: _vm.documentId },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }