import * as types from "../mutation-types";

const state = {
	root_narras: {},
	rows_narras: [],
	narras_templates: [],
	archived_narras: [],
	archived_root_narras: {}
}

const getters = {
	getRowsNarras (state) {
		return state.rows_narras;
	},
	getNarrasTemplates (state) {
		return state.narras_templates;
	},
	getRootNarras (state) {
		return state.root_narras;
	},
	getArchivedNarras (state) {
		return state.archived_narras;
	},
	getArchivedRootNarras (state) {
		return state.archived_root_narras;
	}
}

const mutations = {
	[types.SET_ROWS_NARRAS] (state, payload) {
		state.rows_narras = payload;
	},
	[types.SET_NARRAS_TEMPLATES] (state, payload) {
		state.narras_templates = payload;
	},
	[types.SET_ROOT_NARRAS] (state, payload) {
		state.root_narras = payload;
	},
	[types.SET_ARCHIVED_NARRAS] (state, payload) {
		state.archived_narras = payload;
	},
	[types.SET_ARCHIVED_ROOT_NARRAS] (state, payload) {
		state.archived_root_narras = payload;
	}
}

export default {
	state,
	getters,
	mutations
};
