var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c(
      "td",
      {
        staticClass: "tree_viewer-border-b",
        style: {
          "padding-left": _vm.node.level * _vm.indent + _vm.left_padding + "px",
        },
      },
      [
        _vm.select_name == "true"
          ? _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("selectNarra", _vm.node.data)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.node.name))]
            )
          : _c("span", [_vm._v(_vm._s(_vm.node.name))]),
      ]
    ),
    _vm._v(" "),
    !_vm.only_name_column
      ? _c("td", { staticClass: "tree_viewer-border-b" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.node.template) + "\n\t"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }