var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "tree-as-table" } }, [
    _c(
      "table",
      {
        staticClass:
          "vue-ads-w-full vue-ads-font-sans vue-ads-border vue-ads-w-full",
        attrs: { width: "100%" },
      },
      [
        _c("thead", {}, [
          _c("tr", { staticClass: "vue-ads-border-b" }, [
            _c("th", { attrs: { scope: "col", width: "20%" } }, [
              _vm._v("Name"),
            ]),
            _vm._v(" "),
            !_vm.only_name_column
              ? _c("th", { attrs: { scope: "col", width: "65%" } }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(this.$templatesName) + "\n\t\t\t\t"
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.tree_array, function (node) {
            return _c("TableTreeRow", {
              key: node.id,
              attrs: {
                node: node,
                select_name: _vm.select_name,
                only_name_column: _vm.only_name_column,
              },
              on: { selectNarra: _vm.selectNarra },
            })
          }),
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }