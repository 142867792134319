import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Synonyms extends BaseClient {
	constructor () {
		super(httpClient, "/synonyms/");
	}

	get_synonyms (word, language) {
		return this.get(`${word}/${language}`);
	}
}

export default new Synonyms();
