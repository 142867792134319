var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-templates-row-narra-nv" },
    _vm._l(_vm.filterTemplates, function (template) {
      return _c(
        "div",
        { key: template.id, staticClass: "template-root" },
        [
          _c("RowTemplateNV", {
            ref: "RowTemplateInternal" + template.id,
            refInFor: true,
            attrs: {
              template: template,
              template_root: template,
              currentDocument: _vm.currentDocument,
              currentDomain: _vm.currentDomain,
              currentRoute: _vm.currentRoute,
              document_data_selected: _vm.document_data_selected,
              category_id: _vm.category_id,
              active_autocomplete: _vm.active_autocomplete,
              active_preview: _vm.active_preview,
              refreshHighlights: _vm.refreshHighlights,
              parent: template.subtemplates && template.subtemplates.length > 0,
              templates_without_segements: _vm.templates_without_segements,
              readOnly: _vm.readOnly,
              archivedTemplate: _vm.archivedTemplates,
            },
            on: {
              templateDeleted: _vm.templateDeleted,
              deleteTemplate: _vm.deleteTemplate,
              addNewTemplate: _vm.onAddNewTemplate,
              duplicateTemplate: _vm.templateDuplicated,
              createTempByText: _vm.createTempByText,
              updateTemplate: _vm.updateTemplatesStore,
              filterByTemplateID: _vm.filterByTemplateID,
              newTemplateByType: _vm.addRow,
              clickTemplate: _vm.selectTemplate,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }