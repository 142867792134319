<template>
	<div class="row-templates-container" v-if="templates_without_tree.length >0">
		<div class="root_narra_title" v-on:click="toggleCollapsed()">
			<div class="toggleControlNarras">
				<span v-if="!collapsed"
					><i class="fa fa-caret-down" aria-hidden="true"></i></span>
				<span v-else
					><i class="fa fa-caret-right" aria-hidden="true"></i></span>
			</div>
			<div class="root_narra_name_title">Segments without tree</div>
		</div>
		<div
			v_if="collapsed"
			class="template-root"
		>
			<TableTemplatesForRowNarraNV
				v-if="!collapsed"
				templates_without_segements=true
				:category_id="category_id"
				:documentId="$store.getters.getCurrentDocument.id"
				:currentDomain="$store.getters.getCurrentDomain"
				:currentDocument="$store.getters.getCurrentDocument"
				currentRoute=""
				@deleteTemplate="onDeleteTemplate"
				@templateDeleted="onTemplateDeleted"
				@templateDuplicated="onTemplateDuplicated"
			/>
		</div>
	</div>
</template>

<script>
import TableTemplatesForRowNarraNV from "@/components/templates/TableTemplatesForRowNarraNV";

export default {
	components: {
		TableTemplatesForRowNarraNV
	},
	data () {
		return {
			collapsed: true
		};
	},
	props: {
		category_id: String
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getSegmentIdFilter,
			(segment_id, oldValue) => {
				let template_filter = this.templates_without_tree.filter((t) => {
					return t.id === segment_id;
				});
				console.debug("TablWithoutsegments change segment_id", segment_id, template_filter);

				if (
					Array.isArray(template_filter) &&
          template_filter.length > 0
				) {
					console.debug("TablWithoutsegments template_filter", template_filter);
					this.collapsed = false;
				}
			}
		);
	},
	computed: {
		templates_without_tree () {
			let templates = this.$store.getters.getTemplatesRegular;

			templates = templates.filter((t) => {
				return t.narra_tree_nodes_version.length === 0;
			});

			return templates;
		}
	},
	methods: {
		toggleCollapsed () {
			this.collapsed = !this.collapsed;
		},
		onDeleteTemplate (template) {
			this.$emit("deleteTemplate", template);
		},
		onTemplateDeleted (template) {
			this.$emit("templateDeleted", template);
		},
		onTemplateDuplicated (template) {
			this.$emit("templateDuplicated", template);
		},
	}
}
</script>

<style>

</style>
