import { cond } from "lodash";
import * as par from "@/grammars/narras_grammar_31.js";

export const narrasInterpreterMixins = {
	methods: {
		expressionToConditions (expression) {
			let result = [];
			let clearedExpression = expression.replace(/(\r\n|\n|\r)/gm, "");
			//console.debug("expression:" + clearedExpression);

			let conditionsParsed = par.parse(clearedExpression);

			result.push(conditionsParsed["first"]);

			if ("others" in conditionsParsed) {
				result = result.concat(conditionsParsed["others"]);
			}
			//console.debug("result:" + result);

			for (let i = 0; i < result.length; i++) {
				let order_counter = 0;
				let current_condition = result[i];
				for (let j = 0; j < current_condition.components.length; j++) {
					current_condition.components[j]["o"] = order_counter;
					order_counter++;
				}
			}

			return result;
		},
		conditionsToExpression (conditions) {
			let result = "CALC_NARRA_EXP( ";

			function conditionToExpression (condition, numOfConditions, currentNumOfCondition) {
				//If no components, then ignore
				if (!condition.components) {
					return;
				}

				let result = "";

				let components = condition.components;

				for (let i = 0; i < components.length; i++) {
					let component = components[i];
					let component_result = "";

					if (component.t.startsWith("member")) {
						if (component.t == "member.data_row") {
							component_result = "data_row['" + component.v + "']";
						} else if (component.t == "member.value") {
							component_result = "'" + component.v + "'";
						} else {
							component_result = component.v;
						}
					} else {
						component_result = component.v;
					}
					//The final cast
					if (component.c) {
						component_result = component.c + "(" + component_result + ")";
					}

					result += component_result;
				}

				if (numOfConditions > 1) {
					result = "(" + result + ")";
				}
				if ((condition.boolOperation) && (currentNumOfCondition > 0)) {
					result = " " + condition.boolOperation + " " + result;
				}

				return result;
			}

			for (let i = 0; i < conditions.length; i++) {
				result += conditionToExpression(conditions[i], conditions.length, i);
			}

			result += " )";

			//If only one conditions, remove the spaces
			//if (conditions.length == 1) {
			//result = result.replace("(data_row[", "data_row[");
			//result = result.slice(0, -1);
			//}

			return result;
		}
	}
};
