<template>
	<b-card-body>
		<b-col cols="1"></b-col>
		<b-col>
			<b-card bg-variant="default">
				<b-form-input
					size="sm"
					class="mr-sm-2"
					placeholder="Search template"
					v-model="searchTemplates"
				></b-form-input>
				<table class="table table-bordered table-hover">
					<tbody>
						<tr scope="row" v-for="item in items">
							<td
								class="text-left"
								@blur="updateVersion($event, 0, template.versions[0])"
							>
								<a href="#" @click="$emit('selectTemplate', item)">{{
									item
								}}</a>
							</td>
						</tr>
					</tbody>
				</table>
			</b-card>
		</b-col>
	</b-card-body>
</template>

<script>
export default {
	name: "TemplatesSelector",
	data () {
		return {
			searchTemplates: ""
		};
	},
	computed: {
		items () {
			let items = [];
			let dict = {};
			for (let i = 0; i < this.templates.length; i++) {
				const template = this.templates[i];
				//console.log(template);
				if (
					template.versions.length > 0 &&
					template.versions[0].type.length > 0 &&
					!dict[template.versions[0].type] &&
					template.category.id == 1
				) {
					items.push(template.versions[0].type);
					dict[template.versions[0].type] = {};
				}
			}

			const search = this.searchTemplates;
			return items.filter(function (item) {
				return item.indexOf(search) > -1 ? item : false;
			});
		}
	},
	props: {
		templates: { type: Array, default: [] }
	}
};
</script>

<style scoped>
</style>
