import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class NarrasTreeNodesVersions extends BaseClient {
	constructor () {
		super(httpClient, "/narra_tree_nodes_versions/");
	}

	create (version) {
		return this.post("", version);
	}
}

export default new NarrasTreeNodesVersions();
