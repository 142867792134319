var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "wrap",
      staticClass: "atwho-wrap",
      on: {
        compositionstart: _vm.handleCompositionStart,
        compositionend: _vm.handleCompositionEnd,
        input: function ($event) {
          return _vm.handleInput()
        },
        keydown: _vm.handleKeyDown,
      },
    },
    [
      _vm.atwho
        ? _c("div", { staticClass: "atwho-panel", style: _vm.style }, [
            _c("div", { staticClass: "atwho-inner" }, [
              _c("div", { staticClass: "atwho-view" }, [
                _c(
                  "ul",
                  { staticClass: "atwho-ul" },
                  _vm._l(_vm.atwho.list, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        ref: _vm.isCur(index) && "cur",
                        refInFor: true,
                        staticClass: "atwho-li",
                        class: _vm.isCur(index) && "atwho-cur",
                        attrs: { "data-index": index },
                        on: {
                          mouseenter: _vm.handleItemHover,
                          click: _vm.handleItemClick,
                        },
                      },
                      [
                        _vm._t(
                          "item",
                          function () {
                            return [
                              _c("span", { staticClass: "atnv-value" }, [
                                _vm._v(_vm._s(_vm.itemName(item))),
                              ]),
                            ]
                          },
                          { item: item }
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }