import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Cube extends BaseClient {
	constructor() {
		super(httpClient, "/cube/");
	}

	getByTreeId(treeId) {
		return this.get(`tree/${treeId}`);
	}

	postCategory(data) {
		return this.post("", data);
	}

	postScenario(data) {
		return this.post("", data);
	}

	generateCubes(treeId) {
		return this.post(`tree/${treeId}/generate`);
	}
}

export default new Cube();
