import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class TemplatesMessages extends BaseClient {
	constructor () {
		super(httpClient, "/templates_messages/");
	}

	createMessage (template_id, body, domain_id, document_id, current_route) {
		return this.post(`template/${template_id}`, { body, domain_id, document_id, current_route });
	}

	deleteMessage (message_id, template_id) {
		return this.delete(`message/${message_id}/template/${template_id}`);
	}

	markRead (template_id) {
		return this.put(`template/${template_id}/read`, {});
	}
}

export default new TemplatesMessages();
