//The Vue build version to load with the `import` command
//(runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";

import BootstrapVue from "bootstrap-vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VModal from "vue-js-modal";
import VueSession from "vue-session";
import VueUid from "vue-uid";
import httpClient from "@/api/config/httpClient";

import "@/assets/scss/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faUserSecret,
	faPlus,
	faPen,
	faTrashAlt,
	faScroll,
	faCheck,
	faTimes,
	faCodeBranch,
	faAngleUp,
	faBackward,
	faPlay,
	faCog,
	faPaperclip,
	faCloudUploadAlt,
	faFilter,
	faEye,
	faCode,
	faSyncAlt,
	faFileAlt,
	faExclamation,
	faClone,
	faFileDownload,
	faMapSigns,
	faStar,
	faAsterisk,
	faInfo,
	faAngleDown,
	faExpandArrowsAlt,
	faCompressArrowsAlt,
	faDatabase,
	faEllipsisV,
	faLightbulb,
	faArrowUp,
	faStream,
	faSave,
	faFileImport,
	faPlusSquare,
	faMinusSquare,
	faLanguage,
	faMagic,
	faComments,
	faFile,
	faProjectDiagram,
	faArrowLeft,
	faExclamationCircle,
	faTimesCircle,
	faTrashRestore
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import LiquorTree from "liquor-tree";
import App from "./App";

library.add(
	faUserSecret,
	faPlus,
	faPen,
	faTrashAlt,
	faTrashRestore,
	faScroll,
	faCheck,
	faTimes,
	faTimesCircle,
	faCodeBranch,
	faAngleUp,
	faAngleDown,
	faBackward,
	faPlay,
	faCog,
	faPaperclip,
	faCloudUploadAlt,
	faFilter,
	faEye,
	faCode,
	faSyncAlt,
	faFileAlt,
	faExclamation,
	faClone,
	faFileDownload,
	faMapSigns,
	faStar,
	faAsterisk,
	faInfo,
	faExpandArrowsAlt,
	faCompressArrowsAlt,
	faDatabase,
	faEllipsisV,
	faLightbulb,
	faArrowUp,
	faStream,
	faSave,
	faFileImport,
	faPlusSquare,
	faMinusSquare,
	faLanguage,
	faMagic,
	faComments,
	faFile,
	faProjectDiagram,
	faArrowLeft,
	faExclamationCircle
);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueSession);
Vue.use(LiquorTree);
Vue.use(VModal);
Vue.use(VueUid);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.$templatesName = "Segments";

const token = localStorage.getItem('jwt_token')
if (token) {
  httpClient.defaults.headers.common['Authorization'] = "JWT " + token;
}

/*eslint-disable no-new */
new Vue({
	el: "#app",
	router,
	store,
	axios,
	components: { App },
	template: "<App/>"
});
