var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        adaptive: "",
        height: "95%",
        width: "900",
        name: "find_replace",
      },
    },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "light" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Find & replace"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: { click: _vm.closeModal },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-div-container-find_replace" },
        [
          _c(
            "b-row",
            { staticClass: "mb-2 pr-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form",
                    { staticClass: "float-sm-right", attrs: { inline: "" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mr-2",
                          attrs: { value: "true", "unchecked-value": "false" },
                          on: {
                            change: function ($event) {
                              return _vm.changeFilterByID($event)
                            },
                          },
                          model: {
                            value: _vm.filter_id,
                            callback: function ($$v) {
                              _vm.filter_id = $$v
                            },
                            expression: "filter_id",
                          },
                        },
                        [_vm._v("\n            Filter by ID\n          ")]
                      ),
                      _vm._v(" "),
                      _c("b-form-input", {
                        staticClass: "mr-1",
                        attrs: {
                          placeholder: "ID",
                          readonly: _vm.filter_id === "false",
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.changeSearchString($event)
                          },
                        },
                        model: {
                          value: _vm.template_id,
                          callback: function ($$v) {
                            _vm.template_id = $$v
                          },
                          expression: "template_id",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mr-2",
                          attrs: { value: "true", "unchecked-value": "false" },
                          on: {
                            change: function ($event) {
                              return _vm.changeFilterByType($event)
                            },
                          },
                          model: {
                            value: _vm.filter_type,
                            callback: function ($$v) {
                              _vm.filter_type = $$v
                            },
                            expression: "filter_type",
                          },
                        },
                        [_vm._v("\n            Filter by segment\n          ")]
                      ),
                      _vm._v(" "),
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "Segment",
                          readonly: _vm.filter_type === "false",
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.changeSearchString($event)
                          },
                        },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-2 pl-1 pr-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-form-input", {
                    ref: "search_input",
                    staticClass: "sm-10",
                    attrs: { placeholder: "Search in segments" },
                    on: {
                      keyup: function ($event) {
                        return _vm.changeSearchString($event)
                      },
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-1 pl-1 pr-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-form-input", {
                    staticClass: "sm-10",
                    attrs: { placeholder: "Replace with" },
                    model: {
                      value: _vm.replace,
                      callback: function ($$v) {
                        _vm.replace = $$v
                      },
                      expression: "replace",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.searchResults.length > 0
            ? _c(
                "b-navbar",
                {
                  attrs: { toggleable: "lg", type: "light", variant: "light" },
                },
                [
                  _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    { attrs: { id: "nav-collapse", "is-nav": "" } },
                    [
                      _c(
                        "b-navbar-nav",
                        [
                          _c(
                            "b-nav-item",
                            {
                              attrs: { href: "#", title: "previous" },
                              on: {
                                click: function ($event) {
                                  return _vm.previousOccurrence()
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-arrow-up" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-nav-item",
                            {
                              attrs: { href: "#", title: "next" },
                              on: {
                                click: function ($event) {
                                  return _vm.nextOccurrence()
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-arrow-down" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-nav-item",
                            {
                              attrs: { href: "#", title: "next" },
                              on: {
                                click: function ($event) {
                                  return _vm.replaceCurrent()
                                },
                              },
                            },
                            [_vm._v("\n            Replace\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-nav-item",
                            {
                              attrs: { href: "#", title: "next" },
                              on: {
                                click: function ($event) {
                                  return _vm.replaceAll()
                                },
                              },
                            },
                            [_vm._v("\n            Replace all\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "mt-1 mb-2 result-list-findreplace" },
                  [
                    _vm.searchingSegment
                      ? [
                          _c("b-spinner", {
                            attrs: { variant: "primary", label: "Spinning" },
                          }),
                        ]
                      : _vm.searchResults.length > 0
                      ? _vm._l(_vm.searchResults, function (template) {
                          return _c(
                            "div",
                            {
                              key: template.versions[0].id,
                              ref: "template" + template.id,
                              refInFor: true,
                            },
                            [
                              _c(
                                "div",
                                { class: _vm.getClassForTemplate(template) },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card-header template-header pt-0 pb-0 pl-2",
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "template-row-header",
                                          attrs: { "align-v": "center" },
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "m-0 p-0 pl-2 pt-1",
                                              attrs: { cols: "8" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge template-id-number",
                                                },
                                                [_vm._v(_vm._s(template.id))]
                                              ),
                                              _vm._v(
                                                " \n                      "
                                              ),
                                              _c("label", {
                                                staticClass:
                                                  "template-history-date",
                                                attrs: { spellcheck: "false" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    template.versions[0].type
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-right pr-0 align-middle",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "template-preview-icon template-circle-button",
                                                  attrs: {
                                                    href: "#",
                                                    title:
                                                      "Set current version",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.navigateToTemplate(
                                                        template
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("div", {}, [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-arrow-alt-circle-right fa-lg",
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-row",
                                        { staticClass: "template-row-text" },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "template-cell-text card-body",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    template.versions[0]
                                                      .text_for_editor
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                            ]
                          )
                        })
                      : _vm.search.length > 0 && !_vm.searchingSegment
                      ? [_c("p", [_vm._v("Nothing found")])]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "danger" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeModal.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "info",
                          disabled: _vm.searchResults.length == 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.saveChanges.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Save changes")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }