<template>
	<section class="domains content">
		<div class="container">
			<b-row class="d-flex align-items-center mb-3">
				<b-col>
					<h1>Domains</h1>
					<p>Choose a domain to start working</p>
				</b-col>
				<b-col class="d-flex justify-content-end">
					<b-button variant="success" v-on:click="newModal">
						<font-awesome-icon icon="plus" />&nbsp;New
					</b-button>
				</b-col>
			</b-row>
			<b-row class="domains__search">
				<b-col cols="12">
					<b-form-input
						class="mr-sm-2 input-search-template"
						placeholder="Search"
						v-model="filter"
					></b-form-input>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12">
					<b-table-simple hover small caption-top>
						<b-thead>
							<b-th class="text-left" style="width: 60%">Domain</b-th>
							<b-th class="text-center" style="width: 20%">Engine</b-th>
							<b-th class="text-right" style="width: 15%">Controls</b-th>
						</b-thead>
						<b-tbody>
							<b-tr v-for="domain in domains_filter" v-bind:key="domain.id">
								<b-td class="text-left align-middle">
									<router-link
										:to="{
											name: 'DocumentsList',
											params: { domainId: domain.id },
										}">
										{{ domain.name }}
									</router-link>
								</b-td>
								<b-td class="text-center align-middle">
									{{ engineLabel(domain.engine_id) }}
								</b-td>
								<b-td class="text-right align-middle">
									<!--router-link
										:to="{
											name: 'NarrasList',
											params: { domainId: domain.id }
										}"
									>
										<b-button variant="success">Structure</b-button>
                  </router-link-->
									<router-link
										:to="{
											name: 'DocumentsList',
											params: { domainId: domain.id },
										}"
									>
										<b-button class="mr-1" variant="primary" title="Work with this domain">
											<font-awesome-icon icon="file-alt" />
										</b-button>
									</router-link>
									<b-button
										variant="light"
										v-on:click="updateModal($event, domain)"
										title="Edit domain general settings"
									>
										<font-awesome-icon icon="cog" />
									</b-button>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
			<b-modal hide-footer id="modal-new" title="New Domain" ref="modal-new" centered>
				<b-form @submit.prevent="updateDomain" @reset="cancel">
					<b-form-group
						id="input-group-2"
						label="Engine:"
						label-for="engine"
						label-align="left"
					>
						<b-form-select
							id="engine"
							v-model="form.engine_id"
							:options="engines"
							size="sm"
						></b-form-select>
					</b-form-group>
					<b-form-group
						id="input-group-2"
						label="Name:"
						label-for="name"
						label-align="left"
					>
						<b-form-input
							id="name"
							required
							v-model="form.name"
							placeholder="Name"
						></b-form-input>
					</b-form-group>

					<b-form-group
						id="input-group-2"
						label="Minerva Server:"
						label-for="minerva_server"
						label-align="left"
					>
						<b-form-input
							id="minerva_server"
							v-model="form.minerva_server"
							placeholder="Minerva Server"
						></b-form-input>
					</b-form-group>

					<b-form-group
						id="input-group-2"
						label="Minerva Domain:"
						label-for="minerva_url"
						label-align="left"
					>
						<b-form-input
							id="minerva_url"
							v-model="form.minerva_url"
							placeholder="Minerva Domain"
						></b-form-input>
					</b-form-group>

					<b-form-group
						id="input-group-4"
						label="Description:"
						label-for="description"
						label-align="left"
					>
						<b-form-textarea
							id="description"
							required
							v-model="form.description"
							placeholder="Description"
						></b-form-textarea>
					</b-form-group>

					<footer class="text-right mt-4">
						<b-button type="reset" variant="secondary">Cancel</b-button>
						<b-button type="submit" variant="primary">Save</b-button>
					</footer>
				</b-form>
			</b-modal>
			<Loading v-if="isLoading"></Loading>
		</div>
	</section>
</template>

<script>
import domains_api from "../../api/domain.js";
import engines_api from "../../api/engines.js";
import Loading from "@/components/UI/Loading";
import { alertMixins } from "@/mixins/alertMixins.js";

export default {
	name: "DomainsList",
	components: {
		Loading
	},
	mixins: [alertMixins],
	data () {
		return {
			domains: [],
			isLoading: true,
			form: {
				id: "",
				engine_id: "",
				name: "",
				description: "",
				minerva_url: "",
				minerva_server: "",
				domain_obj: {}
			},
			selected: null,
			engines: [],
			filter: ""
		};
	},
	created () {
		this.getDomains();
		this.getEngines();
	},
	methods: {
		async getDomains () {
			//const [data, err] = await domains_api.getAll();
			let self = this;
			domains_api.getAll().then(function ([data, err]) {
				self.domains = data;
				let breadcrumb_items = [
					{
						text: "Domains",
						to: { name: "Home" },
						active: true
					}
				];
				self.$store.commit("SET_BREADCRUMB_ITEMS", breadcrumb_items);
				self.isLoading = false;
			});
		},

		async getEngines () {
			const [data, err] = this.$store.getters.getEngines;
			if (err || !data) {
				let self = this;
				engines_api.getAll().then(function ([data_engines, err]) {
					if (!err) {
						for (let index = 0; index < data_engines.length; index++) {
							let engine = data_engines[index];
							self.engines.push({ value: engine.id, text: engine.name });
						}

						self.$store.commit("SET_ENGINES", self.engines);
					}
				});
			} else {
				this.engines = data;
			}
		},
		newModal () {
			this.form.id = "";
			this.form.engine_id = 1;
			this.form.name = "";
			this.form.description = "";
			this.form.minerva_url = "";
			this.form.minerva_server = "";
			this.form.domain_obj = {};
			this.$refs["modal-new"].show();
		},
		updateModal ($event, domain) {
			this.form.id = domain.id;
			this.form.engine_id = domain.engine_id;
			this.form.name = domain.name;
			this.form.description = domain.description;
			this.form.minerva_url = domain.minerva_url;
			this.form.minerva_server = domain.minerva_server;
			this.form.domain_obj = domain;
			this.$refs["modal-new"].show();
		},
		async updateDomain () {
			this.isLoading = true;

			let domain = {
				id: this.form.id,
				engine_id: this.form.engine_id,
				name: this.form.name,
				description: this.form.description,
				minerva_url: this.form.minerva_url,
				minerva_server: this.form.minerva_server
			};

			if (domain.id == "") {
				const [data, err] = await domains_api.create(domain);

				if (!err) {
					domain.id = data.id;
					this.domains.push(domain);
				} else {
					console.error(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				const [data, err] = await domains_api.update(domain.id, domain);
				if (!err) {
					this.form.domain_obj.name = domain.name;
					this.form.domain_obj.description = domain.description;
					this.form.domain_obj.minerva_url = domain.minerva_url;
					this.form.domain_obj.minerva_server = domain.minerva_server;
					this.form.domain_obj.engine_id = domain.engine_id;
				} else {
					console.error(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			}
			this.isLoading = false;
			this.$refs["modal-new"].hide();
		},
		cancel () {
			this.$refs["modal-new"].hide();
		},
		engineLabel (id) {
			for (let i = 0; i < this.engines.length; i++) {
				if (this.engines[i].value == id) {
					return this.engines[i].text;
				}
			}
		}
	},
	computed: {
		domains_filter () {
			let regex = new RegExp("(" + this.filter + ")", "i");
			return this.domains.filter(function (domain) {
				return domain.name.match(regex);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.domains {

	&__search {
		margin-bottom: 3rem;
	}

	.table {

		th {
			border-top: 0;
		}
	}
}
</style>
