var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap-delete-parent-segment" },
    [
      _c(
        "b-modal",
        {
          ref: "delete-parent-segment",
          staticClass: "delete-parent-segment",
          attrs: {
            "data-test-id": "delete-parent-segment",
            id: "delete-parent-segment",
            title: "Delete items",
            size: "lg",
            "no-close-on-backdrop": "",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeMainModal },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.confirm-delete-parent-segment",
                          modifiers: { "confirm-delete-parent-segment": true },
                        },
                      ],
                      attrs: { variant: "primary" },
                    },
                    [_vm._v("Delete")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-overlay", {
            attrs: {
              "no-wrap": "",
              show: _vm.waitingResponse,
              rounded: "true",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "archive-parernt-segment__body" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRequestError,
                    expression: "showRequestError",
                  },
                ],
                class: _vm.feedbackCssClass,
              },
              [
                _c(
                  "b-alert",
                  {
                    attrs: { variant: "danger", dismissible: "" },
                    on: { dismissed: _vm.toggleRequestErrorVisibility },
                    model: {
                      value: _vm.showRequestError,
                      callback: function ($$v) {
                        _vm.showRequestError = $$v
                      },
                      expression: "showRequestError",
                    },
                  },
                  [_vm._v("Error")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "form",
              { attrs: { action: "" } },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Elements to delete:" } },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row pl-2" },
                      [
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              name: "some-radios",
                              value: "true",
                              checked: _vm.treeNodeToDelete.isRoot,
                            },
                            model: {
                              value: _vm.includeChildren,
                              callback: function ($$v) {
                                _vm.includeChildren = $$v
                              },
                              expression: "includeChildren",
                            },
                          },
                          [_vm._v("Include all children")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              name: "some-radios",
                              value: "false",
                              disabled: _vm.treeNodeToDelete.isRoot,
                            },
                            model: {
                              value: _vm.includeChildren,
                              callback: function ($$v) {
                                _vm.includeChildren = $$v
                              },
                              expression: "includeChildren",
                            },
                          },
                          [_vm._v("Just the current one")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "pl-3",
                    attrs: {
                      id: "include-segments",
                      disabled: _vm.treeNodeToDelete.isRoot,
                      name: "include-segments",
                      "unchecked-value": "false",
                    },
                    model: {
                      value: _vm.includeSegments,
                      callback: function ($$v) {
                        _vm.includeSegments = $$v
                      },
                      expression: "includeSegments",
                    },
                  },
                  [_c("span", [_vm._v("Include all children segments.")])]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirm-delete-parent-segment",
          attrs: {
            id: "confirm-delete-parent-segment",
            size: "sm",
            title: "Confirm operation",
            "no-close-on-backdrop": "",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeConfirmModal },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.deleteItems },
                    },
                    [_vm._v("Delete")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("p", { staticClass: "my-1" }, [_vm._v("Are you sure?")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }