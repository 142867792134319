import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import all from "./modules/all";
import domains from "./modules/domains";
import scenarios from "./modules/scenarios";
import narras from "./modules/narras"

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		domains,
		scenarios,
		narras,
		all
	}
});
