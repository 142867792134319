var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: { height: "auto", width: "700", name: "modalUploadDataFile" },
        },
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "lg", type: "light", variant: "info" } },
            [
              _c("b-navbar-brand", { attrs: { href: "#" } }, [
                _vm._v("Documents data"),
              ]),
              _vm._v(" "),
              _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
              _vm._v(" "),
              _c("b-navbar-nav", { staticClass: "ml-auto" }, [
                _c(
                  "a",
                  {
                    staticClass: "close-dialog",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.$modal.hide("modalUploadDataFile")
                      },
                    },
                  },
                  [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("UploadFile", {
                attrs: {
                  ref_file_input: "file_input_data",
                  multiple: _vm.uploadMultipleFiles,
                },
                on: { uploadFiles: _vm.upload },
              }),
              _vm._v(" "),
              _c("br"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.updateNSchema()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "save" } }),
                      _vm._v(" Save\n          "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.showModalUpload()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "file-import" },
                      }),
                      _vm._v(" Import data file\n          "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container-flex" }, [
        _c(
          "div",
          { staticClass: "row no-gutters" },
          [
            _c("v-jsoneditor", {
              staticClass: "col-5",
              attrs: { options: _vm.optionsJsonEditor, height: "760px" },
              on: { error: _vm.onError },
              model: {
                value: _vm.nschema_data,
                callback: function ($$v) {
                  _vm.nschema_data = $$v
                },
                expression: "nschema_data",
              },
            }),
            _vm._v(" "),
            _c("graph-viewer", { staticClass: "col-7" }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }