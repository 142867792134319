var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            height: "auto",
            width: "700",
            name: "documentsDataListModalData",
          },
        },
        [
          _c("DocumentsDataList", {
            attrs: {
              documentID: _vm.documentId,
              ref_file_input: "file_input_data",
              modalName: "documentsDataListModalData",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.updateDataFile()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "save" } }),
                      _vm._v(" Save\n\t\t\t\t"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-form",
                    [
                      _c("b-form-select", {
                        staticClass: "input-select-document",
                        attrs: {
                          id: "engine",
                          options: _vm.document_data,
                          size: "sm",
                        },
                        model: {
                          value: _vm.document_data_selected,
                          callback: function ($$v) {
                            _vm.document_data_selected = $$v
                          },
                          expression: "document_data_selected",
                        },
                      }),
                      _vm._v("\n\t\t\t\t\t \n\t\t\t\t\t"),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.dataModal($event)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "paperclip" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-jsoneditor", {
        attrs: { options: _vm.optionsJsonEditor, height: "760px" },
        on: { error: _vm.onError },
        model: {
          value: _vm.file_data,
          callback: function ($$v) {
            _vm.file_data = $$v
          },
          expression: "file_data",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }