var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "branch-generator",
      staticClass: "branch-generator",
      attrs: {
        scrollable: "",
        "body-class": "branch-generator-modal-body",
        "data-test-id": "branch-generator",
        id: "branch-generator",
        size: "lg",
        "no-close-on-backdrop": "",
        centered: "",
        title: _vm.modalTitle,
        centered: "",
      },
      on: { close: _vm.resetGenerator },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.generateBranches },
                },
                [_vm._v("Generate Branches")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-overlay", {
        attrs: { "no-wrap": "", show: _vm.waitingResponse, rounded: "true" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "branch-generator__body",
          attrs: { "data-test-id": "branch-generator" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showRequestError,
                  expression: "showRequestError",
                },
              ],
              class: _vm.feedbackCssClass,
            },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  on: { dismissed: _vm.toggleRequestErrorVisibility },
                  model: {
                    value: _vm.showRequestError,
                    callback: function ($$v) {
                      _vm.showRequestError = $$v
                    },
                    expression: "showRequestError",
                  },
                },
                [_vm._v(_vm._s(_vm.requestErrorText))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "branch-generator__errors" }),
          _vm._v(" "),
          _c("div", { staticClass: "branch-generator__categories row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-12 branch-generator__field-wrap flex-base flex-separate",
              },
              [
                _c("div", { class: _vm.categoryFieldCssClass }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCategory,
                          expression: "selectedCategory",
                        },
                      ],
                      staticClass:
                        "form-control branch-generator__select-category",
                      attrs: { "aria-label": "Select category" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedCategory = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.selectCategoryChanged,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v("Select category"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.categories, function (category, index) {
                        return _c(
                          "option",
                          {
                            key: index,
                            attrs: {
                              disabled: _vm.checkCategoryDisabledOption(
                                category.scenario_category_id
                              ),
                            },
                            domProps: { value: category },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(category.scenario_category_name) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "branch-generator__error-msg error" },
                    [_vm._v(_vm._s(_vm.selectCategoryError))]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass:
                      "btn btn-default branch-generator__add-category",
                    attrs: {
                      variant: "primary",
                      "aria-label": _vm.addCategoryBtnText,
                      title: _vm.addCategoryBtnText,
                    },
                    on: { click: _vm.addCategory },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "plus-square", size: "lg" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.hasAddedCategories
                ? _c(
                    "ul",
                    { staticClass: "branch-generator__list no-pd" },
                    _vm._l(
                      _vm.scenarios.scenarios_categories,
                      function (category, categoryIndex) {
                        return _c(
                          "li",
                          {
                            key: categoryIndex,
                            staticClass: "branch-generator__category",
                          },
                          [
                            _c("p", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "badge badge-primary mr-1",
                                  attrs: { title: _vm.categotoryOrderTooltip },
                                },
                                [_vm._v(_vm._s(categoryIndex + 1))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "branch-generator__category-name",
                                },
                                [
                                  _vm._v(
                                    _vm._s(category.scenario_category_name)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            category.scenarios.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "branch-generator__scenarios flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-4 no-pd-l" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-base branch-generator__field-wrap",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { class: _vm.scenarioField },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          category.selectedScenario,
                                                        expression:
                                                          "category.selectedScenario",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control branch-generator__select-scenario",
                                                    attrs: {
                                                      "aria-label":
                                                        "Select scenario",
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            category,
                                                            "selectedScenario",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        _vm.selectScenarioChanged,
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          value: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Select scenario"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      category.scenarios,
                                                      function (scenario, i) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              disabled:
                                                                _vm.checkScenarioDisabledOption(
                                                                  scenario
                                                                ),
                                                            },
                                                            domProps: {
                                                              value: scenario,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.scenarioName(
                                                                  scenario
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "branch-generator__error-msg error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectScenarioError
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass:
                                                  "btn btn-default branch-generator__add-scenario",
                                                attrs: {
                                                  type: "button",
                                                  variant: "primary",
                                                  "aria-label":
                                                    _vm.addScenarioBtnText,
                                                  title: _vm.addScenarioBtnText,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addScenario(
                                                      category.selectedScenario,
                                                      true,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: "plus-square",
                                                    size: "lg",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.hasAddedScenarios
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "col-8 flex flex-base",
                                          },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "flex flex-center-y flex-wrap flex-justify-start branch-generator__scenarios-list",
                                              },
                                              _vm._l(
                                                _vm.scenariosAddedToBranchGenerator(
                                                  category.scenarios
                                                ),
                                                function (
                                                  scenario,
                                                  scenarioIndex
                                                ) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: scenarioIndex,
                                                      staticClass:
                                                        "branch-generator__scenario-item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-checkbox",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.scenarioName(
                                                                    scenario
                                                                  ),
                                                                checked:
                                                                  scenario.enabled,
                                                              },
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      scenario.enabled,
                                                                    expression:
                                                                      "scenario.enabled",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-check-input",
                                                                attrs: {
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      scenario.enabled
                                                                    )
                                                                      ? _vm._i(
                                                                          scenario.enabled,
                                                                          null
                                                                        ) > -1
                                                                      : scenario.enabled,
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          scenario.enabled,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              scenario,
                                                                              "enabled",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              scenario,
                                                                              "enabled",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          scenario,
                                                                          "enabled",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateScenario(
                                                                        scenario,
                                                                        true,
                                                                        scenario.enabled
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }