<template>
  <div v-bind:class="clsScenarioRow">
    <div class="col-sm-3 col-form-label pt-0 mb-0" placeholder="Name">
      <b-form-group label="Name">
        <div
          class="form-control calc_narra_input"
          contenteditable
          spellcheck="false"
          ref="narras_name"
          @keyup="handleKeyDown"
        >
          {{ this.scenario.versions[0].name }}
        </div>
      </b-form-group>
    </div>
    <div class="col-sm-7">
      <b-form-group label="Definition">
        <at
          :ats="trigerChars"
          :members="autocomplete_members"
          hideOnBlur
          @at="autocompletAt"
          :filterMatch="filterMatch"
          :filterMembers="filterMembersAutocomplete"
          :ref="'at' + scenario.id"
        >
          <div
            contenteditable
            spellcheck="false"
            id="narras"
            placeholder="Definition of Scenario"
            class="form-control calc_narra_input"
            ref="narras_text"
            @keyup="handleKeyDown"
            @blur="closePanel()"
          >
            {{ this.scenario.versions[0].narra }}
          </div>
        </at>
      </b-form-group>
    </div>
    <div class="col-sm-1 text-right" style="margin-top: 1.9em">
      <b-button class="" variant="" @click="updateScenarioValue()">
        <font-awesome-icon icon="save" />
      </b-button>
    </div>
    <div class="col-sm-1 text-right" style="margin-top: 1.9em">
      <b-button
        class=""
        variant="danger"
        @click="confirmDeleteScenario([category, scenario])"
      >
        <font-awesome-icon icon="trash-alt" />
      </b-button>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/UI/Loading";
import At from "@/components/UI/AutoComplete/At.vue";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import tree_nodes_versions_api from "../../api/narras_tree_nodes_versions.js";
import scenario_version_api from "../../api/scenario_version.js";
import scenario_api from "../../api/scenario.js";

export default {
	name: "ScenarioItem",
	mixins: [alertMixins, narrasMixins, templatesMixins],
	props: {
		scenario: {},
		domainId: {},
		documentId: {},
		category: {}
	},
	components: {
		Loading,
		At,
		ButtonCircle
	},
	data () {
		return {
			trigerChars: ["CALC", ".", "'", "CALC_NARRA_", "--"],
			bScenarioChanged: false
		};
	},
	computed: {
		clsScenarioRow () {
			if (this.bScenarioChanged) return "row scenario-row-unsaved";
			return "row scenario-row";
		},
		autocomplete_members () {
			return this.$store.getters.getAutocompleteNSchemaMembers.entities;
		}
	},
	methods: {
		closePanel () {
			let panelRef = this.$refs["at" + this.scenario.id];
			if (Array.isArray(panelRef)) {
				panelRef = panelRef[0];
			}
			panelRef.closePanel();
		},
		confirmDeleteScenario (categoryScenario) {
			this.showDeleteModal(
				categoryScenario,
				this.deleteScenario,
				this.noDeleteScenario
			);
		},
		async deleteScenario (categoryScenario) {
			this.isLoading = true;
			let category = categoryScenario[0];
			const scenario = categoryScenario[1];
			const [data, err] = await scenario_api.deleteScenario(scenario.id);
			if (err) {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				category.scenarios = category.scenarios.filter(function (s) {
					return s.id != scenario.id;
				});
				this.$bvToast.toast("Scenario deleted", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
				this.bScenarioChanged = false;
			}

			this.isLoading = false;
		},
		noDeleteScenario () {},
		handleKeyDown ($event) {
			console.log("global keyup", $event);
			let version = this.scenario.versions[0];
			//console.log("Update", version);

			if (
				version.name != this.$refs.narras_name.innerText ||
        version.narra != this.$refs.narras_text.innerText
			) { this.bScenarioChanged = true; } else this.bScenarioChanged = false;
		},
		async updateScenarioValue () {
			this.isLoading = true;

			let version = this.scenario.versions[0];
			//console.log("Update", version);

			version.name = this.$refs.narras_name.innerText;
			version.narra = this.$refs.narras_text.innerText;

			//console.log("POST version", version);

			const [data, err] = await scenario_version_api.createVersion(version);
			if (err) {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				await this.loadNarras(this.domainId, this.documentId);
				//this.loadTemplates();

				this.$bvToast.toast("Scenario updated", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});

				this.bScenarioChanged = false;
			}
			this.isLoading = false;
		},
		autocompletAt (index, chunk) {
			console.log("evento at " + index + " --- " + chunk);
		},
		filterMatch (text, name, chunk, at) {
			//console.log("filterMatch vue ", text, name, chunk, at);
			return name.indexOf(chunk) > -1;
		},
		filterMembersAutocomplete (text, chunk, at, index) {
			//console.log("filterMembersAutocomplete", text, chunk, at, index);
			if (at == "CALC" || at == "'" || at == "CALC_NARRA_") {
				let items = [];
				if (at == "CALC") {
					items = ["CALC_NARRA_", "CALC_NARRA_EXP(data_row['"];
					//.concat(this.autocomplete_members);
				} else {
					items = this.autocomplete_members;
				}

				return items.filter((v) => {
					return v.indexOf(chunk) > -1;
				});
			} else {
				//es un campo
				//hay que obtener de que entidad se trata
				//Puede ser algo:
				//- [Entity.field
				//- [Entity[0].field
				//- [Entity--relation->Entity2.field
				//- [Entity--relation->Entity2[1].field
				//__len__ y __sort__ estos son bastante especiales

				//hay que buscar la primera aparición hacia atras desde el index de at de los caracteres "[", "(" o "->"
				function searchOperand (text, index) {
					let lastCALC_NARRA = text
						.substring(0, index)
						.lastIndexOf("CALC_NARRA_");
					let lastQuote = text.substring(0, index).lastIndexOf("'");
					let lastBracket = text.substring(0, index).lastIndexOf("[");
					let lastUnderscore = text.substring(0, index).lastIndexOf("_");
					let lastOperand = text.substring(0, index).lastIndexOf("->");
					/*console.log(
						"last ",
						lastCALC_NARRA,
						lastQuote,
						lastBracket,
						lastUnderscore,
						lastOperand
					);*/

					let fromChar =
            Math.max(
            	...[
            		lastCALC_NARRA,
            		lastQuote,
            		lastBracket,
            		lastUnderscore,
            		lastOperand
            	]
            ) + 1;
					//console.log("fromChar1 " + fromChar + ":" + text.substring(fromChar));

					return fromChar;
				}
				let fromChar = searchOperand(text, index);

				let isEndBracket = -1;
				if (text[fromChar - 1] == "[") {
					//puede que si hay un corchete sea por acceder a un elemento [0]
					isEndBracket = text.substring(fromChar - 1, index).lastIndexOf("]");
					//console.log("isEndBracket " + isEndBracket);
					if (isEndBracket > -1) {
						index = fromChar - 1;
						fromChar = searchOperand(text, fromChar - 2);
					}
				}
				//console.log("isEndBracket " + isEndBracket);
				if (
					text.substring(fromChar).lastIndexOf("ALC_NARRA_") > -1 &&
          isEndBracket < 0
				) {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					//console.log("Tiene ALC_NARRA_");
					fromChar += "ALC_NARRA_".length;
				}

				if (text[fromChar] == ">") {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					fromChar++;
				}
				//console.log("fromChar2 " + fromChar + ":" + text.substring(fromChar));

				if (at == "__sort__") index--;
				let entity = text.substring(fromChar, index);
				//console.log("Entity " + entity);
				if (entity.indexOf(".") > -1) {
					//La entidad no puede tener un punto como nombre ya que es un operador. Si viene un punto es que tenemos alguna firigrana
					//del tipo Index.__sort__region_name__desc[0]. La entidad es hasta el primer punto
					entity = entity.substring(0, entity.indexOf("."));
				}
				//console.log("Entity " + entity);
				//console.log("at " + at);

				if (at == "." || at == "__sort__") {
					//console.log("properties");
					//

					return this.$store.getters.getAutocompleteNSchemaMembers.properties[
						entity
					].filter((v) => {
						return v.indexOf(chunk) > -1;
					});
				} else if (at == "--") {
					return this.$store.getters.getAutocompleteNSchemaMembers.relations[
						entity
					].filter((v) => {
						return v.indexOf(chunk) > -1;
					});
				}
			}
		}
	}
};
</script>
