<template>
  <div>
    <div id="graph"></div>
  </div>
</template>
<script>
import { DataSet } from "vis-data/peer";
import { Network } from "vis-network/peer";
import "vis-network/styles/vis-network.css";
export default {
	name: "graph-viewer",
	data () {
		return {
			data: {
				nodes: new DataSet(),
				edges: new DataSet()
			}
		};
	},
	mounted () {
		this.get_data();
		this.draw_graph();
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getNSchema,
			(newValue, oldValue) => {
				this.get_data();
				this.draw_graph();
			}
		);
	},
	methods: {
		get_data () {
			this.data = {
				nodes: new DataSet(),
				edges: new DataSet()
			};
			let nschema_parsed;
			try {
				nschema_parsed = JSON.parse(this.$store.getters.getNSchema);
			} catch (err) {
				nschema_parsed = this.$store.getters.getNSchema;
			}
			const graph = nschema_parsed["@graph"];
			let nodes = {};
			let edges = [];

			if (graph!=null){
				graph.map((item, index) => {
					if (item["@id"].startsWith("nrt:class#")) {
						nodes[item["@id"]] = index;
						this.data.nodes.add({
							id: index,
							label: item["@id"].replace("nrt:class#", "")
						});
					}
					if (item["@id"].startsWith("nrt:relationship#")) {
						edges.push(item);
					}
				});
			}else{
				console.debug("No graph found");
			}

			if (graph!=null){
				edges.map((item, index) => {
					this.data.edges.add({
						id: index,
						from: nodes[item["from"]],
						to: nodes[item["to"]],
						label: item["@id"].replace("nrt:relationship#", ""),
						arrows: "to"
					});
				});
			}else{
				console.debug("No edges found");
			}
		},
		draw_graph () {
			const container = document.getElementById("graph");
			const options = {
				autoResize: true,
				height: "100%",
				width: "100%",
				edges: {
					length: 300
				},
				nodes: {
					font: {
						size: 30
					}
				},
				physics: {
					enabled: true,
					solver: "repulsion",
					repulsion: {
						nodeDistance: 325
					}
				}
			};

			const network = new Network(container, this.data, options);
		}
	}
};
</script>
<style scoped>
#graph {
  border: 1px solid black;
  background: white;
  display: inline-block;
  width: 100%;
  height: 760px;
}
</style>
