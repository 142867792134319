<script>
import {
	closest,
	getOffset,
	getPrecedingRange,
	getRange,
	applyRange,
	scrollIntoView,
	getAtAndIndex
} from "./util";
import AtTemplateNV from "./AtTemplateNV.vue";
import getCaretCoordinates from "textarea-caret";

export default {
	name: "AtNV",
	mixins: [AtTemplateNV],
	props: {
		at: {
			type: String,
			default: null
		},
		ats: {
			type: Array,
			default: () => ["@"]
		},
		suffix: {
			type: String,
			default: ""
		},
		loop: {
			type: Boolean,
			default: false
		},
		allowSpaces: {
			type: [Boolean, String],
			default: false
		},
		avoidEmail: {
			type: Boolean,
			default: false
		},
		hideOnBlur: {
			type: Boolean,
			default: true
		},
		hoverSelect: {
			type: Boolean,
			default: true
		},
		members: {
			type: Array,
			default: () => []
		},
		nameKey: {
			type: String,
			default: ""
		},
		filterMatch: {
			type: Function,
			default: (text, name, chunk, at) => {
				//match at lower-case
				return name.toLowerCase().indexOf(chunk.toLowerCase()) > -1;
			}
		},
		filterMembers: {
			type: Function,
			default: (text, chunk, at, index) => {
				return [];
			}
		},
		deleteMatch: {
			type: Function,
			default: (name, chunk, suffix) => {
				//console.log("deleteMatch ", name, chunk, suffix);
				return chunk === name + suffix;
			}
		},
		scrollRef: {
			type: String,
			default: ""
		}
	},

	data () {
		return {
			hasComposition: false,
			atwho: null
		};
	},
	computed: {
		atItems () {
			return this.at ? [this.at] : this.ats;
		},

		style () {
			if (this.atwho) {
				//console.log("style");
				const { list, cur, x, y } = this.atwho;
				const { wrap } = this.$refs;
				if (wrap) {
					const clientRect = wrap.getBoundingClientRect();
					const field_width = clientRect.width;
					const field_height = clientRect.height;
					const popup_width = 0;
					const offset = getOffset(wrap);
					const scrollLeft = this.scrollRef
						? document.querySelector(this.scrollRef).scrollLeft
						: 0;
					const scrollTop = this.scrollRef
						? document.querySelector(this.scrollRef).scrollTop
						: 0;

					let left = x + scrollLeft + window.pageXOffset - offset.left;
					if (left > field_width - popup_width) { left = field_width - popup_width + "px"; } else left = left + 8 + "px";

					//left -= 800;

					let heigthMembers = Math.min(list.length, 10) * 29;
					if (list.length < 10) {
						heigthMembers += 2 * (10 - list.length);
					}

					const top = y + 10 + scrollTop + window.pageYOffset - offset.top + heigthMembers + "px";

					return { left, top };
				}
			}
			return null;
		}
	},
	watch: {
		"atwho.cur" (index) {
			if (index != null) {
				//cur index exists
				this.$nextTick(() => {
					this.scrollToCur();
				});
			}
		},
		members () {
			this.handleInput(true);
		}
	},

	methods: {
		itemName (v) {
			const { nameKey } = this;
			return nameKey ? v[nameKey] : v;
		},
		isCur (index) {
			return index === this.atwho.cur;
		},

		handleItemHover (e) {
			if (this.hoverSelect) {
				//this.selectElement(e);
			}
		},
		handleItemClick (e) {
			this.selectElement(e);
			this.insertItem();
		},
		handleDelete (e) {
			const range = getPrecedingRange();
			if (range) {
				const { atItems, members, suffix, deleteMatch, itemName } = this;
				const text = range.toString();
				const { at, index } = getAtAndIndex(text, atItems);
				if (index > -1) {
					const chunk = text.slice(index + at.length);
					const has = members.some((v) => {
						const name = itemName(v);
						//return deleteMatch(name, chunk, suffix)
					});
					if (has) {
						e.preventDefault();
						e.stopPropagation();
						const r = getRange();
						if (r) {
							r.setStart(r.endContainer, index);
							r.deleteContents();
							applyRange(r);
							this.handleInput();
						}
					}
				}
			}
		},
		handleKeyDown (e) {
			const { atwho } = this;
			if (atwho) {
				//console.log("keyCode", e.keyCode);
				if (
					e.keyCode === 38 || //arriba
					e.keyCode === 40 || //abajo
					e.keyCode === 35 || //fin
					e.keyCode === 36 || //inicio
					e.keyCode === 33 || //av pag
					e.keyCode === 34 //repag
				) {
					//↑/↓
					if (!(e.metaKey || e.ctrlKey)) {
						e.preventDefault();
						e.stopPropagation();
						this.selectByKeyboard(e);
					}
					return;
				}
				if (e.keyCode === 13) {
					//enter
					this.insertItem();
					e.preventDefault();
					e.stopPropagation();
					return;
				}
				if (e.keyCode === 27) {
					//esc
					this.closePanel();
					return;
				}
			}

			//为了兼容ie ie9~11 editable无input事件 只能靠keydown触发 textarea正常
			//另 ie9 textarea的delete不触发input
			const isValid = (e.keyCode >= 48 && e.keyCode <= 90) || e.keyCode === 8;
			if (isValid) {
				setTimeout(() => {
					this.handleInput();
				}, 50);
			}

			if (e.keyCode === 8) {
				this.handleDelete(e);
			}
		},

		//compositionStart -> input -> compositionEnd
		handleCompositionStart () {
			//console.log("handleCompositionStart");
			this.hasComposition = true;
		},
		handleCompositionEnd () {
			//console.log("handleCompositionEnd");
			this.hasComposition = false;
			this.handleInput();
		},
		handleInput (keep) {
			//console.log("## ## handleInput " + this.hasComposition);
			if (this.hasComposition) {
				return;
			}

			let text;
			const range = getPrecedingRange();
			const el = this.$el.querySelector("input");
			//console.log("EL", el);
			//console.log("range", range);
			if (el) {
				text = el.value.slice(0, el.selectionEnd);
			} else {
				if (range) {
					text = range.toString();
				}
			}

			if (text) {
				const { atItems, avoidEmail, allowSpaces } = this;

				let show = true;
				//console.log('texto "' + text + '"');

				const { at, index } = getAtAndIndex(text, atItems);

				//console.log(at, index);

				if (index < 0) show = false;
				const prev = text[index - 1];
				//console.log("prev", prev);

				//console.log("slice ", index, at.length, text.length);
				const chunk = text.slice(index + at.length, text.length);
				//console.log('chunk "' + chunk + '"');

				if (allowSpaces && /\s/.test(chunk)) {
					show = false;
					//console.log("show false 1");
				}

				//los fragmentos no coinciden con los espacios en blanco al principio, evite que `@` también coincida
				if (/^\s/.test(chunk)) {
					show = false;
					//console.log("show false 2");
				}

				if (!show) {
					this.closePanel();
				} else {
					const { members, filterMatch, itemName } = this;
					/*if (!keep && chunk.length > 0) {
						console.debug("AtNV " + this._uid + " emit 'at' event");
						this.$emit("at", index, chunk);
					}*/
					const matched = this.filterMembers(text, chunk, at, index);
					if (matched && matched.length) {
						this.openPanel(matched, range, index, at, el, chunk);
					} else {
						this.closePanel();
					}
				}
			}
		},

		closePanel () {
			if (this.atwho) {
				this.atwho = null;
			}
		},
		openPanel (list, range, offset, at, el, chunk) {
			const fn = () => {
				if (el) {
					const atEnd = offset + at.length; //从@后第一位开始
					const rect = getCaretCoordinates(el, atEnd);
					this.atwho = {
						chunk,
						offset,
						list,
						atEnd,
						x: rect.left,
						y: rect.top - 4,
						cur: 0 //todo: 尽可能记录
					};
				} else {
					const r = range.cloneRange();
					//console.log("open range ", r);
					r.setStart(r.endContainer, offset + at.length);
					//todo: A juzgar por el espacio de la ventana
					const rect = r.getClientRects()[0];
					this.atwho = {
						range,
						offset,
						list,
						x: rect.left,
						y: rect.top - 4,
						cur: 0 //todo: Grabar tanto como sea posible
					};
				}
			};
			if (this.atwho) {
				fn();
			} else {
				//El foco está fuera del área de visualización. Debe proporcionar un retraso para mover el puntero y calcular la posición.
				setTimeout(fn, 10);
			}
		},

		scrollToCur () {
			const curEl = this.$refs.cur[0];
			const scrollParent = curEl.parentElement.parentElement; //.atwho-view
			scrollIntoView(curEl, scrollParent);
		},
		selectElement (e) {
			const el = closest(e.target, (d) => {
				return d.getAttribute("data-index");
			});
			const cur = +el.getAttribute("data-index");
			this.atwho = {
				...this.atwho,
				cur
			};
		},
		selectByKeyboard (e) {
			let offset = 0;
			switch (e.keyCode) {
			case 38:
				offset = -1;
				break;
			case 40:
				offset = 1;
				break;
			case 33:
				offset = -10;
				break;
			case 34:
				offset = 10;
				break;
			}
			const { cur, list } = this.atwho;
			const nextCur = this.loop
				? (cur + offset + list.length) % list.length
				: Math.max(0, Math.min(cur + offset, list.length - 1));

			if (e.keyCode == 36) {
				//inicio
				this.atwho = {
					...this.atwho,
					cur: 0
				};
			} else if (e.keyCode == 35) {
				//fin
				this.atwho = {
					...this.atwho,
					cur: list.length - 1
				};
			} else {
				this.atwho = {
					...this.atwho,
					cur: nextCur
				};
			}
		},

		insertText (text, r) {
			r.deleteContents();
			const node = r.endContainer;
			if (node.nodeType === Node.TEXT_NODE) {
				//console.log("TEXT");
				const cut = r.endOffset;
				node.data = node.data.slice(0, cut) + text + node.data.slice(cut);
				r.setEnd(node, cut + text.length);
			} else {
				//console.log("NO TEXT");
				const t = document.createTextNode(text);
				r.insertNode(t);
				r.setEndAfter(t);
			}
			r.collapse(false); //参数在IE下必传
			applyRange(r);
		},
		insertItem () {
			const { range, offset, list, cur } = this.atwho;
			const { suffix, atItems, itemName } = this;
			const r = getPrecedingRange().cloneRange();
			const text = r.toString();
			const { at, index } = getAtAndIndex(text, atItems);
			const start = index + at.length; //从@后第一位开始
			//console.log('insertItem "', text, range);
			//console.log("insertItem2 ", atItems, at, start);
			r.setStart(r.endContainer, start);
			//hack: Dos veces seguidas, puede asegurarse de que el foco vuelva después del clic. El rango realmente surte efecto.
			applyRange(r);
			applyRange(r);
			const t = (itemName(list[cur]) + suffix).replace("CALC_NARRA", "_NARRA");

			//console.log("INSERT TEXT ", t);
			this.insertText(t, r);
			this.handleInput();
		}
	}
};
</script>
