var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Add new Category" },
                      on: {
                        click: function ($event) {
                          return _vm.newModalCategory()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" \n          "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Expand" },
                      on: {
                        click: function ($event) {
                          return _vm.expandAll()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-expand",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" \n          "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Collapse" },
                      on: {
                        click: function ($event) {
                          return _vm.collapseAll()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-compress",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" \n          "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-form",
                    [
                      _c("b-form-input", {
                        staticClass: "form-control-sm",
                        attrs: { placeholder: "Filter Category" },
                        model: {
                          value: _vm.searchCategory,
                          callback: function ($$v) {
                            _vm.searchCategory = $$v
                          },
                          expression: "searchCategory",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Go to Top" },
                      on: { click: _vm.topFunction },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-arrow-up",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "h4 scenario_main_title" }, [
            _vm._v("Categories Definitions"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filterCategories, function (category, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "p",
                  { staticClass: "categoryscenario-header" },
                  [
                    _c(
                      "span",
                      {
                        attrs: { contenteditable: "", spellcheck: "false" },
                        on: {
                          blur: function ($event) {
                            return _vm.updateCategory($event, category)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(category.name) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("ButtonCircle", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      ref: "ButtonPlus",
                      refInFor: true,
                      attrs: {
                        template: category,
                        icon: "plus",
                        title: "Add new Category",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addScenario(category)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("ButtonCircle", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      ref: "ButtonTrash",
                      refInFor: true,
                      attrs: {
                        template: category,
                        icon: "trash-alt",
                        title: "Delete Category",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmDeleteCategory(category)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("ButtonCircle", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      ref: "ButtonCollapse" + category.id,
                      refInFor: true,
                      attrs: {
                        template: category,
                        icon: "angle-down",
                        icon_second: "angle-up",
                        title: "Expand / Collapse",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toogleCategory(category)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-collapse",
                  {
                    ref: "collapse-" + category.id,
                    refInFor: true,
                    staticClass: "mt-2",
                    attrs: { id: "collapse-" + category.id },
                  },
                  [
                    _c(
                      "div",
                      { ref: "div_category" + category.id, refInFor: true },
                      _vm._l(
                        category.scenarios,
                        function (scenario, scenarioIndex) {
                          return _c(
                            "div",
                            { key: scenarioIndex },
                            [
                              _c("ScenarioItemV31", {
                                attrs: {
                                  scenario: scenario,
                                  domainId: _vm.domainId,
                                  documentId: _vm.documentId,
                                  category: category,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              ref: "modal-new-cat",
              attrs: {
                "hide-footer": "",
                id: "modal-new-cat",
                title: "New Category",
                centered: "",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-2",
                        label: "Name:",
                        "label-for": "name",
                        "label-align": "left",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "name",
                          state: _vm.validationName,
                          required: "",
                          placeholder: "Name",
                        },
                        model: {
                          value: _vm.name_cat,
                          callback: function ($$v) {
                            _vm.name_cat = $$v
                          },
                          expression: "name_cat",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.validationName,
                        variant: "primary",
                      },
                      on: { click: _vm.insertCategory },
                    },
                    [_vm._v("Save")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "reset", variant: "danger" },
                      on: { click: _vm.cancelCategory },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoading ? _c("Loading") : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }