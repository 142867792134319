<template>
	<div>
		<NarrasTreeEditor :narraId="narraId" navbar="false"></NarrasTreeEditor>
	</div>
</template>

<script>
import domains_api from "../../api/domain.js";
import NarrasTreeEditor from "@/components/narras/NarrasTreeEditor";

export default {
	name: "NarrasEditorView",
	components: { NarrasTreeEditor },
	data () {
		return {
			currentDomain: {}
		};
	},
	created () {
		this.getData();
	},
	methods: {
		async getData () {
			let breadcrumb_items = [
				{
					text: "Domains",
					to: { name: "Home" }
				}
			];

			let [data, err] = await domains_api.getDomain(this.domainId);
			console.log("################### DOMAIN", data, err);
			if (!err) {
				this.currentDomain = data;
				breadcrumb_items.push({
					text: "Structure: " + data.name,
					to: {
						name: "NarrasList",
						params: { domainId: data.id }
					}
				});

				breadcrumb_items.push({
					text: this.narraName,
					active: true
				});
			}

			this.$store.commit("SET_BREADCRUMB_ITEMS", breadcrumb_items);
		}
	},
	props: {
		narraId: {},
		narraName: {},
		domainId: {}
	}
};
</script>
