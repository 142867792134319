export const SET_ENGINES = "SET_ENGINES";
export const SET_ROOT_NARRAS = "SET_ROOT_NARRAS";
export const SET_CURRENT_DOMAIN = "SET_CURRENT_DOMAIN";
export const SET_CURRENT_DOCUMENT = "SET_CURRENT_DOCUMENT";
export const SET_SELECTION_ON_TEMPLATE = "SET_SELECTION_ON_TEMPLATE";
export const SET_ROWS_NARRAS = "SET_ROWS_NARRAS";
export const SET_NARRAS_TEMPLATES = "SET_NARRAS_TEMPLATES";
export const SET_SCENARIOS = "SET_SCENARIOS";
export const SET_ALIASES = "SET_ALIASES";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_TEMPLATES_REGULAR = "SET_TEMPLATES_REGULAR";
export const SET_TEMPLATES_COMPOSITION = "SET_TEMPLATES_COMPOSITION";
export const SET_DOCUMENT_DATA = "SET_DOCUMENT_DATA";
export const SET_DOCUMENT_DATA_SELECTED = "SET_DOCUMENT_DATA_SELECTED";
export const SET_NSCHEMA = "SET_NSCHEMA";
export const SET_AUTOCOMPLETE_MEMBERS = "SET_AUTOCOMPLETE_MEMBERS";
export const SET_BREADCRUMB_ITEMS = "SET_BREADCRUMB_ITEMS";
export const SET_AUTOCOMPLETE_NSCHEMA_MEMBERS = "SET_AUTOCOMPLETE_NSCHEMA_MEMBERS";
export const SET_PREVIEW_VARIATIONS = "SET_PREVIEW_VARIATIONS";
export const SET_PREVIEW_PARAPHRASING = "SET_PREVIEW_PARAPHRASING";
export const SET_NAVIGATE_TEMPLATE = "SET_NAVIGATE_TEMPLATE";
export const SET_JWT_TOKEN = "SET_JWT_TOKEN";
export const SET_USERNAME = "SET_USERNAME";
export const SET_NAVIGATE_ROOT = "SET_NAVIGATE_ROOT";
export const SET_HT_EDITOR_COLLAPSED_STATE = "SET_HT_EDITOR_COLLAPSED_STATE";
export const SET_ROW_NARRA_COLLAPSED_STATE = "SET_ROW_NARRA_COLLAPSED_STATE";
export const SET_FORMULA_FILTERS = "SET_FORMULA_FILTERS";
export const SET_FORMULA_PARSER = "SET_FORMULA_PARSER";
export const SET_STATUS_FILTER = "SET_STATUS_FILTER";
export const SET_SEGMENT_ID_FILTER = "SET_ID_SEGMENT_FILTER";
export const TOGGLE_ADDED_TO_BRANCH_GENERATOR = "TOGGLE_ADDED_TO_BRANCH_GENERATOR";
export const SET_CATEGORIES_BRANCH_GENERATOR = "SET_CATEGORIES_BRANCH_GENERATOR";
export const SET_NODE_TREE_ID = "SET_NODE_TREE_ID";
export const SET_SCENARIOS_BRANCHES_TO_EDIT = "SET_SCENARIOS_BRANCHES_TO_EDIT";
export const SET_NARRAS_TREES_NODE_ID_FOR_CUBES = "SET_NARRAS_TREES_NODE_ID_FOR_CUBES"
export const SET_TEMPLATES_TO_PREVIEW = "SET_TEMPLATES_TO_PREVIEW";
export const SET_TRIGGER_TEMPLATES_PREVIEW = "SET_TRIGGER_TEMPLATES_PREVIEW";
export const SET_UPDATE_TEMPLATE_PREVIEW_STATUS = "SET_UPDATE_TEMPLATE_PREVIEW_STATUS";
export const SET_TRIGGER_REDIRECTION = "SET_TRIGGER_REDIRECTION";
export const TOGGLE_OLD_EDIT_MODE_ACTIVE = "TOGGLE_OLD_EDIT_MODE_ACTIVE"
export const SET_TREE_NODE_TO_ARCHIVE = "SET_TREE_NODE_TO_ARCHIVE";
export const SET_ARCHIVED_NARRAS = "SET_ARCHIVED_NARRAS";
export const SET_ARCHIVED_ROOT_NARRAS = "SET_ARCHIVED_ROOT_NARRAS";
export const SET_CUBES = "SET_CUBES";
export const SET_TREE_NODE_TO_RESTORE = "SET_TREE_NODE_TO_RESTORE";
export const SET_TREE_NODE_TO_DELETE = "SET_TREE_NODE_TO_DELETE";
