import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class FormulaFilters extends BaseClient {
	constructor () {
		super(httpClient, "/formula/");
	}

	createVersion (template) {
		return this.post("", template);
	}

	getAllFormulaFilters () {
		return this.get("filters");
	}
}

export default new FormulaFilters();
