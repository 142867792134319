var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "card mt-1 nschema-slied-selector-card",
          style: { left: _vm.x + "px", top: _vm.y + "px" },
        },
        [
          _c(
            "div",
            { staticClass: "card-header" },
            [
              _c("b-form-input", {
                attrs: { placeholder: "Filter Nschema" },
                model: {
                  value: _vm.filterTree,
                  callback: function ($$v) {
                    _vm.filterTree = $$v
                  },
                  expression: "filterTree",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("tree", {
                staticClass: "tree-container nschema-slied-selector-tree",
                attrs: {
                  data: _vm.nschema,
                  options: _vm.options,
                  filter: _vm.filterTree,
                },
                on: { "node:selected": _vm.selectNode },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }