var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    [
      _c(
        "b-navbar",
        { staticClass: "header__nav", attrs: { toggleable: "lg" } },
        [
          _c("b-navbar-brand", { attrs: { to: _vm.rootPath } }, [
            _c("img", {
              staticClass: "header__logo",
              attrs: {
                src: require("@/assets/NarrativaLogoPNG_50h.png"),
                title: "Home",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ml-4" }, [
              _vm._v(_vm._s(_vm.headerTitle)),
            ]),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", [
                _c("span", { staticClass: "header__build-time" }, [
                  _vm._v("Build time: " + _vm._s(_vm.buildTime)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tokenExists
        ? _c("b-breadcrumb", {
            staticClass: "header__breadcrum",
            attrs: { items: _vm.breadcrumbItems },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }