var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { class: _vm.getClassForTemplate(_vm.template) },
        [
          _c("a", { ref: "anchor", attrs: { name: _vm.template.id } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-header template-header pt-0 pb-0 pl-2" },
            [
              _c(
                "b-row",
                {
                  staticClass: "template-row-header",
                  attrs: { "align-v": "center" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "m-0 p-0 pl-2 pt-1", attrs: { cols: "9" } },
                    [
                      _c("label", {
                        ref: "lblType",
                        staticClass: "template-cell-type",
                        attrs: { contenteditable: "", spellcheck: "false" },
                        domProps: {
                          textContent: _vm._s(_vm.template.versions[0].type),
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.updateVersion(
                              $event,
                              0,
                              _vm.template.versions[0]
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.template.versions[0].type[0] != "_"
                        ? [
                            !_vm.template.narra_tree_nodes_version.length
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "template-circle-button",
                                    attrs: {
                                      href: "#",
                                      title: "Asign Segment to a Scenario",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openSelectNarra(
                                          $event,
                                          _vm.template
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {},
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "plus" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._l(
                                  _vm.template.narra_tree_nodes_version,
                                  function (narra_version) {
                                    return _c(
                                      "a",
                                      {
                                        staticClass: "col-6",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.confirmDeleteNarraFromTemplate(
                                              $event,
                                              _vm.template,
                                              narra_version
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._l(_vm.treePath, function (node) {
                                          return _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge-narra-parent",
                                              },
                                              [_vm._v(_vm._s(node))]
                                            ),
                                            _vm._v(" >\n                "),
                                          ])
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "badge-narra" },
                                          [_vm._v(_vm._s(narra_version.name))]
                                        ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.template.versions[0].type[0] == "_"
                        ? _c("b-form-select", {
                            staticClass: "input-select-weigth",
                            attrs: { options: _vm.weigth_options, size: "sm" },
                            on: {
                              change: function ($event) {
                                return _vm.updateVersion(
                                  $event,
                                  2,
                                  _vm.template.versions[0]
                                )
                              },
                            },
                            model: {
                              value: _vm.weigth,
                              callback: function ($$v) {
                                _vm.weigth = $$v
                              },
                              expression: "weigth",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right pr-0 align-middle",
                      attrs: { cols: "3" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.showHistory.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-history template-circle-button",
                          }),
                          _vm._v(" "),
                          _c("b-badge", {
                            staticClass: "template-updated-at m-0 p-0",
                            attrs: { variant: "light" },
                            domProps: { textContent: _vm._s(_vm.updated_at) },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ButtonCircle", {
                        ref: "ButtonEye",
                        staticClass: "template-preview-icon",
                        attrs: {
                          template: _vm.template,
                          icon: "play",
                          title: "Run this segment with selected data file",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewTemplate(
                              $event,
                              "ButtonEye",
                              false,
                              1
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "template-preview-icon template-circle-button",
                          attrs: {
                            href: "#",
                            title: "Filter to show only this Segment",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$emit(
                                "filterByTemplateID",
                                _vm.template
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "filter", size: "sm" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "template-preview-icon template-circle-button",
                          attrs: { href: "#", title: "Translate" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.translate($event)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "language", size: "sm" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            id: "rowTemplatesExtraOptions",
                            size: "sm",
                            variant: "link",
                            "toggle-class": "text-decoration-none m-0 p-0",
                            "no-caret": "",
                            title: "Extra options",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "template-circle-button" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "ellipsis-v",
                                          size: "sm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "ButtonEye5X",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    5
                                  )
                                },
                              },
                            },
                            [_vm._v("Preview 5x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "ButtonEye20X",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    20
                                  )
                                },
                              },
                            },
                            [_vm._v("Preview 20x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "ButtonEye50X",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    50
                                  )
                                },
                              },
                            },
                            [_vm._v("Preview 50x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "RealPreview",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    1,
                                    "false"
                                  )
                                },
                              },
                            },
                            [_vm._v("Real Preview")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "RealPreview",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    5,
                                    "false"
                                  )
                                },
                              },
                            },
                            [_vm._v("Real Preview 5x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "RealPreview",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    20,
                                    "false"
                                  )
                                },
                              },
                            },
                            [_vm._v("Real Preview 20x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "RealPreview",
                              on: {
                                click: function ($event) {
                                  return _vm.previewTemplate(
                                    $event,
                                    "ButtonEye",
                                    false,
                                    50,
                                    "false"
                                  )
                                },
                              },
                            },
                            [_vm._v("Real Preview 50x")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "ButtonDuplicate",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "duplicateTemplate",
                                    _vm.template
                                  )
                                },
                              },
                            },
                            [_vm._v("Duplicate")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              ref: "ButtonDelete",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTemplate($event)
                                },
                              },
                            },
                            [_vm._v("Delete")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "switch-render-html inline m-0",
                          attrs: {
                            name: "check-renderHTML",
                            size: "sm",
                            inline: "",
                            title: "Render HTML toggle",
                          },
                          model: {
                            value: _vm.renderHTML,
                            callback: function ($$v) {
                              _vm.renderHTML = $$v
                            },
                            expression: "renderHTML",
                          },
                        },
                        [_vm._v("HTML")]
                      ),
                      _vm._v(" "),
                      _vm.renderHTML
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "switch-render-html inline m-0",
                              attrs: {
                                name: "check-showTemplatesLinks",
                                size: "sm",
                                inline: "",
                                title:
                                  "Show Links to Segments in the preview text",
                              },
                              model: {
                                value: _vm.showTemplatesLinks,
                                callback: function ($$v) {
                                  _vm.showTemplatesLinks = $$v
                                },
                                expression: "showTemplatesLinks",
                              },
                            },
                            [_vm._v("Links")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "switch-render-html inline",
                          attrs: {
                            name: "check-renderHTML",
                            size: "sm",
                            inline: "",
                            title: "Render HTML toggle",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.saveIsPrompt(_vm.template.is_prompt)
                            },
                          },
                          model: {
                            value: _vm.template.is_prompt,
                            callback: function ($$v) {
                              _vm.$set(_vm.template, "is_prompt", $$v)
                            },
                            expression: "template.is_prompt",
                          },
                        },
                        [_vm._v("Is Prompt")]
                      ),
                      _vm._v(" "),
                      _c("b-badge", {
                        staticClass: "template-id-number text-right m-0 p-0",
                        attrs: { variant: "light" },
                        domProps: { textContent: _vm._s(_vm.template.id) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "template-row-text" },
            [
              _vm.editMode
                ? _c(
                    "b-col",
                    {
                      staticClass: "template-cell-text card-body",
                      attrs: { cols: "6" },
                    },
                    [
                      _c(
                        "at",
                        {
                          ref: "at" + _vm.template.id,
                          attrs: {
                            ats: _vm.triger_chars,
                            members: _vm.autocomplete_members_in_templates,
                            hideOnBlur: "",
                            filterMatch: _vm.filterMatch,
                            filterMembers:
                              _vm.filterMembersAutocompleteInTemplates,
                          },
                          on: { at: _vm.autocompletAt },
                        },
                        [
                          _c(
                            "Highlightable",
                            {
                              ref: "Highlightable" + _vm.template.id,
                              on: {
                                onVariations: _vm.openPopupVariations,
                                onCreateTempByText: _vm.createTempByText,
                                onParaphrasing: _vm.openPopupParaphrasing,
                              },
                            },
                            [
                              _c("highlightable-input", {
                                ref: "text" + _vm.template.id,
                                attrs: {
                                  "highlight-style": "badge-data-template",
                                  "highlight-enabled": "",
                                  highlight: _vm.highlight,
                                  refreshHighlights: _vm.refreshHighlights,
                                  category_id: _vm.category_id,
                                  canEdit: "",
                                },
                                on: {
                                  mouseup: function ($event) {
                                    return _vm.selectionText(
                                      $event,
                                      _vm.template.versions[0]
                                    )
                                  },
                                  keyup: function ($event) {
                                    return _vm.handleTextKeyDown(
                                      $event,
                                      _vm.template
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.textTemplateLostFocus(
                                      $event,
                                      _vm.template
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.textTemplateGetFocus(
                                      $event,
                                      _vm.template
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.template.versions[0].text_for_editor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.template.versions[0],
                                      "text_for_editor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "template.versions[0].text_for_editor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    {
                      staticClass: "template-cell-text card-body text-danger",
                      attrs: { cols: "6" },
                    },
                    [
                      _c(
                        "Highlightable",
                        {
                          ref: "Highlightable" + _vm.template.id,
                          on: {
                            onVariations: _vm.openPopupVariations,
                            onCreateTempByText: _vm.createTempByText,
                            onParaphrasing: _vm.openPopupParaphrasing,
                          },
                        },
                        [
                          _c("highlightable-input", {
                            attrs: {
                              "highlight-style": "badge-data-template",
                              "highlight-enabled": "",
                              highlight: _vm.highlight,
                              refreshHighlights: _vm.refreshHighlights,
                              category_id: _vm.category_id,
                            },
                            on: { mouseup: _vm.enableEditing },
                            model: {
                              value: _vm.template.versions[0].text_for_editor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.template.versions[0],
                                  "text_for_editor",
                                  $$v
                                )
                              },
                              expression:
                                "template.versions[0].text_for_editor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "template-col-preview", attrs: { cols: "6" } },
                [
                  _vm.variability
                    ? _c("p", {}, [
                        _c(
                          "span",
                          { staticClass: "badge-template badge-data-template" },
                          [
                            _vm._v(
                              "\n              Variability:\n              "
                            ),
                            _c("span", { staticClass: "variability-percent" }, [
                              _vm._v(_vm._s(_vm.variability) + "%"),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.previews, function (preview) {
                    return _c(
                      "div",
                      { staticClass: "template-row-preview" },
                      [
                        !_vm.renderHTML
                          ? [
                              !preview.error
                                ? [
                                    preview.log.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "error-invalid-scenario",
                                          },
                                          _vm._l(
                                            preview.log,
                                            function (error, index) {
                                              return _c("div", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(error.message) + ":"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _vm._v(
                                                    "- " + _vm._s(error.details)
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                preview.log[index + 1]
                                                  ? _c("br")
                                                  : _vm._e(),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !preview.error
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "template-cell-preview-result",
                                          },
                                          [
                                            _c("HTMLValidator", {
                                              attrs: {
                                                html_text: preview.result,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _c("font-awesome-icon", {
                                      staticClass:
                                        "template-cell-preview-error-icon",
                                      attrs: { icon: "exclamation" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass:
                                        "template-cell-preview-error",
                                      domProps: {
                                        innerHTML: _vm._s(preview.error),
                                      },
                                    }),
                                  ],
                            ]
                          : [
                              !preview.error
                                ? [
                                    preview.log.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "error-invalid-scenario",
                                          },
                                          _vm._l(
                                            preview.log,
                                            function (error, index) {
                                              return _c("div", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(error.message) + ":"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _vm._v(
                                                    "- " + _vm._s(error.details)
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                preview.log[index + 1]
                                                  ? _c("br")
                                                  : _vm._e(),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.showTemplatesLinks
                                      ? _c("div", {
                                          staticClass:
                                            "template-cell-preview-result",
                                          domProps: {
                                            innerHTML: _vm._s(preview.result),
                                          },
                                          on: {
                                            mouseup: function ($event) {
                                              return _vm.selectionText(
                                                $event,
                                                _vm.template.versions[0]
                                              )
                                            },
                                          },
                                        })
                                      : _c("div", {
                                          staticClass:
                                            "template-cell-preview-result",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              preview.result_with_sandbox_tags
                                            ),
                                          },
                                          on: {
                                            mouseup: function ($event) {
                                              return _vm.selectionText(
                                                $event,
                                                _vm.template.versions[0]
                                              )
                                            },
                                          },
                                        }),
                                  ]
                                : [
                                    _c("font-awesome-icon", {
                                      staticClass:
                                        "template-cell-preview-error-icon",
                                      attrs: { icon: "exclamation" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass:
                                        "template-cell-preview-error",
                                      domProps: {
                                        innerHTML: _vm._s(preview.error),
                                      },
                                      on: {
                                        mouseup: function ($event) {
                                          return _vm.selectionText(
                                            $event,
                                            _vm.template.versions[0]
                                          )
                                        },
                                      },
                                    }),
                                  ],
                            ],
                        _vm._v(" "),
                        _vm.translates.length > 0
                          ? _c("hr", {
                              staticClass: "hr-template-cell-preview-result",
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _vm.translates.length > 0
                    ? _c(
                        "div",
                        { staticClass: "row segment_preview_row mt-2" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              { staticClass: "template-col-previewnv" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "template-row-preview-nv" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "collapseControl",
                                        attrs: { title: "Translate segment" },
                                        on: { click: _vm.translate },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-language fa-2x",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.translates, function (translate) {
                                  return _c(
                                    "div",
                                    { staticClass: "template-row-preview-nv" },
                                    [
                                      translate.text_dest
                                        ? _c("div", {
                                            staticClass:
                                              "template-cell-preview-result",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                translate.text_dest
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass:
                                          "hr-template-cell-preview-result",
                                      }),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            {
              attrs: {
                height: "950",
                width: "900",
                name: _vm.modalSelecionTextName,
              },
            },
            [
              _c("VariationsSelector", {
                ref: "VariationsSelector",
                attrs: {
                  domain_id: _vm.currentDomain.id,
                  minerva_url: _vm.currentDomain.minerva_url,
                  minerva_server: _vm.currentDomain.minerva_server,
                  language: _vm.currentDocument.language,
                  modal_name: _vm.modalSelecionTextName,
                },
                on: { selectedItems: _vm.insertVariations },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            {
              attrs: {
                height: "950",
                width: "900",
                name: _vm.modalParaphrasingName,
              },
            },
            [
              _c("ParaphrasingSelector", {
                ref: "ParaphrasingSelector",
                attrs: {
                  domain_id: _vm.currentDomain.id,
                  documenta_data_id: this.document_data_selected,
                  language: _vm.currentDocument.language,
                  modal_name: _vm.modalParaphrasingName,
                },
                on: { selectedItems: _vm.insertVariations },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            {
              attrs: {
                height: "950",
                width: "900",
                name: _vm.modalSimilarityName,
              },
            },
            [
              _c("SimilaritySelector", {
                ref: "VariationsSelector",
                attrs: {
                  domain_id: _vm.currentDomain.id,
                  minerva_url: _vm.currentDomain.minerva_url,
                  minerva_server: _vm.currentDomain.minerva_server,
                  language: _vm.currentDocument.language,
                  modal_name: _vm.modalSimilarityName,
                },
                on: { includeSimilariry: _vm.insertSimilarity },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            {
              staticClass: "entities-modal",
              attrs: {
                height: "700",
                width: "1200",
                name: _vm.modalEntitiesName,
              },
            },
            [
              _c("EntitiesSelector", {
                ref: "EntitiesSelector",
                attrs: {
                  language: _vm.currentDocument.language,
                  texts: _vm.textEntities,
                  modal_name: _vm.modalEntitiesName,
                  insert: _vm.entitiesNewTemplates,
                },
                on: {
                  includeEntities: _vm.insertEntities,
                  includeEntitiesTemplate: _vm.insertEntitiesTemplate,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "modal",
            {
              attrs: {
                height: "950",
                width: "900",
                name: _vm.modalHistoryName,
              },
            },
            [
              _c("TemplatesHistory", {
                attrs: {
                  templateId: _vm.template.id,
                  modal_name: _vm.modalHistoryName,
                },
                on: { setCurrentVersion: _vm.setCurrentVersion },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                scrollable: "",
                height: "5000",
                width: "60%",
                resizable: "",
                draggable: "",
                size: "xl",
                name: _vm.narraSelectorModalName,
                id: _vm.narraSelectorModalName,
                centered: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-header",
                  fn: function (ref) {
                    var hide = ref.hide
                    return undefined
                  },
                },
                {
                  key: "modal-footer",
                  fn: function (ref) {
                    var ok = ref.ok
                    var cancel = ref.cancel
                    var hide = ref.hide
                    return [_c("b")]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("NarrasTreesViewer", {
                attrs: {
                  collapse_button: "",
                  tree_editor_button: "false",
                  select_name: "true",
                  rows: _vm.rows,
                  template_name_filter: _vm.template.versions[0].type,
                },
                on: { selectNarra: _vm.selectNarra },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              ref: "modalGetTypeForNewVersion",
              attrs: {
                height: "5000",
                draggable: "",
                size: "lg",
                centered: "",
                "hide-footer": "",
                "hide-header": "",
                centered: "",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-2",
                    label: "New segment name:",
                    "label-for": "type",
                    "label-align": "left",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "type", required: "", placeholder: "Name" },
                    model: {
                      value: _vm.typeTempByText,
                      callback: function ($$v) {
                        _vm.typeTempByText = $$v
                      },
                      expression: "typeTempByText",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { id: "input-group-5", "label-align": "left" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "active",
                        value: "true",
                        "unchecked-value": "false",
                      },
                      model: {
                        value: _vm.associateDefaultByText,
                        callback: function ($$v) {
                          _vm.associateDefaultByText = $$v
                        },
                        expression: "associateDefaultByText",
                      },
                    },
                    [_vm._v("Create associated with default scenario")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.typeTempByText.length,
                    variant: "primary",
                  },
                  on: { click: _vm.createTempByTextWithType },
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { type: "reset", variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs["modalGetTypeForNewVersion"].hide()
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.parent
        ? _c("ButtonCircle", {
            ref: "ButtonCollapse" + _vm.template.id,
            attrs: {
              template: _vm.template,
              icon: "angle-down",
              icon_second: "angle-up",
              customClass: "button-collase-template",
            },
            on: { click: _vm.toggleCollapse },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }