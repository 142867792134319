var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "login content p-0 d-flex align-items-center justify-content-center",
    },
    [
      _c("div", { staticClass: "login__wrap " }, [
        _c(
          "div",
          { staticClass: "login__form" },
          [
            _vm.showError
              ? _c(
                  "b-alert",
                  {
                    staticClass: "mb-4",
                    attrs: { show: "", variant: "danger" },
                  },
                  [_vm._v("Authentication failed")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-card",
              { attrs: { header: "Sign In" } },
              [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.login.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: { id: "input-group-1", "label-for": "input-1" },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "user",
                            type: "text",
                            required: "",
                            placeholder: "User",
                          },
                          model: {
                            value: _vm.credentials.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.credentials, "username", $$v)
                            },
                            expression: "credentials.username",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: { id: "input-group-2", "label-for": "input-2" },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-2",
                            required: "",
                            placeholder: "Password",
                            type: "password",
                          },
                          model: {
                            value: _vm.credentials.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.credentials, "password", $$v)
                            },
                            expression: "credentials.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v("Submit")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }