<template>
  <DomainsList></DomainsList>
</template>

<script>
import DomainsList from "@/components/domain/DomainsList.vue";
import axios from "axios";
export default {
	name: "Home",
	components: {
		DomainsList
	},
	data () {
		return {
			msg: "Nrt Editor",
			file: ""
		};
	},
	methods: {
		handleFileUpload () {
			let reader = new FileReader();
			reader.onload = event => {
				this.file = event.target.result;
			};
			reader.readAsDataURL(this.$refs.file.files[0]);
		},
		submitFile () {
			let data_send = {
				file_tsv: this.file
			};
			console.log(data_send);
			axios
				.post("http://127.0.0.1:8000/api/documents/1/import/", data_send, {
					headers: {
						"Content-Type": "application/json"
					}
				})
				.then(function () {
					console.log("SUCCESS!!");
				})
				.catch(function () {
					console.log("FAILURE!!");
				});
		}
	}
};
</script>
