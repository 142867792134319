var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "documents content" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "documents__header d-flex align-items-center mb-3" },
          [
            _c("b-col", [
              _c("h1", [_vm._v("Documents")]),
              _vm._v(" "),
              _c("p", [_vm._v("Select a document to start working")]),
            ]),
            _vm._v(" "),
            _c(
              "b-col",
              { staticClass: "documents__btns flex flex-end-x" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "documents__btn",
                    attrs: {
                      to: {
                        name: "Home",
                      },
                    },
                  },
                  [
                    _c(
                      "b-button",
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "arrow-left" },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Back")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "documents__btn",
                    attrs: { variant: "success" },
                    on: { click: _vm.newModal },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("New")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c("br"),
                _vm._v(" "),
                _c(
                  "b-table-simple",
                  { attrs: { hover: "", small: "", "caption-top": "" } },
                  [
                    _c(
                      "b-thead",
                      [
                        _c(
                          "b-th",
                          {
                            staticClass: "text-left",
                            staticStyle: { width: "60%" },
                          },
                          [_vm._v("Document")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "10%" },
                          },
                          [_vm._v("Language")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-right",
                            staticStyle: { width: "30%" },
                          },
                          [_vm._v("Controls")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tbody",
                      _vm._l(_vm.documents, function (document) {
                        return _c(
                          "b-tr",
                          { key: document.id, attrs: { scope: "row" } },
                          [
                            _c(
                              "b-td",
                              { staticClass: "text-left align-middle" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "EditorV5",
                                        params: {
                                          domainId: _vm.domainId,
                                          documentId: document.id,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(document.name))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-td",
                              { staticClass: "text-center align-middle" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(document.language) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-td",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "EditorV5",
                                        params: {
                                          domainId: _vm.domainId,
                                          documentId: document.id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "info",
                                          title: "Work with this document",
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "play" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "light",
                                      title:
                                        "Edit general settings for this document",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateModal($event, document)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "cog" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "light",
                                      title: "Clone this document in a new one",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cloneModal($event, document)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "clone" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "EditorNV",
                                        params: {
                                          domainId: _vm.domainId,
                                          documentId: document.id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "warning",
                                          title:
                                            "Work with this document with NV version",
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "play" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "danger",
                                      title:
                                        "Delete document and related elements",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteModal(
                                          $event,
                                          document.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "trash-alt" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "modal-new",
            attrs: {
              "hide-footer": "",
              id: "modal-new",
              title: "Create New Document",
              "no-close-on-backdrop": true,
              centered: "",
            },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.updateDocument.apply(null, arguments)
                  },
                  reset: _vm.cancel,
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Name:",
                      "label-for": "name",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "name", required: "", placeholder: "Name" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Language:",
                      "label-for": "language",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.languageCodesOptions,
                        label: "text",
                        required: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function (ref) {
                            var search = ref.search
                            var searching = ref.searching
                            return [
                              searching
                                ? [
                                    _c("em", [
                                      _vm._v(
                                        "Sorry, no matching option or was already defined"
                                      ),
                                    ]),
                                    _vm._v(".\n                "),
                                  ]
                                : _c("em", [
                                    _vm._v(
                                      "Sorry, no matching option or was already defined"
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.form.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "language", $$v)
                        },
                        expression: "form.language",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "footer",
                  { staticClass: "text-right mt-4" },
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "reset", variant: "secondary" } },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "modal-clone",
            attrs: {
              "hide-footer": "",
              id: "modal-clone",
              title: "Duplicate into new Document",
              "no-close-on-backdrop": true,
              centered: "",
            },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.cloneDocument.apply(null, arguments)
                  },
                  reset: _vm.cancelClone,
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Name:",
                      "label-for": "name",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "name",
                        required: "",
                        placeholder: "New name",
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Language:",
                      "label-for": "language",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.languageCodesOptions,
                        label: "text",
                        required: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function (ref) {
                            var search = ref.search
                            var searching = ref.searching
                            return [
                              searching
                                ? [
                                    _c("em", [
                                      _vm._v(
                                        "Sorry, no matching option or was already defined"
                                      ),
                                    ]),
                                    _vm._v(".\n                "),
                                  ]
                                : _c("em", [
                                    _vm._v(
                                      "Sorry, no matching option or was already defined"
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.form.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "language", $$v)
                        },
                        expression: "form.language",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "footer",
                  { staticClass: "text-right mt-4" },
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "reset", variant: "secondary" } },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v("Duplicate")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isLoading ? _c("Loading") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }