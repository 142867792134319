var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row",
        class: {
          "scenario-row-unsaved": this.bScenarioChanged,
          "scenario-row": !this.bScenarioChanged,
          "scenario-syntax-error": _vm.dataSyntaxError,
        },
      },
      [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-1 col-sm-2 col-form-label",
                attrs: { for: "name" },
              },
              [_vm._v("Name")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-9 col-sm-7" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.versions[0].name,
                    expression: "scenario.versions[0].name",
                  },
                ],
                ref: "narras_name",
                staticClass: "form-control calc_narra_input",
                attrs: {
                  type: "text",
                  id: "name",
                  placeholder: "Category_name",
                },
                domProps: { value: _vm.scenario.versions[0].name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.scenario.versions[0],
                      "name",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-2 col-sm-3 text-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "warning",
                      title: "Restore in memory version",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.restoreInMemoryVersion()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-undo",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateScenarioValue()
                      },
                    },
                  },
                  [_c("font-awesome-icon", { attrs: { icon: "save" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.confirmDeleteScenario([
                          _vm.category,
                          _vm.scenario,
                        ])
                      },
                    },
                  },
                  [_c("font-awesome-icon", { attrs: { icon: "trash-alt" } })],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row mt-2 mb-2" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 col-1 col-form-label",
                attrs: { for: "definition_detailed" },
              },
              [_vm._v("Definition")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10 col-11" },
              [
                _c(
                  "at",
                  {
                    ref: "at",
                    attrs: {
                      ats: _vm.trigerChars,
                      members: _vm.autocomplete_members,
                      hideOnBlur: "",
                      filterMatch: _vm.filterMatch,
                      filterMembers: _vm.filterMembersAutocomplete,
                    },
                    on: { at: _vm.autocompletAt },
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "narras_text",
                        staticClass: "form-control calc_narra_input",
                        attrs: {
                          contenteditable: "",
                          spellcheck: "false",
                          id: "narras",
                          placeholder: "Definition of Scenario",
                        },
                        on: {
                          keyup: _vm.handleKeyDown,
                          blur: function ($event) {
                            return _vm.closePanel()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(this.scenario.versions[0].narra) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row mt-2 mb-2" }, [
            _c("label", { staticClass: "col-sm-2 col-1 col-form-label" }, [
              _vm._v("Description"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-10 col-11" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.versions[0].description,
                    expression: "scenario.versions[0].description",
                  },
                ],
                ref: "narras_description",
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Description" },
                domProps: { value: _vm.scenario.versions[0].description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.scenario.versions[0],
                      "description",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-0 mb-0" }, [
            _c("div", { staticClass: "col-9 text-left" }, [
              _vm.dataSyntaxError
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "Syntax Error! Fix with the assitant and save the scenario"
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm",
                  class: {
                    "btn-secondary": _vm.showDefinitionTool,
                    "btn-success": !_vm.showDefinitionTool,
                  },
                  on: { click: _vm.toggleDefinitionTool },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-wrench",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _vm.showDefinitionTool
                    ? _c("span", [_vm._v("Hide Assistant")])
                    : _c("span", [_vm._v("Show Assistant")]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.showDefinitionTool
            ? _c("div", { staticClass: "row mt-3 mb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4 offset-lg-2 col-6 offset-sm-0" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm btn-block",
                        on: { click: _vm.transferToDefinitionTool },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-arrow-down",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" View in\n              Assistant "),
                        _c("i", {
                          staticClass: "fa fa-arrow-down",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-sm btn-block",
                      on: { click: _vm.transferToDefinition },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-arrow-up",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" Copy to\n              Definition "),
                      _c("i", {
                        staticClass: "fa fa-arrow-up",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.parsingError
            ? _c("div", { staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "text-danger text-center" }, [
                    _vm._v(_vm._s(_vm.parsingError)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center parsingErrorMarked" }, [
                    _vm._v(_vm._s(_vm.parsingErrorMarked)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showDefinitionTool
            ? _c(
                "div",
                [
                  _vm._l(_vm.conditions, function (condition, conditionIndex) {
                    return _c("div", { key: condition.id }, [
                      _c(
                        "div",
                        { staticClass: "form-row mt-4 mb-1 no-gutters" },
                        [
                          _c("div", { staticClass: "col-2" }, [
                            conditionIndex > 0
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: condition.boolOperation,
                                        expression: "condition.boolOperation",
                                      },
                                    ],
                                    staticClass: "form-control form-control-sm",
                                    attrs: {
                                      id: "condition_" + conditionIndex,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          condition,
                                          "boolOperation",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", [_vm._v("and")]),
                                    _vm._v(" "),
                                    _c("option", [_vm._v("or")]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row conditionWrapper" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("div", { staticClass: "col-xl-2 col-3" }),
                            _vm._v(" "),
                            _vm._l(
                              condition.components,
                              function (component, componentIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      component.v +
                                      component.t +
                                      componentIndex,
                                    staticClass: "conditionComponent",
                                    class: {
                                      conditionTypeOperatorComparator:
                                        component.t == "operator.comparator",
                                      conditionTypeOperatorComparator:
                                        component.t == "member.valueBoolean",
                                      conditionTypeOperatorMath:
                                        component.t == "operator.math",
                                      conditionTypeDataRow:
                                        component.t == "member.data_row",
                                      conditionTypeValueNumber:
                                        component.t == "member.valueNumber",
                                      conditionTypeValue:
                                        component.t == "member.value",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row no-gutters" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "conditionComponentValue col-6 col-lg-8",
                                          },
                                          [
                                            component.t == "member.valueBoolean"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: component.v,
                                                        expression:
                                                          "component.v",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control form-control-sm",
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          component,
                                                          "v",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "True",
                                                        },
                                                      },
                                                      [_vm._v("True")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "False",
                                                        },
                                                      },
                                                      [_vm._v("False")]
                                                    ),
                                                  ]
                                                )
                                              : component.t ==
                                                "operator.comparator"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: component.v,
                                                        expression:
                                                          "component.v",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control form-control-sm",
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          component,
                                                          "v",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.condition_operations,
                                                    function (operation) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: String(
                                                            operation
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                operation
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : component.t == "operator.math"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: component.v,
                                                        expression:
                                                          "component.v",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control form-control-sm",
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          component,
                                                          "v",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.condition_maths,
                                                    function (operation) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: String(
                                                            operation
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                operation
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "at",
                                                  {
                                                    ref: "at",
                                                    refInFor: true,
                                                    attrs: {
                                                      ats: _vm.condition_trigerChars,
                                                      members:
                                                        _vm.autocomplete_members,
                                                      hideOnBlur: "",
                                                      filterMatch:
                                                        _vm.filterMatch,
                                                      filterMembers:
                                                        _vm.filterMembersAutocomplete,
                                                    },
                                                    on: {
                                                      at: _vm.autocompletAt,
                                                    },
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-control form-control-sm calc_narra_input",
                                                      attrs: {
                                                        contenteditable: "",
                                                        spellcheck: "false",
                                                        type: "text",
                                                        placeholder:
                                                          "data.expression",
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          component.v
                                                        ),
                                                      },
                                                      on: {
                                                        blur: function (event) {
                                                          _vm.updateConditionComponentOnInput(
                                                            conditionIndex,
                                                            componentIndex,
                                                            event
                                                          )
                                                          _vm.closePanel()
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "conditionComponentConfigurations col-6 col-lg-4",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row no-gutters" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "conditionComponentType col-6",
                                                  },
                                                  [
                                                    component.t
                                                      ? _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  component.t,
                                                                expression:
                                                                  "component.t",
                                                              },
                                                            ],
                                                            attrs: {
                                                              id:
                                                                "condition_component_type_" +
                                                                conditionIndex,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  component,
                                                                  "t",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "operator.comparator",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Comparator"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "operator.math",
                                                                },
                                                              },
                                                              [_vm._v("Math")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "member.data_row",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Data Row"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "member.valueNumber",
                                                                },
                                                              },
                                                              [_vm._v("Number")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "member.value",
                                                                },
                                                              },
                                                              [_vm._v("Text")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "member.valueBoolean",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Boolean"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "conditionComponentCast col-5",
                                                  },
                                                  [
                                                    component.t !=
                                                      "operator.comparator" &&
                                                    component.t !=
                                                      "operator.math"
                                                      ? _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  component.c,
                                                                expression:
                                                                  "component.c",
                                                              },
                                                            ],
                                                            attrs: {
                                                              id:
                                                                "condition_component_cast" +
                                                                "_" +
                                                                conditionIndex +
                                                                "_" +
                                                                componentIndex +
                                                                "_",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  component,
                                                                  "c",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "No Cast"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "len",
                                                                },
                                                              },
                                                              [_vm._v("Length")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "float",
                                                                },
                                                              },
                                                              [_vm._v("Float")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "int",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Integer"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "str",
                                                                },
                                                              },
                                                              [_vm._v("String")]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "conditionComponentRemove col-1 text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                           "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "link text-danger removeFilter",
                                                        attrs: {
                                                          title:
                                                            "Remove Condition",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeConditionComponent(
                                                              conditionIndex,
                                                              componentIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "trash-alt",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      " \n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c("div", { staticClass: "text-right" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger btn-sm",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCondition(
                                            conditionIndex
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-minus-circle",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v(
                                        "\n                        Condition Group\n                      "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success btn-sm",
                                      attrs: { title: "Add more component" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addNewComponentCondition(
                                            condition
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-plus-circle",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v(
                                        "\n                        Component\n                      "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "offset-md-0 col-md-6 text-left" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success btn-sm",
                            attrs: { title: "Add Condition" },
                            on: { click: _vm.addEmptyCondition },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-plus-circle",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(
                              " Condition\n                Group\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }