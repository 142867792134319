var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "light" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Alternatives Selector"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: { click: _vm.closeModal },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-div-container" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "card variations-card-columns variations-card",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Similarity")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("label", { attrs: { for: "input-similarity" } }, [
                          _vm._v("Word:"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "input-similarity",
                            placeholder: "Similarity",
                            trim: "",
                          },
                          on: {
                            keyup: function ($event) {
                              return _vm.changeSymilarity($event)
                            },
                          },
                          model: {
                            value: _vm.similarity_selection,
                            callback: function ($$v) {
                              _vm.similarity_selection = $$v
                            },
                            expression: "similarity_selection",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm.loadingSimilarity
                          ? [
                              _c("b-spinner", {
                                attrs: {
                                  variant: "primary",
                                  label: "Spinning",
                                },
                              }),
                            ]
                          : _vm.similarity_list.length > 0
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  staticClass:
                                    "overflow-auto variations-check-group",
                                  attrs: { label: "Select similarity" },
                                },
                                _vm._l(_vm.similarity_list, function (option) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        stacked: "",
                                      },
                                      model: {
                                        value: _vm.similarity_check,
                                        callback: function ($$v) {
                                          _vm.similarity_check = $$v
                                        },
                                        expression: "similarity_check",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(option.text),
                                        },
                                      }),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "info" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.includeSelected(
                                            _vm.similarity_selection,
                                            _vm.similarity_check
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Match entities")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [_vm._v(" No results found ")],
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }