import * as types from "../mutation-types";

const state = {
	jwt_token: localStorage.getItem("jwt_token"),
	username: localStorage.getItem("username")
};

const getters = {
	getJwtToken (state) {
		return state.jwt_token;
	},
	getUsername (state) {
		return state.username;
	}
};

const mutations = {
	[types.SET_JWT_TOKEN] (state, payload) {
		state.jwt_token = payload;
	},
	[types.SET_USERNAME] (state, payload) {
		state.username = payload;
	}
};

export default {
	state,
	getters,
	mutations
};
