<template>
	<div id="mySidenav" class="sidenav" :style="styleWidth" @keydown.esc="close">
		<a
			href="javascript:void(0)"
			class="closebtn badge badge-success sticky-top"
			@click="close()"
			>&times;</a
		>

		<slot></slot>
		<!--a href="#">About</a>
		<a href="#">Services</a>
		<a href="#">Clients</a>
		<a href="#">Contact</a-->
	</div>
</template>

<script>
export default {
	name: "SideMenu",
	data () {
		return {
			styleWidth: ""
		};
	},
	props: {
		width: {
			type: String,
			default: "250px"
		}
	},
	methods: {
		open () {
			//openSideMenu(this.width);
			this.styleWidth = "width: " + this.width;
		},
		close () {
			this.styleWidth = "width: 0px";
		}
	}
};

function openSideMenu (width) {
	document.getElementById("mySidenav").style.width = width;
}
</script>

<style>
/* The side navigation menu */
.sidenav {
	height: 100%; /* 100% Full-height */
	width: 0; /* 0 width - change this with JavaScript */
	position: fixed; /* Stay in place */
	z-index: 1500; /* Stay on top */
	top: 0; /* Stay at the top */
	right: 0;
	background-color: #e2e8f0d2; /* Black*/
	overflow-x: hidden; /* Disable horizontal scroll */
	padding-top: 35px; /* Place content 60px from the top */
	transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
	position: absolute;
	top: 0;
	margin-top: 5px;
	right: 25px;
	font-size: 30px;
	margin-left: 50px;
}
</style>
