<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="light">
			<b-navbar-brand href="#">Alternatives Selector</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click="closeModal">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>
		<br />
		<div class="modal-div-container">
			<b-row>
				<b-col cols="12">
					<div class="card variations-card-columns variations-card">
						<div class="card-header variations-header">Similarity</div>
						<div class="card-body">
							<label for="input-similarity">Word:</label>
							<b-form-input
								id="input-similarity"
								v-model="similarity_selection"
								placeholder="Similarity"
								trim
								@keyup="changeSymilarity($event)"
							></b-form-input>
							<br />
							<template v-if="loadingSimilarity">
								<b-spinner variant="primary" label="Spinning"></b-spinner>
							</template>
							<template v-else-if="similarity_list.length > 0">
								<b-form-group
									label="Select similarity"
									class="overflow-auto variations-check-group"
								>
									<b-form-checkbox
										v-for="option in similarity_list"
										v-model="similarity_check"
										:key="option.value"
										:value="option.value"
										stacked
										><span v-html="option.text" />
									</b-form-checkbox>
								</b-form-group>
								<div class="text-right">
									<b-button
										variant="info"
										@click.prevent="
											includeSelected(similarity_selection, similarity_check)
										"
										>Match entities</b-button
									>
								</div>
							</template>
							<template v-else> No results found </template>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import minerva_api from "../../../api/minerva.js";

export default {
	name: "SimilaritySelector",
	data () {
		return {
			similarity_selection: "",
			selection_text: "",
			similarity_list: [],
			similarity_check: [],
			loadingSimilarity: false,
			timeout: null
		};
	},
	props: {
		domain_id: String,
		minerva_url: String,
		minerva_server: String,
		language: String,
		modal_name: String
	},
	created () {
		//console.log("create");
	},
	computed: {
		selection () {
			return this.$store.getters.getSelectionOnTemplate;
		}
	},
	methods: {
		closeModal () {
			this.$modal.hide(this.modal_name);
		},
		changeSymilarity ($event) {
			//clear timeout variable
			clearTimeout(this.timeout);

			if ($event.keyCode == 13) {
				this.getListSymilarity();
			} else {
				let self = this;
				this.timeout = setTimeout(function () {
					//enter this block of code after 1 second
					//handle stuff, call search API etc.
					console.log("changeSymilarity:");

					self.getListSymilarity();
				}, 1000);
			}
		},
		async getListSymilarity () {
			this.loadingSimilarity = true;

			let [data, err] = await minerva_api.similarity(
				this.$store.getters.getCurrentDomain.id,
				this.language,
				this.similarity_selection
			);
			//console.log("similarity", data);

			let list_options = [];
			if (!err) {
				let words = this.similarity_selection.split(" ");
				list_options = [];
				const classes = [
					"badge-template badge-data-template",
					"badge-template badge-subtemplate-template",
					"badge-template badge-synonym-template"
				];
				[...data].forEach((d) => {
					let replaced = d.sentence.toLowerCase();
					let ind_class = 0;
					[...words].forEach((word) => {
						if (replaced.indexOf(word) > -1) {
							const regex = new RegExp("\\b" + word.toLowerCase() + "\\b", "g");
							//console.log(d);
							replaced = replaced.replace(
								regex,
								"<span class='" + classes[ind_class] + "'>" + word + "</span>"
							);
							ind_class++;
							if (ind_class >= classes.length) ind_class = 0;
						}
					});
					//console.log(replaced);
					list_options.push({
						text: replaced,
						value: d.sentence
					});
				});
			}
			this.similarity_list = list_options;
			this.loadingSimilarity = false;
		},
		includeSelected (text, selectedItems) {
			//construc the synonyms list like {synom1|synom2|synom3|...}
			//console.log(selectedItems);
			this.$emit("includeSimilariry", selectedItems);
		}
	}
};

function getUnique (arr, comp) {
	const unique = arr
		.map((e) => e[comp])

		//store the keys of the unique objects
		.map((e, i, final) => final.indexOf(e) === i && i)

		//eliminate the dead keys & store unique objects
		.filter((e) => arr[e])
		.map((e) => arr[e]);

	return unique;
}
</script>
