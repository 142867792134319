import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class FormulaGrammar extends BaseClient {
	constructor () {
		super(httpClient, "/formula/");
	}

	getFormulaGrammar () {
		return this.get("grammar");
	}
}

export default new FormulaGrammar();
