<template>
  <div>
    <div v-bind:class="getClassForTemplate(template)">
      <a :name="template.id" ref="anchor"></a>

      <div class="card-header template-header pt-0 pb-0 pl-2">
        <b-row align-v="center" class="template-row-header">
          <b-col cols="9" class="m-0 p-0 pl-2 pt-1">
            <label
              class="template-cell-type"
              contenteditable
              spellcheck="false"
              @blur="updateVersion($event, 0, template.versions[0])"
              v-text="template.versions[0].type"
              ref="lblType"
            ></label>

            <template v-if="template.versions[0].type[0] != '_'">
              <a
                v-if="!template.narra_tree_nodes_version.length"
                href="#"
                @click.prevent="openSelectNarra($event, template)"
                class="template-circle-button"
                title="Asign Segment to a Scenario"
              >
                <div class>
                  <font-awesome-icon icon="plus" />
                </div>
              </a>

              <a
                v-else
                v-for="narra_version in template.narra_tree_nodes_version"
                href="#"
                @click.prevent="
                  confirmDeleteNarraFromTemplate(
                    $event,
                    template,
                    narra_version
                  )
                "
                class="col-6"
              >
                <span v-for="node in treePath">
                  <span class="badge-narra-parent">{{ node }}</span> &gt;
                </span>
                <span class="badge-narra">{{ narra_version.name }}</span>
              </a>
            </template>
            <b-form-select
              v-if="template.versions[0].type[0] == '_'"
              class="input-select-weigth"
              v-model="weigth"
              @change="updateVersion($event, 2, template.versions[0])"
              :options="weigth_options"
              size="sm"
            ></b-form-select>
          </b-col>
          <!--b-col cols="2" class="text-right pr-0 align-middle">

          </b-col-->
          <b-col cols="3" class="text-right pr-0 align-middle">
            <a href="#" @click.prevent="showHistory">
              <i class="fas fa-history template-circle-button"></i>
              <b-badge
                class="template-updated-at m-0 p-0"
                variant="light"
                v-text="updated_at"
              />
            </a>
            <ButtonCircle
              class="template-preview-icon"
              ref="ButtonEye"
              :template="template"
              @click="previewTemplate($event, 'ButtonEye', false, 1)"
              icon="play"
              title="Run this segment with selected data file"
            />

            <a
              href="#"
              @click.prevent="$emit('filterByTemplateID', template)"
              class="template-preview-icon template-circle-button"
              title="Filter to show only this Segment"
            >
              <div class>
                <font-awesome-icon icon="filter" size="sm" />
              </div>
            </a>

            <!-- <a
              href="#"
              @click.prevent="showSimilarity($event)"
              class="template-preview-icon template-circle-button"
              title="Inspiration"
            >
              <div class>
                <font-awesome-icon icon="lightbulb" size="sm" />
              </div>
            </a> -->

            <a
              href="#"
              @click.prevent="translate($event)"
              class="template-preview-icon template-circle-button"
              title="Translate"
            >
              <div class>
                <font-awesome-icon icon="language" size="sm" />
              </div>
            </a>

            <b-dropdown
              id="rowTemplatesExtraOptions"
              size="sm"
              variant="link"
              toggle-class="text-decoration-none m-0 p-0"
              no-caret
              title="Extra options"
            >
              <template v-slot:button-content>
                <span class="template-circle-button">
                  <font-awesome-icon icon="ellipsis-v" size="sm" />
                </span>
              </template>

              <b-dropdown-item
                ref="ButtonEye5X"
                @click="previewTemplate($event, 'ButtonEye', false, 5)"
                >Preview 5x</b-dropdown-item
              >
              <b-dropdown-item
                ref="ButtonEye20X"
                @click="previewTemplate($event, 'ButtonEye', false, 20)"
                >Preview 20x</b-dropdown-item
              >

              <b-dropdown-item
                ref="ButtonEye50X"
                @click="previewTemplate($event, 'ButtonEye', false, 50)"
                >Preview 50x</b-dropdown-item
              >

              <b-dropdown-item
                ref="RealPreview"
                @click="previewTemplate($event, 'ButtonEye', false, 1, 'false')"
                >Real Preview</b-dropdown-item
              >
              <b-dropdown-item
                ref="RealPreview"
                @click="previewTemplate($event, 'ButtonEye', false, 5, 'false')"
                >Real Preview 5x</b-dropdown-item
              >
              <b-dropdown-item
                ref="RealPreview"
                @click="
                  previewTemplate($event, 'ButtonEye', false, 20, 'false')
                "
                >Real Preview 20x</b-dropdown-item
              >
              <b-dropdown-item
                ref="RealPreview"
                @click="
                  previewTemplate($event, 'ButtonEye', false, 50, 'false')
                "
                >Real Preview 50x</b-dropdown-item
              >

              <!-- <b-dropdown-item
                ref="EntitiesExtractor"
                @click="entitiesExtractor"
                >Entities Extractor</b-dropdown-item
              > -->

              <b-dropdown-item
                ref="ButtonDuplicate"
                @click="$emit('duplicateTemplate', template)"
                >Duplicate</b-dropdown-item
              >

              <b-dropdown-item
                ref="ButtonDelete"
                @click="deleteTemplate($event)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
            <b-form-checkbox
              class="switch-render-html inline m-0"
              v-model="renderHTML"
              name="check-renderHTML"
              size="sm"
              inline
              title="Render HTML toggle"
              >HTML</b-form-checkbox
            >
            <b-form-checkbox
              class="switch-render-html inline m-0"
              v-model="showTemplatesLinks"
              name="check-showTemplatesLinks"
              size="sm"
              inline
              v-if="renderHTML"
              title="Show Links to Segments in the preview text"
              >Links</b-form-checkbox
            >
						<b-form-checkbox
							class="switch-render-html inline"
							@change="saveIsPrompt(template.is_prompt)"
							v-model="template.is_prompt"
							name="check-renderHTML"
							size="sm"
							inline
							title="Render HTML toggle"
						>Is Prompt</b-form-checkbox
						>
            <b-badge
              class="template-id-number text-right m-0 p-0"
              variant="light"
              v-text="template.id"
            />
          </b-col>
        </b-row>
      </div>

      <b-row class="template-row-text">
        <b-col v-if="editMode" cols="6" class="template-cell-text card-body">
          <at
            :ats="triger_chars"
            :members="autocomplete_members_in_templates"
            hideOnBlur
            @at="autocompletAt"
            :filterMatch="filterMatch"
            :filterMembers="filterMembersAutocompleteInTemplates"
            :ref="'at' + template.id"
          >
            <Highlightable
              :ref="'Highlightable' + template.id"
              @onVariations="openPopupVariations"
              @onCreateTempByText="createTempByText"
              @onParaphrasing="openPopupParaphrasing"
            >
              <highlightable-input
                highlight-style="badge-data-template"
                highlight-enabled
                :highlight="highlight"
                :refreshHighlights="refreshHighlights"
                v-model="template.versions[0].text_for_editor"
                @mouseup="selectionText($event, template.versions[0])"
                @keyup="handleTextKeyDown($event, template)"
                @blur="textTemplateLostFocus($event, template)"
                @focus="textTemplateGetFocus($event, template)"
                :ref="'text' + template.id"
                :category_id="category_id"
                canEdit
              />
            </Highlightable>
          </at>
        </b-col>
        <b-col v-else cols="6" class="template-cell-text card-body text-danger">
          <Highlightable
            :ref="'Highlightable' + template.id"
            @onVariations="openPopupVariations"
            @onCreateTempByText="createTempByText"
            @onParaphrasing="openPopupParaphrasing"
          >
            <highlightable-input
              highlight-style="badge-data-template"
              highlight-enabled
              :highlight="highlight"
              :refreshHighlights="refreshHighlights"
              v-model="template.versions[0].text_for_editor"
              @mouseup="enableEditing"
              :category_id="category_id"
            />
          </Highlightable>
        </b-col>

        <b-col cols="6" class="template-col-preview">
          <p class="" v-if="variability">
            <span class="badge-template badge-data-template">
              Variability:
              <span class="variability-percent">{{ variability }}%</span>
            </span>
          </p>
          <div v-for="preview in previews" class="template-row-preview">
            <template v-if="!renderHTML">
              <template v-if="!preview.error">
                <div
                  v-if="preview.log.length > 0"
                  class="error-invalid-scenario"
                >
                  <div v-for="(error, index) in preview.log">
                    <div>{{ error.message }}:</div>
                    <div>- {{ error.details }}</div>

                    <br v-if="preview.log[index + 1]" />
                  </div>
                </div>
                <div
                  v-if="!preview.error"
                  class="template-cell-preview-result"

                >
									<HTMLValidator :html_text="preview.result" > </HTMLValidator>
								</div>
              </template>

              <template v-else class="template-cell-preview-error">
                <font-awesome-icon
                  icon="exclamation"
                  class="template-cell-preview-error-icon"
                />
                <span
                  v-html="preview.error"
                  class="template-cell-preview-error"
                ></span>
              </template>
            </template>
            <template v-else>
              <!--div
								v-if="preview.logs_formatted.length > 0"
								class="error-invalid-scenario"
								v-html="preview.logs_formatted"
              ></div-->

              <template v-if="!preview.error">
                <div
                  v-if="preview.log.length > 0"
                  class="error-invalid-scenario"
                >
                  <div v-for="(error, index) in preview.log">
                    <div>{{ error.message }}:</div>
                    <div>- {{ error.details }}</div>

                    <br v-if="preview.log[index + 1]" />
                  </div>
                </div>
                <div
                  class="template-cell-preview-result"
                  v-html="preview.result"
                  v-if="!showTemplatesLinks"
                  @mouseup="selectionText($event, template.versions[0])"
                ></div>
                <div
                  class="template-cell-preview-result"
                  v-html="preview.result_with_sandbox_tags"
                  v-else
                  @mouseup="selectionText($event, template.versions[0])"
                ></div>
              </template>
              <template v-else>
                <font-awesome-icon
                  icon="exclamation"
                  class="template-cell-preview-error-icon"
                />
                <span
                  v-html="preview.error"
                  class="template-cell-preview-error"
                  @mouseup="selectionText($event, template.versions[0])"
                ></span>
              </template>
            </template>

            <hr
              class="hr-template-cell-preview-result"
              v-if="translates.length > 0"
            />
          </div>
          <div
            v-if="translates.length > 0"
            class="row segment_preview_row mt-2"
          >
            <div class="col">
              <div class="template-col-previewnv">
                <p class="template-row-preview-nv">
                  <span
                    @click="translate"
                    title="Translate segment"
                    class="collapseControl"
                    ><i class="fas fa-language fa-2x"></i
                  ></span>
                </p>
                <div
                  v-for="translate in translates"
                  class="template-row-preview-nv"
                >
                  <!--img src="@/assets/imgs/NarrativaLogoPNG_50h_black.png" style="height:20px;" />
									<div
										v-if="translate.text_dest_narrativa"
										class="template-cell-preview-result"
										v-html="translate.text_dest_narrativa"></div>
									<i class="fa fa-google"></i-->
                  <div
                    v-if="translate.text_dest"
                    class="template-cell-preview-result"
                    v-html="translate.text_dest"
                  ></div>
                  <hr class="hr-template-cell-preview-result" />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <modal height="950" width="900" :name="modalSelecionTextName">
        <VariationsSelector
          :domain_id="currentDomain.id"
          :minerva_url="currentDomain.minerva_url"
          :minerva_server="currentDomain.minerva_server"
          :language="currentDocument.language"
          :modal_name="modalSelecionTextName"
          ref="VariationsSelector"
          @selectedItems="insertVariations"
        />
      </modal>

      <modal height="950" width="900" :name="modalParaphrasingName">
        <ParaphrasingSelector
          :domain_id="currentDomain.id"
          :documenta_data_id="this.document_data_selected"
          :language="currentDocument.language"
          :modal_name="modalParaphrasingName"
          ref="ParaphrasingSelector"
          @selectedItems="insertVariations"
        />
      </modal>

      <modal height="950" width="900" :name="modalSimilarityName">
        <SimilaritySelector
          :domain_id="currentDomain.id"
          :minerva_url="currentDomain.minerva_url"
          :minerva_server="currentDomain.minerva_server"
          :language="currentDocument.language"
          :modal_name="modalSimilarityName"
          ref="VariationsSelector"
          @includeSimilariry="insertSimilarity"
        />
      </modal>

      <modal
        height="700"
        width="1200"
        :name="modalEntitiesName"
        class="entities-modal"
      >
        <EntitiesSelector
          ref="EntitiesSelector"
          :language="currentDocument.language"
          :texts="textEntities"
          :modal_name="modalEntitiesName"
          :insert="entitiesNewTemplates"
          @includeEntities="insertEntities"
          @includeEntitiesTemplate="insertEntitiesTemplate"
        />
      </modal>

      <modal height="950" width="900" :name="modalHistoryName">
        <TemplatesHistory
          :templateId="template.id"
          :modal_name="modalHistoryName"
          @setCurrentVersion="setCurrentVersion"
        />
      </modal>

      <b-modal
        scrollable
        height="5000"
        width="60%"
        resizable
        draggable
        size="xl"
        :name="narraSelectorModalName"
        :id="narraSelectorModalName"
				centered
      >
        <template v-slot:modal-header="{ hide }"></template>

        <NarrasTreesViewer
          collapse_button
          tree_editor_button="false"
          select_name="true"
          :rows="rows"
          @selectNarra="selectNarra"
          :template_name_filter="template.versions[0].type"
        ></NarrasTreesViewer>
        <template v-slot:modal-footer="{ ok, cancel, hide }">
          <b></b>
        </template>
      </b-modal>

      <b-modal
        height="5000"
        draggable
        size="lg"
        ref="modalGetTypeForNewVersion"
        centered
        hide-footer
        hide-header
				centered
      >
        <b-form-group
          id="input-group-2"
          label="New segment name:"
          label-for="type"
          label-align="left"
        >
          <b-form-input
            id="type"
            required
            v-model="typeTempByText"
            placeholder="Name"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-5" label-align="left">
          <b-form-checkbox
            v-model="associateDefaultByText"
            id="active"
            value="true"
            unchecked-value="false"
            >Create associated with default scenario</b-form-checkbox
          >
        </b-form-group>
        <b-button
          :disabled="!typeTempByText.length"
          variant="primary"
          v-on:click="createTempByTextWithType"
          >Save</b-button
        >

        <b-button
          type="reset"
          variant="danger"
          v-on:click="$refs['modalGetTypeForNewVersion'].hide()"
          >Cancel</b-button
        >
      </b-modal>
    </div>

    <ButtonCircle
      v-if="parent"
      :ref="'ButtonCollapse' + template.id"
      :template="template"
      icon="angle-down"
      icon_second="angle-up"
      @click="toggleCollapse"
      customClass="button-collase-template"
    />
  </div>
</template>

<script>
import template_version_api from "../../api/template_version.js";
import templates_api from "../../api/template.js";
import narras_templates_api from "../../api/narras_tree_templates";
import preview_api from "../../api/preview";
import tree_nodes_api from "../../api/narras_tree_nodes.js";
import tree_nodes_versions_api from "../../api/narras_tree_nodes_versions.js";
import scenario_categories_api from "../../api/scenario_categories.js";
import scenario_api from "../../api/scenario.js";
import translate_api from "../../api/translate.js";
import { htmlDecode, getPosEL } from "../../utils/common.js";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import { autocompleteMixins } from "@/mixins/autocompleteMixins.js";
import { templateVersionsMixins } from "@/mixins/templateVersionsMixins.js";
import At from "@/components/UI/AutoComplete/At.vue";
import VariationsSelector from "@/components/UI/Variations/VariationsSelector.vue";
import ParaphrasingSelector from "@/components/UI/Variations/ParaphrasingSelector.vue";
import SimilaritySelector from "@/components/UI/Variations/Similarity.vue";
import EntitiesSelector from "@/components/Entities/Entities.vue";
import Highlightable from "@/components/UI/Highlightable/Highlightable.vue";
import HighlightableInput from "@/components/UI/Highlightable/HighlightableInput";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import NarrasTreesViewer from "@/components/narras/NarrasTreesViewer";
import TemplatesHistory from "@/components/templates/TemplatesHistory";
import HTMLValidator from "@/components/templates/HTMLValidator";

export default {
	name: "RowTemplate",
	mixins: [
		alertMixins,
		templatesMixins,
		narrasMixins,
		autocompleteMixins,
		templateVersionsMixins
	],
	components: {
		At,
		VariationsSelector,
		SimilaritySelector,
		Highlightable,
		HighlightableInput,
		ButtonCircle,
		NarrasTreesViewer,
		EntitiesSelector,
		ParaphrasingSelector,
		TemplatesHistory,
		HTMLValidator
		//LevelRowTemplate
	},
	props: {
		template: { type: Object },
		template_root: { type: Object, default: null },
		currentDocument: { type: Object },
		currentDomain: { type: Object },
		document_data_selected: { type: Number, default: 0 },
		level: { type: Number, default: 0 },
		category_id: {},
		active_autocomplete: { type: Boolean, default: true },
		active_preview: { type: Boolean, default: true },
		refreshHighlights: { type: Boolean, default: true },
		collapseID: { type: String },
		parent: { type: Boolean, default: false },
		autoHighlights: { type: Boolean, default: true }
	},
	watch: {
		active_autocomplete: function (val) {
			//this.active_autocomplete = !this.active_autocomplete;
			this.triger_chars = val
				? ["[", "(", "|", "--", ".", "__sort__", "__len__"]
				: [];
		}
	},
	created () {
		this.weigth = this.template.versions[0].weigth;
	},
	data () {
		return {
			highlight: [
				{ text: /{(.*?.*?)}/g, style: "badge-template badge-synonym-template" }
			],
			triger_chars: ["[", "(", "|", "--", ".", "__sort__", "__len__"],
			renderHTML: true,
			showTemplatesLinks: false,
			timeout: null,
			timeout_preview: null,
			template_versions_variation: "",
			selectionWordText: {},
			navigate_here: false,
			typeTempByText: "",
			associateDefaultByText: "true",
			newTemplateByType: {},
			previews: [],
			translates: [],
			variability: false,
			weigth_options: [
				{ value: 30, text: "High" },
				{ value: 20, text: "Normal" },
				{ value: 10, text: "Low" }
			],
			weigth: 20,
			textEntities: [],
			entitiesNewTemplates: false,
			editMode: true
		};
	},
	watch: {
		template () {
			this.previews = [];
			this.translates = [];
		}
	},
	computed: {
		updated_at () {
			const localDate = new Date(this.template.versions[0].updated_at);
			return localDate.toLocaleString("es", {
				day: "numeric", //numeric, 2-digit
				month: "numeric", //numeric, 2-digit, long, short, narrow
				year: "numeric", //numeric, 2-digit
				hour: "2-digit", //numeric, 2-digit
				minute: "numeric", //numeric, 2-digit
				//second: 'numeric', // numeric, 2-digit
				hour12: false
			});
		},
		rows () {
			return this.$store.getters.getRowsNarras;
		},
		narraSelectorModalName () {
			return "NarraSelectorModal" + this.$_uid;
		},
		modalSelecionTextName () {
			return "modalSelecionText" + this.$_uid;
		},
		modalParaphrasingName () {
			return "modalParaphrasing" + this.$_uid;
		},
		modalSimilarityName () {
			return "modalSimilarityName" + this.$_uid;
		},
		modalEntitiesName () {
			return "modalEntitiesName" + this.$_uid;
		},
		modalHistoryName () {
			return "modalHistoryName" + this.$_uid;
		},
		//esta funcion obtiene la ruta de completa del arbol de scenarios del template actual
		treePath () {
			try {
				if (
					this.template.narra_tree_nodes_version != undefined &&
          this.template.narra_tree_nodes_version.length > 0
				) {
					this.template.narra_tree_nodes_version[0].name = this.getScenarioByID(
						this.template.narra_tree_nodes_version[0].scenario_id,
						this.template.narra_tree_nodes_version[0].name
					);

					if (this.template.narra_tree_nodes_version[0].parent_id) {
						let parents = this.getTreeNarraParent(
							this.template.narra_tree_nodes_version[0].parent_id
						);
						return parents.reverse();
					}
				}
			} catch (err) {
				console.log(err);
			}
			return "";
		}
	},
	methods: {
		async translate ($event) {
			await this.previewTemplate($event, "ButtonEye", "false");
			console.log("preview translate ", this.previews);
			for (let i = 0; i < this.previews.length; i++) {
				const [data, err] = await translate_api.translate(
					this.previews[0].result,
					this.currentDocument.language
				);
				console.log("translate", data, err);
				this.translates.push(data);
			}
		},
		autocompletAt (index, chunk) {},
		async updateVersion (event, field_num, version) {
			version = this.getTempalteVersionObjChanged(version, field_num, event);

			this.updateTemplate(version);
			/*if (this.autoHighlights)
				this.$refs["text" + this.template.id].processHighlights();*/
			//
		},
		async saveIsPrompt (isPrompt) {
			console.log("saveIsPrompt", isPrompt, "template", this.template);
			const [response, error] = await templates_api.updateTemplate(
				this.template,
			);
			// if (!error) {
			// 	this.template.is_prompt = response.is_prompt;
			// }
		},
		async updateTemplate (version) {
			if (version) {
				console.log("POST TEMPLATE VERSION", version);
				this.isLoading = false;
				//this.template.versions[0].original_text = version.text_for_editor;

				const [data, err] = await template_version_api.createVersionWithAlias(
					version,
					this.$store.getters.getCurrentDomain.id
				);

				if (!err) {
					console.log("Emite evento updateTemplate");
					this.template.versions[0].updated_at = data.updated_at;
					//this.updateChildsTemplates(data.subtemplates);
					this.$emit("updateTemplate");
				} else {
					return this.showApiRequestErrorAlert(err);
				}

				this.isLoading = false;

				return data;
			}
		},
		setCurrentVersion (template) {
			template.versions[0].current_version = true;
			this.template.versions[0].text_for_editor =
        template.versions[0].text_for_editor;

			this.updateTemplate(template.versions[0]);
			this.$modal.hide(this.modalHistoryName);
		},
		confirmDeleteNarraFromTemplate ($event, template, narra_version) {
			this.showDeleteModal(
				[template, narra_version],
				this.deleteNarraFromTemplateFromTemplate,
				this.deleteNarraFromTemplateFromTemplateCancel
			);
		},
		async deleteNarraFromTemplateFromTemplate (data_list) {
			this.isLoading = true;

			let template = data_list[0];
			let narra_version = data_list[1];

			const [data, err] = await narras_templates_api.remove(
				narra_version.narra_tree_node_id,
				template.id
			);
			if (err) {
				console.log("Delete ", err);
				return this.showApiRequestErrorAlert(err);
			}
			template.narra_tree_nodes_version =
        template.narra_tree_nodes_version.filter(function (obj) {
        	return obj.narra_tree_node_id !== narra_version.narra_tree_node_id;
        });

			//recorremos las rows hasta encontrar el ID del narra y le quitamos el template
			removeTemplateFromNarrasRows(template, narra_version, this.rows);

			this.isLoading = false;
		},
		deleteNarraFromTemplateCancel (data) {
			this.isLoading = false;
		},
		async openPopupVariations (selectedText) {
			if (this.document_data_selected != 0) {
				//hacemos preview de la plantilla para obtener un texto válido para pasar a los modelos de IA
				const [data, err] = await this.make_preview_request(false);
				if (!err) {
					//guardamos la respuesta en Vuex para usar en el componente de VariationsSelector
					this.$store.commit("SET_PREVIEW_VARIATIONS", data);
					this.$modal.show(this.modalSelecionTextName);
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
		},
		async openPopupParaphrasing (selectedText) {
			if (this.document_data_selected != 0) {
				this.isLoading = true;
				//hacemos preview de la plantilla para obtener un texto válido para pasar a los modelos de IA
				const [data, err] = await this.make_preview_request(false);
				this.isLoading = false;
				if (!err) {
					//guardamos la respuesta en Vuex para usar en el componente de VariationsSelector
					this.$store.commit("SET_PREVIEW_PARAPHRASING", data);
					this.$modal.show(this.modalParaphrasingName);
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
		},
		createTempByText (selectedText) {
			//this.$emit("createTempByText", this.template, selectedText);
			const version = {
				type: "",
				narras: "",
				weigth: 0,
				text: selectedText,
				template_id: this.template.id,
				active: true
			};
			this.typeTempByText = "";
			this.associateDefaultByText = "true";
			this.newTemplateByType = { versions: [version] };
			this.$refs["modalGetTypeForNewVersion"].show();
		},
		async createDefaultScenarioIfNotExists (domain_id) {
			let categories = this.$store.getters.getScenarios;
			const default_scenario = categories.filter(function (scenario) {
				return scenario.name == "DEFAULT";
			});

			console.log("default_scenario", default_scenario);

			if (default_scenario.length == 0) {
				//se crea si no existe
				let cat = {
					name: "DEFAULT",
					domain_id: domain_id
				};

				let [category, err] = await scenario_categories_api.createCategory(cat);
				console.log(category, err);
				console.log("category.id", category.id);
				if (!err) {
					const scenario = {
						active: true,
						domain_id: domain_id,
						scenario_category: category.id,
						versions: [
							{
								name: "DEFAULT",
								narra: "",
								description: "DEFAULT",
								active: true,
								current_version: true
							}
						]
					};

					const [data_scenario, err_scenario] =
            await scenario_api.createScenario(scenario);
					console.log(data_scenario, err_scenario);
					if (!err_scenario) {
						category.scenarios = [];
						category.scenarios.push(data_scenario);

						categories.push(category);
						console.log("categories", categories);
						this.$store.commit("SET_SCENARIOS", categories);

						return category;
					}
				}
			} else {
				return default_scenario[0];
			}
		},
		async createRootNarraForTemplateIfNotExists (template_name) {
			let root_narras = this.$store.getters.getRootNarras;

			const narras = root_narras.nodes.filter(function (node) {
				return node.versions[0].name == template_name;
			});

			if (narras.length == 0) {
				let root_narra = await this.createNarraTreeNode(template_name);
				let row_narras = this.$store.getters.getRowsNarras;

				console.log("row_narras", row_narras);
				row_narras[root_narra.id] = [];
				this.$store.commit("SET_ROWS_NARRAS", row_narras);

				return root_narra;
			} else {
				return narras[0];
			}
		},
		async createChildNarraForDefaultScenarioIfNotExists (
			root_narra_tree,
			scenario_default
		) {
			let row_narras = this.$store.getters.getRowsNarras;

			let root = row_narras[root_narra_tree.id];

			if (root.length > 0) {
				for (let i = 0; i < root.length; i++) {
					if (root[i].name == "DEFAULT") {
						return root[i].data_node;
					}
				}
			}

			//Hay que crear el nodo del árbol para default
			console.log("Add node");

			let id_tree_node = root_narra_tree.id;
			let data_node_tree;
			let data, err;

			let node = {
				domain_id: this.currentDomain.id,
				active: true
			};

			console.log("New node");

			[data, err] = await tree_nodes_api.create(node);
			if (!err) {
				data_node_tree = data;
				id_tree_node = data_node_tree.id;

				let version = {
					narra_tree_node_id: id_tree_node,
					parent_id: root_narra_tree.id,
					name: "DEFAULT",
					scenario_id: scenario_default.scenarios[0].id,
					description: "",
					current_version: true,
					active: true
				};

				console.log(version);

				[data, err] = await tree_nodes_versions_api.create(version);

				if (!err) {
					data_node_tree.versions = [data];

					let tree_row = {
						_children: [],
						narras: null,
						scenario_id: version.scenario_id,
						name: "DEFAULT",
						data_node: data_node_tree,
						_showChildren: true
					};

					root.push(tree_row);
					this.$store.commit("SET_ROWS_NARRAS", row_narras);

					return data_node_tree;

					/*
					"_children": [],
					"narras": null,
					"scenario_id": 1630,
					"name": "prueba1",
					"data_node": {
						"id": 4952,
						"domain_id": 90,
						"active": true,
						"versions": [{
							"id": 6065,
							"narra_tree_node_id": 4952,
							"parent_id": 4951,
							"scenario_id": 1630,
							"name": "prueba1",
							"narras": null,
							"description": "",
							"current_version": true,
							"active": true,
							"user": ""
						}],
						"templates": null,
						"children": []
					},
					"_showChildren": true
			*/
				}
			}

			return null;
		},
		async createTempByTextWithType () {
			//this.addRow(template.order, newTemplate);
			this.$refs["modalGetTypeForNewVersion"].hide();

			if (this.associateDefaultByText == "true") {
				const scenario_default = await this.createDefaultScenarioIfNotExists(
					this.currentDomain.id
				);
				console.log("scenario_default", scenario_default);

				const root_narra_tree =
          await this.createRootNarraForTemplateIfNotExists(this.typeTempByText);
				console.log("root_narra_tree", root_narra_tree);

				const child_narra_tree =
          await this.createChildNarraForDefaultScenarioIfNotExists(
          	root_narra_tree,
          	scenario_default
          );
				console.log("child_narra_tree", child_narra_tree);

				this.loadNarras(this.currentDomain.id, this.currentDocument.id);

				this.newTemplateByType.narra_tree_nodes_version =
          child_narra_tree.versions;
			}

			this.newTemplateByType.versions[0].type = this.typeTempByText;

			const text = "[" + this.typeTempByText + "]";
			console.error(
				"createTempByTextWithType",
				text,
				this.selectionWordText,
				this.selectionObject
			);

			const r = this.selectionObject.range;
			r.deleteContents();
			let node = r.endContainer;

			if (node.data) {
				const cut = r.endOffset;
				node.data = node.data.slice(0, cut) + text + node.data.slice(cut);
				r.setEnd(node, cut + text.length);
			} else {
				try {
					let newNode = document.createElement("span");
					newNode.innerHTML = text;
					r.insertNode(newNode);
				} catch (e) {
					this.showErrorAlert("Error: " + e);
				}
			}

			r.collapse(false);
			const selection = window.getSelection();
			if (selection) {
				selection.removeAllRanges();
				selection.addRange(r);
			}

			let innerText = node.innerText;
			if (node.className != "template-cell-text-input p-1") {
				while (node.parentNode.className != "template-cell-text-input p-1") {
					node = node.parentNode;
				}
				innerText = node.parentNode.innerText;
			}

			this.$refs["text" + this.template.id].internalValue = innerText.replace(
				/\|/g,
				"|"
			);

			this.updateVersion(this.selectionObject, 3, this.template.versions[0]);

			//this.newTemplateByType;
			this.$emit("createTempByText", this.newTemplateByType);

			//metemos un retardo para procesar el resaltado para esperar que se produzca la openración en el back y en el store
			self = this;
			setTimeout(function () {
				if (this.autoHighlights) { self.$refs["text" + self.template.id].processHighlights(); }
			}, 500);

			this.typeTempByText = "";
		},
		enableEditing () {
			/*console.log("enableEditing");
			this.editMode = true;

			let self = this;
			setTimeout(function () {
				console.log("focus");
				self.$refs["text" + self.template.id].focus();
			}, 100);*/
		},
		textTemplateGetFocus ($event, template_obj) {
			console.log("textTemplateGetFocus");
			console.log("textTemplateGetFocus", template_obj.versions[0]);
			//template_obj.versions[0].text_for_editor = template_obj.versions[0].original_text;
		},
		textTemplateLostFocus ($event, template_obj) {
			console.log("textTemplateLostFocus");
			const template = JSON.parse(JSON.stringify(template_obj));

			let panelRef = this.$refs["at" + template.id];
			if (Array.isArray(panelRef)) {
				panelRef = panelRef[0];
			}
			panelRef.closePanel();

			//clearTimeout(this.timeout);
			//clearTimeout(this.timeout_preview);

			let self = this;

			/*self.$refs["text" + template.id].processHighlights();

			self.updateVersion($event, 3, template.versions[0]);

			if (self.document_data_selected != 0 && self.active_preview) {
				self.previewTemplate($event, "ButtonEye", "false");
			}

			console.log("disableEditing");
			//this.editMode = false;*/
			//////////////////////////////////////////
			/*this.timeout = setTimeout(function () {
				console.log("textTemplateLostFocus");
				self.$refs["text" + template.id].processHighlights();
			}, 500);

			clearTimeout(this.timeout_preview);
			this.timeout_preview = setTimeout(function () {
				// enter this block of code after 1 second
				// handle stuff, call search API etc.
				self.updateVersion($event, 3, template.versions[0]);

				if (self.document_data_selected != 0 && self.active_preview) {
					self.previewTemplate($event, "ButtonEye", "false");
				}
			}, 1000);*/
		},
		insertVariations (text) {
			this.$modal.hide(this.modalSelecionTextName);
			this.$modal.hide(this.modalParaphrasingName);

			const r = this.selectionObject.range;
			r.deleteContents();
			const node = r.endContainer;

			const cut = r.endOffset;

			if (node.data !== undefined) {
				node.data = node.data.slice(0, cut) + text + node.data.slice(cut);
				r.setEnd(node, cut + text.length);
			} else {
				node.innerText =
          node.innerText.slice(0, cut) + text + node.innerText.slice(cut);
			}

			r.collapse(false);
			const selection = window.getSelection();
			if (selection) {
				selection.removeAllRanges();
				selection.addRange(r);
			}

			this.$refs["text" + this.template.id].internalValue =
        node.parentNode.innerText.replace(/\|/g, "|");

			self = this;
			setTimeout(function () {
				//if (this.autoHighlights)
				//console.log("insertVariations");
				self.$refs["text" + self.template.id].processHighlights();
			}, 500);

			this.updateVersion(
				this.selectionObject,
				3,
				this.template_versions_variation
			);
		},
		handleTextKeyDown ($event, template) {
			let template_version = template.versions[0];
			if ($event.keyCode >= 37 && $event.keyCode <= 40) {
				//son las flechas. Puede que se esté seleccionando texto
				const selection = window.getSelection();
				let r;
				if (selection && selection.rangeCount > 0) {
					this.template_versions_variation = template_version;
					const posEL = getPosEL($event.target);

					const r = selection.getRangeAt(0);
					const range = r.cloneRange();
					range.setStart(range.endContainer, range.endOffset);
					range.collapse(true);

					const rect = range.getClientRects()[0];
					console.log("RECTS", rect);

					$event.layerX = rect.left - posEL.x;
					$event.layerY = rect.top - posEL.y + 10;

					this.selectionText($event, template_version);
				}
			}

			if ($event.keyCode == 32) {
				//si es espacio procesamos los tags html
				/*if (this.autoHighlights)
					this.$refs["text" + this.template.id].processHighlights();*/
			}

			if (window.getSelection().toString().length == 0) {
				this.hideLabelVariations();
			}

			let self = this;
			//clear timeout variable
			/*clearTimeout(this.timeout);
			this.timeout = setTimeout(function () {
				self.$refs["text" + self.template.id].processHighlights();
			}, 1000);*/

			clearTimeout(this.timeout_preview);
			this.timeout_preview = setTimeout(function () {
				//enter this block of code after 1 second
				//handle stuff, call search API etc.
				self.updateVersion($event, 3, template_version);

				if (self.document_data_selected != 0 && self.active_preview) {
					self.previewTemplate($event, "ButtonEye", "false");
				}
			}, 2000);
		},
		async previewTemplate (
			$event,
			button,
			cached = "false",
			times = 1,
			test_mode = "true"
		) {
			if ($event) $event.preventDefault();
			this.$refs[button].loading = true;
			this.translates = [];

			if (this.document_data_selected != 0) {
				const [data, err] = await this.make_preview_request(
					cached,
					times,
					test_mode
				);

				if (!err) {
					if (Array.isArray(data.previews)) {
						[...data.previews].forEach((preview) => {
							if (preview.result_with_sandbox_tags) {
								//buscamos los posibles enlaces a las plantillas que componen la preview
								let regex = RegExp(/\(#(.*?.*?)\)/g);
								let indices = [];
								let match = null;
								while (
									(match = regex.exec(preview.result_with_sandbox_tags)) != null
								) {
									indices.push({
										start: match.index,
										end: match.index + match[0].length - 1,
										template_id: match[1]
									});
								}

								//recorremos las equiquetas encontradas y ponemos enlaces
								let result = "";
								let startingPosition = 0;
								[...indices].forEach((position) => {
									result += preview.result_with_sandbox_tags.substring(
										startingPosition,
										position.start
									);

									const text_tag = preview.result_with_sandbox_tags.substring(
										position.start,
										position.end + 1
									);

									if (position.template_id != 99999) {
										result +=
                      "<a class='' href='#" +
                      position.template_id +
                      "'>" +
                      text_tag +
                      "</a>";
									} else {
										result += text_tag;
									}
									startingPosition = position.end + 1;
								});
								preview.result_with_sandbox_tags = result;
							}
						});

						this.previews = data.previews;
						this.variability = data.variability;
					} else {
						//el json no es valido. Lo ponemos como error en la preview
						this.previews = [{ error: "Document is not a valid JSON" }];
					}
				} else if (!cached) {
					console.log(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
			this.$refs[button].loading = false;
		},
		async make_preview_request (cached, times = 1, test_mode = "true") {
			return await preview_api.getPreview(
				this.currentDomain.id,
				this.currentDocument.id,
				this.document_data_selected,
				cached,
				times,
				this.template.id,
				test_mode
			);
		},
		deleteTemplate ($event, templateDelete) {
			this.showDeleteModal(
				this.template,
				this.delete_template_OK,
				this.delete_template_cancel
			);
		},
		async delete_template_OK (template) {
			this.$refs["ButtonDelete"].loading = true;
			// const [data, err] = await templates_api.patch(this.template.id, {
			// 	active: false
			// });
			const [data, err] = await templates_api.delete(this.template.id, {
				active: false
			});

			if (!err) {
				this.$emit("templateDeleted", this.template);
			} else {
				this.showErrorAlert("Error: " + err.data.error);
			}
			this.$refs["ButtonDelete"].loading = false;
		},
		delete_template_cancel (template) {
			console.log("dont delete");
		},

		selectionText ($event, template_version) {
			const selection = window.getSelection();
			const selectionText = selection.toString();
			if (selectionText.length > 0) {
				this.template_versions_variation = template_version;

				if (
					$event.target.nodeName == "SPAN" ||
          $event.target.nodeName == "DIV"
				) {
					console.error("ES SPAN");
					const textStart =
            $event.currentTarget.innerText.indexOf(selectionText);
					const textStop = textStart + selectionText.length;
					this.selectionObject = {
						target: $event.target,
						selectionText: selectionText,
						textStart: textStart,
						textStop: textStop,
						textComplete: $event.currentTarget.innerText,
						range: selection.getRangeAt(0).cloneRange()
					};
				} else {
					console.error("NO ES SPAN");
					this.selectionObject = {
						target: $event.target,
						selectionText: selectionText,
						textStart: selection.anchorOffset,
						textStop: selection.focusOffset,
						textComplete: selection.anchorNode.textContent,
						range: selection.getRangeAt(0).cloneRange()
					};
				}

				this.$store.commit("SET_SELECTION_ON_TEMPLATE", this.selectionObject);
				//this.$bvModal.show("modalSelecionText");
				//this.$modal.show("modalSelecionText");
				this.$refs["Highlightable" + template_version.template_id].onMouseup(
					$event
				);
			} else if ($event.target.tagName == "A") {
				let hash = $event.target.hash;
				//console.error("SET_NAVIGATE_TEMPLATE", hash);
				this.$store.commit("SET_NAVIGATE_TEMPLATE", hash.substring(1));

				return;

				//obtenemos el type de la nueva template. this
				//el hash en este caso es algo así
				//#new_[type]
				const type = hash.substring("#new_[".length, hash.length - 1);

				if (isNaN(hash.substring(1))) {
					if (this.category_id == "2") {
						//hay que crear el template padre y el árbol de la plantilla

						this.confirmCreateTreeNoExits(type);
					} else if (this.category_id == "1") {
						/*this.$bvModal
							.msgBoxConfirm(
								"The _subtemplate does not exist. Create a new one?",
								{
									title: "Create",
									size: "sm",
									buttonSize: "sm",
									okVariant: "danger",
									okTitle: "YES",
									cancelTitle: "NO",
									footerClass: "p-2",
									hideHeaderClose: true,
									centered: true
								}
							)
							.then(value => {
								const tmpl = {
									versions: [
										{
											type: type,
											narras: "",
											weigth: 0,
											text: ""
										}
									]
								};
								this.$emit("newTemplateByType", -1, tmpl, 1);
								/*let self = this;
								setTimeout(function() {
									self.updateVersion($event, 3, self.template.versions[0]);
									self.$store.commit("SET_NAVIGATE_ROOT", self.template.id);
								}, 200);*/
						//});
					}
				} else {
					//hay que comprobar si es una subplantilla
					//en ese caso hay que desplegar la plantilla padre que la contenga para que se
					const template_id = hash.substring(1);

					const template_filter = this.$store.getters.getTemplates.filter(
						function (template) {
							return template.id == template_id;
						}
					);

					//buscamos la plantilla pladre para expandirla y que se muestre el subtemplate
					if (template_filter.length > 0) {
						console.error("SET_NAVIGATE_TEMPLATE2", hash.substring(1));
						this.$store.commit("SET_NAVIGATE_TEMPLATE", template_filter[0]);
					}
				}
			}
		},
		confirmCreateTreeNoExits (type) {
			//originalmente esta parte creaba el template del mismo nombre
			//pero hemos acordado que sea un tree lo que se cree
			//Lo templates se generaran posteriormente a partir de los nodos de los árboles

			this.$bvModal
				.msgBoxConfirm("The tree does not exist. Create a new one?", {
					title: "Create",
					size: "sm",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "YES",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: true,
					centered: true
				})
				.then((value) => {
					this.isLoading = true;
					if (value) {
						this.createNarraTreeNode(type);
					} else {
						console.log("responde NO");
					}
				})
				.catch((err) => {
					//An error occurred
					console.error("ERROR", err);
					this.isLoading = false;
				});
		},
		async createNarraTreeNode (type) {
			let node = {
				domain_id: this.currentDomain.id,
				active: true
			};

			let [data, err] = await tree_nodes_api.create(node);

			console.error("tree_nodes_api.create", data);

			if (!err) {
				let data_node_tree = data;

				let version = {
					narra_tree_node_id: data_node_tree.id,
					parent_id: null,
					name: type,
					narras: "",
					description: "",
					current_version: true,
					active: true
				};

				[data, err] = await tree_nodes_versions_api.create(version);

				console.error("tree_nodes_versions_api.create", data);
				if (!err) {
					data_node_tree.versions = [data];

					let root_narras = this.$store.getters.getRootNarras;
					root_narras.nodes.push(data_node_tree);
					this.$store.commit("SET_ROOT_NARRAS", root_narras);

					this.$bvToast.toast("Tree created", {
						title: "Success ",
						variant: "success",
						solid: true,
						"auto-hide-delay": "2000",
						appendToast: true
					});
					return data_node_tree;
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}

				this.isLoading = false;
			} else {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
			}
		},
		openSelectNarra ($event, template_versions) {
			this.$bvModal.show(this.narraSelectorModalName);
		},

		async selectNarra (tree_node) {
			this.isLoading = true;

			this.$bvModal.hide(this.narraSelectorModalName);
			const [data, err] = await narras_templates_api.create(
				tree_node.data_node.id,
				{
					template_id: this.template.id
				}
			);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}
			this.template.narra_tree_nodes_version.push(
				tree_node.data_node.versions[0]
			);

			if (tree_node.template) {
				tree_node.template =
          tree_node.template + ", " + this.template.versions[0].type;
			} else tree_node.template = this.template.versions[0].type;

			this.isLoading = false;
		},
		getFocus () {
			this.$refs.lblType.focus();
		},
		isComposition (type) {
			return type[0] != "_" && type[0] != "*" && this.category_id == 1;
		},
		isComponent (type) {
			return type[0] == "*";
		},
		getClassForTemplate (template) {
			let result = "card template_full_row";
			if (this.isComposition(template.versions[0].type)) {
				result += " template_border_root_template";
			} else if (this.isComponent(template.versions[0].type)) {
				result += " template_border_component_template";
			} else {
				result += " template_border_sub_template";
			}

			if (
				this.template.versions[0].type == "" &&
        this.template.versions[0].text_for_editor == ""
			) { result += " empty-template"; }
			if (this.navigate_here) result += " blink-template";

			return result;
		},
		showSimilarity ($event) {
			this.$modal.show(this.modalSimilarityName);
		},
		showEntities (insert) {
			this.entitiesNewTemplates = insert;
			this.$modal.show(this.modalEntitiesName);
		},
		showHistory ($event) {
			this.$modal.show(this.modalHistoryName);
		},
		insertSimilarity (texts) {
			this.$modal.hide(this.modalSimilarityName);
			let texts_ids = [];
			let id = 1;
			[...texts].forEach((text) => {
				const obj = {
					id: id,
					text: text
				};
				texts_ids.push(obj);
				id++;
			});

			this.textEntities = texts_ids;
			this.showEntities(true);
		},
		insertEntities (texts) {
			this.$modal.hide(this.modalEntitiesName);

			[...texts].forEach((text) => {
				let tmpl = JSON.parse(JSON.stringify(this.template));
				tmpl.versions[0].text = text_for_editor;

				//console.error("New template", tmpl);
				this.$emit("duplicateTemplate", tmpl);
			});
		},
		insertEntitiesTemplate (texts) {
			this.$modal.hide(this.modalEntitiesName);

			this.template.versions[0].text_for_editor = texts[0];
			this.updateTemplate(this.template.versions[0]);
		},
		entitiesExtractor () {
			let texts_ids = [];
			let id = 1;
			const obj = {
				id: 1,
				text: this.template.versions[0].text_for_editor
				// text: this.template.versions[0].text_for_editor.replace(/·/g, " ")
			};
			texts_ids.push(obj);

			this.textEntities = texts_ids;
			this.showEntities(false);
		},
		toggleCollapse () {
			this.template.subtemplates_objs_render = this.template.subtemplates_objs;
			this.$root.$emit("bv::toggle::collapse", this.collapseID);
			this.$refs["ButtonCollapse" + this.template_root.id].changeState();
		},
		updateChildsTemplates (subtemplates) {
			//return;
			if (subtemplates) {
				this.template_root.subtemplates_objs = [];

				let templates_remove = [];
				this.template_root.subtemplates = subtemplates;

				this.template_root.subtemplates_objs = this.addSubtemplatesToTemplate(
					this.template_root,
					this.all_templates,
					templates_remove
				);
				this.template_root.subtemplates_objs_render =
          this.template_root.subtemplates_objs;

				this.findTemplatesToRemove(
					this.templates,
					this.template_root,
					templates_remove
				);

				//console.error("Templates a eliminar", templates_remove);
				this.templates = this.filteTemplatesToRemove(
					this.templates,
					templates_remove
				);
			}
		}
	}
};

function removeTemplateFromNarrasRows (template, narra_version, rows) {
	//recorremos el data.rows que tiene los narras para quitar el template eliminado y que no salga en la tablabundleRenderer.renderToStream
	for (let i = 0; i < rows.length; i++) {}
}
</script>

<style scoped>
.v--modal-overlay {
  z-index: 1030;
}

</style>
