import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class ScenarioCategories extends BaseClient {
	constructor () {
		super(httpClient, "/scenarios_categories/");
	}

	createCategory (cat) {
		return this.post("", cat);
	}

	deleteCategory (cat_id) {
		return this.delete(cat_id);
	}

	updateCategory (cat) {
		return this.patch(cat.id, cat);
	}
}

export default new ScenarioCategories();
