<template>
	<div>
		<div class="template-child" :style="'margin-left: 15px;'">
			<b-collapse
				:id="'collapse-' + template_id + '-' + level"
				:ref="'collapse-' + template_id + '-' + level"
				visible
				class="mt-2"
			>
				<div v-for="subtemplate in subtemplates[level]">
					<RowTemplate
						:template="subtemplate.template"
						:currentDocument="currentDocument"
						:currentDomain="currentDomain"
						:document_data_selected="document_data_selected"
						:category_id="category_id"
						:active_autocomplete="active_autocomplete"
						:ref="'RowTemplate' + template_id"
						:refreshHighlights="refreshHighlights"
						:level="level + 1"
						@templateDeleted="templateDeletedEmit"
						@duplicateTemplate="duplicateTemplateEmit"
						@createTempByText="createTempByTextEmit"
						@updateTemplate="$emit('updateTemplatesStore')"
						@filterByTemplateID="filterByTemplateIDEmit"
					/>
				</div>
			</b-collapse>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/UI/Loading";
import RowTemplate from "@/components/templates/RowTemplate";
export default {
	name: "LevelRowTemplate",
	components: {
		Loading,
		"RowTemplate": RowTemplate
	},
	data () {
		return {};
	},
	methods: {
		filterByTemplateIDEmit (template) {
			this.$emit("filterByTemplateID", template);
		},
		createTempByTextEmit (template) {
			this.$emit("createTempByText", template);
		},
		duplicateTemplateEmit (template) {
			this.$emit("templateDuplicated", template);
		},
		templateDeletedEmit (template) {
			this.$emit("templateDeleted", template);
		}
	},
	props: {
		level: { type: String },
		template_id: { type: Number },
		subtemplates: { type: Object },
		template: { type: Object },
		currentDocument: { type: Object },
		currentDomain: { type: Object },
		document_data_selected: { type: Number, default: 0 },
		category_id: {},
		active_autocomplete: { type: Boolean, default: true },
		refreshHighlights: { type: Boolean, default: true },
		prop: {}
	}
};
</script>
