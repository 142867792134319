export const alertMixins = {
	methods: {
		showApiRequestErrorAlert (error) {
			this.showErrorAlert("Error: " + error.data.error);
			this.isLoading = false;
		},
		showErrorAlert (error, title) {
			this.$bvModal
				.msgBoxOk(error,{title:title,centered: true})
				.then(value => {
					this.boxOne = value;
				})
				.catch(err => {
					//An error occurred
				});
		},
		showAlert (msg, title) {
			this.$bvModal
				.msgBoxOk(msg, {
					title:title,
					headerClass: 'p-2 border-bottom-0',
					centered: true
				})
				.then(value => {
					this.boxOne = value;
				})
				.catch(err => {
					//An error occurred
				});
		},
		showDeleteModal (data, functionOK, funtionCancel) {
			this.$bvModal
				.msgBoxConfirm("Please confirm that you want to delete.", {
					title: "Delete",
					size: "sm",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "YES",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: true,
					centered: true
				})
				.then(value => {
					if (value) {
						functionOK(data);
					} else {
						functionCancel(data);
					}
				})
				.catch(err => {
					//An error occurred
					funtionCancel(data);
				});
		},
		showRestoreModal (data, functionOK, funtionCancel) {
			this.$bvModal
				.msgBoxConfirm("Please confirm that you want to restore.", {
					title: "Restore",
					size: "sm",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "YES",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: true,
					centered: true
				})
				.then(value => {
					if (value) {
						functionOK(data);
					} else {
						functionCancel(data);
					}
				})
				.catch(err => {
					//An error occurred
					funtionCancel(data);
				});
		}
	}
};
