export class LinkedListNode {
	constructor (data, next = null, prev = null) {
		this.data = data,
		this.next = next;
		this.prev = prev;
	}
}

export class LinkedList {
	constructor () {
		this.length = 0;
		this.head = null;
		this.tail = null;
	}

	/*insertAtBeginning(data) {
    // A newNode object is created with property data and next = null
    let newNode = new LinkedListNode(data);
    // The pointer next is assigned head pointer so that both pointers now point at the same node.
    newNode.next = this.head;
    // As we are inserting at the beginning the head pointer needs to now point at the newNode.

    this.head = newNode;
    return this.head;
  }*/

	push (data) {
		//A newNode object is created with property data and next=null

		let newNode = new LinkedListNode(data);

		//When head = null i.e. the list is empty, then head itself will point to the newNode.
		if (!this.head) {
			this.head = newNode;
			this.tail = newNode;
		} else {
			this.tail.next = newNode;
			newNode.prev = this.tail;
			this.tail = newNode;
		}

		this.length++;
		return this.head;
	}

	getAt (index) {
		let counter = 0;
		let node = this.head;
		while (node) {
			if (counter === index) {
				return node;
			}
			counter++;
			node = node.next;
		}
		return null;
	}

	deleteList () {
		this.head = null;
	}
}
