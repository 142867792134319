<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="light">
			<b-navbar-brand href="#">Alternatives Selector</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click="closeModal">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>
		<br />
		<div class="modal-div-container">
			<b-row>
				<b-col cols="6">
					<div class="card variations-card-columns variations-card">
						<div class="card-header variations-header">Variations</div>
						<div class="card-body">
							<label for="input-variations">Select Model:</label>
							<div>
								<b-form-select
									v-model="variationsModelSelected"
									:options="optionsVariations"
								></b-form-select>
							</div>
							<br />

							<label for="input-variations">Sentence:</label>
							<b-form-textarea
								id="input-variations"
								v-model="variations_selection"
								placeholder="Variations"
								trim
								rows="3"
								no-resize
								@keyup="changeTextVariations"
							></b-form-textarea>

							<template v-if="loadingVariations">
								<b-spinner variant="primary" label="Spinning"></b-spinner>
							</template>
							<template v-else-if="variations_list.length > 0">
								<b-form-group
									label="Select BERT variations"
									class="overflow-auto variations-check-group-ml"
								>
									<b-form-checkbox
										v-for="option in variations_list"
										v-model="variations_check"
										:key="option.value"
										:value="option.value"
										stacked
									>
										{{ option.text }}
									</b-form-checkbox>
								</b-form-group>
								<div class="text-right">
									<b-button
										variant="info"
										@click.prevent="
											includeSelected(selection_text, variations_check)
										"
										>Include</b-button
									>
								</div>
							</template>
							<template v-else> No results found </template>
						</div>
					</div>
				</b-col>

				<b-col cols="6">
					<div class="card variations-card-columns synonyms-card">
						<div class="card-header synonyms-header">Synonyms</div>
						<div class="card-body">
							<label for="input-synonym">Word:</label>
							<b-form-input
								id="input-synonym"
								v-model="synonym_selection"
								placeholder="Synonym"
								@change="getListSynonyms"
								trim
							></b-form-input>
							<br />
							<template v-if="loadingSynonyms">
								<b-spinner variant="primary" label="Spinning"></b-spinner>
							</template>
							<template v-else-if="synonyms_list.length > 0">
								<b-form-group
									label="Select synonyms"
									class="overflow-auto variations-check-group"
								>
									<b-form-checkbox
										v-for="option in synonyms_list"
										v-model="synonyms_check"
										:key="option.value"
										:value="option.value"
										stacked
									>
										{{ option.text }}
									</b-form-checkbox>
								</b-form-group>
								<div class="text-right">
									<b-button
										variant="info"
										@click.prevent="
											includeSelected(synonym_selection, synonyms_check)
										"
										>Include</b-button
									>
								</div>
							</template>
							<template v-else> No results found </template>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import minerva_api from "../../../api/minerva.js";
import synonyms_api from "../../../api/synonyms.js";

export default {
	name: "VariationsSelector",
	data () {
		return {
			optionsVariations: [
				{ value: 0, text: "BERT" },
				{ value: 1, text: "Context2vec" },
				{ value: 2, text: "GPT-2", disabled: true }
			],
			variationsModelSelected: 0,
			synonym_selection: "",
			variations_selection: "",
			selection_text: "",
			synonyms_list: [],
			variations_list: [],
			synonyms_check: [],
			variations_check: [],
			loadingSynonyms: false,
			loadingVariations: false,
			timeout: null
		};
	},
	props: {
		domain_id: String,
		minerva_url: String,
		minerva_server: String,
		language: String,
		modal_name: String
	},
	mounted () {
		//console.log("create");
		this.getAlternatives();
		this.getListVariations();
	},
	computed: {
		selection () {
			return this.$store.getters.getSelectionOnTemplate;
		},
		preview () {
			return this.$store.getters.getPreviewVariations.previews;
		},
		async getListSynonyms () {
			this.loadingSynonyms = true;

			let [data, err] = await synonyms_api.get_synonyms(
				this.synonym_selection,
				this.language
			);
			//console.log("synonyms", data);

			let list_options = [];
			if (!err) {
				for (let i = 0; i < data.length; i++) {
					const synonyms = data[i].synonyms.split(",");
					for (let j = 0; j < synonyms.length; j++) {
						const word = synonyms[j]
							.replace(/\[/g, "")
							.replace(/\]/g, "")
							.replace(/'/g, "")
							.trim();
						list_options.push({ text: word, value: word });
					}
				}
			}
			this.synonyms_list = getUnique(list_options, "text");
			this.loadingSynonyms = false;
		}
	},
	methods: {
		closeModal () {
			this.$modal.hide(this.modal_name);
		},
		changeTextVariations ($event) {
			clearTimeout(this.timeout);

			let self = this;
			this.timeout = setTimeout(function () {
				console.error("call getListVariations");
				self.getListVariations();
			}, 1000);
		},
		async getListVariations () {
			this.loadingVariations = true;

			let data, err;
			let list_options = [];

			switch (this.variationsModelSelected) {
			case 0: //BERT
				this.variations_selection = this.variations_selection.replace(
					"[]",
					"[MASK]"
				);

				[data, err] = await minerva_api.variations_bert(
					this.$store.getters.getCurrentDomain.id,
					this.language,
					"[CLS] " + this.variations_selection + " [SEP]"
				);

				if (!err) {
					for (let i = 0; i < data.length; i++) {
						list_options.push({
							text: data[i].token,
							value: data[i].token
						});
					}
				}
				break;

			case 1: //Context2vec
				this.variations_selection = this.variations_selection.replace(
					"[MASK]",
					"[]"
				);
				[data, err] = await minerva_api.variations_context2vec(
					this.$store.getters.getCurrentDomain.id,
					this.language,
					this.variations_selection
				);

				if (!err) {
					for (let i = 0; i < data.length; i++) {
						list_options.push({
							text: data[i].sentence,
							value: data[i].sentence
						});
					}
				}
				break;
			case 2: //GTP2
				break;
			}

			console.log("variations", data);

			this.variations_list = list_options;
			this.loadingVariations = false;
		},
		includeSelected (text, selectedItems) {
			//construc the synonyms list like {synom1|synom2|synom3|...}
			//console.log(selectedItems);
			this.$emit(
				"selectedItems",
				"{" + [text].concat(selectedItems).join("|") + "}"
			);
		},
		getAlternatives () {
			//console.log(				"SELECTION #########################################",				this.selection			);
			const selectionText = this.selection.selectionText;
			const textStart = this.selection.textStart;
			const textStop = this.selection.textStop;
			const textComplete = this.selection.textComplete;
			const textBegin = textComplete.substring(0, textStart);
			const textEnd = textComplete.substring(textStop);

			this.selection_text = selectionText;
			this.synonym_selection = selectionText;

			console.log("textStart", textStart);
			console.log("textStop", textStop);
			console.log("textComplete", textComplete);
			console.log("textBegin", textBegin);
			console.log("textEnd", textEnd);

			const sentencesBegin = textBegin.split(".");
			console.log("sentencesBegin", sentencesBegin);

			const lastSentenceBegin = sentencesBegin[sentencesBegin.length - 1]
				.trim()
				.split(" ");
			console.log("lastSentenceBegin", lastSentenceBegin);

			const firstWords = lastSentenceBegin.slice(
				Math.max(lastSentenceBegin.length - 3, 0)
			);
			console.log("firstWords", firstWords);

			const sentencesEnd = textEnd.split(".")[0].trim().split(" ");
			console.log("sentencesEnd", sentencesEnd);

			const lastWords = sentencesEnd.splice(
				0,
				Math.max(lastSentenceBegin.length, 3)
			);
			console.log("lastWords", lastWords);

			console.log("PREVIEWSSSSS", this.preview);
			let textPreview = "";
			for (let i = 0; i < this.preview.length; i++) {
				if (!this.preview[i].error) {
					textPreview = this.preview[i].result.replace(
						"(invalid scenario)",
						""
					);
					break;
				}
			}
			console.log("PREVIEW " + textPreview);
			if (textPreview.length > 0) {
				const word_search = textComplete.substring(textStart, textStop);
				console.log("palabra " + word_search);

				const finalSentence = textPreview.replace(word_search, "[MASK]");

				console.log("finalSentence", finalSentence);

				this.variations_selection = finalSentence;
			} else {
				const finalSentence =
					firstWords.join(" ") + " [MASK] " + lastWords.join(" ");

				this.variations_selection = finalSentence;
			}
		}
	}
};

function getUnique (arr, comp) {
	const unique = arr
		.map((e) => e[comp])

		//store the keys of the unique objects
		.map((e, i, final) => final.indexOf(e) === i && i)

		//eliminate the dead keys & store unique objects
		.filter((e) => arr[e])
		.map((e) => arr[e]);

	return unique;
}
</script>
