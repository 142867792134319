/* eslint-disable */
<template>
  <section class="content no-mw no-pd" @keydown.ctrl.90="handleKeyDown($event)">
    <!--b-navbar toggleable="lg" type="light" variant="info">
			<b-navbar-brand href="#">Narras Trees</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    </b-navbar-->

    <b-tabs content-class="mt-0 " nav-class="sticky-top" v-model="tabSelected">
      <b-tab v-if="is_stuff || username==='raico.acosta' || username ==='anayeli.tapia' || username ==='rodrigo.gutierrez' || username === 'juliana.fregoso2'">
        <template v-slot:title>
          <div class="title-nav-item">
            <i class="fa fa-sitemap" aria-hidden="true" title="1. NScheme"></i>
            <div class="title-nav-item-text">NScheme</div>
          </div>
        </template>
        <NSchemaViewer :domainId="domainId" />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <div class="title-nav-item">
            <i class="fa fa-database" aria-hidden="true" title="2. Data"></i>
            <div class="title-nav-item-text">Data</div>
          </div>
        </template>
        <DataViewer :documentId="documentId" />
      </b-tab>
      <b-tab v-if="is_stuff || username==='raico.acosta' || username ==='anayeli.tapia' || username ==='rodrigo.gutierrez' || username === 'juliana.fregoso2'">
        <template v-slot:title>
          <div class="title-nav-item">
            <i class="fa fa-cubes" aria-hidden="true" title="3. Scenarios"></i>
            <div class="title-nav-item-text">Scenarios</div>
          </div>
        </template>
        <div class="container-fluid no-pd">
        <div class="row no-gutters">
          <div class="col-md-4">
            <NarrasTreeEditor
              ref="NarrasTreeEditor"
              navbar="false"
              :domainId="domainId"
              :documentId="documentId"
            ></NarrasTreeEditor>
          </div>
          <div class="col-md-8" id="scenarios_editor">
            <ScenariosEditor
              ref="ScenariosEditor"
              :domainId="domainId"
              :documentId="documentId"
            ></ScenariosEditor>
          </div>
        </div>
        </div>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <div class="title-nav-item">
            <i class="fa fa-puzzle-piece" aria-hidden="true" title="4. Compositions"></i>
            <div class="title-nav-item-text">Compositions</div>
          </div>
        </template>
        <TableTemplatesV5
          :documentId="documentId"
          category_id="2"
          title_navbar="Compositions"
          :currentDocument="currentDocument"
          :currentDomain="currentDomain"
          @refresh="getEditorData()"
          @migrate="migrate_domain()"
          @importSegments="importSegments"
        ></TableTemplatesV5
      ></b-tab>

      <b-tab>
        <template v-slot:title>

          <div class="title-nav-item">
            <i class="fa fa-pencil" aria-hidden="true" title="'5. ' + $templatesName"></i>
            <div class="title-nav-item-text">Segments</div>
          </div>
        </template>

        <TableTemplatesNV
          :documentId="documentId"
          category_id="1"
          :title_navbar="$templatesName"
          :currentDocument="currentDocument"
          :currentDomain="currentDomain"
          @refresh="getEditorData()"
          @migrate="migrate_domain()"
          @importSegments="importSegments"
        ></TableTemplatesNV
      ></b-tab>

      <b-tab>
        <template v-slot:title>

          <div class="title-nav-item">
            <i class="fa fa-star" aria-hidden="true" title="Aliases"></i>
            <div class="title-nav-item-text">Aliases</div>
          </div>
        </template>
        <AliasesEditor
          ref="AliasesEditor"
          :domainId="domainId"
          :documentId="documentId"
        ></AliasesEditor>
      </b-tab>
    </b-tabs>
    <Loading v-if="isLoading"></Loading>
  </section>
</template>

<script>
import domains_api from "../../api/domain.js";
import document_templates_api from "../../api/document_templates.js";
import documents_api from "../../api/document.js";
import document_data_api from "../../api/document_data.js";

import Loading from "@/components/UI/Loading";
import NarrasTreesViewer from "@/components/narras/NarrasTreesViewer";
import TableTemplatesV5 from "@/components/templates/TableTemplatesV5.2";
import TableTemplatesNV from "@/components/templates/TableTemplatesNV";
import NarrasTreeEditor from "@/components/narras/NarrasTreeEditor";
import ScenariosEditor from "@/components/scenarios/ScenariosEditor";
import AliasesEditor from "@/components/aliases/AliasesEditor";
import DataViewer from "@/components/documents/DataViewer";
import NSchemaViewer from "@/components/nschema/NSchemaViewer";
import { alertMixins } from "@/mixins/alertMixins.js";
import { nschemaMixins } from "@/mixins/nschemaMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";

export default {
	name: "EditorV5",
	mixins: [alertMixins, nschemaMixins, narrasMixins, templatesMixins],
	components: {
		Loading,
		NarrasTreesViewer,
		TableTemplatesV5,
		TableTemplatesNV,
		NarrasTreeEditor,
		ScenariosEditor,
		AliasesEditor,
		DataViewer,
		NSchemaViewer
	},
	data () {
		return {
			root_children: [],
			template_menu: null,
			currentDocument: {},
			currentDomain: {},
			isLoading: true,
			narraIdEditor: 0,
			tabSelected: 0,
			is_stuff: false,
			username: "",
		};
	},
	created () {
		this.is_stuff = localStorage.getItem("is_stuff") === "true";
		this.username = localStorage.getItem("username");
		this.$store.commit("SET_DOCUMENT_DATA_SELECTED", 0);
		this.getEditorData();
		//console.log("SEGMENT SELECTED?", this.$route.query);
		if (this.$route.query.segment_id) {
			//console.log("SEGMENT SELECTED OK", this.$route.query.segment_id);
			this.tabSelected = 4;
		}

		//this.interval = setInterval(() => this.getTemplates(), 10000);
	},
	computed: {
		rows: {
			get () {
				return this.$store.getters.getRowsNarras;
			},
			set (value) {
				this.$store.commit("SET_ROWS_NARRAS", value);
			}
		},
		root_narras: {
			get () {
				return this.$store.getters.getRootNarras;
			},
			set (value) {
				this.$store.commit("SET_ROOT_NARRAS", value);
			}
		}
	},
	methods: {
		async getEditorData () {
			console.log("aqui2")
			this.isLoading = true;
			let data, err;

			this.$store.commit("SET_TEMPLATES", []);
			this.$store.commit("SET_TEMPLATES_REGULAR", []);
			this.$store.commit("SET_TEMPLATES_COMPOSITION", []);
			this.$store.commit("SET_ROOT_NARRAS", []);
			this.$store.commit("SET_ROWS_NARRAS", []);
			this.$store.commit("SET_CURRENT_DOMAIN", []);

			let breadcrumb_items = [
				{
					text: "Domains",
					to: { name: "Home" }
				}
			];

			let self = this;
			//load de document
			domains_api.getDomain(this.domainId).then(function ([data, err]) {
				//console.log("################### DOMAIN", data, err);
				if (!err) {
					self.currentDomain = data;
					self.$store.commit("SET_CURRENT_DOMAIN", data);
					breadcrumb_items.push({
						text: "Documents: " + data.name,
						to: {
							name: "DocumentsList",
							params: { domainId: data.id }
						}
					});
				} else {
					console.error("Error", err);
					self.$store.commit("SET_TRIGGER_REDIRECTION", `${process.env.ROOT_PATH}` + "/");
				}
			}).catch(error => {
				console.error(error);
			});

			documents_api.getDocument(this.documentId).then(function ([data, err]) {
				//console.log("################### DOCUMENT", data, err);
				if (!err) {
					self.currentDocument = data;
					self.$store.commit("SET_CURRENT_DOCUMENT", data);
					breadcrumb_items.push({
						text: data.name,
						active: true
					});
					self.$store.commit("SET_BREADCRUMB_ITEMS", breadcrumb_items);
				}
			}).catch(error => {
				console.error(error);
			});

			domains_api.getScenarios(this.domainId).then(function ([data, err]) {
				if (!err) {
					self.$store.commit("SET_SCENARIOS", data);
					self.$store.commit("SET_CATEGORIES_BRANCH_GENERATOR", data);
				} else {
					self.$store.commit("SET_SCENARIOS", []);
					self.$store.commit("SET_CATEGORIES_BRANCH_GENERATOR", []);
				}
			}).catch(error => {
				console.error(error);
			});

			this.loadNarras(this.domainId, this.documentId);
			// this.fetchArchivedNarras(this.domainId, this.documentId);
			//this.$refs.NarrasTreeEditor.putRules();

			this.loadNSchema();

			this.loadTemplatesNV();

			//load data for previews
			document_data_api
				.getByDocumentId(this.documentId)
				.then(function ([data, err]) {
					if (!err) {
						self.$store.commit("SET_DOCUMENT_DATA", data);
						if (data.length > 0) {
							self.$store.commit("SET_DOCUMENT_DATA_SELECTED", data[0].id);
						}
					} else {
						self.$store.commit("SET_DOCUMENT_DATA", []);
					}
				}).catch(error => {
					console.error(error);
				});
		},
		handleKeyDown ($event) {
			console.log("global keyup", $event);
			if ($event.key == "z" && $event.ctrlKey) {
				console.log("Ctrl + Z");

				//$event.preventDefault();
			}
		},
		async migrate_domain () {
			this.$bvModal
				.msgBoxConfirm("Please confirm that you want to copy to production.", {
					title: "Copy to production",
					size: "sm",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "YES",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: true,
					centered: true
				})
				.then((value) => {
					if (value) {
						this.copyToProduction();
					} else {
					}
				})
				.catch((err) => {
					//An error occurred
				});
		},
		async copyToProduction () {
			this.isLoading = true;
			const [data, err] = await domains_api.migrate(this.domainId, this.documentId);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.showErrorAlert("Copy finished");
			}
			this.isLoading = false;
		},
		async importSegments (domain, document, selectedItems) {
			this.isLoading = true;
			console.log("EditorNV.importSegments");
			const data = {
				id: this.currentDomain.id,
				document_id: this.currentDocument.id,
				segments: selectedItems
			};
			const [result, err] = await domains_api.importSegements(
				domain,
				document,
				data
			);
			if (err) {
				this.showErrorAlert("There was an error in the process: " + err.data.error);
			}else{
				let title = "Import OK"

				let message = "<p>Imported elements:</p><ul>"
				message += "<li>Segments: " + Object.keys(result.segments_mapping).length + "</li>"
				message += "<li>Scenarios: " + Object.keys(result.scenarios_mapping).length + "</li>"
				message += "<li>Scenarios Categories: " + Object.keys(result.categories_scenarios_mapping).length + "</li>"
				message += "<li>Scenarios Tree Nodes: " + Object.keys(result.narra_tree_mapping).length + "</li>"
				message += "<li>Aliases: " + Object.keys(result.aliases_mapping).length + "</li>"
				message += "</ul>"

				const messageNodes = this.$createElement(
					'div',
					{
						domProps: { innerHTML: message }
					}
				)


				/*message += "- Aliases: " + length(result.narra_tree_mapping)*/
				/*message+=JSON.stringify(result)*/

				this.showAlert(messageNodes, title)
				console.debug("Result: " + result)
				console.debug("Message: " + message)
			}

			this.getEditorData();
			this.isLoading = false;
		}
	},
	props: {
		domainId: {},
		documentId: {},
		lang: { type: String, default: "ES" }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Style the navbar */
#navbar {
  overflow: hidden;
  background-color: #333;
}

/* Navbar links */
#navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

/* Page content */

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}
.v--modal-overlay {
  z-index: 1030;
}

#scenarios_editor{
  border-left: 1px solid #ddd;
}
.title-nav-item{
  text-align: center;
}

.title-nav-item-text{
  font-size: 0.5em;
}

</style>
