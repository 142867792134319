export default scrollTo = ({elementSelector, top = 0,  left = 0, timer = 250, behavior = "smooth"}) => {
	setTimeout(()=> {
		if (!elementSelector) {
			return false;
		}

		const element = document.querySelector(elementSelector);

		if (!element) {
			return false;
		}

		element.scrollTo({
			top: top,
			left: left,
			behavior: behavior
		})
	}, timer)
}
