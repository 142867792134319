import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Templates extends BaseClient {
	constructor () {
		super(httpClient, "/templates/");
	}

	createTemplate (template) {
		return this.post("", template);
	}

	deleteTemplate (template_id) {
		return this.delete(template_id);
	}

	updateTemplate (template) {
		return this.patch(template.id, template);
	}

	getAllVersions (templateId) {
		return this.get(`${templateId}?current_version=all`);
	}
}

export default new Templates();
