import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Engines extends BaseClient {
	constructor () {
		super(httpClient, "/engines/");
	}

	getAll () {
		return this.get();
	}
}

export default new Engines();
