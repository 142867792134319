<template>
	<header class="header">
		<b-navbar class="header__nav" toggleable="lg">
      <b-navbar-brand :to="rootPath">
        <img class="header__logo" src="@/assets/NarrativaLogoPNG_50h.png" title="Home"/>
				<span class="ml-4">{{ headerTitle }}</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
					<span class="header__build-time">Build time: {{ buildTime }}</span>
				</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-breadcrumb
			class="header__breadcrum"
      v-if="tokenExists"
      :items="breadcrumbItems"
    ></b-breadcrumb>
	</header>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";

export default {
	name: "AppHeader",
	data () {
		return {
			rootPath: process.env.ROOT_PATH,
			//eslint-disable-next-line no-undef
			buildTime: __BUILDTIME__,
			username: ""
		};
	},
	computed: {
		...mapGetters({
			jwtToken: "getJwtToken"
		}),
		tokenExists () {
			return this.jwtToken && this.jwtToken !== "";
		},
		appIcon () {
			//eslint-disable-next-line no-undef
			return faScroll;
		},
		breadcrumbItems () {
			return this.$store.getters.getBreadcrumbItems;
		},
		headerTitle () {
			if (this.username === "pharma") {
				return "Pharma NLG Solution";
			}
			return "";
		}
	},
	methods: {
		checkLoggedIn () {
			if (this.jwtToken === "") {
				this.$store.commit("SET_USERNAME", "");
				this.username = "";
				localStorage.removeItem("username");
				console.log("No token for user available");
				router.push(process.env.ROOT_PATH + "/login");
			}
		}
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getUsername,
			(username, oldValue) => {
				this.username = username;
			}
		);
		this.username = this.$store.getters.getUsername;
	},
	mounted () {
		this.checkLoggedIn();
	}
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.header {
	background-color: $black;
	//min-height: 42px;

	&__logo {
		width: 1em;
	}

	&__nav {
		padding: 0.12rem 1rem;
	}

	&__breadcrum {
		padding: 0.25rem 1rem;
	}

	&__build-time {
		color: var(--white);
		font-size: 0.75em;
	}
}
</style>
