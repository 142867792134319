<template>
	<footer class="footer">
		<div class="row text-light pt-2">
			<div class="col">
				<div class="footer__top row">
					<div class="col-md-4 pl-3">
						<h3>
							<a class="footer__logo" href="https://www.narrativa.com" target="_blank">
								<img
									width="100px"
									height="16px"
									src="https://covid19tracking.narrativa.com/img/NarrativaLogoBlanco.png"
								/>
							</a>
						</h3>
					</div>

				</div>
				<div class="footer__bottom row mb-1 mt-0">
					<div class="col-md-10">
						<p class="footer__copy">© Copyright All Rights Reserved 2015-2021 by Narrativa</p>
					</div>
					<ul class="footer__social col-md-2 mb-0 d-flex justify-content-end">
						<li class="footer__social-item">
							<a class="footer__link footer__social-link" href="https://www.linkedin.com/company/9329868" target="_blank">
								<i aria-hidden="true" class="fa fa-linkedin"></i>
								<span class="d-none">linkedin</span>
							</a>
						</li>
						<li class="footer__social-item">
							<a class="footer__link footer__social-link" href="https://www.twitter.com/NarrativaAI" target="_blank">
								<i aria-hidden="true" class="fa fa-twitter"></i>
								<span class="d-none">twitter</span>
							</a>
						</li>
						<li class="footer__social-item">
							<a class="footer__link footer__social-link" href="https://www.facebook.com/narrativaai/" target="_blank">
							<i aria-hidden="true" class="fa fa-facebook"></i>
							<span class="d-none">facebook</span>
						</a>
						</li>
						<li class="footer__social-item">
							<a class="footer__link footer__social-link" href="mailto:info@narrativa.com" target="_blank">
								<i aria-hidden="true" class="fa fa-envelope"></i>
								<span class="d-none">email</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "AppFooter"
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.footer {
  background-color: $black;
	height: 83px;
	padding: 0 1rem;

	&__link {
		color: $white;
	}

	&__social-item {
		display: inline-block;
		margin-right: 0.5em;

		&:last-of-type {
			margin-right: 0;
		}
	}

	&__copy {
		font-size:0.8em;
	}
}

</style>
