import { render, staticRenderFns } from "./ButtonCircle.vue?vue&type=template&id=bcbdc02a&scoped=true&"
import script from "./ButtonCircle.vue?vue&type=script&lang=js&"
export * from "./ButtonCircle.vue?vue&type=script&lang=js&"
import style0 from "./ButtonCircle.vue?vue&type=style&index=0&id=bcbdc02a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbdc02a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ivan/Narrativa/Editor/nrt_editor_frontend/nrt-editor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bcbdc02a')) {
      api.createRecord('bcbdc02a', component.options)
    } else {
      api.reload('bcbdc02a', component.options)
    }
    module.hot.accept("./ButtonCircle.vue?vue&type=template&id=bcbdc02a&scoped=true&", function () {
      api.rerender('bcbdc02a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UI/Buttons/ButtonCircle.vue"
export default component.exports