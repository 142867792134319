<template>
  <section class="documents content">
    <div class="container">
      <b-row class="documents__header d-flex align-items-center mb-3">
				<b-col>
					<h1>Documents</h1>
					<p>Select a document to start working</p>
				</b-col>
				<b-col class="documents__btns flex flex-end-x">
					<router-link
						class="documents__btn"
						:to="{
							name: 'Home',
						}"
					>
						<b-button>
							<font-awesome-icon icon="arrow-left" />
							<span>Back</span>
						</b-button>
					</router-link>
					<b-button class="documents__btn" variant="success" @click="newModal">
						<font-awesome-icon icon="plus" />
						<span>New</span>
					</b-button>
				</b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <br />
          <b-table-simple hover small caption-top>
            <b-thead>
              <b-th class="text-left" style="width: 60%">Document</b-th>
              <b-th class="text-center" style="width: 10%">Language</b-th>
              <b-th class="text-right" style="width: 30%">Controls</b-th>
            </b-thead>
            <b-tbody>
              <b-tr
                scope="row"
                v-for="document in documents"
                v-bind:key="document.id"
              >
                <b-td class="text-left align-middle">
                  <router-link
                    :to="{
                      name: 'EditorV5',
                      params: { domainId: domainId, documentId: document.id },
                    }"
                    >{{ document.name }}</router-link
                  >
                </b-td>
                <b-td class="text-center align-middle">
                  {{ document.language }}
                </b-td>
                <b-td class="text-right">
                  <router-link
                    :to="{
                      name: 'EditorV5',
                      params: { domainId: domainId, documentId: document.id },
                    }"
                  >
                    <b-button variant="info" title="Work with this document">
                      <font-awesome-icon icon="play" />
                    </b-button>
                  </router-link>
                  <b-button
                    variant="light"
                    v-on:click="updateModal($event, document)"
                    title="Edit general settings for this document"
                  >
                    <font-awesome-icon icon="cog" />
                  </b-button>
                  <b-button
                    variant="light"
                    v-on:click="cloneModal($event, document)"
                    title="Clone this document in a new one"
                  >
                    <font-awesome-icon icon="clone" />
                  </b-button>
                  <router-link
                    :to="{
                      name: 'EditorNV',
                      params: { domainId: domainId, documentId: document.id },
                    }"
                  >
                    <b-button
                      variant="warning"
                      title="Work with this document with NV version"
                    >
                      <font-awesome-icon icon="play" />
                    </b-button>
                  </router-link>

                  <b-button
                    variant="danger"
                    @click="deleteModal($event, document.id)"
										title="Delete document and related elements"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-modal
        hide-footer
        id="modal-new"
        title="Create New Document"
        ref="modal-new"
        :no-close-on-backdrop="true"
				centered
      >
        <b-form @submit.prevent="updateDocument" @reset="cancel">
          <b-form-group
            id="input-group-2"
            label="Name:"
            label-for="name"
            label-align="left"
          >
            <b-form-input
              id="name"
              required
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Language:"
            label-for="language"
            label-align="left"
          >
            <!--b-form-input
              id="language"
              required
              v-model="form.language"
              placeholder="Language"
            ></b-form-input-->
            <v-select
              :options="languageCodesOptions"
              label="text"
              v-model="form.language"
							required
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em>Sorry, no matching option or was already defined</em>.
                </template>
                <em v-else>Sorry, no matching option or was already defined</em>
              </template>
            </v-select>
          </b-form-group>
          <footer class="text-right mt-4">
						<b-button type="reset" variant="secondary">Cancel</b-button>
						<b-button type="submit" variant="primary">Save</b-button>
					</footer>
        </b-form>
      </b-modal>
      <b-modal
        hide-footer
        id="modal-clone"
        title="Duplicate into new Document"
        ref="modal-clone"
        :no-close-on-backdrop="true"
				centered
      >
        <b-form @submit.prevent="cloneDocument" @reset="cancelClone">
          <b-form-group
            id="input-group-2"
            label="Name:"
            label-for="name"
            label-align="left"
          >
            <b-form-input
              id="name"
              required
              v-model="form.name"
              placeholder="New name"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Language:"
            label-for="language"
            label-align="left"
          >
            <v-select
              :options="languageCodesOptions"
              label="text"
              v-model="form.language"
							required
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em>Sorry, no matching option or was already defined</em>.
                </template>
                <em v-else>Sorry, no matching option or was already defined</em>
              </template>
            </v-select>
          </b-form-group>
					<footer class="text-right mt-4">
						<b-button type="reset" variant="secondary">Cancel</b-button>
						<b-button type="submit" variant="primary">Duplicate</b-button>
					</footer>
        </b-form>
      </b-modal>
      <Loading v-if="isLoading"></Loading>
    </div>
  </section>
</template>

<script>
import domain_api from "../../api/domain.js";
import documents_api from "../../api/document.js";
import documents_duplicate_api from "../../api/document_duplicate.js";
import Loading from "@/components/UI/Loading";
import { alertMixins } from "@/mixins/alertMixins.js";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

export default {
	mixins: [alertMixins],
	name: "DocumentsList",
	components: {
		Loading,
		vSelect
	},
	data () {
		return {
			documents: null,
			isLoading: true,
			form: {
				id: "",
				name: "",
				language: "",
				document_obj: {}
			},
			documentIDData: 0,
			languageCodesOptions: []
		};
	},
	created () {
		this.getDocuments();
		this.getLanguageCodes();
	},
	methods: {
		async getLanguageCodes () {
			let self = this;

			const response = await fetch("/static/language_codes.json");
			const json = await response.json();

			let options = [];

			let keys = Object.keys(json.data);

			for (let i = 0; i < keys.length; i++) {
				options.push({ value: keys[i], text: json.data[keys[i]] });
			}

			self.languageCodesOptions = options;
		},
		async getDocuments () {
			const [data, err] = await domain_api.getDocuments(this.domainId);

			console.debug(data);

			if (data.documents != undefined) this.documents = data.documents;
			else this.documents = [];

			let breadcrumb_items = [
				{
					text: "Domains",
					to: { name: "Home" }
				},
				{
					text: "Documents: " + data.name,
					active: true
				}
			];
			this.$store.commit("SET_BREADCRUMB_ITEMS", breadcrumb_items);

			this.isLoading = false;
		},
		newModal () {
			this.form.id = "";
			this.form.name = "";
			this.form.language = "";
			this.form.document_obj = {};
			this.$refs["modal-new"].show();
		},
		updateModal ($event, document) {
			this.form.id = document.id;
			this.form.name = document.name;
			this.form.language = document.language;
			this.form.document_obj = document;
			this.$refs["modal-new"].show();
		},
		cloneModal ($event, document) {
			this.form.id = document.id;
			this.form.name = document.name + "_NEW";
			this.form.language = null;
			this.form.document_obj = document;
			this.$refs["modal-clone"].show();
		},
		async updateDocument () {
			this.isLoading = true;

			let document = {
				id: this.form.id,
				language: this.form.language.value,
				name: this.form.name,
				domain_id: this.domainId
			};

			if (document.id == "") {
				console.debug("New");
				const [data, err] = await documents_api.create(document);
				document.id = data.id;
				if (!err) this.documents.push(document);
				else {
					console.error(err);
					console.error("Error en insert " + data);
				}
			} else {
				console.debug("Update");
				const [data, err] = await documents_api.patch(document.id, document);
				if (!err) {
					this.form.document_obj.name = document.name;
					this.form.document_obj.language = document.language;
				} else {
					console.error(err);
				}
			}
			this.isLoading = false;
			this.$refs["modal-new"].hide();
		},
		async cloneDocument () {

			if (this.form.name==null){
				this.showErrorAlert("No name privided. Plase, type one and try again");
				return
			}

			if (this.form.language==null){
				this.showErrorAlert("No language selected. Plase, choose one and try again");
				return
			}


			this.isLoading = true;

			let document = {
				id: this.form.id,
				language: this.form.language.value,
				name: this.form.name,
				domain_id: this.domainId
			};

			const [data, err] = await documents_duplicate_api.duplicate(
				document.id,
				document
			);
			if (!err) {
				this.getDocuments();
			} else {
				console.error(err);
			}

			this.isLoading = false;
			this.$refs["modal-clone"].hide();
		},
		cancel () {
			this.$refs["modal-new"].hide();
		},
		cancelClone () {
			this.$refs["modal-clone"].hide();
		},
		async deleteDocument (document_id) {
			this.isLoading = true;
			const [data, err] = await documents_api.deleteDocument(document_id);
			this.isLoading = false;
			if (err) {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.getDocuments();
			}
		},
		deleteModal ($event, document_id) {
			this.$bvModal
				.msgBoxConfirm(
					"Please confirm that you want to delete this document and all its segments. The operation can't be undone",
					{
						title: "Delete",
						size: "sm",
						buttonSize: "sm",
						okVariant: "danger",
						okTitle: "Delete",
						cancelTitle: "Cancel",
						footerClass: "p-2",
						hideHeaderClose: true,
						centered: true
					}
				)
				.then((value) => {
					if (value) {
						this.deleteModalModal($event, document_id);
					}
				})
				.catch((err) => {
					//An error occurred
					this.isLoading = false;
				});
		},
		deleteModalModal ($event, document_id) {
			this.$bvModal
				.msgBoxConfirm(
					"Are you sure? All document's segments will be deleted",
					{
						title: "Delete",
						size: "sm",
						buttonSize: "sm",
						okVariant: "danger",
						okTitle: "Delete, I'm sure",
						cancelTitle: "Cancel",
						footerClass: "p-2",
						hideHeaderClose: true,
						centered: true
					}
				)
				.then((value) => {
					if (value) {
						this.deleteDocument(document_id);
					}
				})
				.catch((err) => {
					//An error occurred
					this.isLoading = false;
				});
		}
	},
	props: {
		domainId: {}
	}
};
</script>

<style lang="scss" scoped>
.documents {

	&__btn {
		margin-right: .5rem;


		&:last-child {
			margin-right: 0;
		}
	}
	.table {

		th {
			border-top: 0;
		}
	}
}
</style>
