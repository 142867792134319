<template>
<div class="wrap-delete-parent-segment">
	<b-modal data-test-id="delete-parent-segment" class="delete-parent-segment" id="delete-parent-segment" ref="delete-parent-segment" title="Delete items" size="lg" no-close-on-backdrop centered>
		<b-overlay no-wrap :show="waitingResponse" rounded="true"></b-overlay>
		<div class="archive-parernt-segment__body">
			<div v-bind:class="feedbackCssClass" v-show="showRequestError">
				<b-alert v-model="showRequestError" variant="danger" dismissible @dismissed="toggleRequestErrorVisibility">Error</b-alert>
			</div>
			<form action="">
				<b-form-group label="Elements to delete:">
					<div class="d-flex flex-row pl-2">
						<b-form-radio v-model="includeChildren" name="some-radios" value="true" class="mr-4" :checked="treeNodeToDelete.isRoot">Include all children</b-form-radio>
						<b-form-radio v-model="includeChildren" name="some-radios" value="false" :disabled="treeNodeToDelete.isRoot">Just the current one</b-form-radio>
					</div>
				</b-form-group>
				<b-form-checkbox
					id="include-segments"
					v-model="includeSegments"
					:disabled="treeNodeToDelete.isRoot"
					name="include-segments"
					unchecked-value="false"
					class="pl-3"
				>
					<span>Include all children segments.</span>
				</b-form-checkbox>
			</form>
		</div>
		<template #modal-footer>
			<b-button @click="closeMainModal" variant="secondary">Cancel</b-button>
			<b-button v-b-modal.confirm-delete-parent-segment variant="primary">Delete</b-button>
		</template>
	</b-modal>
	<b-modal id="confirm-delete-parent-segment" ref="confirm-delete-parent-segment" size="sm" title="Confirm operation" no-close-on-backdrop centered>
    <p class="my-1">Are you sure?</p>
		<template #modal-footer>
			<b-button @click="closeConfirmModal" variant="secondary">Cancel</b-button>
			<b-button @click="deleteItems" variant="danger">Delete</b-button>
		</template>
  </b-modal>
</div>
</template>

<script>
import narrasTreeNodesApi from "@/api/narras_tree_nodes";
import { mapGetters } from "vuex";

export default {
	name: "ModalDeleteTreeNode",
	data () {
		return {
			includeChildren: false,
			includeSegments: false,
			waitingResponse: false,
			showRequestError: false
		}
	},
	mounted () {
		this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.includeSegments = this.treeNodeToDelete.isRoot;
			this.includeChildren = this.treeNodeToDelete.isRoot;
			this.showRequestError = false;
    })
	},
	computed: {
		...mapGetters({
			treeNodeToDelete: "getTreeNodeToDelete"
		}),
		feedbackCssClass: function () {
			return {
				"branch-generator__feedback": true,
				"visible": this.showRequestError
			}
		}
	},
	methods: {
		closeMainModal () {
			this.showRequestError = false;
			this.waitingResponse = false;
			this.includeSegments = false;
			this.includeChildren = false;
			this.$refs["delete-parent-segment"].hide();
		},
		closeConfirmModal () {
			this.$refs["confirm-delete-parent-segment"].hide();
		},
		async deleteItems () {
			this.waitingResponse = true;
			const [data, err] = await narrasTreeNodesApi.deleteSegments(this.treeNodeToDelete.id, this.includeChildren, this.includeSegments);

			if (err) {
				this.showRequestError = true;
				this.waitingResponse = false;
				return false;
			}

			this.waitingResponse = false;
			this.$emit("refreshData");
			this.closeConfirmModal();
			this.closeMainModal();
			this.$bvToast.show("delete-segments-toast");
		},
		toggleRequestErrorVisibility () {
			this.showRequestError = !this.showRequestError;
		}
	}
}
</script>
