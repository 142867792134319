import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class DocumentsDuplicate extends BaseClient {
	constructor () {
		super(httpClient, "/documents/");
	}

	duplicate (documentId, data) {
		console.log("duplicate");
		return this.post(`${documentId}/duplicate`, data);
	}
}

export default new DocumentsDuplicate();
