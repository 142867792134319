<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
			<b-navbar-brand href="#">Segment history</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click.prevent="closeModal">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>
    <div class="modal-div-container-history">
			<b-row>
				<b-col cols="12">
					    <div v-for="template in templates" v-bind:key="template.versions[0].id">

                <div :class="getClassForTemplate(template)">

                  <div class="card-header template-header pt-0 pb-0 pl-2">
                    <b-row align-v="center" class="template-row-header">
                      <b-col cols="8" class="m-0 p-0 pl-2 pt-1">
                        <label
                          class="template-history-date"
                          spellcheck="false"
                          v-text="updated_at(template)">
                        </label>
                        <label
                          class="template-history-date"
                          spellcheck="false"
                          v-text="template.versions[0].user">
                        </label>
                      </b-col>
                      <b-col cols="4" class="text-right pr-0 align-middle">

                        <a
                          href="#"
                          @click.prevent="$emit('setCurrentVersion', template)"
                          class="template-preview-icon template-circle-button"
                          title="Set current version"
                          v-if="!template.versions[0].current_version"
                        >
                          <div class>
                            <i class="fas fa-arrow-alt-circle-right fa-lg"></i>
                          </div>
                        </a>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row class="template-row-text">

                    <b-col cols="12" class="template-cell-text card-body">
                      <Highlightable
                        :ref="'Highlightable' + template.id"
                      >
                        <highlightable-input
                          highlight-style="badge-data-template"
                          highlight-enabled
                          :highlight="highlight"
                          :refreshHighlights="refreshHighlights"
                          :category_id="template.category.id.toString()"
                          v-model="template.versions[0].text_for_editor"
                        />
                      </Highlightable>
                    </b-col>

                  </b-row>

                </div>
                <br>
              </div>

				</b-col>
      </b-row>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>

import { alertMixins } from "@/mixins/alertMixins.js";
import templates_api from "../../api/template.js";

import Highlightable from "@/components/UI/Highlightable/Highlightable.vue";
import HighlightableInput from "@/components/UI/Highlightable/HighlightableInput";
import Loading from "@/components/UI/Loading";

export default {
	"name": "TemplatesHistory",
	mixins: [
		alertMixins
	],
	props: {
		templateId: 0,
		modal_name: ""
	},
	components: {
		Highlightable,
		HighlightableInput,
		Loading
	},
	data () {
		return {
			templates: [],
			highlight: [
				{ text: /{(.*?.*?)}/g, style: "badge-template badge-synonym-template" }
			],
			refreshHighlights: false,
			isLoading: true
		};
	},
	created () {
		this.getTemplateVersions();
	},
	methods: {
		async getTemplateVersions () {
			const [data, err] = await templates_api.getAllVersions(this.templateId);
			this.isLoading = false;

			if (data) {
				this.templates = data;
			} else {
				return this.showApiRequestErrorAlert(err);
			}
		},
		closeModal () {
			this.$modal.hide(this.modal_name);
		},
		getClassForTemplate (template) {
			if (template == undefined) return "";

			let result = "card template_full_row";
			if (template.versions[0].current_version) {
				result += " template_border_root_template";
			} else if (template.versions[0].type[0] == "*") {
				result += " template_border_component_template";
			} else {
				result += " template_border_sub_template";
			}

			if (template.versions[0].type == "" && template.versions[0].text_for_editor == "") {
				result += " empty-template";
			}

			return result;
		},
		updated_at (template) {
			const localDate = new Date(template.versions[0].updated_at);
			return localDate.toLocaleString("es", {
				year: "numeric", //numeric, 2-digit
				month: "numeric", //numeric, 2-digit, long, short, narrow
				day: "numeric", //numeric, 2-digit
				hour: "2-digit", //numeric, 2-digit
				minute: "numeric", //numeric, 2-digit
				//second: 'numeric', // numeric, 2-digit
				hour12: false
			});
		}
	}
};
</script>
