var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "aliasRow",
      class: {
        hoverable: !this.showEdit,
        aliasRowUnsaved: _vm.unsaved_changes,
      },
    },
    [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass: "row",
            on: {
              click: function (data) {
                return (this$1.showEdit = true)
              },
            },
          },
          [
            _c("div", { staticClass: "col-3" }, [
              _c("strong", [_vm._v("#" + _vm._s(this.aliasItemCopy.alias))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-5" }, [
              _vm._v(_vm._s(this.aliasItemCopy.value)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [
              _vm._v(
                "\n          " +
                  _vm._s(this.aliasItemCopy.description) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-1 text-right text-danger" }, [
              _c(
                "a",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteAlias()
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "trash-alt" } })],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm.showEdit
            ? _c("div", { staticClass: "col-12 mt-2 mb-2" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "alias_" + _vm.aliasItem.id },
                    },
                    [_vm._v("Name:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aliasItemCopy.alias,
                          expression: "aliasItemCopy.alias",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "alias_" + _vm.aliasItem.id,
                        placeholder: "AliasIdentifier",
                      },
                      domProps: { value: _vm.aliasItemCopy.alias },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.aliasItemCopy,
                            "alias",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "value_" + _vm.aliasItem.id },
                    },
                    [_vm._v("Value:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    [
                      _c(
                        "AtTextareaNV",
                        {
                          attrs: {
                            ats: _vm.triger_chars,
                            members: _vm.autocomplete_members_in_templates,
                            hideOnBlur: "",
                            filterMatch: _vm.filterMatch,
                            filterMembers: _vm.filterNotAliases,
                          },
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.aliasItemCopy.value,
                                expression: "aliasItemCopy.value",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "value_" + _vm.aliasItem.id },
                            domProps: { value: _vm.aliasItemCopy.value },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.aliasItemCopy,
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "description_" + _vm.aliasItem.id },
                    },
                    [_vm._v("Description:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aliasItemCopy.description,
                          expression: "aliasItemCopy.description",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "description",
                        id: "description_" + _vm.aliasItem.id,
                      },
                      domProps: { value: _vm.aliasItemCopy.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.aliasItemCopy,
                            "description",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row text-right" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary",
                        on: {
                          click: function ($event) {
                            return _vm.cancel()
                          },
                        },
                      },
                      [_vm._v("\n                Cancel\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-success",
                        on: {
                          click: function ($event) {
                            return _vm.updateAlias()
                          },
                        },
                      },
                      [_vm._v("\n                Save\n              ")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }