var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-body",
    [
      _c("b-col", { attrs: { cols: "1" } }),
      _vm._v(" "),
      _c(
        "b-col",
        [
          _c(
            "b-card",
            { attrs: { "bg-variant": "default" } },
            [
              _c("b-form-input", {
                staticClass: "mr-sm-2",
                attrs: { size: "sm", placeholder: "Search template" },
                model: {
                  value: _vm.searchTemplates,
                  callback: function ($$v) {
                    _vm.searchTemplates = $$v
                  },
                  expression: "searchTemplates",
                },
              }),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered table-hover" }, [
                _c(
                  "tbody",
                  _vm._l(_vm.items, function (item) {
                    return _c("tr", { attrs: { scope: "row" } }, [
                      _c(
                        "td",
                        {
                          staticClass: "text-left",
                          on: {
                            blur: function ($event) {
                              return _vm.updateVersion(
                                $event,
                                0,
                                _vm.template.versions[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("selectTemplate", item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item))]
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }