export const templateVersionsMixins = {
	methods: {
		getTempalteVersionObjChanged (version_obj, field_num, event) {
			const version = JSON.parse(JSON.stringify(version_obj));

			let field_value = "";
			if (field_num != 3 && field_num != 2) {
				field_value = this.removeColorsInText(event.target.innerHTML); //htmlDecode(event.target.innerHTML);
			}

			this.hideLabelVariations();

			switch (field_num) {
			case 0:
				if (version.type == field_value) return;
				version.type = field_value;
				version_obj.type = field_value;
				break;
			case 1:
				if (version.narras == field_value) return;
				version.narras = field_value;
				version_obj.narras = field_value;
				break;
			case 2:
				field_value = this.weigth;
				if (version.weigth == field_value) return;
				version.weigth = field_value;
				version_obj.weigth = field_value;
				break;
			case 3:
				//field_value = this.$refs["text" + this.template.id].value;
				field_value = this.$refs["text" + this.template.id].internalValue;
				//if (version.text == field_value) return;
				// version.text_for_editor = field_value.replace(/·/g, " ");
				version.text_for_editor = field_value;
				version_obj.text_for_editor = version.text_for_editor;
				break;
			}

			version.current_version = true;
			version.root_id = this.template_root.id;
			//console.log("obj " + version_obj.text);
			//console.log("cop " + version.text);
			return version;
		},
		hideLabelVariations () {
			//Ocultamos los Highlightable que esten visibles
			for (const component in this.$refs) {
				if (component.indexOf("Highlightable") > -1) {
					if (this.$refs[component]) { this.$refs[component]._data.showMenu = false; }
				}
			}
		}
	}
};
