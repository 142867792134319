import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class DocumentTemplates extends BaseClient {
	constructor () {
		super(httpClient, "/documents/");
	}

	getAllCurrent (documentId) {
		return this.get(`${documentId}/templates/?current_version=true`);
	}

	getParents (documentId) {
		return this.get(`${documentId}/templates/parents`);
	}

	getAllCurrentSubtemplates (documentId) {
		return this.get(`${documentId}/templates/?current_version=true`);
	}

	getById (documentId, templateId) {
		return this.get(`${documentId}/templates/${templateId}`);
	}

	getTemplatesByNarraId (documentId, narraId) {
		return this.get(`${documentId}/templates/narra/${narraId}`);
	}

	getTemplatesByType (documentId, type) {
		return this.get(`${documentId}/templates/type/${type}`);
	}

	create (documentId, data) {
		return this.post(`${documentId}/templates/`, data);
	}

	delete (documentId) {
		return this.delete(`${documentId}/templates/${templateId}`);
	}

	order_update (documentId, data) {
		return this.patch(`${documentId}/templates/order`, data);
	}

	search (documentId, text, type) {
		return this.get(`${documentId}/templates/search/${text}?type=${type}`);
	}

	check (documentId) {
		return this.get(`${documentId}/templates/check`);
	}
}

export default new DocumentTemplates();
