var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "domains content" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "d-flex align-items-center mb-3" },
          [
            _c("b-col", [
              _c("h1", [_vm._v("Domains")]),
              _vm._v(" "),
              _c("p", [_vm._v("Choose a domain to start working")]),
            ]),
            _vm._v(" "),
            _c(
              "b-col",
              { staticClass: "d-flex justify-content-end" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success" },
                    on: { click: _vm.newModal },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                    _vm._v(" New\n\t\t\t\t\t"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          { staticClass: "domains__search" },
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c("b-form-input", {
                  staticClass: "mr-sm-2 input-search-template",
                  attrs: { placeholder: "Search" },
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-table-simple",
                  { attrs: { hover: "", small: "", "caption-top": "" } },
                  [
                    _c(
                      "b-thead",
                      [
                        _c(
                          "b-th",
                          {
                            staticClass: "text-left",
                            staticStyle: { width: "60%" },
                          },
                          [_vm._v("Domain")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "20%" },
                          },
                          [_vm._v("Engine")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-right",
                            staticStyle: { width: "15%" },
                          },
                          [_vm._v("Controls")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tbody",
                      _vm._l(_vm.domains_filter, function (domain) {
                        return _c(
                          "b-tr",
                          { key: domain.id },
                          [
                            _c(
                              "b-td",
                              { staticClass: "text-left align-middle" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "DocumentsList",
                                        params: { domainId: domain.id },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(domain.name) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-td",
                              { staticClass: "text-center align-middle" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.engineLabel(domain.engine_id)) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-td",
                              { staticClass: "text-right align-middle" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "DocumentsList",
                                        params: { domainId: domain.id },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          variant: "primary",
                                          title: "Work with this domain",
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "file-alt" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "light",
                                      title: "Edit domain general settings",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateModal($event, domain)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "cog" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "modal-new",
            attrs: {
              "hide-footer": "",
              id: "modal-new",
              title: "New Domain",
              centered: "",
            },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.updateDomain.apply(null, arguments)
                  },
                  reset: _vm.cancel,
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Engine:",
                      "label-for": "engine",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-select", {
                      attrs: { id: "engine", options: _vm.engines, size: "sm" },
                      model: {
                        value: _vm.form.engine_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "engine_id", $$v)
                        },
                        expression: "form.engine_id",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Name:",
                      "label-for": "name",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "name", required: "", placeholder: "Name" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Minerva Server:",
                      "label-for": "minerva_server",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "minerva_server",
                        placeholder: "Minerva Server",
                      },
                      model: {
                        value: _vm.form.minerva_server,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "minerva_server", $$v)
                        },
                        expression: "form.minerva_server",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Minerva Domain:",
                      "label-for": "minerva_url",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "minerva_url",
                        placeholder: "Minerva Domain",
                      },
                      model: {
                        value: _vm.form.minerva_url,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "minerva_url", $$v)
                        },
                        expression: "form.minerva_url",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-4",
                      label: "Description:",
                      "label-for": "description",
                      "label-align": "left",
                    },
                  },
                  [
                    _c("b-form-textarea", {
                      attrs: {
                        id: "description",
                        required: "",
                        placeholder: "Description",
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "footer",
                  { staticClass: "text-right mt-4" },
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "reset", variant: "secondary" } },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isLoading ? _c("Loading") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }