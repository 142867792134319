<template>
  <div>
    <div class="container">
      <br />
      <!--modal
				height="auto"
				width="100%"
				scrollable
				resizable
				name="NarraTreeEditorModal"
			>
				<NarrasTreeEditor :narraId="narraIdEditor"></NarrasTreeEditor>
			</modal-->

      <b-row>
        <b-col>
          <b-button variant="success" v-on:click="newModal">
            <font-awesome-icon icon="plus" />&nbsp;New
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h3 class="text-center">Structure</h3>
          <p class="text-center">Select a Section to start working</p>
          <p class="text-center">
            <router-link
              :to="{
                name: 'Home',
              }"
            >
              <b-button variant="secondary">
                <font-awesome-icon icon="backward" />&nbsp;Back
              </b-button>
            </router-link>
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="2"></b-col>
        <b-col cols="8">
          <br />
          <b-table-simple hover small caption-top>
            <b-thead>
              <b-th class="text-center" style="width: 65%">Section</b-th>
              <b-th class="text-center" style="width: 20%">Controls</b-th>
            </b-thead>
            <b-tbody>
              <b-tr
                scope="row"
                v-for="narra in narras.nodes"
                v-bind:key="narra.id"
              >
                <b-td class="text-left">
                  <router-link
                    v-if="
                      narra.versions[0].active ||
                      narra.versions[0].active == 'true'
                    "
                    :to="{
                      name: 'NarrasEditorView',
                      params: {
                        domainId: domainId,
                        narraId: narra.id,
                        narraName: narra.versions[0].name,
                      },
                    }"
                    >{{ narra.versions[0].name }}</router-link
                  >

                  <span v-else>{{ narra.versions[0].name }}</span>
                </b-td>
                <b-td class="text-right">
                  <router-link
                    v-if="
                      narra.versions[0].active ||
                      narra.versions[0].active == 'true'
                    "
                    :to="{
                      name: 'NarrasEditorView',
                      params: {
                        domainId: domainId,
                        narraId: narra.id,
                        narraName: narra.versions[0].name,
                      },
                    }"
                  >
                    <b-button variant="info"
                      ><font-awesome-icon icon="play"
                    /></b-button>
                  </router-link>
                  <b-button
                    variant="light"
                    v-on:click="updateModal($event, narra)"
                  >
                    <font-awesome-icon icon="cog" />
                  </b-button>

                  <b-button
                    variant="danger"
                    v-on:click="deleteModal($event, narra.id)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-modal
        hide-footer
        id="modal-new"
        title="New root narras tree"
        ref="modal-new"
        :no-close-on-backdrop="true"
				centered
      >
        <b-form @submit.stop.prevent>
          <b-form-group
            id="input-group-2"
            label="Name:"
            label-for="name"
            label-align="left"
          >
            <b-form-input
              id="name"
              :state="validationName"
              v-model="form.name"
              required
              placeholder="Name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label="Description:"
            label-for="description"
            label-align="left"
          >
            <b-form-textarea
              id="description"
              v-model="form.description"
              placeholder="Description"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-group-5" label-align="left">
            <b-form-checkbox
              id="active"
              value="true"
              v-model="form.active"
              unchecked-value="false"
              >Active</b-form-checkbox
            >
          </b-form-group>

          <b-button
            :disabled="!validationName"
            variant="primary"
            v-on:click="updateNarra"
            >Save</b-button
          >
          <b-button
            :disabled="!validationName"
            variant="primary"
            v-on:click="updateNarraAndEdit"
            >Save and add childs</b-button
          >
          <b-button type="reset" variant="danger" v-on:click="cancel"
            >Cancel</b-button
          >
        </b-form>
      </b-modal>

      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import domains_api from "../../api/domain.js";
import tree_nodes_api from "../../api/narras_tree_nodes.js";
import tree_nodes_versions_api from "../../api/narras_tree_nodes_versions.js";
import document_templates_api from "../../api/document_templates.js";
import template_version_api from "../../api/template_version.js";
import templates_api from "../../api/template.js";
import Loading from "@/components/UI/Loading";
import { alertMixins } from "@/mixins/alertMixins.js";
import { nschemaMixins } from "@/mixins/nschemaMixins.js";

export default {
	name: "NarrasList",
	mixins: [alertMixins, nschemaMixins],
	components: {
		Loading
	},
	data () {
		return {
			narras: {},
			isLoading: true,
			showDialog: false,
			narraIdEditor: 0,
			form: {
				id: "",
				name: "",
				narras: "",
				description: "",
				active: "1",
				domain_id: "",
				parent_id: "",
				narra_obj: {}
			}
		};
	},
	created () {
		this.getNarras();
		//templates = document_templates_api.getAll(documentId)
	},
	methods: {
		openDialog (narra) {
			console.log(narra);
			this.narraIdEditor = narra.id;
			//this.showDialog = true;
			this.$modal.show("NarraTreeEditorModal");
		},
		closeDialog () {
			this.showDialog = false;
		},
		isActive (value) {
			if (value == true && value == "true") {
				return true;
			}
			return false;
		},
		async getNarras () {
			let [data, err] = await domains_api.getNarras(this.domainId);

			let breadcrumb_items = [
				{
					text: "Domains",
					to: { name: "Home" }
				}
			];
			if (!err) {
				console.log(data);

				if (data.nodes == null) {
					data.nodes = [];
				}

				this.narras = data;
				breadcrumb_items.push({
					text: "Structure: " + data.name,
					active: true
				});
			} else {
				this.narras = { nodes: [] };
			}

			this.$store.commit("SET_BREADCRUMB_ITEMS", breadcrumb_items);

			let nschema = {};
			[data, err] = await domains_api.getNSchema(this.domainId);
			if (!err) {
				//console.log(data);
				nschema = data.nschema.replace(/\'/g, "\"").replace(/True/g, "\"True\"");
				this.$store.commit("SET_NSCHEMA", nschema);

				this.$store.commit(
					"SET_AUTOCOMPLETE_NSCHEMA_MEMBERS",
					this.putNSchemaAutocomplete(nschema, false)
				);
			} else {
				this.$store.commit("SET_NSCHEMA", {});
				this.$store.commit("SET_AUTOCOMPLETE_NSCHEMA_MEMBERS", {
					entities: []
				});
			}
			this.isLoading = false;
		},
		newModal () {
			this.form.active = true;
			this.form.id = "";
			this.form.name = "";
			this.form.narras = "";
			this.form.description = "";
			this.form.narra_obj = {};
			this.$refs["modal-new"].show();
		},
		updateModal (event, narra) {
			console.log(narra);
			this.form.active = narra.versions[0].active;
			this.form.id = narra.id;
			this.form.name = narra.versions[0].name;
			this.form.narras = narra.versions[0].narras;
			this.form.description = narra.versions[0].description;

			this.form.domain_id = this.domainId;
			this.form.parent_id = null;
			this.form.narra_obj = narra;

			this.$refs["modal-new"].show();
		},
		deleteModal ($event, narra_id) {
			this.$bvModal
				.msgBoxConfirm(
					"Please confirm that you want to delete this Segment. The operation can't be undone",
					{
						title: "Delete",
						size: "sm",
						buttonSize: "sm",
						okVariant: "danger",
						okTitle: "YES",
						cancelTitle: "NO",
						footerClass: "p-2",
						hideHeaderClose: true,
						centered: true
					}
				)
				.then((value) => {
					if (value) {
						this.deleteNarra(narra_id);
					}
				})
				.catch((err) => {
					//An error occurred
					this.isLoading = false;
				});
		},
		async deleteNarra (narra_id) {
			this.isLoading = true;
			console.log("eliminados " + narra_id);
			console.log(tree_nodes_api);
			const [data, err] = await tree_nodes_api.remove(narra_id);
			if (!err) {
				this.showForm = false;
				for (let i = 0; i < this.narras.nodes.length; i++) {
					if (this.narras.nodes[i].id === narra_id) {
						this.narras.nodes.splice(i, 1);
						i--;
					}
				}
			} else {
				console.log(err);
				this.showErrorAlert("Error: " + err.data.error);
			}
			this.isLoading = false;
		},
		async updateNarraAndEdit () {
			let data = await this.updateNarra();
			this.narraIdEditor = data.id;
			this.$modal.show("NarraTreeEditorModal");
			/*this.$router.push({
        name: "NarrasTreeEditor",
        params: { narraId: data.id }
      });*/
		},
		async updateNarra () {
			console.log("Add node");
			this.isLoading = true;

			let id_tree_node = this.form.id;
			let data_node_tree;
			let err;
			if (id_tree_node == "") {
				console.log("New node");
				let node = {
					domain_id: this.domainId,
					active: this.form.active
				};

				const [data, err] = await tree_nodes_api.create(node);
				data_node_tree = data;
				id_tree_node = data_node_tree.id;
			} else {
				data_node_tree = this.form.narra_obj;
				console.log("Update node node");
				err = 0;
			}

			if (!err) {
				//si se crea vien el nodo creamos la versión
				let version = {
					narra_tree_node_id: id_tree_node,
					parent_id: null,
					name: this.form.name,
					narras: this.form.narras,
					description: this.form.description,
					current_version: true,
					active: this.form.active
				};

				const [data_dt, err_dt] = await tree_nodes_versions_api.create(version);

				if (!err_dt) {
					console.log(data_dt);

					if (this.form.id == "") {
						data_node_tree.versions = [data_dt];
						this.narras.nodes.push(data_node_tree);
					} else {
						this.form.narra_obj.versions[0].name = this.form.name;
						this.form.narra_obj.versions[0].active = this.form.active;
					}
					this.$refs["modal-new"].hide();
				} else {
					console.error(err_dt);
				}
			} else {
				console.error(err);
			}
			this.isLoading = false;
			return data_node_tree;
		},
		cancel () {
			this.$refs["modal-new"].hide();
		}
	},
	props: {
		domainId: {}
	},
	computed: {
		validationName () {
			return this.form.name.length > 0;
		}
	}
};
</script>
