<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="light">
			<b-navbar-brand href="#">Paraphrasing Selector</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click="closeModal">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>
		<br />
		<div class="modal-div-container">
			<b-row>
				<b-col cols="12">
					<div class="card variations-card-columns variations-card">
						<div class="card-header variations-header">Paraphrasing</div>
						<div class="card-body">
							<label for="input-variations">Sentence:</label>
							<b-form-textarea
								id="input-variations"
								v-model="paraphrasing_selection"
								placeholder="Variations"
								trim
								rows="3"
								no-resize
								@keyup="changeTextVariations"
							></b-form-textarea>
							<br />

							<template v-if="loadingVariations">
								<b-spinner variant="primary" label="Spinning"></b-spinner>
							</template>
							<template v-else-if="variations_list.length > 0">
								<b-form-group
									label="Select phrases"
									class="overflow-auto paraphrasing-check-group-ml"
								>
									<b-form-checkbox
										v-for="option in variations_list"
										v-model="variations_check"
										:key="option.value"
										:value="option.value"
										stacked
									>
										{{ option.text }}
									</b-form-checkbox>
								</b-form-group>
								<div class="text-right">
									<b-button
										variant="info"
										@click.prevent="
											includeSelected(selection_text, variations_check)
										"
										>Include</b-button
									>
								</div>
							</template>
							<template v-else> No results found </template>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import minerva_api from "../../../api/minerva.js";

export default {
	name: "ParaphrasingSelector",
	data () {
		return {
			paraphrasing_selection: "",
			selection_text: "",
			variations_list: [],
			variations_check: [],
			loadingVariations: false,
			timeout: null
		};
	},
	props: {
		domain_id: Number,
		documenta_data_id: Number,
		language: String,
		modal_name: String
	},
	created () {
		//console.log("create");
		this.getAlternatives();
		this.getListVariations();
	},
	computed: {
		selection () {
			return this.$store.getters.getSelectionOnTemplate;
		},
		preview () {
			return this.$store.getters.getPreviewParaphrasing.previews;
		}
	},
	methods: {
		closeModal () {
			this.$modal.hide(this.modal_name);
		},
		changeTextVariations ($event) {
			clearTimeout(this.timeout);

			let self = this;
			this.timeout = setTimeout(function () {
				console.error("call getListVariations");
				self.getListVariations();
			}, 1000);
		},
		async getListVariations () {
			this.loadingVariations = true;

			let data, err;
			let list_options = [];

			[data, err] = await minerva_api.paraphrasing(
				this.$store.getters.getCurrentDomain.id,
				this.language,
				this.documenta_data_id,
				this.paraphrasing_selection
			);

			if (!err) {
				for (let i = 0; i < data.length; i++) {
					list_options.push({
						text: data[i].phrase,
						value: data[i].phrase_with_segments
					});
				}
			}

			console.log("paraphrasing", data);

			this.variations_list = list_options;
			this.loadingVariations = false;
		},
		includeSelected (text, selectedItems) {
			console.log("text", text);
			console.log("selectedItems", selectedItems);
			this.$emit("selectedItems", selectedItems);
		},
		getAlternatives () {
			//console.log(				"SELECTION #########################################",				this.selection			);
			let textPreview = "";
			for (let i = 0; i < this.preview.length; i++) {
				if (!this.preview[i].error) {
					textPreview = this.preview[i].result.replace(
						"(invalid scenario)",
						""
					);
					break;
				}
			}
			console.log("PREVIEW " + textPreview);
			this.paraphrasing_selection = textPreview; //this.selection.selectionText.replace(/·/g, " ");
			this.selection_text = this.paraphrasing_selection;
			return;

			const selectionText = this.selection.selectionText;
			const textStart = this.selection.textStart;
			const textStop = this.selection.textStop;
			const textComplete = this.selection.textComplete;
			const textBegin = textComplete.substring(0, textStart);
			const textEnd = textComplete.substring(textStop);

			this.selection_text = selectionText;

			console.log("textStart", textStart);
			console.log("textStop", textStop);
			console.log("textComplete", textComplete);
			console.log("textBegin", textBegin);
			console.log("textEnd", textEnd);

			const sentencesBegin = textBegin.split(".");
			console.log("sentencesBegin", sentencesBegin);

			const lastSentenceBegin = sentencesBegin[sentencesBegin.length - 1]
				.trim()
				.split(" ");
			console.log("lastSentenceBegin", lastSentenceBegin);

			const firstWords = lastSentenceBegin.slice(
				Math.max(lastSentenceBegin.length - 3, 0)
			);
			console.log("firstWords", firstWords);

			const sentencesEnd = textEnd.split(".")[0].trim().split(" ");
			console.log("sentencesEnd", sentencesEnd);

			const lastWords = sentencesEnd.splice(
				0,
				Math.max(lastSentenceBegin.length, 3)
			);
			//console.log("lastWords", lastWords);

			//console.log("PREVIEWSSSSS", this.preview);
			let textPreview2 = "";
			for (let i = 0; i < this.preview.length; i++) {
				if (!this.preview[i].error) {
					textPreview = this.preview[i].result.replace(
						"(invalid scenario)",
						""
					);
					break;
				}
			}
			console.log("PREVIEW " + textPreview);
			if (textPreview.length > 0) {
				const word_search = textComplete.substring(textStart, textStop);
				console.log("palabra " + word_search);

				const finalSentence = textPreview.replace(word_search, "[MASK]");

				console.log("finalSentence", finalSentence);

				this.paraphrasing_selection = finalSentence;
			} else {
				const finalSentence =
					firstWords.join(" ") + " [MASK] " + lastWords.join(" ");

				this.paraphrasing_selection = finalSentence;
			}
		}
	}
};
</script>
