import document_templates_api from "../api/document_templates.js";

export const templatesMixins = {
	computed: {
		templates: {
			get () {
				if (this.category_id == 1) { return this.$store.getters.getTemplatesRegular; } else if (this.category_id == 2) { return this.$store.getters.getTemplatesComposition; }
			},
			set (value) {
				if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", value); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", value); }

				/*if (
          this.document_data_selected != 0 &&
          this.filterSelected.name.length > 0
        ) {
          setTimeout(() => {
            //this.previewAll(null);
          }, 500);
        }*/
			}
		},
		all_templates () {
			return this.$store.getters.getTemplates;
		}
	},
	methods: {
		putColorsInText (text) {
			return text;
			/*return text
        .replace(/\[/g, '[<span class="badge badge-data-template">')
        .replace(/\]/g, "</span>]")
        .replace(/{/g, '{<span class="badge badge-synonym-template">')
        .replace(/}/g, "</span>}");*/
		},
		removeColorsInText (text) {
			/*text = text
        .replace(/\[<span class=\"badge badge-data-template\">/g, "[")
        .replace(/<\/span>\]/g, "]")
        .replace(/{<span class="badge badge-synonym-template">/g, "{")
        .replace(/<\/span>}/g, "}");*/
			//console.error("removeColorsInText", text);
			text = text.replace(/<\/?[^>]+(>|$)/g, "");
			text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");

			return text;
		},
		async loadTemplatesNV () {
			let self = this;
			document_templates_api.getAllCurrentSubtemplates(this.documentId).then(function ([data, err]) {
				//console.log(data);
				if (!err) {
					self.replaceSpacesWithPoints(data.templates);

					let templates_regular = data.templates.filter(function (template) {
						return template.category != null ? template.category.id == 1 : false;
					});

					let templates_composition = data.templates.filter(function (template) {
						return template.category != null ? template.category.id == 2 : false;
					});

					self.check_templates_order(templates_regular);
					self.check_templates_order(templates_composition);

					self.$store.commit("SET_TEMPLATES", data.templates);
					self.$store.commit("SET_TEMPLATES_REGULAR", templates_regular);
					self.$store.commit("SET_TEMPLATES_COMPOSITION", templates_composition);
				} else {
					self.$store.commit("SET_TEMPLATES", []);
					self.$store.commit("SET_TEMPLATES_REGULAR", []);
					self.$store.commit("SET_TEMPLATES_COMPOSITION", []);
				}
				self.isLoading = false;
			});
		},
		async loadTemplates () {
			let self = this;
			document_templates_api.getAllCurrentSubtemplates(this.documentId).then(function ([data, err]) {
				//console.log(data);
				if (!err) {
					//self.replaceSpacesWithPoints(data.templates);

					/*let templates_regular = data.templates.filter(function (template) {
            return template.category != null ? template.category.id == 1 : false;
          });*/

					let templates_composition = data.templates.filter(function (template) {
						return template.category != null ? template.category.id == 2 : false;
					});

					//formateamos los subtemplates
					/*let plain = false;
          if (self.$route.query.plain && self.$route.query.plain == "1")
            plain = true;

          if (!plain)
            templates_regular = self.putSubtemplatesObjs(templates_regular);

          self.check_templates_order(templates_regular);*/
					self.check_templates_order(templates_composition);

					self.$store.commit("SET_TEMPLATES", data.templates);
					//self.$store.commit("SET_TEMPLATES_REGULAR", templates_regular);
					self.$store.commit("SET_TEMPLATES_COMPOSITION", templates_composition);
				} else {
					self.$store.commit("SET_TEMPLATES", []);
					self.$store.commit("SET_TEMPLATES_REGULAR", []);
					self.$store.commit("SET_TEMPLATES_COMPOSITION", []);
				}
				self.isLoading = false;
			});
		},
		replaceSpacesWithPoints (templates) {
			[...templates].forEach(template => {
				try {
					template.versions[0].text = template.versions[0].text.replace(/\s+(?=[^<>]*(?:|$))/g, "\u00B7");
					template.versions[0].original_text = template.versions[0].text;
				} catch (err) {
					//console.debug(template, err)
				}
			});
		},
		check_templates_order (templates) {
			let order_template = 1;
			for (let index = 0; index < templates.length; index++) {
				//if (templates[index].order == null) {
				templates[index].order = order_template;
				//}
				order_template += 1;
			}
		},
		putSubtemplatesObjs (templates) {
			//return templates;
			//por cada template alimentamos sus subtemplates
			let templates_remove = {};
			[...templates].forEach(template => {
				template.subtemplates_objs = [];
				if (template.subtemplates) {
					//template.subtemplates_objs = {};
					this.addSubtemplatesToTemplate(template, templates, templates_remove);
				}
			});

			/*// quitar las subplatillas que tienen padre del primer nivel
      [...templates].forEach(template1 => {
        // por cada template hemos de ver si esta como subtemplate de otra
        //console.log("template1 id", template1.id);
        this.findTemplatesToRemove(templates, template1, templates_remove);
      });*/

			//console.error("Templates a eliminar", templates_remove);
			return this.filteTemplatesToRemove(templates, templates_remove);
		},
		putSubtemplatesObjsNV (templates) {
			//return templates;
			//por cada template alimentamos sus subtemplates
			let templates_remove = {};
			[...templates].forEach(template => {
				template.subtemplates_objs = [];
				if (template.subtemplates) {
					//template.subtemplates_objs = {};
					this.addSubtemplatesToTemplateNV(template, templates, templates_remove);
				}
			});

			/*// quitar las subplatillas que tienen padre del primer nivel
      [...templates].forEach(template1 => {
        // por cada template hemos de ver si esta como subtemplate de otra
        //console.log("template1 id", template1.id);
        this.findTemplatesToRemove(templates, template1, templates_remove);
      });*/

			//console.error("Templates a eliminar", templates_remove);
			return this.filteTemplatesToRemove(templates, templates_remove);
		},
		getTemplateObjByType (templates, subtemplate) {
			let objs = [];
			[...templates].forEach(template => {
				let type = "";
				if (template.versions) {
					if (template.versions[0]) {
						type = template.versions[0].type;
					} else {
						type = "";
					}
				} else {
					type = template;
				}
				//console.log("getTemplateObjByType", type, subtemplate.template);
				if (type == subtemplate.template) {
					//console.log("getTemplateObjByType push", template);
					objs.push({ "level": subtemplate.level, "template": template });
				}
			});
			return objs;
		},
		findTemplatesToRemove (templates, template1, templates_remove) {
			/*//console.log("findTemplatesToRemove");
      [...templates].forEach(template2 => {
        //console.log("template2 id", template2.id);
        [...template1.subtemplates_objs].forEach(subtemplate => {
          //console.log("subtemplate id", subtemplate.template.id);
          if (template2.id == subtemplate.template.id) {
            //console.log("Repetida", template2.versions[0].type);
            templates_remove[template2.id] = {};
          }
        });
      });*/
		},
		filteTemplatesToRemove (templates, templates_remove) {
			return templates.filter(function (template) {
				return !templates_remove[template.id];
			});
		},
		addSubtemplatesToTemplate (template, templates, templates_remove) {
			let plain = false;
			if (this.$route.query.plain && this.$route.query.plain == "1") { plain = true; }

			if (plain) return [];

			//console.log("addSubtemplatesToTemplate");
			[...template.subtemplates].forEach(subtemplate => {
				let tmpl = this.getTemplateObjByType(templates, subtemplate);
				//template.subtemplates_objs[subtemplate.level.toString()] = tmpl;
				//console.log("addSubtemplatesToTemplate", tmpl);
				[...tmpl].forEach(t => {
					templates_remove[t.template.id] = {};
				});
				template.subtemplates_objs = template.subtemplates_objs.concat(tmpl);
				template.subtemplates_objs_render = [];
			});
			//console.log("addSubtemplatesToTemplate FIN", template.subtemplates_objs);
			return template.subtemplates_objs;
		},
		addSubtemplatesToTemplateNV (template, templates, templates_remove) {
			let plain = false;
			if (this.$route.query.plain && this.$route.query.plain == "1") { plain = true; }

			if (plain) return [];

			//console.log("addSubtemplatesToTemplate");
			[...template.subtemplates].forEach(subtemplate => {
				let tmpl = this.getTemplateObjByType(templates, subtemplate);
				if (!subtemplate.template.includes("*")) {
					//template.subtemplates_objs[subtemplate.level.toString()] = tmpl;
					//console.log("addSubtemplatesToTemplate", tmpl);
					[...tmpl].forEach(t => {
						templates_remove[t.template.id] = {};
					});
					template.subtemplates_objs = template.subtemplates_objs.concat(tmpl);
					template.subtemplates_objs_render = [];
				};
			});
			//console.log("addSubtemplatesToTemplate FIN", template.subtemplates_objs);
			return template.subtemplates_objs;
		}
	}
};
