var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.html_errors.length > 0
    ? _c(
        "div",
        _vm._l(_vm.html_errors, function (error) {
          return _c(
            "pre",
            { key: error.id, staticClass: "preview_html_formated" },
            [
              !error.is_error
                ? _c("code", { staticClass: "code-style" }, [
                    _vm._v(_vm._s(error.text)),
                  ])
                : _c("code", { staticClass: "code-style-error" }, [
                    _vm._v(
                      _vm._s(error.text) + "\n" + _vm._s(error.error_text)
                    ),
                  ]),
              _vm._v("\n\t\t"),
            ]
          )
        }),
        0
      )
    : _c("pre", { staticClass: "preview_html_formated" }, [
        _c("code", { staticClass: "code-style" }, [
          _vm._v(_vm._s(_vm.html_formatted) + "\n\t\t"),
        ]),
        _vm._v("\n\t"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }