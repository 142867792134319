<template>
	<button
		@click.prevent="clic($event, template)"
		:class="buttonClass"
		id="preview-button"
	>
		<div class="">
			<font-awesome-icon :icon="icon_state" :size="size" v-if="!loading" />
			<b-spinner small label="Spinning" v-if="loading"></b-spinner>
			<span class="previews-multiplier" v-if="!loading && legend.length">{{
				legend
			}}</span>
		</div>
	</button>
</template>

<script>
export default {
	name: "ButtonCircle",
	data () {
		return {
			loading: false,
			state: true,
			icon_state: "play"
		};
	},
	props: {
		template: { type: Object },
		icon: { type: String, default: "play" },
		icon_second: { type: String, default: "" },
		legend: { type: String, default: "" },
		customClass: { type: String, default: "" },
		size: { type: String, default: "sm" }
	},
	mounted () {
		this.icon_state = this.icon;
	},
	computed: {
		buttonClass () {
			return "template-circle-button " + this.customClass;
		}
	},
	methods: {
		clic ($event, template) {
			//this.loading = true;
			this.$emit("click", $event, template);
		},
		changeState () {
			if (this.state) {
				this.icon_state = this.icon_second;
			} else {
				this.icon_state = this.icon;
			}
			this.state = !this.state;
		}
	}
};
</script>

<style lang="css" scoped>

button:focus {
	outline: none;
}
.invisible {
	display: none;
}

.visible {
	display: block;
}
</style>
