import axios from 'axios';
import store from "@/store";

const httpClient = axios.create({
  baseURL: process.env.ROOT_API,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json'
  },
});



httpClient.interceptors.request.use(function (config) {
	if (httpClient.defaults.headers.common["Authorization"] == undefined) {
		httpClient.defaults.headers.common["Authorization"] = "JWT " + localStorage.getItem("jwt_token");
	}

	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

httpClient.interceptors.response.use(function (response) {
	/*console.log(response)*/
	return [response.data, null];
}, function (error) {
	if (error.response.status == 401) {
		localStorage.removeItem("jwt_token");
		localStorage.removeItem("username");
		store.state.username = "";
		store.commit("SET_TRIGGER_REDIRECTION", `${process.env.ROOT_PATH}` + "/login");
	}
	return [null, error.response ? error.response : error.message];
});

export default httpClient;
