<template>
  <modal adaptive height="95%" width="900" name="find_replace">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="#">Find & replace</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-navbar-nav class="ml-auto">
        <a href="#" class="close-dialog" @click="closeModal">
          <font-awesome-icon icon="times" />
        </a>
      </b-navbar-nav>
    </b-navbar>
    <div class="modal-div-container-find_replace">
      <b-row class="mb-2 pr-1">
        <b-col cols="12">
          <b-form inline class="float-sm-right">
            <b-form-checkbox
              class="mr-2"
              value="true"
              v-model="filter_id"
              unchecked-value="false"
              @change="changeFilterByID($event)"
            >
              Filter by ID
            </b-form-checkbox>
            <b-form-input
              class="mr-1"
              placeholder="ID"
              :readonly="filter_id === 'false'"
              @keyup="changeSearchString($event)"
              v-model="template_id"
            />
            <b-form-checkbox
              class="mr-2"
              value="true"
              v-model="filter_type"
              unchecked-value="false"
              @change="changeFilterByType($event)"
            >
              Filter by segment
            </b-form-checkbox>
            <b-form-input
              class=""
              placeholder="Segment"
              :readonly="filter_type === 'false'"
              @keyup="changeSearchString($event)"
              v-model="type"
            />
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-2 pl-1 pr-1">
        <b-col cols="12">
          <b-form-input
            ref="search_input"
            class="sm-10"
            placeholder="Search in segments"
            @keyup="changeSearchString($event)"
            v-model="search"
          />
        </b-col>
      </b-row>

      <b-row class="mb-1 pl-1 pr-1">
        <b-col cols="12">
          <b-form-input
            class="sm-10"
            placeholder="Replace with"
            v-model="replace"
          />
        </b-col>
      </b-row>

      <hr />
      <b-navbar
        v-if="searchResults.length > 0"
        toggleable="lg"
        type="light"
        variant="light"
      >
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#" @click="previousOccurrence()" title="previous">
              <i class="fas fa-arrow-up"></i>
            </b-nav-item>
            <b-nav-item href="#" @click="nextOccurrence()" title="next">
              <i class="fas fa-arrow-down"></i>
            </b-nav-item>
            <b-nav-item href="#" @click="replaceCurrent()" title="next">
              Replace
            </b-nav-item>
            <b-nav-item href="#" @click="replaceAll()" title="next">
              Replace all
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-row>
        <b-col cols="12">
          <div class="mt-1 mb-2 result-list-findreplace">
            <template v-if="searchingSegment">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </template>
            <template v-else-if="searchResults.length > 0">
              <div
                v-for="template in searchResults"
                v-bind:key="template.versions[0].id"
                :ref="'template' + template.id"
              >
                <div :class="getClassForTemplate(template)">
                  <div class="card-header template-header pt-0 pb-0 pl-2">
                    <b-row align-v="center" class="template-row-header">
                      <b-col cols="8" class="m-0 p-0 pl-2 pt-1">
                        <span class="badge template-id-number">{{
                          template.id
                        }}</span
                        >&nbsp;
                        <label
                          class="template-history-date"
                          spellcheck="false"
                          v-text="template.versions[0].type"
                        >
                        </label>
                      </b-col>
                      <b-col cols="4" class="text-right pr-0 align-middle">
                        <a
                          href="#"
                          @click.prevent="navigateToTemplate(template)"
                          class="template-preview-icon template-circle-button"
                          title="Set current version"
                        >
                          <div class>
                            <i class="fas fa-arrow-alt-circle-right fa-lg"></i>
                          </div>
                        </a>
                      </b-col>
                    </b-row>
                    <b-row class="template-row-text">
                      <b-col cols="12" class="template-cell-text card-body">
                        <div
                          v-html="template.versions[0].text_for_editor"
                        ></div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <br />
              </div>
            </template>
            <template v-else-if="search.length > 0 && !searchingSegment">
              <p>Nothing found</p>
            </template>
          </div>
          <div class="text-right">
            <b-button variant="danger" @click.prevent="closeModal"
              >Cancel</b-button
            >
            <b-button
              variant="info"
              :disabled="searchResults.length == 0"
              @click.prevent="saveChanges"
              >Save changes</b-button
            >
          </div>
        </b-col>
      </b-row>
      <br />
    </div>
  </modal>
</template>

<script>
import document_templates_api from "@/api/document_templates.js";
import Highlightable from "@/components/UI/Highlightable/Highlightable.vue";
import HighlightableInput from "@/components/UI/Highlightable/HighlightableInput";
import { LinkedList, LinkedListNode } from "@/utils/LinkedList.js";

export default {
	name: "FindReplace",
	props: {
		documentId: {}
	},
	components: {
		Highlightable,
		HighlightableInput
	},
	data () {
		return {
			search: "",
			lastSearch: "",
			replace: "",
			searchingSegment: false,
			timeout: null,
			searchResults: [],
			filter_id: "false",
			template_id: "",
			filter_type: "false",
			type: "",
			listResults: null,
			currentTemplate: null,
			currentOccurrence: null
		};
	},
	computed: {
		thereAreOccurences () {
			if (this.listResults.head) {
				let currentTemplate = this.listResults.head;
				do {
					let currentText = currentTemplate.data.matches.head;

					let template_text = "";
					do {
						if (!currentText.data.replaced && !currentText.data.last) {
							return true;
						}
						currentText = currentText.next;
					} while (currentText);

					//currentTemplate.data.template.versions[0].text = template_text

					currentTemplate = currentTemplate.next;
				} while (currentTemplate);
			}

			return false;
		}
	},
	methods: {
		navigateToTemplate (template) {
			this.$store.commit("SET_ID_SEGMENT_FILTER", template.id);
			this.closeModal();
		},
		show () {
			this.search = "";
			this.lastSearch = "";
			this.replace = "";
			this.searchingSegment = "";
			this.searchResults = [];
			this.filter_type = "false";
			this.filter_id = "false";
			this.template_id = "";
			this.type = "";
			this.listResults = new LinkedList();

			this.$modal.show("find_replace");

			console.log(this.$refs);
			//this.$refs["search_input"].$el.focus();
			let self = this;
			setTimeout(() => {
				self.$refs["search_input"].$el.focus();
			}, 500);
		},
		closeModal () {
			this.$modal.hide("find_replace");
		},
		changeFilterByType () {
			if (this.type.length >= 0) {
				this.searchSegments();
			}
		},
		changeFilterByID () {
			if (this.template_id.length >= 0) {
				this.searchSegments();
			}
		},
		changeSearchString ($event) {
			//clear timeout variable
			clearTimeout(this.timeout);

			if ($event.keyCode == 13) {
				this.searchSegments();
			} else {
				if (this.search == this.lastSearch) {
					return;
				}
				let self = this;

				if (this.search == "") {
					this.searchResults = [];
					this.processSearch([]);
					this.highlightCurrentMatch();
					return;
				}

				this.searchingSegment = true;

				this.timeout = setTimeout(function () {
					//enter this block of code after 1 second
					//handle stuff, call search API etc.
					self.searchSegments();
				}, 1000);
			}
		},
		async searchSegments () {
			this.lastSearch = this.search;

			if (this.filter_id === "true" && this.template_id.length > 0) {
				this.searchingSegment = false; //search by id
				let templates = this.$store.getters.getTemplatesRegular;

				templates = templates.filter((template) => {
					return template.id == this.template_id;
				});

				this.searchResults = JSON.parse(JSON.stringify(templates));

				if (this.search.length > 0) {
					this.processSearch(this.searchResults);
					this.highlightCurrentMatch();
				}
			} else {
				if (this.searchSegments.length == 0) {
					this.searchResults = [];
				}

				this.searchingSegment = true;
				let searchType = "";
				if (this.filter_type == "true") searchType = this.type;

				const [data, err] = await document_templates_api.search(
					this.documentId,
					this.search,
					searchType
				);

				this.searchingSegment = false;

				if (data) {
					this.searchResults = JSON.parse(JSON.stringify(data));
					this.processSearch(this.searchResults);
					this.highlightCurrentMatch();
				}
			}
		},
		processSearch (results) {
			this.listResults = new LinkedList();

			// for (let i = 0; i < results.length; i++) {
				// let template = results[i];
			for (let template of results) {
				const text = template.versions[0].text_for_editor;
				const text_replace =
          "<span class='badge-template badge-found'>" + this.search + "</span>";
				const parts = text.split(this.search);
				let listMatches = new LinkedList();

				for (let j = 0; j < parts.length; j++) {
					const last_part = j == parts.length - 1;

					const data = {
						//template: template,
						beginText: parts[j],
						replaced: false,
						textReplace: text_replace,
						last: last_part
					};
					listMatches.push(data);
				}
				const dataTemplate = {
					template: template,
					matches: listMatches
				};
				this.listResults.push(dataTemplate);
			}

			this.currentTemplate = this.listResults.getAt(0);
			this.currentOccurrence = this.currentTemplate.data.matches.getAt(0);

			this.currentOccurrence.data.textReplace =
        "<span class='badge-template badge-found-current'>" +
        this.search +
        "</span>";
		},
		highlightCurrentMatch () {
			let currentTemplate = this.listResults.head;
			do {
				let currentText = currentTemplate.data.matches.head;

				let template_text = "";
				do {
					if (currentText.data.last) {
						template_text = template_text + currentText.data.beginText;
					} else {
						template_text =
              template_text +
              currentText.data.beginText +
              currentText.data.textReplace;
					}
					currentText = currentText.next;
				} while (currentText);

				currentTemplate.data.template.versions[0].text_for_editor =
          template_text;

				currentTemplate = currentTemplate.next;
			} while (currentTemplate);
		},
		nextOccurrence () {
			if (!this.currentOccurrence.data.replaced) {
				this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found'>" + this.search + "</span>";
			}

			if (this.currentOccurrence.next) {
				this.currentOccurrence = this.currentOccurrence.next;
			}

			if (this.currentOccurrence.data.last) {
				if (this.currentTemplate.next) {
					this.currentTemplate = this.currentTemplate.next;
				} else {
					this.currentTemplate = this.listResults.getAt(0);
				}
				this.currentOccurrence = this.currentTemplate.data.matches.getAt(0);
			}

			if (this.currentOccurrence.data.replaced) {
				if (this.thereAreOccurences) {
					this.nextOccurrence();
				}

				//this.currentOccurrence.data.textReplace = "<span class='badge-template badge-found-current'>" + this.replace + "</span>";
			} else {
				this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found-current'>" +
          this.search +
          "</span>";
			}
			this.scrollToNewItem(this.currentTemplate.data.template.id);
			this.highlightCurrentMatch();
		},
		previousOccurrence () {
			if (!this.currentOccurrence.data.replaced) {
				this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found'>" + this.search + "</span>";
			}

			if (this.currentOccurrence.prev) {
				this.currentOccurrence = this.currentOccurrence.prev;
			} else {
				if (this.currentTemplate.prev) {
					this.currentTemplate = this.currentTemplate.prev;
				} else {
					this.currentTemplate = this.listResults.getAt(
						this.listResults.length - 1
					);
				}
				this.currentOccurrence = this.currentTemplate.data.matches.getAt(
					this.currentTemplate.data.matches.length - 2
				);
			}

			if (this.currentOccurrence.data.replaced) {
				if (this.thereAreOccurences) {
					this.previousOccurrence();
				}
			} else {
				this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found-current'>" +
          this.search +
          "</span>";
			}
			this.scrollToNewItem(this.currentTemplate.data.template.id);
			this.highlightCurrentMatch();
		},
		replaceCurrent () {
			if (!this.currentOccurrence.data.replaced) {
				this.currentOccurrence.data.replaced = true;
				this.currentOccurrence.data.textReplace = this.replace;
			}
			this.nextOccurrence();
		},
		replaceAll () {
			while (this.thereAreOccurences) {
				this.replaceCurrent();
			}
		},
		saveChanges () {
			let templates = [];
			if (this.listResults.head) {
				let currentTemplate = this.listResults.head;
				do {
					let currentText = currentTemplate.data.matches.head;

					let changed = false;
					do {
						if (currentText.data.replaced && !currentText.data.last) {
							changed = true;
						}
						currentText = currentText.next;
					} while (currentText);
					if (changed) {
						console.log("push ", currentTemplate.data.template.id);
						templates.push(currentTemplate.data.template);
					}

					currentTemplate = currentTemplate.next;
				} while (currentTemplate);
			}
			this.closeModal();
			this.$emit("save", templates);
		},
		getClassForTemplate (template) {
			if (template == undefined) return "";

			let result = "card template_full_row";
			if (
				template.versions[0].type[0] != "_" &&
        template.versions[0].type[0] != "*"
			) {
				result += " template_border_root_template";
			} else if (template.versions[0].type[0] == "*") {
				result += " template_border_component_template";
			} else {
				result += " template_border_sub_template";
			}

			if (template.versions[0].type == "" && template.versions[0].text == "") {
				result += " empty-template";
			}

			return result;
		},
		scrollToNewItem (id) {
			const element = this.$refs["template" + id][0];
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center"
			});
		}
	}
};
</script>

<style scoped>
.result-list-findreplace {
  max-height: calc(100vh - 420px);
  overflow-y: auto;
  overflow-x: hidden;
  /*display: flex;*/
  /*align-items: stretch;*/
}

.modal-div-container-find_replace {
  margin: 10px;
  max-height: 780px;
}
</style>
