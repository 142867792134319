var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMenu,
              expression: "showMenu",
            },
          ],
          staticClass: "menu-highlightable",
          style: { left: _vm.x + "px", top: _vm.y + "px" },
          on: {
            mousedown: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "item-highlightable",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.handleAction("onVariations")
                },
              },
            },
            [_vm._v("\n\t\t\t\tVariations\n\t\t\t")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "item-highlightable",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.handleAction("onParaphrasing")
                },
              },
            },
            [_vm._v("\n\t\t\t\tParaphrasing\n\t\t\t")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "item-highlightable",
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.handleAction("onCreateTempByText")
                },
              },
            },
            [_vm._v("\n\t\t\t\tCreate segment\n\t\t\t")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }