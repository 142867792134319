import domains_api from "../api/domain.js";
import tree_nodes_api from "../api/narras_tree_nodes.js";
import tree_nodes_versions_api from "../api/narras_tree_nodes_versions.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";

export const narrasMixins = {
	mixins: [templatesMixins],
	computed: {
		scenarios () {
			const categories = this.$store.getters.getScenarios;
			let options = [];
			//options.push({ value: null, text: "Select a scenario" });
			for (let i = 0; i < categories.length; i++) {
				for (let j = 0; j < categories[i].scenarios.length; j++) {
					const scenario = categories[i].scenarios[j];
					options.push({ value: scenario.id, text: scenario.versions[0].name });
				}
			}

			let options_sorted = options.sort((x, y) => {
				/*if (x.text == "Select a scenario") return 1;
        if (y.text == "Select a scenario") return 1;*/

				if (x.text.toLowerCase() < y.text.toLowerCase()) return -1;
				if (x.text.toLowerCase() > y.text.toLowerCase()) return 1;

				return 0;
			});

			return options_sorted;
		}
	},
	methods: {
		getScenarioByID (scenario_id, name) {
			const scenarios = this.scenarios.filter(s => {
				return s.value == scenario_id && scenario_id != null;
			});
			if (scenarios.length > 0) {
				return scenarios[0].text;
			}
			return name;
		},
		async loadNarras (domainId, documentId) {
			let self = this;
			domains_api.getNarrasTreeTemplate(domainId, documentId).then(function ([data, err]) {
				let root_narras_obj = {};
				if (err) {
					root_narras_obj = { nodes: [] };
					self.$store.commit("SET_ROOT_NARRAS", root_narras_obj);
					return;
				}
				//console.log("getNarrasTreeTemplate", data);

				[...data].forEach(node => {
					node.versions[0].name = self.getScenarioByID(
						node.versions[0].scenario_id,
						node.versions[0].name
					);
				});

				self.$store.commit("SET_NARRAS_TEMPLATES", data);

				root_narras_obj.nodes = [];
				for (let index = 0; index < data.length; index++) {
					if (data[index].versions[0].parent_id == null) {
						root_narras_obj.nodes.push(data[index]);
					}
				}

				let rows = {};
				for (let index = 0; index < root_narras_obj.nodes.length; index++) {
					//Creamos un objeto con la geraquia del arbol
					let [root_branches, rows_tree] = self.getTreeSchema(
						root_narras_obj.nodes[index].id,
						data,
						""
					);
					rows[root_narras_obj.nodes[index].id] = rows_tree;
					//self.root_children[root_narras_obj.nodes[index].id] = root_branches;
				}
				self.$store.commit("SET_ROWS_NARRAS", rows);
				self.$store.commit("SET_ROOT_NARRAS", root_narras_obj);
			});
		},
		async fetchArchivedNarras (domainId, documentId) {
			let root_narras_obj = {};
			let self = this;
			const [data, error] = await domains_api.getArchivedNarras(domainId, documentId).then(function ([data, error]) {

				if (error) {
					root_narras_obj = { nodes: [] };
					return false;
				}

				root_narras_obj.nodes = [];
				for (let index = 0; index < data.length; index++) {
					if (data[index].versions[0].parent_id == null) {
						root_narras_obj.nodes.push(data[index]);
					}
				}

				let rows = {};
				for (let index = 0; index < root_narras_obj.nodes.length; index++) {
					//Creamos un objeto con la geraquia del arbol
					let [root_branches, rows_tree] = self.getTreeSchema(
						root_narras_obj.nodes[index].id,
						data,
						""
					);
					rows[root_narras_obj.nodes[index].id] = rows_tree;
					//self.root_children[root_narras_obj.nodes[index].id] = root_branches;
				}
				self.$store.commit("SET_ARCHIVED_ROOT_NARRAS", root_narras_obj);
				self.$store.commit("SET_ARCHIVED_NARRAS", rows);
			});
		},
		requestRemoveNode (node) {
			this.$bvModal
				.msgBoxConfirm("Please confirm that you want to delete.", {
					title: "Delete",
					size: "sm",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "YES",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: true,
					centered: true
				})
				.then(value => {
					if (value) {
						console.log("invoca");

						this.removeNode(node);
					}
				})
				.catch(err => {
					//An error occurred
					this.isLoading = false;
				});
		},
		async removeNode (node) {
			this.isLoading = true;
			console.log("eliminamos ", node);
			if (node.data) {
				const [data, err] = await tree_nodes_api.remove(node.data.id);
				if (!err) {
					this.showForm = false;
					node.remove();
					this.loadNarras(this.domainId, this.documentId);
					this.loadTemplates();
				} else {
					console.log(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				const [data, err] = await tree_nodes_api.remove(node.id);
				if (!err) {
					this.showForm = false;

					this.loadNarras(this.domainId, this.documentId);
					this.loadTemplates();
				} else {
					console.log(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			}
			this.isLoading = false;
		},
		getTreeSchema (root_id, narra_tree_node_branches, narras) {
			let node_schema = [];
			let rows_tree = [];
			//console.log("getTreeSchema");
			for (let i = 0; i < narra_tree_node_branches.length; i++) {
				if (root_id == narra_tree_node_branches[i].versions[0].parent_id) {
					let node_tree = {};
					let children = [];
					if (narras != "" && narra_tree_node_branches[i].versions[0].narras) {
						try {
							narra_tree_node_branches[i].versions[0].narras =
                narras +
                ", " +
                narra_tree_node_branches[i].versions[0].narras.replace(
                	",CALC_NARRA",
                	", CALC_NARRA"
                );
						} catch (err) {
							console.debug(narra_tree_node_branches[i], err);
						}
					}
					[narra_tree_node_branches[i].children, children] = this.getTreeSchema(
						narra_tree_node_branches[i].id,
						narra_tree_node_branches,
						narra_tree_node_branches[i].versions[0].narras
					);

					node_tree._children = children;

					node_tree.narras = narra_tree_node_branches[i].versions[0].narras;
					node_tree.scenario_id =
            narra_tree_node_branches[i].versions[0].scenario_id;

					const scenarios = this.scenarios.filter(s => {
						return (
							s.value == node_tree.scenario_id && node_tree.scenario_id != null
						);
					});
					//console.error("scenarios length ", scenarios);
					if (scenarios.length > 0) {
						node_tree.name = scenarios[0].text;
					} else {
						node_tree.name = narra_tree_node_branches[i].versions[0].name;
					}
					//console.log(narra_tree_node_branches[i].versions[0].name);
					//console.log(narra_tree_node_branches[i]);
					if (narra_tree_node_branches[i].templates) {
						//console.log("narra_tree_node_branches", narra_tree_node_branches[i]);
						if (narra_tree_node_branches[i].templates.length > 1) {
							node_tree.template = narra_tree_node_branches[i].templates.reduce(
								function (valorAnterior, valorActual, indice, vector) {
									//console.log("Actual");
									//console.log(valorAnterior);
									//console.log(valorActual);
									if (typeof valorAnterior === "object") {
										//si es la primera vuelta del bucle
										if (valorAnterior.versions[0].current_version)
										//Si es versión actual se añade, si no se desprecia
										{ valorAnterior = valorAnterior.versions[0].type; } else valorAnterior = "";
									}

									if (valorAnterior.length > 0) {
										if (valorActual.versions[0].current_version) {
											return (
												valorAnterior + ", " + valorActual.versions[0].type
											);
										} else {
											return valorAnterior;
										}
									} else if (valorActual.versions[0].current_version) {
										return valorActual.versions[0].type;
									} else {
										return "";
									}
								}
							);
						} else if (narra_tree_node_branches[i].templates.length > 0) {
							node_tree.template =
                narra_tree_node_branches[i].templates[0].versions[0].type;
						}
					}
					//node_tree.data_template = 				narra_tree_node_branches[i].template.versions[0];
					node_tree.data_node = narra_tree_node_branches[i];
					node_tree._showChildren = true;
					//node_tree._selectable = true;

					rows_tree.push(node_tree);
					node_schema.push(narra_tree_node_branches[i]);
				}
			}
			return [node_schema, rows_tree];
		},
		async updateNarra () {
			console.log("Add node");
			this.isLoading = true;

			let id_tree_node = this.form.id;
			let data_node_tree;
			let err;
			let message = "";
			if (id_tree_node == "") {
				message = "Tree created";
				console.log("New node");
				let node = {
					domain_id: this.domainId,
					active: this.form.active
				};

				const [data, err] = await tree_nodes_api.create(node);
				if (err) {
					console.error(err);
					this.showErrorAlert("Error: " + err.data.error);
					this.isLoading = false;
					this.$refs["modal-new"].hide();
					return;
				}

				data_node_tree = data;
				id_tree_node = data_node_tree.id;
			} else {
				data_node_tree = this.form.narra_obj;
				console.log("Update node node");
				err = 0;
			}

			if (!err) {
				//si se crea vien el nodo creamos la versión
				let version = {
					narra_tree_node_id: id_tree_node,
					parent_id: null,
					name: this.form.name,
					narras: this.form.narras,
					description: this.form.description,
					current_version: true,
					active: this.form.active
				};

				const [data_dt, err_dt] = await tree_nodes_versions_api.create(version);

				if (!err_dt) {
					console.log(data_dt);

					data_node_tree.versions = [data_dt];

					this.$refs["modal-new"].hide();

					setTimeout(function () {
						window.scrollTo(
							0,
							document.body.scrollHeight ||
              document.documentElement.scrollHeight
						);
					}, 500);

					this.$bvToast.toast(message, {
						"title": "Success ",
						"variant": "success",
						"solid": true,
						"auto-hide-delay": "2000",
						"appendToast": true
					});
				} else {
					console.error(err_dt);
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			}

			this.loadNarras(this.domainId, this.documentId);
			this.isLoading = false;
			return data_node_tree;
		},
		//esta funcion obtiene los padres de un elemento de un arbol de scenarios a partir del id de nodo pasado por parámetro
		getTreeNarraParent (id, level = 1) {
			if (level > 20) return [];
			const narras = this.$store.getters.getNarrasTemplates;
			let parents = [];
			[...narras].forEach((row) => {
				if (row && row.id == id) {
					let name = this.getScenarioByID(
						row.versions[0].scenario_id,
						row.versions[0].name
					);
					parents.push(name);

					if (row.versions[0].parent_id) {
						parents = parents.concat(
							this.getTreeNarraParent(row.versions[0].parent_id, level + 1)
						);

						return parents;
					}
				}
			});
			return parents;
		}
	}
};
