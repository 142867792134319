import { cond } from "lodash";
import * as par from "@/grammars/narras_grammar.js";

export const narrasInterpreterMixins = {
	methods: {
		expressionToConditions (expression) {
			let result = [];
			let clearedExpression = expression.replace(/(\r\n|\n|\r)/gm, "");
			console.debug("expression:" + clearedExpression);

			let conditionsParsed = par.parse(clearedExpression);

			result.push(conditionsParsed["first"]);

			if ("others" in conditionsParsed) {
				result = result.concat(conditionsParsed["others"]);
			}
			console.debug("result:" + result);

			return result;
		},
		conditionsToExpression (conditions) {
			let result = "CALC_NARRA_EXP( ";

			function conditionToExpression (condition, position) {
				//If not any conditions, then ignore
				if ((!condition.cond1) || (!condition.cond2)) {
					return "";
				}

				let cond1 = "data_row['" + condition.cond1 + "']";
				let cond2 = condition.cond2;

				if (condition.cast) {
					cond1 = condition.cast + "(" + cond1 + ")";
					cond2 = condition.cast + "(" + cond2 + ")";
				}

				let result = "(" + String(cond1) +
                    "" + String(condition.operator) + "" +
                    String(cond2) + ")";

				if (position != 0) {
					result = " " + condition.boolOperation + " " + result;
				}
				return result;
			}

			for (let i = 0; i < conditions.length; i++) {
				result += conditionToExpression(conditions[i], i);
			}

			result += " )";

			//If only one conditions, remove the spaces
			//if (conditions.length == 1) {
			//result = result.replace("(data_row[", "data_row[");
			//result = result.slice(0, -1);
			//}

			return result;
		}
	}
};
