<template>
  <b-modal class="segments-checker"  id="segments-checker" ref="segments-checker" size="lg" centered title="Segments Checker" @close="closeModal">
    <div class="segments-checker__body">
      <div class="row">
				<div class="col-12">
					<div v-bind:class="messageCssClass">
						<span v-if="!checkPerformed">Press in Check Segments to perform a check.</span>
						<span v-else-if="(hasErrors)"><font-awesome-icon icon="exclamation-circle" size="lg" /><strong>Found {{searchResults.length}} segments with errors.</strong></span>
						<span v-else-if="checkPerformed">No errors found.</span>
					</div>
				</div>
			</div>
			<template v-if="searchingSegment">
				<div class="segments-checker__spinner flex flex-center-x flex-center-y">
					<b-spinner variant="primary" label="Spinning"></b-spinner>
				</div>
			</template>
			<div v-if="searchResults.length > 0" class="row">
        <div class="col-12">
					<div class="flex flex-col">
						<div class="segments-checker__result-list">
							<div
							class="segments-checker__segment"
								v-for="template in searchResults"
								v-bind:key="template.versions[0].id"
								:ref="'template' + template.id"
							>
								<div :class="getClassForTemplate(template)">
									<div class="card-header segments-checker__card-template-header template-header pt-0 pb-0">
										<div class="flex flex-center-y flex-separate segments-checker__template-header template-row-header">
											<div class="pl-0">
												<span class="segments-checker__id badge template-id-number">{{ template.id }}</span>
												<span
													class="segments-checker__name template-history-date"
													spellcheck="false"
													v-text="template.versions[0].type"
												>
												</span>
											</div>
											<div class="segments-checker__preview-wrap text-right align-middle">
												<a
													href="#"
													@click.prevent="navigateToTemplate(template)"
													class="segments-checker__preview-icon template-preview-icon template-circle-button"
													title="Set current version"
												>
													<div>
														<i class="fas fa-arrow-alt-circle-right fa-lg"></i>
													</div>
												</a>
											</div>
										</div>
										<div class="segments-checker__template-text template-row-text">
											<div class="template-cell-text card-body">
												<div v-html="template.versions[0].text_for_editor"></div>
											</div>
										</div>
										<div class="segments-checker__fail-causes">
											<p><strong>Errors Found:</strong></p>
											<ul class="segments-checker__error-list">
												<li
													v-for="cause in template.failures.cause"
													:key="cause.type"
												>
													{{ cause.msg }}
													<div v-if="cause.type == 'MALFORMED_LABELS'">
														<ul>
															<li v-for="label in cause.labels" :key="label">{{ label }}</li>
														</ul>
													</div>
													<div v-else-if="cause.type == 'DUPLICATED_SEGMENT_TEXT'">
														<ul>
															<li v-for="segment_id in cause.repeated_segments_id" :key="segment_id">{{ segment_id }}</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
        </div>
      </div>
			<template v-else-if="search.length > 0 && !searchingSegment">
				<p>Nothing found</p>
			</template>
    </div>
		<template #modal-footer>
			<button class="btn btn-secondary" @click.prevent="closeModal">Close</button>
			<button class="btn btn-primary" @click.prevent="checkSegments" variant="primary">Check Segments</button>
		</template>
  </b-modal>
</template>

<script>
import document_templates_api from "@/api/document_templates.js";
import Highlightable from "@/components/UI/Highlightable/Highlightable.vue";
import HighlightableInput from "@/components/UI/Highlightable/HighlightableInput";
import { LinkedList, LinkedListNode } from "@/utils/LinkedList.js";

export default {
  name: "SegmentsChecker",
  props: {
    documentId: {},
  },
  components: {
    Highlightable,
    HighlightableInput,
  },
  data() {
    return {
      search: "",
      lastSearch: "",
      replace: "",
      searchingSegment: false,
      timeout: null,
      searchResults: [],
      filter_id: "false",
      template_id: "",
      filter_type: "false",
      type: "",
      listResults: null,
      currentTemplate: null,
      currentOccurrence: null,
			checkPerformed: false
    };
  },
  computed: {
    thereAreOccurences() {
      if (this.listResults.head) {
        let currentTemplate = this.listResults.head;
        do {
          let currentText = currentTemplate.data.matches.head;

          let template_text = "";
          do {
            if (!currentText.data.replaced && !currentText.data.last) {
              return true;
            }
            currentText = currentText.next;
          } while (currentText);

          //currentTemplate.data.template.versions[0].text = template_text

          currentTemplate = currentTemplate.next;
        } while (currentTemplate);
      }

      return false;
    },
		hasErrors () {
			return this.searchResults && this.searchResults.length > 0;
		},
		messageCssClass () {
			return ({
				"segments-checker__check-message": true,
				"segments-checker__check-message--error": this.hasErrors
			})
		}
  },
  methods: {
    navigateToTemplate(template) {
      this.$store.commit("SET_SEGMENT_ID_FILTER", template.id);
      this.closeModal();
    },
    show() {
      this.search = "";
      this.lastSearch = "";
      this.replace = "";
      this.searchingSegment = "";
      this.searchResults = [];
      this.filter_id = "false";
      this.template_id = "";

      this.$modal.show("segments_checker");

      console.debug(this.$refs);
    },
    closeModal() {
			this.$refs["segments-checker"].hide();
			this.searchResults = [];
			this.checkPerformed = false;
			this.searchingSegment = false;
    },

    processSearch(results) {
      this.listResults = new LinkedList();

      for (let i = 0; i < results.length; i++) {
        let template = results[i];
        const text = template.versions[0].text_for_editor;
        const text_replace =
          "<span class='badge-template badge-found'>" + this.search + "</span>";
        const parts = text.split(this.search);
        let listMatches = new LinkedList();

        for (let j = 0; j < parts.length; j++) {
          const last_part = j == parts.length - 1;

          const data = {
            //template: template,
            beginText: parts[j],
            replaced: false,
            textReplace: text_replace,
            last: last_part,
          };
          listMatches.push(data);
        }
        const dataTemplate = {
          template: template,
          matches: listMatches,
        };
        this.listResults.push(dataTemplate);
      }

      this.currentTemplate = this.listResults.getAt(0);
    },

    nextOccurrence() {
      if (!this.currentOccurrence.data.replaced) {
        this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found'>" + this.search + "</span>";
      }

      if (this.currentOccurrence.next) {
        this.currentOccurrence = this.currentOccurrence.next;
      }

      if (this.currentOccurrence.data.last) {
        if (this.currentTemplate.next) {
          this.currentTemplate = this.currentTemplate.next;
        } else {
          this.currentTemplate = this.listResults.getAt(0);
        }
        this.currentOccurrence = this.currentTemplate.data.matches.getAt(0);
      }

      if (this.currentOccurrence.data.replaced) {
        if (this.thereAreOccurences) {
          this.nextOccurrence();
        }

        //this.currentOccurrence.data.textReplace = "<span class='badge-template badge-found-current'>" + this.replace + "</span>";
      } else {
        this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found-current'>" +
          this.search +
          "</span>";
      }
      this.scrollToNewItem(this.currentTemplate.data.template.id);
    },
    previousOccurrence() {
      if (!this.currentOccurrence.data.replaced) {
        this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found'>" + this.search + "</span>";
      }

      if (this.currentOccurrence.prev) {
        this.currentOccurrence = this.currentOccurrence.prev;
      } else {
        if (this.currentTemplate.prev) {
          this.currentTemplate = this.currentTemplate.prev;
        } else {
          this.currentTemplate = this.listResults.getAt(
            this.listResults.length - 1
          );
        }
        this.currentOccurrence = this.currentTemplate.data.matches.getAt(
          this.currentTemplate.data.matches.length - 2
        );
      }

      if (this.currentOccurrence.data.replaced) {
        if (this.thereAreOccurences) {
          this.previousOccurrence();
        }
      } else {
        this.currentOccurrence.data.textReplace =
          "<span class='badge-template badge-found-current'>" +
          this.search +
          "</span>";
      }
      this.scrollToNewItem(this.currentTemplate.data.template.id);
    },
    replaceCurrent() {
      if (!this.currentOccurrence.data.replaced) {
        this.currentOccurrence.data.replaced = true;
        this.currentOccurrence.data.textReplace = this.replace;
      }
      this.nextOccurrence();
    },
    replaceAll() {
      while (this.thereAreOccurences) {
        this.replaceCurrent();
      }
    },
    async checkSegments() {

      this.isLoading = true;
			this.checkPerformed = true;
      this.searchingSegment = true;
      const [data, err] = await document_templates_api.check(this.documentId);

      if (data) {
        let resultTemplatesIds = data.failures.map((a) => a.template_id);
        let failuresDict = Object.assign(
          {},
          ...data.failures.map((x) => ({
            [x.template_id]: JSON.parse(JSON.stringify(x)),
          }))
        );

        this.searchResults = data;

        let templates = this.$store.getters.getTemplatesRegular;

        console.debug("Antes de filtrar");
				templates = templates.filter((template) => {
          return resultTemplatesIds.find((x) => x == template.id);
        });

        for (const template of templates) {
          template.failures = failuresDict[template.id];
        }

        this.searchResults = JSON.parse(JSON.stringify(templates));

        this.processSearch(this.searchResults);
      }
      this.isLoading = false;
      this.searchingSegment = false;
    },
    saveChanges() {
      let templates = [];
      if (this.listResults.head) {
        let currentTemplate = this.listResults.head;
        do {
          let currentText = currentTemplate.data.matches.head;

          let changed = false;
          do {
            if (currentText.data.replaced && !currentText.data.last) {
              changed = true;
            }
            currentText = currentText.next;
          } while (currentText);
          if (changed) {
            console.log("push ", currentTemplate.data.template.id);
            templates.push(currentTemplate.data.template);
          }

          currentTemplate = currentTemplate.next;
        } while (currentTemplate);
      }
      this.closeModal();
      this.$emit("save", templates);
    },
    getClassForTemplate(template) {
      if (template == undefined) return "";

      let result = "card template_full_row";
      if (
        template.versions[0].type[0] != "_" &&
        template.versions[0].type[0] != "*"
      ) {
        result += " template_border_root_template";
      } else if (template.versions[0].type[0] == "*") {
        result += " template_border_component_template";
      } else {
        result += " template_border_sub_template";
      }

      if (template.versions[0].type == "" && template.versions[0].text == "") {
        result += " empty-template";
      }

      return result;
    },
    scrollToNewItem(id) {
      const element = this.$refs["template" + id][0];
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.segments-checker {

	/deep/ .modal-body {
		padding-top: 0;
	}

	&__body {
  	max-height: 700px;
	}

	&__result-list {
		max-height: calc(100vh - 295px);
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__fail-causes {
		background-color: white;
		margin: 0;
		padding: .8rem;
	}

	&__check-message {
		text-align: center;
		padding: 1rem 0;
		width: 100%;

		&--error {
			background-color: var(--red);
			color: white;
			margin-bottom: 1rem;
		}
		svg {
			margin-right: .5rem;
		}
	}

	&__spinner {
		height: 50px;
	}

	&__template-header {
		margin: 0 !important;
		padding: .5rem;
	}

	&__preview-wrap {
		padding-right: .3rem;
	}

	&__preview-icon {
		font-size: 16px;
	}

	&__name {
		color: black;
		font-size: 1rem;
	}

	&__template-text {
		padding: .5rem;
	}

	&__card-template-header {
		padding: 0 !important;
	}

	&__id {
		font-family: inherit;
	}

	&__segment {
		margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
	}

}
</style>
