<template>
<div data-test-id="scenarios-branch-visual" class="scenarios-branch-visual">
	<template v-if="isLoading">
		<div class="d-flex justify-content-center align-items-center" style="height: 40px">
			<b-spinner variant="primary" label="Loading branches"></b-spinner>
		</div>
	</template>
	<template v-else>
		<template v-if="hasBranches">
			<ul class="scenarios-branch-visual__categories no-pd">
				<li
					class="scenarios-branch-visual__category-item"
					v-for="(category, categoryIndex) in addedCategories(branches)"
					:key="categoryIndex"
				>
					<span class="scenarios-branch-visual__category">{{ category.scenario_category_name }}</span>
					<ul class="scenarios-branch-visual__scenarios flex">
						<li
							class="scenarios-branch-visual__scenario"
							v-for="(scenario, indexScenario) in addedScenarios(category.scenarios)"
							:key="indexScenario"
						>
							<v-checkbox :label="scenario.name" :checked="scenario.enabled" :small="true">
								<input type="checkbox" :checked="scenario.enabled" disabled>
							</v-checkbox>
						</li>
					</ul>
				</li>
			</ul>
		</template>
		<template v-else>
			<p class="scenarios-branch-visual__no-data d-none">No cubes available</p>
		</template>
	</template>
</div>
</template>

<script>
import Checkbox from "@/components/UI/Checkbox.vue";
import { mapGetters } from 'vuex';

export default {
	name: "ScenariosBranchVisual",
	components: {
		"v-checkbox": Checkbox
	},
	data () {
		return {};
	},
	props: {
		branches: Array,
		isLoading: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		hasBranches () {
			return this.branches && this.branches.length > 0;
		},
		addedCategories () {
			return categories => categories.filter(category => category.active);
		},
		addedScenarios () {
			return scenarios => scenarios.filter(scenario => scenario.visible);
		}
	}
};
</script>

<style lang="scss" scoped>
.scenarios-branch-visual {
	background-color: var(--white);
	padding: 1.25rem;

	&__categories {
		max-height: 300px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&__category {
		display: inline-block;
		margin-bottom: .5rem;
	}

	&__scenario {
		margin-bottom: .5rem;
		margin-right: .5rem;
	}
}
</style>
