import * as types from "../mutation-types";

const state = {
	current_document: {},
	templates: [],
	templates_regular: [],
	templates_composition: [],
	nschema: [],
	autocomplete_members: [],
	autocomplete_nschema_members: [],
	selection_on_template: {},
	breadcrumb_items: [],
	document_data: [],
	document_data_selected: 0,
	preview_variations: [],
	preview_paraphrasing: [],
	navigate_template: [],
	scenarios: [],
	aliases: [],
	navigate_root: 0,
	engines: [],
	htEditorCollapsedState: [],
	status_filter: "",
	segment_id_filter: "",
	categoriesBranchGenerator: [],
	templatesToPreview: [],
	triggerTemplatesPreview: false,
	redirectTo: "",
	oldEditModeActive: true,
	treeNodeToArchive: {
		id: null,
		isRoot: false
	},
	treeNodeToRestore: {
		id: null,
		isRoot: false
	},
	treeNodeToDelete: {
		id: null,
		isRoot: false
	}
};

const getters = {
	getTemplates (state) {
		return state.templates;
	},
	getTemplatesRegular (state) {
		return state.templates_regular;
	},
	getTemplatesComposition (state) {
		return state.templates_composition;
	},
	getAutocompleteMembers (state) {
		return state.autocomplete_members;
	},
	getAutocompleteNSchemaMembers (state) {
		return state.autocomplete_nschema_members;
	},
	getNSchema (state) {
		return state.nschema;
	},
	getCurrentDocument (state) {
		return state.current_document;
	},
	getSelectionOnTemplate (state) {
		return state.selection_on_template;
	},
	getBreadcrumbItems (state) {
		return state.breadcrumb_items;
	},
	getTemplatesByFilter: state => (searchString, category_id) => {
		let templates;
		if (category_id == 1) templates = state.templates_regular;
		else if (category_id == 2) templates = state.templates_composition;

		if (templates) {
			return templates.filter(function (template) {
				let regex = new RegExp("(" + searchString + ")", "i");

				if (!template.versions[0]) return false;

				let foundName =
					template.versions[0].type != null
						? template.versions[0].type.match(regex)
						: false;
				let foundNarras =
					template.versions[0].narras != null
						? template.versions[0].narras.match(regex)
						: false;
				let foundText =
					template.versions[0].text != null
						? template.versions[0].text.match(regex)
						: false;

				return foundName || foundNarras || foundText;
			});
		}
		return [];

		//return state.templates.find(todo => todo.id === id)
	},
	getTemplatesByFilterOnlyParents: state => (searchString, category_id) => {
		let templates;
		if (category_id == 1) templates = state.templates_regular;
		else if (category_id == 2) templates = state.templates_composition;

		//console.log("getTemplatesByFilterOnlyParents");
		//Remove subtemplates
		templates = templates.filter(function (template) {
			if (template.versions.length == 0) {
				return false;
			}

			return !template.versions[0].type.startsWith("_");
		});

		if (templates) {
			return templates.filter(function (template) {
				let regex = new RegExp("(" + searchString + ")", "i");

				if (!template.versions[0]) return false;

				let foundName =
					template.versions[0].type != null
						? template.versions[0].type.match(regex)
						: false;
				let foundNarras =
					template.versions[0].narras != null
						? template.versions[0].narras.match(regex)
						: false;
				let foundText =
					template.versions[0].text != null
						? template.versions[0].text.match(regex)
						: false;

				return foundName || foundNarras || foundText;
			});
		}
		return [];

		//return state.templates.find(todo => todo.id === id)
	},
	getTemplatesByFilterOnlyParentsWithNarraTreeNode: state => (searchString, category_id, narra_tree_node_id) => {
		let templates;
		if (category_id == 1) templates = state.templates_regular;
		else if (category_id == 2) templates = state.templates_composition;

		templates = templates.filter(function (template) {
			if (template.versions.length == 0) {
				return false;
			}
			return !template.versions[0].type.startsWith("_");
		});

		if (templates) {
			let result = templates.filter(function (template) {
				let regex = new RegExp("(" + searchString + ")", "i");

				if (!template.versions[0]) return false;
				if (!((template.narra_tree_nodes_version[0]) && (template.narra_tree_nodes_version[0].narra_tree_node_id))) return false;

				let narra_tree_node_id_found = template.narra_tree_nodes_version[0].narra_tree_node_id == narra_tree_node_id;

				if (!narra_tree_node_id_found) {
					return false;
				}

				let foundName =
					template.versions[0].type != null
						? template.versions[0].type.match(regex)
						: false;
				let foundNarras =
					template.versions[0].narras != null
						? template.versions[0].narras.match(regex)
						: false;
				let foundText =
					template.versions[0].text != null
						? template.versions[0].text.match(regex)
						: false;

				let result = foundName || foundNarras || foundText;

				return result;
			});

			return result;
		}
		return [];

		//return state.templates.find(todo => todo.id === id)
	},
	getTemplatesByFilterByType: state => (searchType) => {
		if (!searchType) return [];
		searchType = searchType.replace("[", "").replace("]", "").replace("|m", "");

		if (!(searchType.startsWith("_") || searchType.startsWith("*"))) {
			return [];
		}

		let templates;
		templates = state.templates;

		//console.log("store.index.getTemplatesByFilterByType (" + searchType + "):" + templates.length);

		if (templates) {
			return templates.filter(function (template) {
				if (!template.versions[0]) return false;

				let foundName =
					template.versions[0].type != null
						? template.versions[0].type == searchType
						: false;
				return foundName;
			});
		}
		return [];

		//return state.templates.find(todo => todo.id === id)
	},
	getDocumentData (state) {
		return state.document_data;
	},
	getDocumentDataSelected (state) {
		return state.document_data_selected;
	},
	getPreviewVariations (state) {
		return state.preview_variations;
	},
	getPreviewParaphrasing (state) {
		return state.preview_paraphrasing;
	},
	getNavigateTemplate (state) {
		return state.navigate_template;
	},
	getScenarios (state) {
		return state.scenarios;
	},
	categoriesBranchGenerator (state) {
		return state.categoriesBranchGenerator;
	},
	getAliases (state) {
		return state.aliases;
	},
	getAllFormulaFilters (state) {
		return state.formula_filters;
	},
	getFormulaParser (state) {
		return state.formula_parser;
	},
	getStatusFilter (state) {
		return state.status_filter;
	},
	getSegmentIdFilter (state) {
		return state.segment_id_filter;
	},
	getNavigateRoot (state) {
		return state.navigate_root;
	},
	getEngines (state) {
		return state.engines;
	},
	getHtEditorCollapsedState (state) {
		return state.htEditorCollapsedState;
	},
	getHtEditorCollapsedStateForKey: state => (collapseIdKey) => {
		let key = "htEditorCollapsedState." + collapseIdKey;
		if (localStorage.getItem(key) !== null) {
			return localStorage.getItem(key) === "true";
		}
		return true;
	},
	getRowNarraCollapsedStateForKey: state => (collapseIdKey) => {
		let key = "rowNarraCollapsedState." + collapseIdKey;
		if (localStorage.getItem(key) !== null) {
			return localStorage.getItem(key) === "true";
		}
		return true;
	},
	getTemplatesToPreview (state) {
		return state.templatesToPreview
	},
	getTriggerTemplatesPreview (state) {
		return state.triggerTemplatesPreview;
	},
	getRedirectTo (state) {
		return state.redirectTo;
	},
	getOldEditModeActive (state) {
		return state.oldEditModeActive;
	},
	getTreeNodeToArchive (state) {
		return state.treeNodeToArchive;
	},
	getTreeNodeToRestore (state) {
		return state.treeNodeToRestore;
	},
	getTreeNodeToDelete (state) {
		return state.treeNodeToDelete;
	}
};

const mutations = {
	[types.SET_ENGINES] (state, payload) {
		state.engines = payload;
	},
	[types.SET_CURRENT_DOCUMENT] (state, payload) {
		state.current_document = payload;
	},
	[types.SET_TEMPLATES] (state, payload) {
		state.templates = payload;
	},
	[types.SET_TEMPLATES_REGULAR] (state, payload) {
		state.templates_regular = payload;
	},
	[types.SET_TEMPLATES_COMPOSITION] (state, payload) {
		state.templates_composition = payload;
	},
	[types.SET_DOCUMENT_DATA] (state, payload) {
		state.document_data = payload;
	},
	[types.SET_DOCUMENT_DATA_SELECTED] (state, payload) {
		state.document_data_selected = payload;
	},
	[types.SET_NSCHEMA] (state, payload) {
		state.nschema = payload;
	},
	[types.SET_AUTOCOMPLETE_MEMBERS] (state, payload) {
		state.autocomplete_members = payload;
	},
	[types.SET_BREADCRUMB_ITEMS] (state, payload) {
		state.breadcrumb_items = payload;
	},
	[types.SET_AUTOCOMPLETE_NSCHEMA_MEMBERS] (state, payload) {
		state.autocomplete_nschema_members = payload;
	},
	[types.SET_SELECTION_ON_TEMPLATE] (state, payload) {
		state.selection_on_template = payload;
	},
	[types.SET_PREVIEW_VARIATIONS] (state, payload) {
		state.preview_variations = payload;
	},
	[types.SET_NAVIGATE_TEMPLATE] (state, payload) {
		state.navigate_template = payload;
	},
	[types.SET_SCENARIOS] (state, payload) {
		let list = [...payload];
		list.map(category => category.scenarios.map(scenario => {
			scenario.visible = false;
			return true;
		}));
		state.scenarios = payload;
	},
	[types.SET_CATEGORIES_BRANCH_GENERATOR] (state, payload) {
		let categories = [...state.scenarios];
		//Structure adapted to branch generator
		if (categories.length) {
			categories = categories.map(el => {
				el.scenarios.map(item => { item.enabled = false; });
				return { scenario_category_id: el.id, scenario_category_name: el.name, order: null, scenarios: el.scenarios, disabled: false, selectedScenario: "" };
			});
		}
		state.categoriesBranchGenerator = categories;
	},
	[types.SET_ALIASES] (state, payload) {
		state.aliases = payload;
	},
	[types.SET_FORMULA_FILTERS] (state, payload) {
		state.formula_filters = payload;
	},
	[types.SET_FORMULA_PARSER] (state, payload) {
		state.formula_parser = payload;
	},
	[types.SET_STATUS_FILTER] (state, payload) {
		state.status_filter = payload;
	},
	[types.SET_SEGMENT_ID_FILTER] (state, payload) {
		state.segment_id_filter = payload;
	},
	[types.SET_NAVIGATE_ROOT] (state, payload) {
		state.navigate_root = payload;
	},
	[types.SET_HT_EDITOR_COLLAPSED_STATE] (state, payload) {
		state.htEditorCollapsedState = payload.currentData;
		let key = "htEditorCollapsedState." + payload.collapseIdKeyVar;
		localStorage.setItem(key, payload.value);
	},
	[types.SET_ROW_NARRA_COLLAPSED_STATE] (state, payload) {
		state.rowNarraCollapsedState = payload.currentData;
		let key = "rowNarraCollapsedState." + payload.collapseIdKeyVar;
		localStorage.setItem(key, payload.value);
	},
	[types.SET_TRIGGER_TEMPLATES_PREVIEW] (state, payload) {
		state.triggerTemplatesPreview = payload;
	},
	[types.SET_TEMPLATES_TO_PREVIEW] (state, payload) {
		state.templatesToPreview = payload;
	},
	[types.SET_UPDATE_TEMPLATE_PREVIEW_STATUS] (state, payload) {
		const template = state.templatesToPreview.find(template => template.id === payload.id);
		template.previewed = !template.previewed;
	},
	[types.SET_TRIGGER_REDIRECTION] (state, payload) {
		state.redirectTo = payload;
	},
	[types.TOGGLE_OLD_EDIT_MODE_ACTIVE] (state, payload) {
		state.oldEditModeActive = !state.oldEditModeActive;
	},
	[types.SET_TREE_NODE_TO_ARCHIVE] (state, payload) {
		state.treeNodeToArchive = payload;
	},
	[types.SET_TREE_NODE_TO_RESTORE] (state, payload) {
		state.treeNodeToRestore = payload;
	},
	[types.SET_TREE_NODE_TO_DELETE] (state, payload) {
		state.treeNodeToDelete = payload;
	}
};

export default {
	state,
	getters,
	mutations
};
