<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="light">
			<b-navbar-brand href="#">Entities selector</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-navbar-nav class="ml-auto">
				<a href="#" class="close-dialog" @click.prevent.stop="closeModal">
					<font-awesome-icon icon="times" />
				</a>
			</b-navbar-nav>
		</b-navbar>
		<br />
		<div class="modal-div-container">
			<b-row>
				<b-col class="ml-1">
					<div class="card entities-card-columns variations-card">
						<div class="card-header variations-header">Texts</div>
						<div class="card-body">
							<table class="table table-hover table-bordered">
								<tbody>
									<tr v-for="text in texts" :key="text.id">
										<td>
											<a href="#" @click.prevent.stop="selectText(text.id)">{{
												text.text
											}}</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</b-col>
				<b-col class="mr-1">
					<div class="card entities-card-columns variations-card">
						<div class="card-header variations-header">Entities</div>
						<div class="card-body">
							<EntitiesFormater :text_entities="text_entities_selected" />
						</div>
					</div>
				</b-col>
			</b-row>
			<div class="text-right mt-2 mr-1">
				<b-button variant="info" @click.prevent="includeEntities"
					>Create segment</b-button
				>
			</div>
		</div>
		<Loading v-if="isLoading"></Loading>
	</div>
</template>

<script>
import entities_api from "../../api/entities";

import Loading from "@/components/UI/Loading";
import EntitiesFormater from "@/components/Entities/EntitiesFormater.vue";
import { alertMixins } from "@/mixins/alertMixins.js";

export default {
	name: "EntitiesSelector",
	mixins: [alertMixins],
	components: { EntitiesFormater, Loading },
	data () {
		return {
			text_entities: [],
			text_entities_selected: [],
			isLoading: true
		};
	},
	props: {
		texts: Array,
		language: String,
		modal_name: String,
		insert: Boolean
	},
	created () {
		this.getEntities();
	},
	computed: {},
	methods: {
		async getEntities () {
			//se crea el objeto para obtener las entidades del backend

			const [data, err] = await entities_api.getAll({
				"language": this.language,
				"texts": this.texts
			});

			if (!err) {
				this.text_entities = data;
				this.selectText(1);
			} else {
				this.showErrorAlert("Error: " + err.data.error);
			}
			this.isLoading = false;
		},
		closeModal () {
			this.$modal.hide(this.modal_name);
		},
		selectText (text_id) {
			this.text_entities_selected = this.text_entities.texts.filter((t) => {
				return t.id == text_id;
			})[0];
		},
		includeEntities () {
			let texts = [];

			[...this.text_entities.texts].forEach((text) => {
				let new_text = "";
				[...text.fragments].forEach((fragment) => {
					new_text = new_text + fragment.text;
				});
				texts.push(new_text);
			});
			if (this.insert) {
				this.$emit("includeEntities", texts);
			} else {
				this.$emit("includeEntitiesTemplate", texts);
			}
		}
	}
};
</script>
