var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "light" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Alternatives Selector"),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-navbar-nav", { staticClass: "ml-auto" }, [
            _c(
              "a",
              {
                staticClass: "close-dialog",
                attrs: { href: "#" },
                on: { click: _vm.closeModal },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-div-container" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass: "card variations-card-columns variations-card",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header variations-header" },
                      [_vm._v("Variations")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("label", { attrs: { for: "input-variations" } }, [
                          _vm._v("Select Model:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("b-form-select", {
                              attrs: { options: _vm.optionsVariations },
                              model: {
                                value: _vm.variationsModelSelected,
                                callback: function ($$v) {
                                  _vm.variationsModelSelected = $$v
                                },
                                expression: "variationsModelSelected",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "input-variations" } }, [
                          _vm._v("Sentence:"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-textarea", {
                          attrs: {
                            id: "input-variations",
                            placeholder: "Variations",
                            trim: "",
                            rows: "3",
                            "no-resize": "",
                          },
                          on: { keyup: _vm.changeTextVariations },
                          model: {
                            value: _vm.variations_selection,
                            callback: function ($$v) {
                              _vm.variations_selection = $$v
                            },
                            expression: "variations_selection",
                          },
                        }),
                        _vm._v(" "),
                        _vm.loadingVariations
                          ? [
                              _c("b-spinner", {
                                attrs: {
                                  variant: "primary",
                                  label: "Spinning",
                                },
                              }),
                            ]
                          : _vm.variations_list.length > 0
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  staticClass:
                                    "overflow-auto variations-check-group-ml",
                                  attrs: { label: "Select BERT variations" },
                                },
                                _vm._l(_vm.variations_list, function (option) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        stacked: "",
                                      },
                                      model: {
                                        value: _vm.variations_check,
                                        callback: function ($$v) {
                                          _vm.variations_check = $$v
                                        },
                                        expression: "variations_check",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(option.text) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "info" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.includeSelected(
                                            _vm.selection_text,
                                            _vm.variations_check
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Include")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [_vm._v(" No results found ")],
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  { staticClass: "card variations-card-columns synonyms-card" },
                  [
                    _c("div", { staticClass: "card-header synonyms-header" }, [
                      _vm._v("Synonyms"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("label", { attrs: { for: "input-synonym" } }, [
                          _vm._v("Word:"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "input-synonym",
                            placeholder: "Synonym",
                            trim: "",
                          },
                          on: { change: _vm.getListSynonyms },
                          model: {
                            value: _vm.synonym_selection,
                            callback: function ($$v) {
                              _vm.synonym_selection = $$v
                            },
                            expression: "synonym_selection",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm.loadingSynonyms
                          ? [
                              _c("b-spinner", {
                                attrs: {
                                  variant: "primary",
                                  label: "Spinning",
                                },
                              }),
                            ]
                          : _vm.synonyms_list.length > 0
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  staticClass:
                                    "overflow-auto variations-check-group",
                                  attrs: { label: "Select synonyms" },
                                },
                                _vm._l(_vm.synonyms_list, function (option) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        stacked: "",
                                      },
                                      model: {
                                        value: _vm.synonyms_check,
                                        callback: function ($$v) {
                                          _vm.synonyms_check = $$v
                                        },
                                        expression: "synonyms_check",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(option.text) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "info" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.includeSelected(
                                            _vm.synonym_selection,
                                            _vm.synonyms_check
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Include")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [_vm._v(" No results found ")],
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }