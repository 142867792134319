import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class DocumentData extends BaseClient {
	constructor () {
		super(httpClient, "/documents/");
	}

	create (documentId, data) {
		return this.post(`${documentId}/data`, data);
	}

	getByDocumentId (documentId) {
		return this.get(`${documentId}/data`);
	}

	deleteDocumentData (documentId, data_id) {
		console.log("delete");
		return this.delete(`${documentId}/data/${data_id}`);
	}
	update (documentId, data_id, data) {
		return this.put(`${documentId}/data/${data_id}`, data);
	}

	getAll (documentId) {
		return this.get(`${documentId}/data/`);
	}
}

export default new DocumentData();
