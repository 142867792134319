<template>
	<div class="row-templates-container" v-if="archived_root_narras.length >0">
		<div class="root_narra_title" v-on:click="toggleCollapsed()">
			<div class="toggleControlNarras">
				<span v-if="!collapsed"
					><i class="fa fa-caret-down" aria-hidden="true"></i></span>
				<span v-else
					><i class="fa fa-caret-right" aria-hidden="true"></i></span>
			</div>
			<div class="root_narra_name_title">Archived trees</div>
		</div>
		<div
			v_if="collapsed"
			class="template-root"
		>
		<div class="row-templates-container">
      <div v-for="root_narra in archived_root_narras" :key="root_narra.id">
        <TableRootNarrasNV
					v-if="!collapsed"
          :root_narra="root_narra"
					:category_id="category_id"
					:documentId="$store.getters.getCurrentDocument.id"
					:currentDomain="$store.getters.getCurrentDomain"
					:currentDocument="$store.getters.getCurrentDocument"
					archived="true"
        >
        </TableRootNarrasNV>
				<!-- <TableRootNarrasNV
          :root_narra="root_narra"
          :first_child_of_root_narra_selected="
            first_child_of_root_narra_selected
          "
          :childs_of_root_narra_selected="childs_of_root_narra_selected"
          @addNewSegmentForRowNarra="addNewSegmentForRowNarra"
          @deleteTemplate="deleteTemplate"
          @templateDeleted="deleteTemplate"
          @templateDuplicated="templateDuplicated"
        >
        </TableRootNarrasNV> -->
      </div>
    </div>
			<!-- <TableTemplatesForRowNarraNV
				v-if="!collapsed"
				:templates_without_segements="false"
				:archived_root_narras="true"
				:category_id="category_id"
				:documentId="$store.getters.getCurrentDocument.id"
				:currentDomain="$store.getters.getCurrentDomain"
				:currentDocument="$store.getters.getCurrentDocument"
				currentRoute=""
			/> -->
		</div>
	</div>
</template>

<script>
import TableRootNarrasNV from "@/components/templates/TableRootNarrasNV";
export default {
	name: "TableArchivedSegments",
	components: {
		TableRootNarrasNV
	},
	data () {
		return {
			collapsed: true
		}
	},
	props: {
		category_id: String
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getSegmentIdFilter,
			(segment_id, oldValue) => {
				let template_filter = this.archived_root_narras.filter((t) => {
					return t.id === segment_id;
				});

				if (Array.isArray(template_filter) && template_filter.length > 0) {
					console.debug("TablWithoutsegments template_filter", template_filter);
					this.collapsed = false;
				}
			}
		);
	},
	computed: {
		archived_root_narras () {

			let narras = JSON.parse(
				JSON.stringify(this.$store.getters.getRootNarras)
			);

			if ("nodes" in narras) {
				return narras.nodes;
			}
			return [];

			let nodes = [];
			for (let narra of Object.keys(narras)) {
				console.debug("narra", narra);
				nodes.push(narras[narra][0].data_node);
			}

			return nodes;

		}
	},
	methods: {
		toggleCollapsed () {
			this.collapsed = !this.collapsed;
		}
	}
}
</script>
