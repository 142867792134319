var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row", class: _vm.clsScenarioRow }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-1 col-sm-2 col-form-label",
              attrs: { for: "name" },
            },
            [_vm._v("Name")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-9 col-sm-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: this.scenario.versions[0].name,
                  expression: "this.scenario.versions[0].name",
                },
              ],
              ref: "narras_name",
              staticClass: "form-control calc_narra_input",
              attrs: { type: "text", id: "name", placeholder: "Category_name" },
              domProps: { value: this.scenario.versions[0].name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    this.scenario.versions[0],
                    "name",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-2 col-sm-3 text-right" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "warning",
                    title: "Restore in memory version",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.restoreInMemoryVersion()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-undo",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "" },
                  on: {
                    click: function ($event) {
                      return _vm.updateScenarioValue()
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "save" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDeleteScenario([
                        _vm.category,
                        _vm.scenario,
                      ])
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "trash-alt" } })],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row mt-2 mb-2" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 col-1 col-form-label",
              attrs: { for: "definition_detailed" },
            },
            [_vm._v("Definition")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-10 col-11" },
            [
              _c(
                "at",
                {
                  ref: "at",
                  attrs: {
                    ats: _vm.trigerChars,
                    members: _vm.autocomplete_members,
                    hideOnBlur: "",
                    filterMatch: _vm.filterMatch,
                    filterMembers: _vm.filterMembersAutocomplete,
                  },
                  on: { at: _vm.autocompletAt },
                },
                [
                  _c(
                    "div",
                    {
                      ref: "narras_text",
                      staticClass: "form-control calc_narra_input",
                      attrs: {
                        contenteditable: "",
                        spellcheck: "false",
                        id: "narras",
                        placeholder: "Definition of Scenario",
                      },
                      on: {
                        keyup: _vm.handleKeyDown,
                        blur: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(this.scenario.versions[0].narra) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-0 mb-0" }, [
          _c("div", { staticClass: "col text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm",
                class: {
                  "btn-secondary": _vm.showDefinitionTool,
                  "btn-success": !_vm.showDefinitionTool,
                },
                on: { click: _vm.toggleDefinitionTool },
              },
              [
                _c("i", {
                  staticClass: "fa fa-wrench",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _vm.showDefinitionTool
                  ? _c("span", [_vm._v("Hide Assistant")])
                  : _c("span", [_vm._v("Show Assistant")]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.showDefinitionTool
          ? _c("div", { staticClass: "row mt-3 mb-3" }, [
              _c(
                "div",
                { staticClass: "col-lg-4 offset-lg-2 col-6 offset-sm-0" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-sm btn-block",
                      on: { click: _vm.transferToDefinitionTool },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-arrow-down",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" View in Assistant "),
                      _c("i", {
                        staticClass: "fa fa-arrow-down",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4 col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-sm btn-block",
                    on: { click: _vm.transferToDefinition },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-arrow-up",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Copy to\n            Definition "),
                    _c("i", {
                      staticClass: "fa fa-arrow-up",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.parsingError
          ? _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", { staticClass: "text-danger text-center" }, [
                  _vm._v(_vm._s(_vm.parsingError)),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showDefinitionTool
          ? _c(
              "div",
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.conditions, function (condition, conditionIndex) {
                  return _c(
                    "div",
                    { key: condition.id, staticClass: "form-row mb-2" },
                    [
                      _c("div", { staticClass: "col-xl-1 col-2" }, [
                        conditionIndex > 0
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: condition.boolOperation,
                                    expression: "condition.boolOperation",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: { id: "condition" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      condition,
                                      "boolOperation",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", [_vm._v("and")]),
                                _vm._v(" "),
                                _c("option", [_vm._v("or")]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-7 col-4" },
                        [
                          _c(
                            "at",
                            {
                              ref: "at",
                              refInFor: true,
                              attrs: {
                                ats: _vm.condition_trigerChars,
                                members: _vm.autocomplete_members,
                                hideOnBlur: "",
                                filterMatch: _vm.filterMatch,
                                filterMembers: _vm.filterMembersAutocomplete,
                              },
                              on: { at: _vm.autocompletAt },
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "form-control form-control-sm calc_narra_input",
                                attrs: {
                                  contenteditable: "",
                                  spellcheck: "false",
                                  type: "text",
                                  placeholder: "data.expression",
                                },
                                domProps: {
                                  innerHTML: _vm._s(condition.cond1),
                                },
                                on: {
                                  blur: function (event) {
                                    _vm.updateConditionOnInput(
                                      conditionIndex,
                                      event
                                    )
                                    _vm.closePanel()
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-1 col-2" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: condition.operator,
                                expression: "condition.operator",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  condition,
                                  "operator",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(
                            _vm.condition_operations,
                            function (operation) {
                              return _c("option", { key: String(operation) }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(operation) +
                                    "\n              "
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-3 col-4" }, [
                        _c("div", { staticClass: "row no-gutters" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: condition.cond2,
                                  expression: "condition.cond2",
                                },
                              ],
                              staticClass:
                                "form-control form-control-sm calc_narra_input",
                              attrs: { type: "text", placeholder: "value" },
                              domProps: { value: condition.cond2 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    condition,
                                    "cond2",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: condition.cast,
                                    expression: "condition.cast",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      condition,
                                      "cast",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.condition_cast, function (cast_type) {
                                return _c(
                                  "option",
                                  { key: String(cast_type) },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(cast_type) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-1 text-left pl-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger btn-sm",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCondition(conditionIndex)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-minus-circle",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "offset-md-0 col-md-6 text-left" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm",
                        attrs: { title: "Add Condition" },
                        on: { click: _vm.addEmptyCondition },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus-circle",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-1 col-2" }, [_vm._v("and/or")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-7 col-4" }, [_vm._v("Data")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-1 col-2" }, [_vm._v("Op.")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-3 col-4" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "col-6" }, [_vm._v("Value")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [_vm._v("Cast")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1 text-left pl-1" }, [_vm._v(" ")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }