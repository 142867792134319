var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "table-templates-nv",
      attrs: { id: "templates_back_ground" },
    },
    [
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar navbar-fixed-top",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              staticClass:
                "d-flex flex-column justify-content-start align-items-start",
              attrs: { id: "nav-collapse", "is-nav": "" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "row mb-2 mr-0 ml-0",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col pr-0 pl-0 d-flex justify-content-between",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-navbar-nav",
                            [
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "num-templates",
                                  attrs: { href: "#", title: "# of templates" },
                                },
                                [_vm._v(_vm._s(_vm.filterTemplates.length))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    href: "#",
                                    title: "Add a new segment",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRow(-1)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Add")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    href: "#",
                                    title:
                                      "Force reload all information from Database",
                                  },
                                  on: { click: _vm.refreshTemplates },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "sync-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Reload")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: { href: "#", title: "Find & Replace" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.$refs["findReplace"].show()
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fas fa-search" }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Find & Replace")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    href: "#",
                                    title:
                                      "Import parent segments from another domain",
                                  },
                                  on: { click: _vm.showImportModal },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "file-import" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Import")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    href: "#",
                                    title: "Check segments' syntax",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.$bvModal.show(
                                        "segments-checker"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "check" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Check")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    href: "#",
                                    title:
                                      "Deploy these templates in production",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("migrate")
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "database" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Copy to production")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-navbar-nav",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "b-nav-form",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("b-form-select", {
                                staticClass: "input-select-document mr-2",
                                attrs: {
                                  id: "engine",
                                  options: _vm.document_data,
                                  size: "sm",
                                },
                                model: {
                                  value: _vm.document_data_selected,
                                  callback: function ($$v) {
                                    _vm.document_data_selected = $$v
                                  },
                                  expression: "document_data_selected",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    variant: "success",
                                    title:
                                      "Run ALL templates with selected data file",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.previewAll($event)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "play" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    variant: "warning",
                                    title: "Manage data files",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dataModal($event)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "paperclip" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.topFunction },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "arrow-up" },
                                  }),
                                  _vm._v("Top\n\t\t\t\t\t\t\t\t"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "row mr-0 ml-0",
                  staticStyle: { width: "100%" },
                },
                [
                  _c("div", { staticClass: "col pr-0 pl-0" }, [
                    _c("form", [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("v-select", {
                              staticClass: "v-select mt-1 ml-0 pl-0",
                              attrs: {
                                options: _vm.narras_filter,
                                label: "text",
                                placeholder: "Filter by narra tree node",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "no-options",
                                  fn: function (ref) {
                                    var search = ref.search
                                    var searching = ref.searching
                                    return [
                                      searching
                                        ? [
                                            _c("em", [
                                              _vm._v(
                                                "Sorry, no matching option or was already defined"
                                              ),
                                            ]),
                                            _vm._v(".\n\t\t\t\t\t\t\t\t\t\t\t"),
                                          ]
                                        : _c("em", [
                                            _vm._v(
                                              "Sorry, no matching option or was already defined"
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.narras_filter_selected,
                                callback: function ($$v) {
                                  _vm.narras_filter_selected = $$v
                                },
                                expression: "narras_filter_selected",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-2" },
                          [
                            _c("v-select", {
                              staticClass: "v-select mt-1",
                              attrs: {
                                options: _vm.segment_id_filter,
                                label: "text",
                                placeholder: "Filter segment by id",
                              },
                              on: { input: _vm.changeSegmentIdFilter },
                              scopedSlots: _vm._u([
                                {
                                  key: "no-options",
                                  fn: function (ref) {
                                    var search = ref.search
                                    var searching = ref.searching
                                    return [
                                      searching
                                        ? [
                                            _c("em", [
                                              _vm._v(
                                                "Sorry, no matching option or was already defined"
                                              ),
                                            ]),
                                            _vm._v(".\n\t\t\t\t\t\t\t\t\t\t\t"),
                                          ]
                                        : _c("em", [
                                            _vm._v(
                                              "Sorry, no matching option or was already defined"
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.segment_id_filter_selected,
                                callback: function ($$v) {
                                  _vm.segment_id_filter_selected = $$v
                                },
                                expression: "segment_id_filter_selected",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filter_by_status,
                                  expression: "filter_by_status",
                                },
                              ],
                              staticClass: "form-control form-control-sm mt-1",
                              attrs: { id: "filter_by_status" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.filter_by_status = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.changeFilterByStatus,
                                ],
                              },
                            },
                            _vm._l(
                              _vm.filter_by_status_options,
                              function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.label,
                                    domProps: { value: option.value },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(option.label) +
                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-10" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sort_segments,
                                      expression: "sort_segments",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-sm mt-1",
                                  attrs: { id: "sort_segments" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.sort_segments = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.changeSortSegments,
                                    ],
                                  },
                                },
                                _vm._l(
                                  _vm.sort_segment_options,
                                  function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option.label,
                                        domProps: { value: option.value },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(option.label) +
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-2 pr-0" }, [
                              _c("div", { staticClass: "btn-group sort-btn" }, [
                                _c(
                                  "button",
                                  {
                                    class: "btn btn-light e ",
                                    attrs: { "data-sort": "none" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.changeSortSegmentsOrder.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      class: _vm.btn_sort_segments_class,
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row-templates-container" },
        _vm._l(_vm.root_narras, function (root_narra) {
          return _c(
            "div",
            { key: root_narra.id },
            [
              _c("TableRootNarrasNV", {
                attrs: {
                  root_narra: root_narra,
                  first_child_of_root_narra_selected:
                    _vm.first_child_of_root_narra_selected,
                  childs_of_root_narra_selected:
                    _vm.childs_of_root_narra_selected,
                  category_id: _vm.category_id,
                  documentId: _vm.documentId,
                  currentDomain: _vm.currentDomain,
                  currentDocument: _vm.currentDocument,
                  archived: "false",
                },
                on: {
                  addNewSegmentForRowNarra: _vm.addNewSegmentForRowNarra,
                  deleteTemplate: _vm.deleteTemplate,
                  templateDeleted: _vm.deleteTemplate,
                  templateDuplicated: _vm.templateDuplicated,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("TableTemplatesWithoutSegements", {
        attrs: { category_id: _vm.category_id },
        on: {
          deleteTemplate: _vm.deleteTemplate,
          templateDeleted: _vm.deleteTemplate,
          templateDuplicated: _vm.templateDuplicated,
        },
      }),
      _vm._v(" "),
      _c("TableArchivedSegments", { attrs: { category_id: _vm.category_id } }),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            height: "auto",
            width: "700",
            scrollable: "",
            resizable: "",
            name: "documentsDataListModal" + _vm.category_id,
          },
        },
        [
          _c("DocumentsDataList", {
            attrs: {
              documentID: _vm.documentId,
              ref_file_input: "file_input_templates" + _vm.category_id,
              modalName: "documentsDataListModal" + _vm.category_id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            height: "auto",
            width: "900",
            scrollable: "",
            resizable: "",
            name: "importModal" + _vm.category_id,
          },
        },
        [
          _c("ImportTemplatesModal", {
            attrs: { modalName: "importModal" + _vm.category_id },
            on: { importSegments: _vm.importSegments },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            height: "auto",
            width: "500",
            scrollable: "",
            resizable: "",
            name: "checkSegments" + _vm.category_id,
          },
        },
        [_c("div", { staticClass: "mt-4 mb-4" }, [_c("p", [_vm._v("Soon")])])]
      ),
      _vm._v(" "),
      _c("FindReplace", {
        ref: "findReplace",
        attrs: { documentId: _vm.documentId },
        on: { save: _vm.saveTemplatesFindReplace },
      }),
      _vm._v(" "),
      _c("SegmentsChecker", {
        ref: "segmentsChecker",
        attrs: { documentId: _vm.documentId },
        on: { save: _vm.saveTemplatesFindReplace },
      }),
      _vm._v(" "),
      _c("ModalArchiveTreeNode", { on: { refreshData: _vm.refreshData } }),
      _vm._v(" "),
      _c("ModalRestoreTreeNode", { on: { refreshData: _vm.refreshData } }),
      _vm._v(" "),
      _c("ModalDeleteTreeNode", { on: { refreshData: _vm.refreshData } }),
      _vm._v(" "),
      _c(
        "b-toast",
        {
          attrs: {
            id: "archive-segments-toast",
            variant: "success",
            solid: "",
          },
          scopedSlots: _vm._u([
            {
              key: "toast-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-1 align-items-baseline" },
                    [
                      _c("strong", { staticClass: "mr-auto" }, [
                        _vm._v("Elements archived"),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Elements archived succefully.")])]
      ),
      _vm._v(" "),
      _c(
        "b-toast",
        {
          attrs: {
            id: "restore-segments-toast",
            variant: "success",
            solid: "",
          },
          scopedSlots: _vm._u([
            {
              key: "toast-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-1 align-items-baseline" },
                    [
                      _c("strong", { staticClass: "mr-auto" }, [
                        _vm._v("Elements restored"),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Elements restored succefully.")])]
      ),
      _vm._v(" "),
      _c(
        "b-toast",
        {
          attrs: { id: "delete-segments-toast", variant: "success", solid: "" },
          scopedSlots: _vm._u([
            {
              key: "toast-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-1 align-items-baseline" },
                    [
                      _c("strong", { staticClass: "mr-auto" }, [
                        _vm._v("Elements deleted"),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Elements deleted succefully.")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }