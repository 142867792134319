<template>
  <div>
    <div class="row" v-bind:class="clsScenarioRow">
      <div class="col">
        <div class="form-group row">
          <label for="name" class="col-1 col-sm-2 col-form-label">Name</label>
          <div class="col-9 col-sm-7">
            <input
              type="text"
              v-model="this.scenario.versions[0].name"
              class="form-control calc_narra_input"
              id="name"
              placeholder="Category_name"
              ref="narras_name"
            />
          </div>
          <div class="col-2 col-sm-3 text-right">
            <b-button class="" variant="warning" @click="restoreInMemoryVersion()" title="Restore in memory version">
              <i class="fa fa-undo" aria-hidden="true"></i>
            </b-button>
            <b-button class="" variant="" @click="updateScenarioValue()">
              <font-awesome-icon icon="save" />
            </b-button>
            <b-button
              class=""
              variant="danger"
              @click="confirmDeleteScenario([category, scenario])"
            >
              <font-awesome-icon icon="trash-alt" />
            </b-button>
          </div>
        </div>
        <div class="form-group row mt-2 mb-2">
          <label for="definition_detailed" class="col-sm-2 col-1 col-form-label"
            >Definition</label
          >
          <div class="col-sm-10 col-11">
            <at
              :ats="trigerChars"
              :members="autocomplete_members"
              hideOnBlur
              @at="autocompletAt"
              :filterMatch="filterMatch"
              :filterMembers="filterMembersAutocomplete"
              :ref="'at'"
            >
              <div
                contenteditable
                spellcheck="false"
                id="narras"
                placeholder="Definition of Scenario"
                class="form-control calc_narra_input"
                ref="narras_text"
                @keyup="handleKeyDown"
                @blur="closePanel()"
              >
                {{ this.scenario.versions[0].narra }}
              </div>
            </at>
          </div>
        </div>
        <div class="row mt-0 mb-0">
          <div class="col text-right">
            <button
              class="btn btn-sm"
              :class="{
                'btn-secondary': showDefinitionTool,
                'btn-success': !showDefinitionTool,
              }"
              @click="toggleDefinitionTool"
            >
              <i class="fa fa-wrench" aria-hidden="true"></i>
              <span v-if="showDefinitionTool">Hide Assistant</span>
              <span v-else>Show Assistant</span>
            </button>
          </div>
        </div>

        <div class="row mt-3 mb-3" v-if="showDefinitionTool">
          <div class="col-lg-4 offset-lg-2 col-6 offset-sm-0">
            <button
              class="btn btn-success btn-sm btn-block"
              @click="transferToDefinitionTool"
            >
              <i class="fa fa-arrow-down" aria-hidden="true"></i> View in Assistant <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-lg-4 col-6">
            <button
              class="btn btn-warning btn-sm btn-block"
              @click="transferToDefinition"
            >
              <i class="fa fa-arrow-up" aria-hidden="true"></i> Copy to
              Definition <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="row mt-2" v-if="parsingError">
          <div class="col">
            <p class="text-danger text-center">{{ parsingError }}</p>
          </div>
        </div>

        <div v-if="showDefinitionTool">
          <!-- conditions list -->
          <div class="row">
            <div class="col-xl-1 col-2">and/or</div>
            <div class="col-xl-7 col-4">Data</div>
            <div class="col-xl-1 col-2">Op.</div>
            <div class="col-xl-3 col-4">
              <div class="row no-gutters">
                <div class="col-6">Value</div>
                <div class="col-4">Cast</div>
                <div class="col-1 text-left pl-1">&nbsp;</div>
              </div>
            </div>
          </div>

          <div
            class="form-row mb-2"
            v-for="(condition, conditionIndex) in conditions"
            v-bind:key="condition.id"
          >
            <div class="col-xl-1 col-2">
              <select
                class="form-control form-control-sm"
                id="condition"
                v-if="conditionIndex > 0"
                v-model="condition.boolOperation"
              >
                <option>and</option>
                <option>or</option>
              </select>
            </div>
            <div class="col-xl-7 col-4">
              <at
                :ats="condition_trigerChars"
                :members="autocomplete_members"
                hideOnBlur
                @at="autocompletAt"
                :filterMatch="filterMatch"
                :filterMembers="filterMembersAutocomplete"
                :ref="'at'"
              >
                <div
                  contenteditable
                  spellcheck="false"
                  type="text"
                  placeholder="data.expression"
                  class="form-control form-control-sm calc_narra_input"
                  @blur="
                    (event) => {
                      updateConditionOnInput(conditionIndex, event);
                      closePanel();
                    }
                  "
                  v-html="condition.cond1"
                ></div>
              </at>
            </div>
            <div class="col-xl-1 col-2">
              <select
                v-model="condition.operator"
                class="form-control form-control-sm"
              >
                <option
                  v-for="operation in condition_operations"
                  v-bind:key="String(operation)"
                >
                  {{ operation }}
                </option>
              </select>
            </div>
            <div class="col-xl-3 col-4">
              <div class="row no-gutters">
                <div class="col-6">
                  <input
                    type="text"
                    placeholder="value"
                    class="form-control form-control-sm calc_narra_input"
                    v-model="condition.cond2"
                  />
                </div>
                <div class="col-4">
                  <select
                    v-model="condition.cast"
                    class="form-control form-control-sm"
                  >
                    <option
                      v-for="cast_type in condition_cast"
                      v-bind:key="String(cast_type)"
                    >
                      {{ cast_type }}
                    </option>
                  </select>
                </div>
                <div class="col-1 text-left pl-1">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="removeCondition(conditionIndex)"
                  >
                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="offset-md-0 col-md-6 text-left">
              <button
                class="btn btn-success btn-sm"
                @click="addEmptyCondition"
                title="Add Condition"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/UI/Loading";
import At from "@/components/UI/AutoComplete/At.vue";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import { narrasInterpreterMixins } from "@/mixins/narrasInterpreterMixins.js";
import tree_nodes_versions_api from "../../api/narras_tree_nodes_versions.js";
import scenario_version_api from "../../api/scenario_version.js";
import scenario_api from "../../api/scenario.js";
import { getRandomStringId } from "../../utils/common.js";

export default {
	name: "ScenarioItemV3",
	mixins: [alertMixins, narrasMixins, templatesMixins, narrasInterpreterMixins],
	props: {
		scenario: {},
		domainId: {},
		documentId: {},
		category: {}
	},
	components: {
		Loading,
		At,
		ButtonCircle
	},
	data () {
		return {
			trigerChars: ["CALC", ".", "'", "CALC_NARRA_", "--"],

			condition_operations: ["==", "!=", "<", ">", "<=", ">="],
			condition_cast: [" ", "str", "float", "int"],
			condition_trigerChars: [".", "'", "--"],
			bScenarioChanged: false,
			conditions: [this.generateNewCondition()],
			parsingError: "",
			showDefinitionTool: false
		};
	},
	computed: {
		clsScenarioRow () {
			if (this.bScenarioChanged) return "row scenario-row-unsaved";
			return "row scenario-row";
		},
		autocomplete_members () {
			return this.$store.getters.getAutocompleteNSchemaMembers.entities;
		}
	},
	methods: {
		updateConditionOnInput (conditionIndex, event) {
			let text = event.target.innerText;
			this.conditions[conditionIndex].cond1 = text;
		},

		generateNewCondition () {
			let condition = {
				id: getRandomStringId(),
				cond1: "",
				cond2: "",
				cast: "",
				operator: "==",
				boolOperation: "and"
			};

			return condition;
		},
		toggleDefinitionTool () {
			this.showDefinitionTool = !this.showDefinitionTool;
		},
		closeDefinitionTool () {
			this.showDefinitionTool = false;
		},
		async transferToDefinition () {
			this.calculateDefinitionFromConditions();
		},
		async transferToDefinitionTool () {
			this.showDefinitionTool = true;
			this.parsingError = "";

			//let manualDefinition = this.scenario.versions[0].narra;
			let manualDefinition = this.$refs.narras_text.innerText;
			console.debug("manualDefinition:" + manualDefinition);

			let conditionsFromParse = [];
			try {
				conditionsFromParse = this.expressionToConditions(manualDefinition);
			} catch (error) {
				let errorText = "Parsing Error:" + error;
				this.parsingError = errorText;
				console.error(errorText);
				return;
			}

			if (conditionsFromParse) {
				let lenConditions = this.conditions.length;
				console.debug("lenConditions:" + lenConditions);
				this.conditions.splice(0, lenConditions);
				for (let i = 0; i < conditionsFromParse.length; i++) {
					this.conditions.push(conditionsFromParse[i]);
				}
			}
		},

		async removeCondition (index) {
			//console.debug("removeCondition:" + index);
			this.conditions.splice(index, 1);
			this.calculateDefinitionFromConditions();
		},
		addEmptyCondition () {
			//console.debug("addEmptyCondition");
			this.conditions.push(this.generateNewCondition());
		},
		async onBlurDefinition () {
			//console.debug("onBlurDefinition: " + value);
		},

		restoreInMemoryVersion () {
			this.showRestoreModal("nothing",
				() => {
					this.$refs.narras_text.innerText = this.scenario.versions[0].narra;
					this.$refs.narras_name.value = this.scenario.versions[0].name;
				},
				() => {
					console.debug("No restore scenario");
				}
			);
		},
		async calculateDefinitionFromConditions () {
			let result = this.conditionsToExpression(this.conditions);
			this.$refs.narras_text.innerText = result;
			this.checkDefinitionIntegrity();
			//this.scenario.versions[0].narra = result;
		},

		closePanel () {
			try {
				let panelRef = this.$refs["at"];
				if (Array.isArray(panelRef)) {
					panelRef = panelRef[0];
				}
				panelRef.closePanel();
			} catch (e) {
				//console.debug("Could not close panel:" + e);
			}
		},

		confirmDeleteScenario (categoryScenario) {
			this.showDeleteModal(
				categoryScenario,
				this.deleteScenario,
				this.noDeleteScenario
			);
		},
		async deleteScenario (categoryScenario) {
			this.isLoading = true;
			let category = categoryScenario[0];
			const scenario = categoryScenario[1];
			const [data, err] = await scenario_api.deleteScenario(scenario.id);
			if (err) {
				//console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				category.scenarios = category.scenarios.filter(function (s) {
					return s.id != scenario.id;
				});
				this.$bvToast.toast("Scenario deleted", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
				this.bScenarioChanged = false;
			}

			this.isLoading = false;
		},
		noDeleteScenario () {},
		handleKeyDown ($event) {
			checkDefinitionIntegrity();
		},
		checkDefinitionIntegrity () {
			let version = this.scenario.versions[0];

			if (
				version.name != this.$refs.narras_name.value ||
        version.narra != this.$refs.narras_text.innerText
			) { this.bScenarioChanged = true; } else this.bScenarioChanged = false;
		},
		async updateScenarioValue () {
			console.debug("updateScenarioValue");
			this.isLoading = true;

			let version = this.scenario.versions[0];

			version.name = this.$refs.narras_name.value;
			version.narra = this.$refs.narras_text.innerText;

			//console.log("POST version", version);

			const [data, err] = await scenario_version_api.createVersion(version);
			if (err) {
				//console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				await this.loadNarras(this.domainId, this.documentId);
				//this.loadTemplates();

				this.$bvToast.toast("Scenario updated", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});

				this.bScenarioChanged = false;
			}
			this.isLoading = false;
		},
		autocompletAt (index, chunk) {
			//console.log("evento at " + index + " --- " + chunk);
		},
		filterMatch (text, name, chunk, at) {
			//console.log("filterMatch vue ", text, name, chunk, at);
			return name.indexOf(chunk) > -1;
		},
		filterMembersAutocomplete (text, chunk, at, index) {
			//console.debug("filterMembersAutocomplete", text, chunk, at, index);
			if (at == "CALC" || at == "'" || at == "CALC_NARRA_") {
				let items = [];
				if (at == "CALC") {
					items = ["CALC_NARRA_", "CALC_NARRA_EXP(data_row['"];
					//.concat(this.autocomplete_members);
				} else {
					items = this.autocomplete_members;
				}

				return items.filter((v) => {
					return v.indexOf(chunk) > -1;
				});
			} else {
				//es un campo
				//hay que obtener de que entidad se trata
				//Puede ser algo:
				//- [Entity.field
				//- [Entity[0].field
				//- [Entity--relation->Entity2.field
				//- [Entity--relation->Entity2[1].field
				//__len__ y __sort__ estos son bastante especiales

				//hay que buscar la primera aparición hacia atras desde el index de at de los caracteres "[", "(" o "->"
				function searchOperand (text, index) {
					let lastCALC_NARRA = text
						.substring(0, index)
						.lastIndexOf("CALC_NARRA_");
					let lastQuote = text.substring(0, index).lastIndexOf("'");
					let lastBracket = text.substring(0, index).lastIndexOf("[");
					let lastUnderscore = text.substring(0, index).lastIndexOf("_");
					let lastOperand = text.substring(0, index).lastIndexOf("->");
					/*//console.log(
						"last ",
						lastCALC_NARRA,
						lastQuote,
						lastBracket,
						lastUnderscore,
						lastOperand
					);*/

					let fromChar =
            Math.max(
            	...[
            		lastCALC_NARRA,
            		lastQuote,
            		lastBracket,
            		lastUnderscore,
            		lastOperand
            	]
            ) + 1;
					//console.log("fromChar1 " + fromChar + ":" + text.substring(fromChar));

					return fromChar;
				}
				let fromChar = searchOperand(text, index);

				let isEndBracket = -1;
				if (text[fromChar - 1] == "[") {
					//puede que si hay un corchete sea por acceder a un elemento [0]
					isEndBracket = text.substring(fromChar - 1, index).lastIndexOf("]");
					//console.log("isEndBracket " + isEndBracket);
					if (isEndBracket > -1) {
						index = fromChar - 1;
						fromChar = searchOperand(text, fromChar - 2);
					}
				}
				//console.log("isEndBracket " + isEndBracket);
				if (
					text.substring(fromChar).lastIndexOf("ALC_NARRA_") > -1 &&
          isEndBracket < 0
				) {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					//console.log("Tiene ALC_NARRA_");
					fromChar += "ALC_NARRA_".length;
				}

				if (text[fromChar] == ">") {
					//si se trata del operador -> tenemos que avanzar el indice para evitar el caracter >
					fromChar++;
				}
				//console.log("fromChar2 " + fromChar + ":" + text.substring(fromChar));

				if (at == "__sort__") index--;
				let entity = text.substring(fromChar, index);
				//console.debug("Entity " + entity);
				if (entity.indexOf(".") > -1) {
					//La entidad no puede tener un punto como nombre ya que es un operador. Si viene un punto es que tenemos alguna firigrana
					//del tipo Index.__sort__region_name__desc[0]. La entidad es hasta el primer punto
					entity = entity.substring(0, entity.indexOf("."));
				}
				//console.log("Entity " + entity);
				//console.log("at " + at);

				if (at == "." || at == "__sort__") {
					//console.log("properties");
					//

					return this.$store.getters.getAutocompleteNSchemaMembers.properties[
						entity
					].filter((v) => {
						return v.indexOf(chunk) > -1;
					});
				} else if (at == "--") {
					return this.$store.getters.getAutocompleteNSchemaMembers.relations[
						entity
					].filter((v) => {
						return v.indexOf(chunk) > -1;
					});
				}
			}
		}
	}
};
</script>
