var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "content no-mw no-pd",
      on: {
        keydown: function ($event) {
          if (!$event.type.indexOf("key") && $event.keyCode !== 90) {
            return null
          }
          if (!$event.ctrlKey) {
            return null
          }
          return _vm.handleKeyDown($event)
        },
      },
    },
    [
      _c(
        "b-tabs",
        {
          attrs: { "content-class": "mt-0 ", "nav-class": "sticky-top" },
          model: {
            value: _vm.tabSelected,
            callback: function ($$v) {
              _vm.tabSelected = $$v
            },
            expression: "tabSelected",
          },
        },
        [
          _c(
            "b-tab",
            { attrs: { title: "Compositions" } },
            [
              _c("TableTemplatesV5", {
                attrs: {
                  active: "",
                  documentId: _vm.documentId,
                  category_id: "2",
                  title_navbar: "Compositions",
                  currentDocument: _vm.currentDocument,
                  currentDomain: _vm.currentDomain,
                },
                on: {
                  refresh: function ($event) {
                    return _vm.getEditorData()
                  },
                  migrate: function ($event) {
                    return _vm.migrate_domain()
                  },
                  importSegments: _vm.importSegments,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: _vm.$templatesName } },
            [
              _c("TableTemplatesV5", {
                attrs: {
                  documentId: _vm.documentId,
                  category_id: "1",
                  title_navbar: _vm.$templatesName,
                  currentDocument: _vm.currentDocument,
                  currentDomain: _vm.currentDomain,
                },
                on: {
                  refresh: function ($event) {
                    return _vm.getEditorData()
                  },
                  migrate: function ($event) {
                    return _vm.migrate_domain()
                  },
                  importSegments: _vm.importSegments,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Scenarios" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "cols-tree-scenarios",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("ScenariosEditor", {
                        ref: "ScenariosEditor",
                        attrs: {
                          domainId: _vm.domainId,
                          documentId: _vm.documentId,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "cols-tree-scenarios",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("NarrasTreeEditor", {
                        ref: "NarrasTreeEditor",
                        attrs: {
                          navbar: "false",
                          domainId: _vm.domainId,
                          documentId: _vm.documentId,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Data" } },
            [_c("DataViewer", { attrs: { documentId: _vm.documentId } })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "NScheme" } },
            [_c("NSchemaViewer", { attrs: { domainId: _vm.domainId } })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "title-nav-item" }, [
                        _vm._v("\n            Aliases\n          "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("AliasesEditor", {
                ref: "AliasesEditor",
                attrs: { domainId: _vm.domainId, documentId: _vm.documentId },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }