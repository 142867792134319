import * as types from "../mutation-types";

const state = {
	current_domain: {}
};

const getters = {
	getCurrentDomain (state) {
		return state.current_domain;
	}
};

const mutations = {
	[types.SET_CURRENT_DOMAIN] (state, payload) {
		state.current_domain = payload;
	}
};

export default {
	state,
	getters,
	mutations
};
