var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "hight",
    staticClass: "template-cell-text-input p-1 highlight-input",
    attrs: { contenteditable: _vm.canEdit, spellcheck: "false" },
    on: {
      blur: function ($event) {
        $event.preventDefault()
        return _vm.handleAction("blur", $event)
      },
      mouseup: function ($event) {
        return _vm.handleAction("mouseup", $event)
      },
      keyup: function ($event) {
        $event.preventDefault()
        return _vm.handleAction("keyup", $event)
      },
      focus: function ($event) {
        $event.preventDefault()
        return _vm.handleAction("focus", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }