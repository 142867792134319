import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class ScenariosVersions extends BaseClient {
	constructor () {
		super(httpClient, "/scenarios_versions/");
	}

	createVersion (version) {
		return this.post("", version);
	}

	deleteVersion (version_id) {
		return this.delete(version_id);
	}

	updateVersion (version) {
		return this.patch(version.id, version);
	}
}

export default new ScenariosVersions();
