import * as types from "../mutation-types";

const state = {
	nodeTreeId: null,
	setScenariosBranchesToEdit: {},
	cubes: []
};

const getters = {
	getNodeTreeId (state) {
		return state.nodeTreeId;
	},
	getScenariosBranchesToEdit (state) {
		return state.setScenariosBranchesToEdit;
	},
	getCubes (state) {
		return state.cubes;
	}
};

const mutations = {
	[types.SET_NODE_TREE_ID] (state, payload) {
		state.nodeTreeId = payload;
	},
	[types.SET_SCENARIOS_BRANCHES_TO_EDIT] (state, payload) {
		state.setScenariosBranchesToEdit = payload;
	},
	[types.SET_CUBES] (state, payload) {
		console.log(payload)
		const item = state.cubes.findIndex(item => item.id === payload.id);
		console.log(item)
		if (item > -1) {
			state.cubes[item].branches = payload.branches
		} else {
			state.cubes.push({ id: payload.id, branches: payload.branches })
		}
	}
};

export default {
	state,
	getters,
	mutations
};
