var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content" },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "light", variant: "info" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _vm._v("Document ID " + _vm._s(_vm.documentId)),
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    { attrs: { href: "#" }, on: { click: _vm.addRow } },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/imgs/plus_icon.png") },
                      }),
                      _vm._v("New Template\n\t\t\t\t"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("table", { staticClass: "table table-bordered table-hover" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.templates, function (template) {
            return _c("tr", { attrs: { scope: "row" } }, [
              _c("td", {
                staticClass: "text-left",
                attrs: { contenteditable: "", spellcheck: "false" },
                domProps: { textContent: _vm._s(template.versions[0].type) },
                on: {
                  blur: function ($event) {
                    return _vm.updateVersion($event, 0, template.versions[0])
                  },
                },
              }),
              _vm._v(" "),
              _c("td", {
                staticClass: "text-left",
                attrs: { contenteditable: "", spellcheck: "false" },
                domProps: { textContent: _vm._s(template.versions[0].narras) },
                on: {
                  blur: function ($event) {
                    return _vm.updateVersion($event, 1, template.versions[0])
                  },
                },
              }),
              _vm._v(" "),
              _c("td", {
                staticClass: "text-left",
                attrs: { contenteditable: "", spellcheck: "false" },
                domProps: { textContent: _vm._s(template.versions[0].weigth) },
                on: {
                  blur: function ($event) {
                    return _vm.updateVersion($event, 2, template.versions[0])
                  },
                },
              }),
              _vm._v(" "),
              _c("td", {
                staticClass: "text-left",
                attrs: { contenteditable: "", spellcheck: "false" },
                domProps: {
                  textContent: _vm._s(template.versions[0].text_for_editor),
                },
                on: {
                  blur: function ($event) {
                    return _vm.updateVersion($event, 3, template.versions[0])
                  },
                },
              }),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Narras")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Weigth")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Text")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }