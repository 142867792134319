var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        {
          staticClass: "navigation_sticky_bar",
          attrs: {
            sticky: "",
            toggleable: "lg",
            type: "light",
            variant: "light",
          },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Add new Scenario Tree" },
                      on: {
                        click: function ($event) {
                          return _vm.newModal()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" \n          "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Expand" },
                      on: {
                        click: function ($event) {
                          return _vm.expandAll()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-expand",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" \n          "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { href: "#", title: "Collapse" },
                      on: {
                        click: function ($event) {
                          return _vm.collapseAll()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-compress",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" \n          "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-form",
                    [
                      _c("b-form-input", {
                        staticClass: "form-control-sm",
                        attrs: { placeholder: "Filter Tree" },
                        model: {
                          value: _vm.searchTree,
                          callback: function ($$v) {
                            _vm.searchTree = $$v
                          },
                          expression: "searchTree",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "h4 scenario_main_title" }, [
            _vm._v("Scenarios Trees"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filterTrees, function (root_node, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "ml-0",
                attrs: { "align-h": "start" },
              },
              [
                _c(
                  "div",
                  { staticClass: "categoryscenario-header" },
                  [
                    _c(
                      "span",
                      {
                        class: { "node-text-inactive": !root_node.active },
                        attrs: { contenteditable: "", spellcheck: "false" },
                        on: {
                          blur: function ($event) {
                            return _vm.editNode($event, root_node)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(root_node.versions[0].name) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "template-circle-button",
                        attrs: { title: "Add new node" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.addNode(root_node)
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "plus", size: "sm" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "template-circle-button",
                        attrs: { title: "Add node by category" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.addNodeByCategory(root_node)
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "code-branch", size: "sm" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.enableCubes
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            ref: "openBranchGeneratorBtn",
                            refInFor: true,
                            staticClass:
                              "template-circle-button categoryscenario-header__generator-btn",
                            attrs: { title: "Open branch generator" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.openBranchGenerator(root_node.id)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "project-diagram", size: "sm" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ButtonCircle", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      ref: "ButtonCollapse" + root_node.id,
                      refInFor: true,
                      attrs: {
                        template: root_node,
                        icon: "angle-down",
                        icon_second: "angle-up",
                        title: "Expand / Collapse",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toogleTree(root_node)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-collapse",
                  {
                    ref: "collapse-" + root_node.id,
                    refInFor: true,
                    staticClass: "mt-2",
                    attrs: { id: "collapse-" + root_node.id },
                  },
                  [
                    _c("ScenariosTree", {
                      ref: "tree" + root_node.id,
                      refInFor: true,
                      attrs: {
                        root_node: root_node,
                        domainId: _vm.domainId,
                        documentId: _vm.documentId,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("Loading") : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal-new",
          attrs: {
            "hide-footer": "",
            id: "modal-new",
            title: "New root narras tree",
            "no-close-on-backdrop": true,
            centered: "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-2",
                    label: "Name:",
                    "label-for": "name",
                    "label-align": "left",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      state: _vm.validationName,
                      required: "",
                      placeholder: "Name",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-4",
                    label: "Description:",
                    "label-for": "description",
                    "label-align": "left",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: { id: "description", placeholder: "Description" },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { id: "input-group-5", "label-align": "left" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "active",
                        value: "true",
                        "unchecked-value": "false",
                      },
                      model: {
                        value: _vm.form.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "active", $$v)
                        },
                        expression: "form.active",
                      },
                    },
                    [_vm._v("Active")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { disabled: !_vm.validationName, variant: "primary" },
                  on: { click: _vm.updateNarra },
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { type: "reset", variant: "danger" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.enableCubes
        ? _c("scenarios-branch-generator", {
            attrs: { branches: _vm.scenariosBranches },
            on: { reloadTree: _vm.reloadTree },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-toast",
        {
          attrs: {
            id: "branch-generator-toast",
            variant: "success",
            solid: "",
          },
          scopedSlots: _vm._u([
            {
              key: "toast-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-1 align-items-baseline" },
                    [
                      _c("strong", { staticClass: "mr-auto" }, [
                        _vm._v("Branches generator"),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Branches generated succefully.")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }