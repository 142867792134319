var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidenav",
      style: _vm.styleWidth,
      attrs: { id: "mySidenav" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "closebtn badge badge-success sticky-top",
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_vm._v("×")]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }