/* eslint-disable */
<template>
  <div class="table-templates-nv" id="templates_back_ground">
    <b-navbar
      sticky
      toggleable="lg"
      type="light"
      variant="light"
      class="navigation_sticky_bar navbar-fixed-top"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="d-flex flex-column justify-content-start align-items-start">
				<div class="row mb-2 mr-0 ml-0" style="width: 100%">
					<div class="col pr-0 pl-0 d-flex justify-content-between">
						<div>
							<b-navbar-nav>
								<b-nav-item href="#" class="num-templates" title="# of templates">{{filterTemplates.length}}</b-nav-item>
								<b-nav-item href="#" @click="addRow(-1)" title="Add a new segment">
									<font-awesome-icon icon="plus" class="icon" />
									<span>Add</span>
								</b-nav-item>
								<b-nav-item
									href="#"
									@click="refreshTemplates"
									title="Force reload all information from Database"
								>
									<font-awesome-icon icon="sync-alt" />
									<span>Reload</span>
								</b-nav-item>
								<b-nav-item
									href="#"
									@click.prevent="$refs['findReplace'].show()"
									title="Find & Replace"
								>
									<i class="fas fa-search"></i>
									<span>Find & Replace</span>
								</b-nav-item>
								<b-nav-item
									href="#"
									@click="showImportModal"
									title="Import parent segments from another domain"
								>
									<font-awesome-icon icon="file-import" />
									<span>Import</span>
								</b-nav-item>
								<b-nav-item
									href="#"
									@click.prevent="$bvModal.show('segments-checker')" title="Check segments' syntax">
									<font-awesome-icon icon="check" />
									<span>Check</span>
								</b-nav-item>
								<b-nav-item
										href="#"
										@click="$emit('migrate')"
										title="Deploy these templates in production"
									>
										<font-awesome-icon icon="database" />
										<span>Copy to production</span>
									</b-nav-item>
							</b-navbar-nav>
						</div>
						<b-navbar-nav class="ml-auto">
							<b-nav-form class="d-flex align-items-center">
								<b-form-select
									class="input-select-document mr-2"
									id="engine"
									v-model="document_data_selected"
									:options="document_data"
									size="sm"
								></b-form-select
								>
								<b-button
									class="mr-2"
									variant="success"
									@click.prevent="previewAll($event)"
									title="Run ALL templates with selected data file"
								>
									<font-awesome-icon icon="play" /></b-button
								>
								<b-button
									class="mr-2"
									variant="warning"
									v-on:click="dataModal($event)"
									title="Manage data files"
								>
									<font-awesome-icon icon="paperclip" /></b-button
								>
								<!-- <b-form-checkbox
									class="switch-autocomplete mr-2 mb-2 d-flex align-items-center"
									checked
									v-model="oldEditModeActive"
									name="edit-mode"
									switch
									v-b-tooltip.hover :title="editModeText"
									>
										<span>Edit mode</span>
									</b-form-checkbox> -->
								<b-nav-item href="#" @click="topFunction">
									<font-awesome-icon icon="arrow-up" />Top
								</b-nav-item>
							</b-nav-form>
						</b-navbar-nav>
					</div>
				</div>
				<div class="row mr-0 ml-0"  style="width: 100%">
					<div class="col pr-0 pl-0">
						<form>
							<div class="form-row">
								<div class="col-6">
									<v-select
										:options="narras_filter"
										label="text"
										v-model="narras_filter_selected"
										placeholder="Filter by narra tree node"
										class="v-select mt-1 ml-0 pl-0"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												<em>Sorry, no matching option or was already defined</em>.
											</template>
											<em v-else
												>Sorry, no matching option or was already defined</em
											>
										</template>
									</v-select>
								</div>
								<div class="col-2">
									<v-select
										:options="segment_id_filter"
										label="text"
										v-model="segment_id_filter_selected"
										placeholder="Filter segment by id"
										class="v-select mt-1"
										@input="changeSegmentIdFilter"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												<em>Sorry, no matching option or was already defined</em>.
											</template>
											<em v-else
												>Sorry, no matching option or was already defined</em
											>
										</template>
									</v-select>
								</div>
								<div class="col-2">
									<select
										class="form-control form-control-sm mt-1"
										id="filter_by_status"
										v-model="filter_by_status"
										@change="changeFilterByStatus"
									>
										<option
											v-for="option in filter_by_status_options"
											:key="option.label"
											:value="option.value"
										>
											{{ option.label }}
										</option>
									</select>
								</div>
								<div class="col-2">
									<div class="form-row">
										<div class="col-10">
											<select
												class="form-control form-control-sm mt-1"
												id="sort_segments"
												v-model="sort_segments"
												@change="changeSortSegments"
											>
												<option
													v-for="option in sort_segment_options"
													:key="option.label"
													:value="option.value"
												>
													{{ option.label }}
												</option>
											</select>
										</div>
										<div class="col-2 pr-0">
											<div class="btn-group sort-btn">
												<button
													:class="'btn btn-light e '"
													data-sort="none"
													@click.prevent="changeSortSegmentsOrder"
												>
													<i :class="btn_sort_segments_class"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</b-collapse>
    </b-navbar>
    <div class="row-templates-container">
      <div v-for="root_narra in root_narras" :key="root_narra.id">
        <TableRootNarrasNV
          :root_narra="root_narra"
          :first_child_of_root_narra_selected="
            first_child_of_root_narra_selected
          "
          :childs_of_root_narra_selected="childs_of_root_narra_selected"
          :category_id="category_id"
          :documentId="documentId"
          :currentDomain="currentDomain"
          :currentDocument="currentDocument"
					archived=false
          @addNewSegmentForRowNarra="addNewSegmentForRowNarra"
          @deleteTemplate="deleteTemplate"
          @templateDeleted="deleteTemplate"
          @templateDuplicated="templateDuplicated"
        >
        </TableRootNarrasNV>
      </div>
    </div>
		<hr>
		<TableTemplatesWithoutSegements
			:category_id="category_id"
			@deleteTemplate="deleteTemplate"
			@templateDeleted="deleteTemplate"
			@templateDuplicated="templateDuplicated"
		/>
		<TableArchivedSegments :category_id="category_id" />

    <Loading v-if="isLoading"></Loading>

    <modal
      height="auto"
      width="700"
      scrollable
      resizable
      :name="'documentsDataListModal' + category_id"
    >
      <DocumentsDataList
        :documentID="documentId"
        :ref_file_input="'file_input_templates' + category_id"
        :modalName="'documentsDataListModal' + category_id"
      />
    </modal>

    <modal
      height="auto"
      width="900"
      scrollable
      resizable
      :name="'importModal' + category_id"
    >
      <ImportTemplatesModal
        :modalName="'importModal' + category_id"
        @importSegments="importSegments"
      />
    </modal>

    <modal
      height="auto"
      width="500"
      scrollable
      resizable
      :name="'checkSegments' + category_id"
    >
      <div class="mt-4 mb-4">
        <p>Soon</p>
      </div>
    </modal>

    <FindReplace
      :documentId="documentId"
      ref="findReplace"
      @save="saveTemplatesFindReplace"
    >
    </FindReplace>
    <SegmentsChecker
      :documentId="documentId"
      ref="segmentsChecker"
      @save="saveTemplatesFindReplace"
    >
    </SegmentsChecker>
		<ModalArchiveTreeNode @refreshData="refreshData" />
		<ModalRestoreTreeNode @refreshData="refreshData" />
		<ModalDeleteTreeNode @refreshData="refreshData" />
		<b-toast id="archive-segments-toast" variant="success" solid>
			<template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Elements archived</strong>
        </div>
      </template>
      <span>Elements archived succefully.</span>
    </b-toast>
		<b-toast id="restore-segments-toast" variant="success" solid>
			<template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Elements restored</strong>
        </div>
      </template>
      <span>Elements restored succefully.</span>
    </b-toast>
		<b-toast id="delete-segments-toast" variant="success" solid>
			<template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Elements deleted</strong>
        </div>
      </template>
      <span>Elements deleted succefully.</span>
    </b-toast>
  </div>
</template>

<script>
import document_templates_api from "../../api/document_templates.js";
import template_version_api from "../../api/template_version.js";
import formula_filters_api from "../../api/formula_filters.js";
import formula_grammar_api from "../../api/formula_grammar.js";
import peg from "pegjs";
import templates_api from "../../api/template.js";
import narras_templates_api from "../../api/narras_tree_templates";
import preview_api from "../../api/preview";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import "../../utils/common.js";
//import contextMenu from "vue-context-menu";
import Loading from "@/components/UI/Loading";
import NarrasTreesViewerNV from "@/components/narras/NarrasTreesViewerNV";
import RowTemplateNV from "@/components/templates/RowTemplateNV";
import TableRootNarrasNV from "@/components/templates/TableRootNarrasNV";
import DocumentsDataList from "@/components/documents/DocumentsDataList";
import TemplatesSelector from "@/components/templates/TemplatesSelector";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import ImportTemplatesModal from "@/components/templates/ImportTemplatesModal";
import TableTemplatesWithoutSegements from "@/components/templates/TableTemplatesWithoutSegements";
import TableArchivedSegments from "@/components/templates/TableArchivedSegments";
import FindReplace from "@/components/UI/FindReplace/FindReplace";
import SegmentsChecker from "@/components/UI/SegmentsChecker/SegmentsChecker";
import ModalDeleteTreeNode from "@/components/templates/ModalDeleteTreeNode";
import ModalArchiveTreeNode from "@/components/templates/ModalArchiveTreeNode";
import ModalRestoreTreeNode from "@/components/templates/ModalRestoreTreeNode";
import vSelect from "vue-select";
import { mapMutations, mapGetters } from 'vuex';

export default {
	name: "TableTemplatesNV",
	mixins: [alertMixins, templatesMixins],
	components: {
		//contextMenu,
		NarrasTreesViewerNV,
		Loading,
		DocumentsDataList,
		RowTemplateNV,
		TemplatesSelector,
		ButtonCircle,
		TableRootNarrasNV,
		ImportTemplatesModal,
		FindReplace,
		vSelect,
		SegmentsChecker,
		TableTemplatesWithoutSegements,
		ModalDeleteTreeNode,
		ModalArchiveTreeNode,
		ModalRestoreTreeNode,
		TableArchivedSegments
	},
	mounted () {
		this.loadFormulaFilters();
		this.loadFormulaParser();
	},

	created () {
		this.$store.commit("SET_STATUS_FILTER", "");

		this.$store.watch(
			(state, getters) => getters.getNavigateRoot,
			(template, oldValue) => {
				console.log("NAVIGATE Root", template.id);
				this.$refs["collapse-" + template.id][0].show = true;
			}
		);
		this.$store.watch(
			(state, getters) => getters.getNavigateTemplate,
			(template, oldValue) => {
				if (
					this.category_id == 1 &&
          "versions" in template &&
          "type" in template.versions[0]
				) {
					console.log("clickTemplate", template);
					this.selectTemplate(template.versions[0].type);
				}
			}
		);

		let self = this;

		window.addEventListener("keydown", function (e) {
			if (e.ctrlKey && e.key === "f") {
				e.preventDefault();
				self.$refs["findReplace"].show();
			}
		});

		window.addEventListener("keydown", function (e) {
			if (e.ctrlKey && e.key === "s") {
				e.preventDefault();
				self.$refs["segmentsChecker"].show();
			}
		});
	},
	data () {
		return {
			menuData: {},
			rowCopied: 0,
			active_autocomplete: true,
			active_preview: true,
			searchTemplate: "",
			currentSort: "type",
			currentSortDir: "asc",
			template_versions_selected: {},
			mousePosition: [],
			isLoading: false,
			filterSelected: { name: "" },
			refreshHighlights: true,
			narras_filter_selected: null,
			segment_id_filter_selected: null,
			first_child_of_root_narra_selected: null,
			childs_of_root_narra_selected: null,
			filter_by_status: "",
			filter_by_status_options: [
				{ value: "", label: "Filter..." },
				{ value: null, label: "Not completed" },
				{ value: "completed", label: "Completed" },
				{ value: "errors", label: "Errors" },
				{ value: "reviewed", label: "Reviewed" }
			],
			sort_segments: "alphabetically",
			sort_segments_order: "asc",
			btn_sort_segments_class: "fa fa-sort-asc"
		};
	},
	computed: {
		...mapGetters([
			"getOldEditModeActive",
			"getTreeNodeToArchive"
		]),
		editModeText () {
			return this.oldEditModeActive ? "Old edit mode active" : "New edit mode active";
		},
		oldEditModeActive: {
			get () {
				return this.getOldEditModeActive
			},
			set () {
				this.$store.commit("TOGGLE_OLD_EDIT_MODE_ACTIVE");
			}
		},
		templates_without_tree () {
			let templates = this.$store.getters.getTemplatesRegular;

			templates = templates.filter((t) => {
				return t.narra_tree_nodes_version.length === 0;
			});

			return templates;
		},
		sort_segment_options () {
			let options = [{ value: "alphabetically", label: "Alphabetically" }];
			let compositions = this.$store.getters.getTemplatesComposition;

			for (let i = 0; i < compositions.length; i++) {
				const composition = compositions[i];
				options.push({
					value: composition.id,
					label: "Composition -> " + composition.id
				});
			}
			return options;
		},
		segment_id_filter () {
			let options = [];

			let templates = this.$store.getters.getTemplatesRegular;
			for (const template of templates) {
				options.push({
					value: template.id,
					text: template.id + " - " + template.versions[0].type
				});
			}
			return options;
		},
		narras_filter () {
			let narras = [];
			let root_narras = this.$store.getters.getRootNarras.nodes;
			let row_narras = this.$store.getters.getRowsNarras;

			if (!root_narras) {
				return [];
			}

			for (let i = 0; i < root_narras.length; i++) {
				narras.push({
					value: root_narras[i].id,
					text: root_narras[i].versions[0].name
				});

				let the_value = row_narras[root_narras[i].id];
				let data_nodes = the_value.map(function (elem) {
					return elem.data_node;
				});

				data_nodes.forEach(async (data_node) => {
					const root_name =
            root_narras[i].versions[0].name +
            " > " +
            data_node.versions[0].name;
					narras.push({
						value: data_node.id,
						text: root_name
					});

					if (data_node.children.length > 1) {
						const childrens = this.getFlattenedChildrens(data_node, root_name);
						childrens.forEach(async (children) => {
							narras.push({
								value: children.id,
								text: children.name
							});
						});
					}
				});
			}
			return narras;
		},
		document_data_selected: {
			get () {
				return this.$store.getters.getDocumentDataSelected;
			},
			set (value) {
				this.$store.commit("SET_DOCUMENT_DATA_SELECTED", value);
			}
		},
		paste_menu_enabled () {
			if (this.rowCopied == 0) {
				return "ctx-item disabled";
			} else {
				return "ctx-item";
			}
		},

		enabled_drag () {
			console.log("computed");
			return this.searchTemplate.length === 0;
		},
		rows () {
			return this.$store.getters.getRowsNarras;
		},
		root_narras () {
			let narras = JSON.parse(
				JSON.stringify(this.$store.getters.getRootNarras)
			);

			if (this.narras_filter_selected) {
				const narras_parts = this.narras_filter_selected.text.split(" > ");
				if (narras_parts.length >= 1) {
					narras.nodes = narras.nodes.filter((row_narra) => {
						return row_narra.versions[0].name === narras_parts[0];
					});
				}

				if (narras_parts.length >= 2) {
					this.first_child_of_root_narra_selected = narras_parts[1];
				}
				this.childs_of_root_narra_selected = null;
				if (narras_parts.length > 2) {
					this.childs_of_root_narra_selected = narras_parts.slice(2);
				}
			} else {
				this.first_child_of_root_narra_selected = null;
				this.childs_of_root_narra_selected = null;
			}
			const order = this.sort_segments_order == "asc" ? "asc" : "desc";

			if ("nodes" in narras) {
				if (this.sort_segments == "alphabetically") {
					return this.sortRootNarrasAlphabetically(narras.nodes, order);
				} else {
					return this.sortRootNarrasByComposition(narras.nodes, order);
				}
			}
			return [];
		},
		document_data () {
			let data = this.$store.getters.getDocumentData;

			let options = [];
			options.push({ value: 0, text: "Select a data file" });
			for (let index = 0; index < data.length; index++) {
				let document_data = data[index];

				options.push({
					value: document_data.id,
					text: document_data.name.trunc(50)
				});
			}
			return options;
		},
		filterTemplates: {
			get () {
				return this.$store.getters.getTemplatesByFilterOnlyParents(
					this.searchTemplate,
					this.category_id
				);
			},
			set (value) {}
		},

		narraSelectorModalName () {
			return "NarraSelectorModal" + this.category_id;
		},
		sortedTemplates: function () {
			return this.filterTemplates.sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === "desc") modifier = -1;

				let field = this.currentSort;
				if (!a.versions) {
					a.versions = [{ field: "" }];
				}
				if (!b.versions) {
					b.versions = [{ field: "" }];
				}

				if (this.currentSort == "narras") {
					if (!a.narra_tree_nodes_version[0]) {
						a.narra_tree_nodes_version[0] = { name: "" };
					}
					if (!b.narra_tree_nodes_version[0]) {
						b.narra_tree_nodes_version[0] = { name: "" };
					}

					if (
						a.narra_tree_nodes_version[0].name.toString().toLowerCase() <
            b.narra_tree_nodes_version[0].name.toString().toLowerCase()
					) { return -1 * modifier; }
					if (
						a.narra_tree_nodes_version[0].name.toString().toLowerCase() >
            b.narra_tree_nodes_version[0].name.toString().toLowerCase()
					) { return 1 * modifier; }
				} else {
					if (
						a.versions[0][this.currentSort].toString().toLowerCase() <
            b.versions[0][this.currentSort].toString().toLowerCase()
					) { return -1 * modifier; }
					if (
						a.versions[0][this.currentSort].toString().toLowerCase() >
            b.versions[0][this.currentSort].toString().toLowerCase()
					) { return 1 * modifier; }
				}
				return 0;
			});
		}
	},
	methods: {
		...mapMutations({
			setTemplatesToPreview: "SET_TEMPLATES_TO_PREVIEW",
			triggerTemplatesPreview: "SET_TRIGGER_TEMPLATES_PREVIEW"
		}),
		refreshData () {
			console.log("aqui")
			this.$emit("refresh");
		},
		changeSegmentIdFilter () {
			if (this.segment_id_filter_selected) {
				this.$store.commit(
					"SET_ID_SEGMENT_FILTER",
					this.segment_id_filter_selected.value
				);
			} else {
				this.$store.commit("SET_ID_SEGMENT_FILTER", null);
			}
		},

		sortRootNarrasByComposition (narras_nodes, order) {
			const composition_id = this.sort_segments;
			let sort_nodes = [];
			let nodes_not_in_composition = [];
			let ids_included = [];

			let compositions = this.$store.getters.getTemplatesComposition;

			for (let i = 0; i < compositions.length; i++) {
				if (composition_id == compositions[i].id) {
					//\[(.*?)\]
					let segments = compositions[i].versions[0].text.match(/\[(.*?)\]/g);
					segments.forEach((segment) => {
						narras_nodes.forEach((node) => {
							if (
								node.versions[0].name ===
                segment.replace("[", "").replace("]", "")
							) {
								sort_nodes.push(node);
								ids_included.push(node.id);
							}
						});
					});

					if (order === "desc") sort_nodes.reverse(); //.concat(not_found_nodes);
					narras_nodes.forEach((node) => {
						if (!ids_included.includes(node.id)) {
							nodes_not_in_composition.push(node);
						}
					});
					nodes_not_in_composition = this.sortRootNarrasAlphabetically(
						nodes_not_in_composition,
						order
					);
				}
			}
			return sort_nodes.concat(nodes_not_in_composition);
		},
		sortRootNarrasAlphabetically (nodes, order) {
			return nodes.sort((x, y) => {
				let name1 = x.versions[0].name.toLowerCase();
				if (name1[0] == "*") name1 = name1.substring(1);
				let name2 = y.versions[0].name.toLowerCase();
				if (name2[0] == "*") name2 = name2.substring(1);

				if (name1 < name2) {
					if (order == "asc") return -1;
					else return 1;
				}
				if (name1 > name2) {
					if (order == "asc") return 1;
					else return -1;
				}

				return 0;
			});
		},
		changeSortSegmentsOrder () {
			if (this.sort_segments_order == "asc") {
				this.sort_segments_order = "desc";
			} else {
				this.sort_segments_order = "asc";
			}
			this.btn_sort_segments_class = "fa fa-sort-" + this.sort_segments_order;
		},
		changeFilterByStatus () {
			this.$store.commit("SET_STATUS_FILTER", this.filter_by_status);
		},
		changeSortSegments () {
			//console.debug("changeSortSegments", this.sort_segments);
		},
		getFlattenedChildrens (data_node, root_name) {
			let result = [];
			data_node.children.forEach(async (children) => {
				result.push({
					name: root_name + " > " + children.versions[0].name,
					id: children.id
				});

				result = result.concat(
					this.getFlattenedChildrens(
						children,
						root_name + " > " + children.versions[0].name
					)
				);
			});

			return result;
		},
		addNewSegmentForRowNarra (type, narra_tree_node_id) {
			console.log(
				"TableTemplatesNV.addNewSegmentForRowNarra:" +
          type +
          "," +
          narra_tree_node_id
			);
			console.log("Process");
			this.onAddNewTemplateWithTypeAndTreeNodeId(type, narra_tree_node_id);
		},
		async addRow (
			order,
			template = 0,
			category_id = this.category_id,
			updateTemplatesStore = true
		) {
			console.log("Add ", category_id);
			let new_template_obj = {};
			this.isLoading = true;
			//creamos el template
			let [data, err] = await templates_api.createTemplate({
				active: true,
				category_id: category_id
			});
			if (!err) {
				let template_order = this.templates.length + 1;
				if (order != -1) template_order = order;

				//si se crea bien lo asociamos al documento
				const [data_dt, err_dt] = await document_templates_api.create(
					this.documentId,
					{
						template_id: data.id,
						order: template_order
					}
				);

				if (!err_dt) {
					let new_version = {
						template_id: data.id,
						current_version: true,
						active: true
					};

					if (template == 0) {
						if (category_id == 2) new_version.type = "composition";
						else new_version.type = "";
						new_version.narras = "";
						new_version.weigth = 20;
						new_version.text = "";
						new_version.text_for_editor = "";
					} else {
						new_version.type = template.versions[0].type;
						new_version.narras = template.versions[0].narras;
						new_version.weigth = template.versions[0].weigth;
						new_version.text_for_editor =
              template.versions[0].text_for_editor
						// new_version.text_for_editor =
						// 	template.versions[0].text_for_editor.replace(/·/g, " ");
					}

					const [datav, errv] =
            await template_version_api.createVersionWithAlias(
            	new_version,
            	this.$store.getters.getCurrentDomain.id
            );

					if (!errv) {
						console.log(datav);
						new_version.id = datav.id;

						new_template_obj = {
							id: new_version.template_id,
							category: { id: category_id },
							order: template_order,
							versions: [new_version],
							narra_tree_nodes_version: []
						};

						let tmpls;
						if (category_id == 2) {
							tmpls = this.$store.getters.getTemplatesComposition;
						} else {
							tmpls = this.$store.getters.getTemplatesRegular;
						}
						tmpls.push(new_template_obj);
						this.$store.getters.getTemplates.push(new_template_obj);

						//Ponemos el foco en la nueva fila
						/*setTimeout(() => {
              // retardamos el coger el foco para que de tiempo a que se cree el componente
              this.$refs["RowTemplate" + new_template_obj.id][0].getFocus();
            }, 100);*/
					} else {
						console.error(errv);
						this.showErrorAlert("Error: " + errv.data.error);
					}
				} else {
					console.error(err_dt);
					this.showErrorAlert("Error: " + err_dt.data.error);
				}
			} else {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			}
			/*console.log("scroll to bottom");
			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight
			);*/

			console.log("addRow.updateTemplatesStore()");
			if (updateTemplatesStore) {
				this.updateTemplatesStore();
			}

			this.isLoading = false;
			return new_template_obj;
		},
		updateTemplatesStore () {
			console.log("updateTemplatesStore");
			/*if (this.category_id == 1)
        this.$store.commit("SET_TEMPLATES_REGULAR", this.templates);
      else if (this.category_id == 2)
        this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates);*/
		},
		async templateDuplicated (template) {
			console.log("templateDuplicated", template);

			//insertamos el template
			let template_new = await this.addRow(template.order, template);
			/*console.log(
        "template.narra_tree_nodes_version",
        template.narra_tree_nodes_version
      );*/
			if (
				template.narra_tree_nodes_version &&
        template.narra_tree_nodes_version.length > 0
			) {
				this.template_versions_selected = template_new;

				let tree_node = {
					data_node: {
						id: template.narra_tree_nodes_version[0].narra_tree_node_id,
						versions: [template.narra_tree_nodes_version[0]]
					}
				};
				await this.associateNarra(tree_node);
			}
			//Do not filter navigation
			/*if (this.category_id == 1) {
				this.$store.commit("SET_NAVIGATE_TEMPLATE", template_new);
			}*/

			this.refreshTemplates();
			//this.updateTemplatesStore();
		},
		async associateNarra (tree_node) {
			console.log("------------ selectNarra", tree_node);
			console.log(this.template_versions_selected);

			this.isLoading = true;

			//this.$modal.hide(this.narraSelectorModalName);
			this.$bvModal.hide(this.narraSelectorModalName);
			const [data, err] = await narras_templates_api.create(
				tree_node.data_node.id,
				{
					template_id: this.template_versions_selected.id
				}
			);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}
			this.template_versions_selected.narra_tree_nodes_version.push(
				tree_node.data_node.versions[0]
			);

			if (tree_node.template) {
				tree_node.template =
          tree_node.template +
          ", " +
          this.template_versions_selected.versions[0].type;
			} else { tree_node.template = this.template_versions_selected.versions[0].type; }
			this.isLoading = false;
		},
		templateDeleted (template) {
			console.log("TableTemplatesNV.templateDeleted");
			const template_id = template.id;
			let all_templates = this.$store.getters.getTemplates();

			//For the list of all templates with no hierarchy
			all_templates = all_templates.filter(function (template) {
				return template.id != template_id;
			});

			this.$store.commit("SET_TEMPLATES", all_templates);

			//For regular or composition
			this.templates = this.templates.filter(function (template) {
				return template.id != template_id;
			});

			if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", this.templates); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates); }
		},
		deleteTemplate (template) {
			console.log("TableTemplatesNV.deleteTemplate");
			const template_id = template.id;
			let all_templates = this.$store.getters.getTemplates;

			let list_of_indices_to_remove = [];
			for (let i = 0; i < all_templates.length; i++) {
				if (all_templates[i].id == template_id) {
					list_of_indices_to_remove.push(i);
				}
			}

			console.log("Antes:" + this.$store.getters.getTemplates.length);
			for (let i = 0; i < list_of_indices_to_remove.length; i++) {
				this.$store.getters.getTemplates.splice(
					list_of_indices_to_remove[i],
					1
				);
			}
			console.log("Después:" + this.$store.getters.getTemplates.length);

			//this.$store.commit("SET_TEMPLATES", all_templates);

			//For regular or composition
			console.log("Antes this.Templates:" + this.templates.length);
			this.templates = this.templates.filter(function (template) {
				return template.id != template_id;
			});
			console.log("Después this.Templates:" + this.templates.length);

			if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", this.templates); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates); }
		},
		onAddNewTemplate (tmplateType) {
			/*console.debug(
        "[" +
          this._uid +
          "] TableTemplatesNV.onAddNewTemplate.value: " +
          tmplateType
      );*/

			//Build the new segment
			let template = {
				versions: [
					{
						type: tmplateType,
						narras: "",
						weigth: 20,
						text: "",
						text_for_editor: ""
					}
				]
			};

			this.addRow(-1, template, 1);
		},
		async onAddNewTemplateWithTypeAndTreeNodeId (
			tmplateType,
			narra_tree_node_id
		) {
			//Build the new segment
			let template = {
				versions: [
					{
						type: tmplateType,
						narras: "",
						weigth: 20,
						text: "",
						text_for_editor: ""
					}
				]
			};

			console.log("Template:" + template);
			let new_template = await this.addRow(-1, template, 1, false);

			//Add disables the loading, then we recover it again
			this.isLoading = true;
			console.log(
				"onAddNewTemplateWithTypeAndTreeNodeId:" +
          narra_tree_node_id +
          "," +
          new_template.id
			);

			//asociate template and narra
			console.log("narras_templates_api:" + new_template.id);
			const [data, err] = await narras_templates_api.create(
				narra_tree_node_id,
				{
					template_id: new_template.id
				}
			);
			this.updateTemplatesStore();
			console.log("DESPUES narras_templates_api:" + new_template.id);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}

			this.refreshTemplates();
		},

		createTempByText (template) {
			this.addRow(-1, template, 1);
		},

		async selectNarra (tree_node) {
			console.log("+++------------ selectNarra", tree_node);
			console.log(this.template_versions_selected);

			this.isLoading = true;

			//this.$modal.hide(this.narraSelectorModalName);
			//console.log("ref", this.$refs);

			console.log("get temapltes by narra");
			const tree_id = tree_node.data_node
				? tree_node.data_node.id
				: tree_node.narra_tree_node_id;
			console.log("tree_id ", this.documentId, tree_id);

			const [data, err] = await document_templates_api.getTemplatesByNarraId(
				this.documentId,
				tree_id
			);

			console.log("templates", data, err);

			if (err) {
				if (err.status == 404) {
					this.showErrorAlert(
						"There aren't segments associated with the scenario"
					);
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				//this.$store.commit("SET_TEMPLATES_REGULAR", data);
				this.replaceSpacesWithPoints(data);

				this.filterSelected = {
					name: tree_node.name,
					obj: tree_node,
					type: "narras"
				};

				this.templates = this.putSubtemplatesObjs(data);
				/*[...data].forEach(template => {
					template.subtemplates_objs = [];
					if (template.subtemplates) {
						[...template.subtemplates].forEach(subtemplate => {
							console.log("subtemplate", subtemplate);
							let tmpl = this.getTemplateObjByType(data, subtemplate);
							template.subtemplates_objs = template.subtemplates_objs.concat(
								tmpl
							);
						});
					}
				});*/
				//this.templates = data;
			}

			this.isLoading = false;
		},
		async selectTemplate (template) {
			this.isLoading = true;

			const [data, err] = await document_templates_api.getTemplatesByType(
				this.documentId,
				template
			);

			console.log("templates", data, err);

			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.replaceSpacesWithPoints(data);
				this.filterSelected = {
					name: template,
					obj: template,
					type: "template"
				};
				this.templates = data;
				//this.templates = this.putSubtemplatesObjs(data);
			}

			this.isLoading = false;
		},
		dataModal ($event) {
			this.$modal.show("documentsDataListModal" + this.category_id);
		},
		previewAll ($event) {
			if (this.document_data_selected != 0) {
				//console.log("preview ref ", this.$refs, this.filterTemplates);
				let templatesToPreview = [];
				for (let i = 0; i < this.filterTemplates.length; i++) {
					/*console.debug(
						"ID",
						this.filterTemplates[i].id,
						this.$refs["RowTemplate" + this.filterTemplates[i].id]
					);
						console.log(this.filterTemplates[i].id)*/
						templatesToPreview.push({ id: this.filterTemplates[i].id, previewed: false });
					/* if (this.$refs["RowTemplate" + this.filterTemplates[i].id]) {
						this.$refs[
							"RowTemplate" + this.filterTemplates[i].id
						][0].previewTemplate($event, "ButtonEye", "true");
					}*/
					//this.previewTemplate($event, this.filterTemplates[i]);
				}
				this.setTemplatesToPreview(templatesToPreview);
				this.triggerTemplatesPreview(true);
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
		},
		makeFilterTemplates ($event) {
			console.log("makeFilterTemplates", $event);
			//desactivamos el refresco de los higthligth
			this.refreshHighlights = false;

			let self = this;
			setTimeout(() => {
				this.searchTemplate = $event.target.value;
			}, 100);
			setTimeout(() => {
				self.refreshHighlights = true;
			}, 500);
		},
		async filterByTemplateID (template) {
			console.log("filterByTemplateID");
			this.isLoading = true;

			const [data, err] = await document_templates_api.getById(
				this.documentId,
				template.id
			);

			if (!err) {
				this.replaceSpacesWithPoints(data.templates);
				this.templates = this.putSubtemplatesObjs(data.templates);
				//this.templates = data.templates;
				console.log("filter");
				this.filterSelected = {
					name: template.versions[0].type + " (" + template.id + ")",
					obj: template,
					type: "row"
				};
			}

			this.isLoading = false;
		},
		async refreshTemplates () {
			this.isLoading = true;
			if (this.filterSelected.name.length > 0) {
				if (this.filterSelected.type == "row") {
					await this.filterByTemplateID(this.filterSelected.obj);
				} else if (this.filterSelected.type == "narras") {
					await this.selectNarra(this.filterSelected.obj);
				} else if (this.filterSelected.type == "template") {
					await this.selectTemplate(this.filterSelected.obj);
				}
			} else {
				await this.loadTemplatesNV();
			}
			this.isLoading = false;
		},
		expandAll () {
			this.Loading = true;
			[...this.filterTemplates].forEach((template) => {
				if (this.$refs["collapse-" + template.id]) {
					template.subtemplates_objs_render = template.subtemplates_objs;
					for (
						let i = 0;
						i < this.$refs["collapse-" + template.id].length;
						i++
					) {
						this.$refs["collapse-" + template.id][i].show = true;
						console.log("expand");
					}
				}
			});
			this.Loading = false;
			console.log("End of expandAll function");
		},
		collapseAll () {
			this.Loading = true;
			[...this.filterTemplates].forEach((template) => {
				if (this.$refs["collapse-" + template.id]) {
					for (
						let i = 0;
						i < this.$refs["collapse-" + template.id].length;
						i++
					) {
						this.$refs["collapse-" + template.id][i].show = false;
						console.log("collapse");
					}
				}
			});
			this.Loading = false;

			console.log("End of collapseAll function");
		},
		topFunction () {
			document.body.scrollTop = 0; //For Safari
			document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera
		},
		showImportModal () {
			//importModal
			console.log("showImportModal " + "importModal" + this.category_id);
			this.$modal.show("importModal" + this.category_id);
		},
		showSegmentsChecker () {
			self.$refs["segmentsChecker"].show();
		},

		importSegments (domain, document, selectedItems) {
			console.log("TableTemplatesNV selectedItems", selectedItems);
			this.$emit("importSegments", domain, document, selectedItems);
		},
		async saveTemplatesFindReplace (templates) {
			this.isLoading = true;

			for (let i = 0; i < templates.length; i++) {
				for (
					let j = 0;
					j < this.$store.getters.getTemplatesRegular.length;
					j++
				) {
					let template = this.$store.getters.getTemplatesRegular[j];
					if (template.id == templates[i].id) {
						console.log("template", template);
						let version = templates[i].versions[0];
						template.versions[0].text_for_editor = templates[
							i
						].versions[0].text_for_editortemplate.versions[0].text_for_editor = templates[
							i
							].versions[0].text_for_editor
						// template.versions[0].text_for_editor = templates[
						// 	i
						// 	].versions[0].text_for_editor.replace(/·/g, " ");
						//this.updateTemplate(this.template.versions[0]);

						if (version) {
							console.log("POST TEMPLATE VERSION", version);
							const [data, err] =
                await template_version_api.createVersionWithAlias(
                	version,
                	this.$store.getters.getCurrentDomain.id
                );

							if (!err) {
								console.log("UPDATE", data);
								template.versions[0].updated_at = data.updated_at;
							} else {
								this.isLoading = false;
								return this.showApiRequestErrorAlert(err);
							}
						}
						break;
					}
				}
			}

			this.isLoading = false;
		},
		async loadFormulaFilters () {
			this.isLoading = true;

			const [data, err] = await formula_filters_api.getAllFormulaFilters();
			if (err) {
				console.error(err);
				this.isLoading = false;
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				function addMinParamsToFilters (filters) {
					for (let i = 0; i < filters.length; i++) {
						let minParameters = 0;
						let maxParameters = 10;
						if (filters[i].num_parameters) {
							if (filters[i].num_parameters.includes(",")) {
								minParameters = parseInt(
									filters[i].num_parameters.split(",")[0]
								);
								maxParameters = parseInt(
									filters[i].num_parameters.split(",")[1]
								);
							} else {
								minParameters = parseInt(filters[i].num_parameters);
								maxParameters = minParameters;
							}
						}

						filters[i].min_parameters = minParameters;
						filters[i].max_parameters = maxParameters;
						//Add minimun parameters empty
						filters[i].params = [];
						for (let j = 0; j < minParameters; j++) {
							filters[i].params.push({ v: "", t: "param", id: _.uniqueId() });
						}
					}
				}
				let filtersWithMinParameters = addMinParamsToFilters(data);
				this.$store.commit("SET_FORMULA_FILTERS", data);
			}
			this.isLoading = false;
		},
		async loadFormulaParser () {
			this.isLoading = true;

			const [data, err] = await formula_grammar_api.getFormulaGrammar();
			if (err) {
				console.error(err);
				this.isLoading = false;
				this.showErrorAlert(
					"Error while dowloading the formulas grammar : " + err.data.error
				);
			} else {
				let grammar = data.grammar;
				let parser = peg.generate(grammar);
				this.$store.commit("SET_FORMULA_PARSER", parser);
			}
			this.isLoading = false;
		}
	},

	props: {
		documentId: {},
		category_id: {},
		title_navbar: {},
		currentDocument: { type: Object },
		currentDomain: { type: Object }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.table-templates-nv {

	td {
		font-family: "Lucida Console", Monaco, monospace, Helvetica, Arial, sans-serif;
		/*-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;*/
		color: #000000;
		margin-top: 0px;
	}

	#navbar {
		overflow: hidden;
		background-color: #333;
	}

	/* Navbar links */
	#navbar a {
		float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		padding: 14px;
		text-decoration: none;
	}

	/* The sticky class is added to the navbar with JS when it reaches its scroll position */
	.sticky {
		position: fixed;
		top: 0;
		width: 100%;
	}

	/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
	.sticky + .content {
		padding-top: 60px;
	}

	.v--modal-overlay {
		z-index: 1030;
	}

	.row-templates-container {
		/*font-family: "Menlo Regular", "Avenir", Helvetica, Arial, sans-serif;*/
		scroll-behavior: auto;
		margin-top: 2em;
		padding-left: 1em;
		padding-right: 1em;
	}

	html {
		scroll-behavior: auto;
	}

	.num-templates {
		background-color: #157ef3;
	}

	.num-templates a {
		color: #fff !important;
	}

	.v-select {
		background: #ffffff;
		border: 1px;
		color: #394066;
		margin: 5px;
		/*text-transform: lowercase;
		font-variant: small-caps;*/
	}
}
</style>
