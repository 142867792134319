import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Domains extends BaseClient {
	constructor () {
		super(httpClient, "/domains/");
	}

	getAll () {
		return this.get();
	}

	getDomain (domainId) {
		return this.get(domainId);
	}

	getDocuments (domainId) {
		return this.get(`${domainId}/documents`);
	}

	getNarras (domainId) {
		return this.get(`${domainId}/narras/root`);
	}

	getNSchema (domainId) {
		return this.get(`${domainId}/nschema`);
	}

	setNSchema (domainId, data) {
		return this.put(`${domainId}/nschema`, data);
	}

	uploadNSchemaDataFile (domainId, data) {
		return this.put(`${domainId}/nschema/upload_data_file`, data);
	}

	getScenarios (domainId) {
		return this.get(`${domainId}/scenarios`);
	}

	getNarrasTreeTemplate (domainId, documentId) {
		return this.get(`${domainId}/narras/tree/templates/${documentId}`);
	}

	getArchivedNarras (domainId, documentId) {
		return this.get(`${domainId}/narras/tree/templates/${documentId}?only_archived=true`);
	}

	create (domain) {
		return this.post("", domain);
	}

	update (id, domain) {
		return this.patch(`${id}`, domain);
	}

	migrate (domainId, documentId) {
		return this.get(`${domainId}/migrate/${documentId}`);
	}
	importSegements (domainId, documentId, data) {
		return this.post(`${domainId}/document/${documentId}/copy_segments`, data);
	}
}

export default new Domains();
