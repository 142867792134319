/* eslint-disable */
<template>
  <div class="table-root-narras-nv" v-if="row_narras.length>0">
    <div class="root_narra_title" v-on:click="toggleCollapsed()">
      <div class="toggleControlNarras">
        <span v-if="!collapsed">
					<i class="fa fa-caret-down" aria-hidden="true"></i>
				</span>
        <span v-else>
					<i class="fa fa-caret-right" aria-hidden="true"></i>
				</span>
      </div>
      <div class="root_narra_name_title" :title="root_narra.id">
        <span>{{ root_narra.versions[0].name }}</span>
				<button style="background: transparent;"
            class="icon--xs"
            v-if="archived=='true' && !root_narra.active"
            @click.stop="opeRestoreTreeNode(root_narra.id)"
            v-b-tooltip.hover title="Restore elements">
					<i class="fas fa-trash-restore"></i>
				</button>
				<button class="icon--xs"
            v-if="archived=='false' && root_narra.active"
            @click.stop="openArchiveParentSegment(root_narra.id)"
            v-b-tooltip.hover title="Archive elements">
					<i class="fas fa-trash"></i>
				</button>
				<button class="icon--xs"
            v-if="archived=='true' && !root_narra.active"
            @click.stop="openDeleteParentSegment(root_narra.id)"
            v-b-tooltip.hover title="Delete elements">
					<i class="fas fa-trash"></i>
				</button>
      </div>
    </div>

    <div v-for="narra in this.row_narras" :key="narra.key">
      <TableRowNarrasNV
        v-if="!collapsed"
        :row_narra="narra"
        :category_id="category_id"
        :documentId="documentId"
        :currentDomain="currentDomain"
        :currentDocument="currentDocument"
        :prev_row_narra_name="root_narra.versions[0].name"
        :search_segment="search_segment"
        :archived="archived"
        @addNewSegmentForRowNarra="addNewSegmentForRowNarra"
        @deleteTemplate="onDeleteTemplate"
        @templateDeleted="onTemplateDeleted"
        @templateDuplicated="onTemplateDuplicated"
      >
      </TableRowNarrasNV>
    </div>
  </div>
</template>

<script>
import document_templates_api from "../../api/document_templates.js";
import template_version_api from "../../api/template_version.js";
import templates_api from "../../api/template.js";
import narras_templates_api from "../../api/narras_tree_templates";
import uniqueid from "lodash/uniqueId";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";
import { narrasMixins } from "@/mixins/narrasMixins.js";
import "../../utils/common.js";
//import contextMenu from "vue-context-menu";
import Loading from "@/components/UI/Loading";
import DocumentsDataList from "@/components/documents/DocumentsDataList";
import TemplatesSelector from "@/components/templates/TemplatesSelector";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
//import TableRowNarrasNV from "@/components/templates/TableRowNarrasNV";
import document_templates from "../../api/document_templates.js";

export default {
	name: "TableRootNarrasNV",
	mixins: [alertMixins, templatesMixins, narrasMixins],
	components: {
		Loading,
		DocumentsDataList,
		TemplatesSelector,
		ButtonCircle,
		TableRowNarrasNV: () => import("./TableRowNarrasNV.vue")
	},
	created () {
		this.$store.watch(
			(state, getters) => getters.getNavigateRoot,
			(template, oldValue) => {
				console.log("NAVIGATE Root", template.id);
				this.$refs["collapse-" + template.id][0].show = true;
			}
		);
		this.$store.watch(
			(state, getters) => getters.getNavigateTemplate,
			(template, oldValue) => {
				if (
					this.category_id == 1 &&
          "versions" in template &&
          "type" in template.versions[0]
				) {
					console.log("clickTemplate", template);
					this.selectTemplate(template.versions[0].type);
				}
			}
		);
		this.$store.watch(
			(state, getters) => getters.getSegmentIdFilter,
			(segment_id, oldValue) => {
				//console.debug("TableRoot change segment_id", segment_id);
				this.search_segment = this.find_segment(this.row_narras, segment_id);

				if (
					Array.isArray(this.search_segment) &&
          this.search_segment.length > 0
				) {
					console.debug("TableRoot this.search_segment", this.search_segment);
					this.collapsed = false;
				}
			}
		);

		this.search_segment = this.find_segment(
			this.row_narras,
			this.$route.query.segment_id
		);
	},
	data () {
		return {
			menuData: {},
			rowCopied: 0,
			active_autocomplete: true,
			active_preview: true,
			searchTemplate: "",
			currentSort: "type",
			currentSortDir: "asc",
			template_versions_selected: {},
			mousePosition: [],
			isLoading: false,
			filterSelected: { name: "" },
			refreshHighlights: true,
			collapsed: true,
			search_segment: []
		};
	},
	computed: {
		row_narras: {
			get () {
				let row_narras = this.$store.getters.getRowsNarras;

				//clone
				row_narras = JSON.parse(JSON.stringify(row_narras));

				let the_value = row_narras[this.root_narra.id];
				if (!the_value) {
					return [];
				}
				//console.log("row_narras.get().value" + the_value);

				let the_value_2 = the_value.map(function (elem) {
					let data = elem.data_node;
					data.key = uniqueid();

					return data;
				});
				if (this.first_child_of_root_narra_selected) {
					the_value_2 = the_value_2.filter((row_narra) => {
						return (
							row_narra.versions[0].name ===
              this.first_child_of_root_narra_selected
						);
					});

					if (this.childs_of_root_narra_selected) {
						the_value_2[0] = this.filterRowChildrenByFilter(the_value_2[0]);
					}
				}

				let statusFilter = this.$store.getters.getStatusFilter;
				if (statusFilter === null || statusFilter.length > 0) {
					let templates = [];
					for (const row_narra of the_value_2) {
						templates = templates.concat(
							this.getTemplatesForRowNarraAndChidren(row_narra)
						);
					}

					const templates_filtered = templates.filter((template) => {
						return template.versions[0].status === statusFilter;
					});

					if (templates_filtered.length === 0) {
						return [];
					}
				}

				// if (this.archived === 'false' && !this.root_narra.active) {
				// 	return [];
				// }

				the_value_2 = this.getRowsToShow(the_value_2);

				return the_value_2;
			}
		},
		document_data_selected: {
			get () {
				return this.$store.getters.getDocumentDataSelected;
			},
			set (value) {
				this.$store.commit("SET_DOCUMENT_DATA_SELECTED", value);
			}
		},
		paste_menu_enabled () {
			if (this.rowCopied == 0) {
				return "ctx-item disabled";
			} else {
				return "ctx-item";
			}
		},

		enabled_drag () {
			console.log("computed");
			return this.searchTemplate.length === 0;
		},
		rows () {
			return this.$store.getters.getRowsNarras;
		},
		document_data () {
			let data = this.$store.getters.getDocumentData;

			let options = [];
			options.push({ value: 0, text: "Select a data file" });
			for (let document_data of data) {
				options.push({
					value: document_data.id,
					text: document_data.name.trunc(50)
				});
			}
			return options;
		},
		filterTemplates () {
			return this.$store.getters.getTemplatesByFilterOnlyParents(
				this.searchTemplate,
				this.category_id
			);
		},

		narraSelectorModalName () {
			return "NarraSelectorModal" + this.category_id;
		},
		domainId () {
			return this.$store.getters.getCurrentDomain.id;
		},
		sortedTemplates: function () {
			console.log("sortedTemplates");
			return this.filterTemplates.sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === "desc") modifier = -1;

				let field = this.currentSort;
				if (!a.versions) {
					a.versions = [{ field: "" }];
				}
				if (!b.versions) {
					b.versions = [{ field: "" }];
				}

				if (this.currentSort == "narras") {
					if (!a.narra_tree_nodes_version[0]) {
						a.narra_tree_nodes_version[0] = { name: "" };
					}
					if (!b.narra_tree_nodes_version[0]) {
						b.narra_tree_nodes_version[0] = { name: "" };
					}

					if (
						a.narra_tree_nodes_version[0].name.toString().toLowerCase() <
            b.narra_tree_nodes_version[0].name.toString().toLowerCase()
					) { return -1 * modifier; }
					if (
						a.narra_tree_nodes_version[0].name.toString().toLowerCase() >
            b.narra_tree_nodes_version[0].name.toString().toLowerCase()
					) { return 1 * modifier; }
				} else {
					if (
						a.versions[0][this.currentSort].toString().toLowerCase() <
            b.versions[0][this.currentSort].toString().toLowerCase()
					) { return -1 * modifier; }
					if (
						a.versions[0][this.currentSort].toString().toLowerCase() >
            b.versions[0][this.currentSort].toString().toLowerCase()
					) { return 1 * modifier; }
				}
				return 0;
			});
		}
	},
	mounted () {
		this.collapsed = this.getCollapsedFromData();

		//console.log("TEST_collapse_not_selected", this.search_segment, this.$route.query.segment_id)

		if ("segment_id" in this.$route.query) {
			if (
				Array.isArray(this.search_segment) &&
        this.search_segment.length > 0
			) {
				//console.log("COLLAPSED_TEST", this.search_segment);
				this.collapsed = false;
			} else {
				this.collapsed = true;
			}
		}
	},
	methods: {
		openArchiveParentSegment (id) {
			this.$store.commit("SET_TREE_NODE_TO_ARCHIVE", {id: id, isRoot: true});
			this.$bvModal.show("archive-parent-segment");
		},
		openDeleteParentSegment (id) {
			this.$store.commit("SET_TREE_NODE_TO_DELETE", {id: id, isRoot: true});
			this.$bvModal.show("delete-parent-segment");
		},
		opeRestoreTreeNode (id) {
				this.$store.commit("SET_TREE_NODE_TO_RESTORE", {id: id, isRoot: true});
				this.$bvModal.show("restore-parent-segment");
		},
		getRowsToShow (rows_narra) {
			let rows_to_show = [];
			for (let row_narra of rows_narra) {
				console.debug("getRowsToShow", row_narra.id);

				if (!row_narra ) {
					continue
				}

				if (this.archived === "false" && row_narra.active) {
					rows_to_show.push(row_narra);
				}
				else if (this.archived === "true" && !row_narra.active) {
					rows_to_show.push(row_narra);
				}
				else if (row_narra.children) {
					const row_chidren = this.getRowsToShow (row_narra.children);
					if (row_chidren.length > 0) {
						rows_to_show.push(row_narra);
						// rows_to_show = rows_to_show.concat(rown_chidren);
					}
				}

			}
			return rows_to_show;
		},
		getTemplatesForRowNarraAndChidren (row_narra) {
			if (!row_narra || !row_narra.versions) {
				return [];
			}

			const narra_tree_node_id = row_narra.versions[0].narra_tree_node_id;

			let templates =
        this.$store.getters.getTemplatesByFilterOnlyParentsWithNarraTreeNode(
        	"",
        	this.category_id,
        	narra_tree_node_id
        );

			for (let i = 0; i < row_narra.children.length; i++) {
				templates = templates.concat(
					this.getTemplatesForRowNarraAndChidren(row_narra.children[i])
				);
			}

			return templates;
		},
		filterRowChildrenByFilter (row_narra, level = 0) {
			let self = this;
			row_narra.children = row_narra.children.filter((child) => {
				if (
					child.children.length > 0 &&
          this.childs_of_root_narra_selected.length > level + 1
				) {
					child = self.filterRowChildrenByFilter(child, level + 1);
				}

				return (
					child.versions[0].name === this.childs_of_root_narra_selected[level]
				);
			});
			return row_narra;
		},
		onDeleteTemplate (template) {
			this.$emit("deleteTemplate", template);
		},
		onTemplateDeleted (template) {
			this.$emit("templateDeleted", template);
		},
		onTemplateDuplicated (template) {
			this.$emit("templateDuplicated", template);
		},
		addNewSegmentForRowNarra (type, narra_tree_node_id) {
			console.log(
				"TableRootNarrasNV.addNewSegmentForRowNarra:" +
          type +
          "," +
          narra_tree_node_id
			);
			this.$emit("addNewSegmentForRowNarra", type, narra_tree_node_id);
		},
		toggleCollapsed () {
			this.setCollapsedFromData(!this.collapsed);
			this.collapsed = !this.collapsed;
		},
		getCollapsedFromData () {
			let key = this.root_narra.id;
			//console.log("getCollapsedFromData:" + key);
			let result = this.$store.getters.getRowNarraCollapsedStateForKey(key);
			return result;
		},
		setCollapsedFromData (value) {
			let collapseIdKeyVar = this.root_narra.id;
			//console.log("setCollapsedFromData:" + collapseIdKeyVar);
			let currentData = this.$store.getters.getRowNarraCollapsedStateForKey;
			currentData[collapseIdKeyVar] = value;

			this.$store.commit("SET_ROW_NARRA_COLLAPSED_STATE", {
				currentData,
				collapseIdKeyVar,
				value
			});
		},

		async addRow (order, template = 0, category_id = this.category_id) {
			console.log("Add ", category_id);
			let new_template_obj = {};
			this.isLoading = true;
			//creamos el template
			let [data, err] = await templates_api.createTemplate({
				active: true,
				category_id: category_id
			});
			if (!err) {
				let template_order = this.templates.length + 1;
				if (order != -1) template_order = order;

				//si se crea bien lo asociamos al documento
				const [data_dt, err_dt] = await document_templates_api.create(
					this.documentId,
					{
						template_id: data.id,
						order: template_order
					}
				);

				if (!err_dt) {
					let new_version = {
						template_id: data.id,
						current_version: true,
						active: true
					};

					if (template == 0) {
						if (category_id == 2) new_version.type = "composition";
						else new_version.type = "";
						new_version.narras = "";
						new_version.weigth = 20;
						new_version.text_for_editor = "";
					} else {
						new_version.type = template.versions[0].type;
						new_version.narras = template.versions[0].narras;
						new_version.weigth = template.versions[0].weigth;
						new_version.text_for_editor = template.versions[0].text_for_editor;
						new_version.text_for_editor = template.versions[0].text_for_editor
						// new_version.text_for_editor =
						// 	template.versions[0].text_for_editor.replace(/·/g, " ");
					}

					const [datav, errv] =
            await template_version_api.createVersionWithAlias(
            	new_version,
            	this.$store.getters.getCurrentDomain.id
            );

					if (!errv) {
						console.log(datav);
						new_version.id = datav.id;

						new_template_obj = {
							id: new_version.template_id,
							category: { id: category_id },
							order: template_order,
							versions: [new_version],
							narra_tree_nodes_version: []
						};

						let tmpls;
						if (category_id == 2) {
							tmpls = this.$store.getters.getTemplatesComposition;
						} else {
							tmpls = this.$store.getters.getTemplatesRegular;
						}
						tmpls.push(new_template_obj);
						this.$store.getters.getTemplates.push(new_template_obj);

						//Ponemos el foco en la nueva fila
						/*setTimeout(() => {
              // retardamos el coger el foco para que de tiempo a que se cree el componente
              this.$refs["RowTemplate" + new_template_obj.id][0].getFocus();
            }, 100);*/
					} else {
						console.error(errv);
						this.showErrorAlert("Error: " + errv.data.error);
					}
				} else {
					console.error(err_dt);
					this.showErrorAlert("Error: " + err_dt.data.error);
				}
			} else {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			}
			/*console.log("scroll to bottom");
			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight
			);*/

			console.log("addRow.updateTemplatesStore()");
			this.updateTemplatesStore();

			this.isLoading = false;
			return new_template_obj;
		},
		updateTemplatesStore () {
			console.log("updateTemplatesStore");
			/*if (this.category_id == 1)
        this.$store.commit("SET_TEMPLATES_REGULAR", this.templates);
      else if (this.category_id == 2)
        this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates);*/
		},
		async templateDuplicated (template) {
			console.log("TableRootNarrasNV.templateDuplicated", template);

			//insertamos el template
			let template_new = await this.addRow(template.order, template);
			console.log(
				"template.narra_tree_nodes_version",
				template.narra_tree_nodes_version
			);
			if (
				template.narra_tree_nodes_version &&
        template.narra_tree_nodes_version.length > 0
			) {
				this.template_versions_selected = template_new;

				let tree_node = {
					data_node: {
						id: template.narra_tree_nodes_version[0].narra_tree_node_id,
						versions: [template.narra_tree_nodes_version[0]]
					}
				};
				this.associateNarra(tree_node);
			}
			//Do not filter navigation
			/*if (this.category_id == 1) {
				this.$store.commit("SET_NAVIGATE_TEMPLATE", template_new);
			}*/

			//this.refreshTemplates();
			//this.updateTemplatesStore();
		},
		async associateNarra (tree_node) {
			console.log("------------ selectNarra", tree_node);
			console.log(this.template_versions_selected);

			this.isLoading = true;

			//this.$modal.hide(this.narraSelectorModalName);
			this.$bvModal.hide(this.narraSelectorModalName);
			const [data, err] = await narras_templates_api.create(
				tree_node.data_node.id,
				{
					template_id: this.template_versions_selected.id
				}
			);
			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
				this.isLoading = false;
				return;
			}
			this.template_versions_selected.narra_tree_nodes_version.push(
				tree_node.data_node.versions[0]
			);

			if (tree_node.template) {
				tree_node.template =
          tree_node.template +
          ", " +
          this.template_versions_selected.versions[0].type;
			} else { tree_node.template = this.template_versions_selected.versions[0].type; }
			this.isLoading = false;
		},
		templateDeleted (template) {
			console.log("TableTemplatesNV.templateDeleted");
			const template_id = template.id;
			let all_templates = this.$store.getters.getTemplates();

			//For the list of all templates with no hierarchy
			all_templates = all_templates.filter(function (template) {
				return template.id != template_id;
			});

			this.$store.commit("SET_TEMPLATES", all_templates);

			//For regular or composition
			this.templates = this.templates.filter(function (template) {
				return template.id != template_id;
			});

			if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", this.templates); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates); }
		},
		deleteTemplate (template) {
			console.log("TableTemplatesNV.deleteTemplate");
			const template_id = template.id;
			let all_templates = this.$store.getters.getTemplates;

			let list_of_indices_to_remove = [];
			for (let i = 0; i < all_templates.length; i++) {
				if (all_templates[i].id == template_id) {
					list_of_indices_to_remove.push(i);
				}
			}

			console.log("Antes:" + this.$store.getters.getTemplates.length);
			for (let i = 0; i < list_of_indices_to_remove.length; i++) {
				this.$store.getters.getTemplates.splice(
					list_of_indices_to_remove[i],
					1
				);
			}
			console.log("Después:" + this.$store.getters.getTemplates.length);

			//this.$store.commit("SET_TEMPLATES", all_templates);

			//For regular or composition
			console.log("Antes this.Templates:" + this.templates.length);
			this.templates = this.templates.filter(function (template) {
				return template.id != template_id;
			});
			console.log("Después this.Templates:" + this.templates.length);

			if (this.category_id == 1) { this.$store.commit("SET_TEMPLATES_REGULAR", this.templates); } else if (this.category_id == 2) { this.$store.commit("SET_TEMPLATES_COMPOSITION", this.templates); }
		},
		onAddNewTemplate (tmplateType) {
			/*console.debug(
        "[" +
          this._uid +
          "] TableTemplatesNV.onAddNewTemplate.value: " +
          tmplateType
      );*/

			//Build the new segment
			let template = {
				versions: [
					{
						type: tmplateType,
						narras: "",
						weigth: 20,
						text: "",
						text_for_editor: ""
					}
				]
			};

			this.addRow(-1, template, 1);
		},

		createTempByText (template) {
			this.addRow(-1, template, 1);
		},
		async selectNarra (tree_node) {
			console.log("+++------------ selectNarra", tree_node);
			console.log(this.template_versions_selected);

			this.isLoading = true;

			console.log("get temapltes by narra");
			const tree_id = tree_node.data_node
				? tree_node.data_node.id
				: tree_node.narra_tree_node_id;
			console.log("tree_id ", this.documentId, tree_id);

			const [data, err] = await document_templates_api.getTemplatesByNarraId(
				this.documentId,
				tree_id
			);

			console.log("templates", data, err);

			if (err) {
				if (err.status == 404) {
					this.showErrorAlert(
						"There aren't segments associated with the scenario"
					);
				} else {
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				this.replaceSpacesWithPoints(data);

				this.filterSelected = {
					name: tree_node.name,
					obj: tree_node,
					type: "narras"
				};

				this.templates = this.putSubtemplatesObjs(data);
			}

			this.isLoading = false;
		},
		async selectTemplate (template) {
			this.isLoading = true;

			const [data, err] = await document_templates_api.getTemplatesByType(
				this.documentId,
				template
			);

			console.log("templates", data, err);

			if (err) {
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.replaceSpacesWithPoints(data);
				this.filterSelected = {
					name: template,
					obj: template,
					type: "template"
				};
				this.templates = data;
				//this.templates = this.putSubtemplatesObjs(data);
			}

			this.isLoading = false;
		},
		async eraseFilter () {
			this.filterSelected = { name: "" };
			this.refreshTemplates();
		},
		popMenu (e, template) {
			let self = this;
			e.preventDefault();
			this.template_menu = template;

			if (e.button === 2) {
				let x = e.layerX;
				let y = e.layerY;
				self.mousePosition = [x, y];
			} else if (e.button === 0) {
				self.mousePosition = ["close"];
			}
		},
		async list_item_click (it) {
			console.log(this.template_menu);
			switch (it) {
			case 0:
				alert("Color");
				break;
			case 1:
				/*let template_deactivate = {
            id: this.template_menu.id,
            active: !template_menu.active
          }
          const [ datav, errv ] = await templates_api.updateTemplate(template_deactivate)

          if (!errv) {
            //this.templates.pop(this.template_menu)
            alert("Modificado")
          } else {
            console.error(errv)
          }
          break;*/
				alert("Modificado");
			}
		},
		sort: function (s) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
			}
			this.currentSort = s;
		},
		onCtxOpen (locals) {
			console.log("open", locals);
			this.menuData = locals;
		},
		insert_template_below ($event, node) {
			//insertamos una plantilla debajo de la actual
			console.log(node);
			this.addRow(node.order + 1);
		},
		insert_template_over ($event, node) {
			//insertamos una plantilla debajo de la actual
			console.log(node);
			this.addRow(node.order);
		},
		copy_template ($event, menuData) {
			console.log("copy", menuData);
			this.rowCopied = menuData;
		},
		async paste_template_over ($event, templatePaste) {
			console.log("Paste over", this.rowCopied, templatePaste);

			this.paste_template(templatePaste, templatePaste.order);
		},
		async paste_template_below ($event, templatePaste) {
			console.log("Paste below", this.rowCopied, templatePaste);

			this.paste_template(templatePaste, templatePaste.order + 1);
		},
		async paste_template (templatePaste, order) {
			//insertamos el template
			let template_new = await this.addRow(
				templatePaste.order + 1,
				this.rowCopied
			);

			if (this.rowCopied.narra_tree_nodes_version.length > 0) {
				this.template_versions_selected = template_new;

				let tree_node = {
					data_node: {
						id: this.rowCopied.narra_tree_nodes_version[0].narra_tree_node_id,
						versions: [this.rowCopied.narra_tree_nodes_version[0]]
					}
				};
				this.selectNarra(tree_node);
			}
		},
		dataModal ($event) {
			this.$modal.show("documentsDataListModal" + this.category_id);
		},
		previewAll ($event) {
			if (this.document_data_selected != 0) {
				//console.log("preview ref ", this.$refs, this.filterTemplates);
				for (let i = 0; i < this.filterTemplates.length; i++) {
					console.log(
						"ID",
						this.filterTemplates[i].id,
						this.$refs["RowTemplate" + this.filterTemplates[i].id]
					);
					if (this.$refs["RowTemplate" + this.filterTemplates[i].id]) {
						this.$refs[
							"RowTemplate" + this.filterTemplates[i].id
						][0].previewTemplate($event, "ButtonEye", "true");
					}
					//this.previewTemplate($event, this.filterTemplates[i]);
				}
			} else {
				this.showErrorAlert("Please, select a data file first");
			}
		},
		makeFilterTemplates ($event) {
			console.log("makeFilterTemplates", $event);
			//desactivamos el refresco de los higthligth
			this.refreshHighlights = false;

			let self = this;
			setTimeout(() => {
				this.searchTemplate = $event.target.value;
			}, 100);
			setTimeout(() => {
				self.refreshHighlights = true;
			}, 500);
		},
		async filterByTemplateID (template) {
			console.log("filterByTemplateID");
			this.isLoading = true;

			const [data, err] = await document_templates_api.getById(
				this.documentId,
				template.id
			);

			if (!err) {
				this.replaceSpacesWithPoints(data.templates);
				this.templates = this.putSubtemplatesObjs(data.templates);
				//this.templates = data.templates;
				console.log("filter");
				this.filterSelected = {
					name: template.versions[0].type + " (" + template.id + ")",
					obj: template,
					type: "row"
				};
			}

			this.isLoading = false;
		},
		async refreshTemplates () {
			this.isLoading = true;
			if (this.filterSelected.name.length > 0) {
				if (this.filterSelected.type == "row") {
					await this.filterByTemplateID(this.filterSelected.obj);
				} else if (this.filterSelected.type == "narras") {
					await this.selectNarra(this.filterSelected.obj);
				} else if (this.filterSelected.type == "template") {
					await this.selectTemplate(this.filterSelected.obj);
				}
			} else {
				await this.loadTemplatesNV();
			}
			this.isLoading = false;
		},
		expandAll () {
			this.Loading = true;
			[...this.filterTemplates].forEach((template) => {
				if (this.$refs["collapse-" + template.id]) {
					template.subtemplates_objs_render = template.subtemplates_objs;
					for (
						let i = 0;
						i < this.$refs["collapse-" + template.id].length;
						i++
					) {
						this.$refs["collapse-" + template.id][i].show = true;
						console.log("expand");
					}
				}
			});
			this.Loading = false;
			console.log("End of expandAll function");
		},
		collapseAll () {
			this.Loading = true;
			[...this.filterTemplates].forEach((template) => {
				if (this.$refs["collapse-" + template.id]) {
					for (
						let i = 0;
						i < this.$refs["collapse-" + template.id].length;
						i++
					) {
						this.$refs["collapse-" + template.id][i].show = false;
						console.log("collapse");
					}
				}
			});
			this.Loading = false;

			console.log("End of collapseAll function");
		},
		topFunction () {
			document.body.scrollTop = 0; //For Safari
			document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera
		},
		find_segment (row_narra, segment_id) {
			for (let item in row_narra) {
				//console.log("find_segment", row_narra[item], segment_id);

				let narras_ids = this.find_segment_aux(row_narra[item], segment_id);

				//console.log("find_segment_2", narras_ids, segment_id);
				if (Array.isArray(narras_ids) && narras_ids.length > 0) {
					let return_value = narras_ids;

					if (return_value[0] != row_narra[item].id) {
						return_value = [row_narra[item].id].concat(narras_ids);
					}

					//console.log("find_segment_3", return_value);
					return return_value;
				}
			}

			return [];
		},
		find_segment_aux (narra, segment_id) {
			//console.log("find_segment_aux_1", narra, segment_id);
			for (let template in narra.templates) {
				//console.log("find_segment_aux_2", template, segment_id);
				if (narra.templates[template].id == segment_id) {
					//console.log("find_segment_aux_found", narra.templates[template].id);
					return [narra.id, narra.templates[template].id];
				}
			}

			//console.log("find_segment_aux_3", narra, segment_id);
			for (let child in narra.children) {
				let child_search = this.find_segment_aux(
					narra.children[child],
					segment_id
				);

				if (Array.isArray(child_search) && child_search.length > 0) {
					child_search = [narra.id].concat(child_search);

					//console.log("find_segment_aux_4", child_search);
					return child_search;
				}
			}

			return [];
		}
	},
	props: {
		root_narra: {},
		documentId: {},
		category_id: {},
		title_navbar: {},
		currentDocument: { type: Object },
		currentDomain: { type: Object },
		first_child_of_root_narra_selected: { type: String },
		childs_of_root_narra_selected: { type: Array },
		archived: {}
	}
};

function reorderTemplates (templates, documentId) {
	let order_template = 1;
	let tempaltes_update = [];

	console.log("reorderTemplates");
	for (let index = 0; index < templates.length; index++) {
		console.log(templates[index].order, order_template);
		templates[index].order = order_template;

		tempaltes_update.push({
			document_id: documentId,
			template_id: templates[index].id,
			order: order_template
		});

		order_template += 1;
	}
	console.log(tempaltes_update);
	return tempaltes_update;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.table-root-narras-nv {

	td {
		font-family: "Lucida Console", Monaco, monospace, Helvetica, Arial, sans-serif;
		/*-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;*/
		color: #000000;
		margin-top: 0px;
	}

	#navbar {
		overflow: hidden;
		background-color: #333;
	}

	/* Navbar links */
	#navbar a {
		float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		padding: 14px;
		text-decoration: none;
	}

	/* The sticky class is added to the navbar with JS when it reaches its scroll position */
	.sticky {
		position: fixed;
		top: 0;
		width: 100%;
	}

	/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
	.sticky + .content {
		padding-top: 60px;
	}

	.v--modal-overlay {
		z-index: 1030;
	}

	.row-templates-container {
		/*font-family: "Menlo Regular", "Avenir", Helvetica, Arial, sans-serif;*/
		scroll-behavior: auto;
		/*margin-top:2em;*/
		padding-left: 1em;
		padding-right: 1em;
	}

	.num-templates {
		background-color: #157ef3;
	}

	.num-templates a {
		color: #fff !important;
	}
}

</style>
