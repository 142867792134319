import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class TemplatesVersions extends BaseClient {
	constructor () {
		super(httpClient, "/templates_versions/");
	}

	createVersion (template) {
		return this.post("", template);
	}

	createVersionWithAlias (template, domainId) {
		return this.post(`domains/${domainId}`, template);
	}

	deleteVersion (template_id) {
		return this.delete(template_id);
	}

	scan_words (version_id, preview) {
		return this.post(`${version_id}/scan_words`, preview);
	}

	available_statuses () {
		return this.get("available_statuses");
	}

	update_template_versions_status (id, status_id) {
		const data = {
			id,
			status_id
		};
		return this.patch("", data);
	}
}

export default new TemplatesVersions();
