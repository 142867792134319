var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.clsScenarioRow }, [
    _c(
      "div",
      {
        staticClass: "col-sm-3 col-form-label pt-0 mb-0",
        attrs: { placeholder: "Name" },
      },
      [
        _c("b-form-group", { attrs: { label: "Name" } }, [
          _c(
            "div",
            {
              ref: "narras_name",
              staticClass: "form-control calc_narra_input",
              attrs: { contenteditable: "", spellcheck: "false" },
              on: { keyup: _vm.handleKeyDown },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(this.scenario.versions[0].name) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-7" },
      [
        _c(
          "b-form-group",
          { attrs: { label: "Definition" } },
          [
            _c(
              "at",
              {
                ref: "at" + _vm.scenario.id,
                attrs: {
                  ats: _vm.trigerChars,
                  members: _vm.autocomplete_members,
                  hideOnBlur: "",
                  filterMatch: _vm.filterMatch,
                  filterMembers: _vm.filterMembersAutocomplete,
                },
                on: { at: _vm.autocompletAt },
              },
              [
                _c(
                  "div",
                  {
                    ref: "narras_text",
                    staticClass: "form-control calc_narra_input",
                    attrs: {
                      contenteditable: "",
                      spellcheck: "false",
                      id: "narras",
                      placeholder: "Definition of Scenario",
                    },
                    on: {
                      keyup: _vm.handleKeyDown,
                      blur: function ($event) {
                        return _vm.closePanel()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(this.scenario.versions[0].narra) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col-sm-1 text-right",
        staticStyle: { "margin-top": "1.9em" },
      },
      [
        _c(
          "b-button",
          {
            attrs: { variant: "" },
            on: {
              click: function ($event) {
                return _vm.updateScenarioValue()
              },
            },
          },
          [_c("font-awesome-icon", { attrs: { icon: "save" } })],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col-sm-1 text-right",
        staticStyle: { "margin-top": "1.9em" },
      },
      [
        _c(
          "b-button",
          {
            attrs: { variant: "danger" },
            on: {
              click: function ($event) {
                return _vm.confirmDeleteScenario([_vm.category, _vm.scenario])
              },
            },
          },
          [_c("font-awesome-icon", { attrs: { icon: "trash-alt" } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }