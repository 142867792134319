<template>
  <div>
    <b-navbar
      sticky
      toggleable="lg"
      type="light"
      variant="light"
      class="navigation_sticky_bar"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="newModalCategory()" v-b-tooltip.hover title="Add new Category">
            <font-awesome-icon icon="plus" />&nbsp;
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="expandAll()" v-b-tooltip.hover title="Expand">
            <i class="fa fa-expand" aria-hidden="true"></i>&nbsp;
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="collapseAll()" v-b-tooltip.hover title="Collapse"
            ><i class="fa fa-compress" aria-hidden="true"></i>&nbsp;
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-input
              v-model="searchCategory"
              placeholder="Filter Category"
              class="form-control-sm"
            />
          </b-nav-form>
          <b-nav-item href="#" @click="topFunction" v-b-tooltip.hover title="Go to Top">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="container">
      <h1 class="h4 scenario_main_title">Categories Definitions</h1>
      <div v-for="(category, index) in filterCategories" :key="index">
        <p class="categoryscenario-header">
          <span
            contenteditable
            spellcheck="false"
            @blur="updateCategory($event, category)"
          >
            {{ category.name }}
          </span>
          <ButtonCircle
            ref="ButtonPlus"
            :template="category"
            icon="plus"
            @click="addScenario(category)"
						v-b-tooltip.hover title="Add new Category"
          />
          <ButtonCircle
            ref="ButtonTrash"
            :template="category"
            icon="trash-alt"
            @click="confirmDeleteCategory(category)"
						v-b-tooltip.hover title="Delete Category"
          />
          <ButtonCircle
            :ref="'ButtonCollapse' + category.id"
            :template="category"
            icon="angle-down"
            icon_second="angle-up"
            @click="toogleCategory(category)"
						v-b-tooltip.hover title="Expand / Collapse"

          />
        </p>

        <!--div class="row" v-for="scenario in category.scenarios">
						<div class="col"-->
        <b-collapse
          :id="'collapse-' + category.id"
          :ref="'collapse-' + category.id"
          class="mt-2"
        >
          <div :ref="'div_category' + category.id">
            <div v-for="(scenario, scenarioIndex) in category.scenarios" :key="scenarioIndex">
              <!--<ScenarioItemV3
                :scenario="scenario"
                :domainId="domainId"
                :documentId="documentId"
                :category="category"
              />-->
              <ScenarioItemV31
                :scenario="scenario"
                :domainId="domainId"
                :documentId="documentId"
                :category="category"
              />
            </div>
          </div>
        </b-collapse>
        <br />
      </div>

      <b-modal
        hide-footer
        id="modal-new-cat"
        title="New Category"
        ref="modal-new-cat"
				centered
      >
        <b-form @submit.stop.prevent>
          <b-form-group
            id="input-group-2"
            label="Name:"
            label-for="name"
            label-align="left"
          >
            <b-form-input
              id="name"
              :state="validationName"
              v-model="name_cat"
              required
              placeholder="Name"
            ></b-form-input>
          </b-form-group>

          <b-button
            :disabled="!validationName"
            variant="primary"
            v-on:click="insertCategory"
            >Save</b-button
          >

          <b-button type="reset" variant="danger" v-on:click="cancelCategory"
            >Cancel</b-button
          >
        </b-form>
      </b-modal>

      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import scenario_categories_api from "../../api/scenario_categories.js";
import scenario_api from "../../api/scenario.js";
import scenario_version_api from "../../api/scenario_version.js";
import ScenarioItem from "@/components/scenarios/ScenarioItem";
import ScenarioItemV3 from "@/components/scenarios/ScenarioItemV3";
import ScenarioItemV31 from "@/components/scenarios/ScenarioItemV31";
import Loading from "@/components/UI/Loading";
import At from "@/components/UI/AutoComplete/At.vue";
import ButtonCircle from "@/components/UI/Buttons/ButtonCircle";
import { alertMixins } from "@/mixins/alertMixins.js";
import { templatesMixins } from "@/mixins/templatesMixins.js";

export default {
	name: "ScenariosEditor",
	mixins: [alertMixins, templatesMixins],
	props: {
		domainId: {},
		documentId: {}
	},
	components: {
		ScenarioItem,
		ScenarioItemV3,
		ScenarioItemV31,
		Loading,
		At,
		ButtonCircle
	},
	data () {
		return {
			name_cat: "",
			isLoading: false,
			scenario: {
				name: "",
				narras: "",
				description: ""
			},
			trigerChars: ["CALC", ".", "'", "CALC_NARRA_", "--"],
			searchCategory: ""
		};
	},
	computed: {
		autocomplete_members () {
			return this.$store.getters.getAutocompleteNSchemaMembers.entities;
		},
		categories: {
			get () {
				return this.$store.getters.getScenarios;
			},
			set (value) {
				console.log("Update categories");
				this.$store.commit("SET_SCENARIOS", value);
			}
		},
		filterCategories () {
			const filter = this.searchCategory;
			return this.categories.filter((c) => {
				return c.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
			});
		},
		validationName () {
			return this.name_cat.length > 0;
		}
	},
	methods: {
		toogleCategory (category) {
			this.$root.$emit("bv::toggle::collapse", "collapse-" + category.id);
			this.$refs["ButtonCollapse" + category.id][0].changeState();
		},
		expandAll () {
			[...this.categories].forEach((category) => {
				this.$refs["collapse-" + category.id][0].show = true;
			});
		},
		collapseAll () {
			[...this.categories].forEach((category) => {
				this.$refs["collapse-" + category.id][0].show = false;
			});
		},
		newModalCategory () {
			this.name_cat = "";
			this.$refs["modal-new-cat"].show();
		},
		cancelCategory () {
			this.$refs["modal-new-cat"].hide();
		},

		async insertCategory () {
			this.isLoading = true;

			let cat = {
				name: this.name_cat,
				domain_id: this.domainId
			};

			const [data, err] = await scenario_categories_api.createCategory(cat);
			if (err) {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				//console.log("antes push ", this.categories);
				await this.addScenario(data);
				//console.log("antes commit", data);
				this.categories.push(data);
				let self = this;
				setTimeout(() => {
					if (self.$refs["collapse-" + data.id]) { self.$refs["collapse-" + data.id][0]; }
				}, 100);

				this.$bvToast.toast("Category created", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});

				//this.$store.commit("SET_SCENARIOS", this.categories);
			}

			this.$refs["modal-new-cat"].hide();
			this.isLoading = false;
		},
		async updateCategory ($event, category) {
			console.debug("updateCategory:", $event.target, category);
			if ($event.target.innerText != category.name) {
				this.isLoading = true;
				category.name = $event.target.innerText;
				const [data, err] = await scenario_categories_api.updateCategory(
					category
				);
				if (err) {
					console.error(err);
					this.showErrorAlert("Error: " + err.data.error);
				} else {
					//console.log("antes push ", this.scenarios);
					//this.categories.push(cat);
					//console.log("antes commit");
					this.$store.commit("SET_SCENARIOS", this.categories);
					this.$bvToast.toast("Category update", {
						title: "Success ",
						variant: "success",
						solid: true,
						"auto-hide-delay": "2000",
						appendToast: true
					});
				}

				this.isLoading = false;
			}
		},
		confirmDeleteCategory (category) {
			this.showDeleteModal(
				category,
				this.deleteCategory,
				this.noDeleteCategory
			);
		},
		async deleteCategory (category) {
			this.isLoading = true;
			const [data, err] = await scenario_categories_api.deleteCategory(
				category.id
			);
			if (err) {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			} else {
				this.categories = this.categories.filter(function (c) {
					return c.id != category.id;
				});

				this.$bvToast.toast("Category deleted", {
					title: "Success ",
					variant: "success",
					solid: true,
					"auto-hide-delay": "2000",
					appendToast: true
				});
			}

			this.isLoading = false;
		},
		noDeleteCategory () {},
		async addScenario (category) {
			this.isLoading = true;

			//creamos un escenario vacío
			const scenario = {
				active: true,
				domain_id: this.domainId,
				scenario_category: category.id,
				versions: [
					{
						name: "",
						narra: "",
						description: "",
						active: true,
						current_version: true
					}
				]
			};

			let [data, err] = await scenario_api.createScenario(scenario);
			if (!err) {
				scenario.id = data.id;
				scenario.versions[0].scenario_id = data.id;
				[data, err] = await scenario_version_api.createVersion(
					scenario.versions[0]
				);

				if (!err) {
					if (!category.scenarios) {
						category.scenarios = [];
					}
					category.scenarios.push(scenario);
					this.$store.commit("SET_SCENARIOS", this.categories);

					if (this.$refs["collapse-" + category.id]) { this.$refs["collapse-" + category.id][0].show = true; }

					this.scrollToNewItem(category.id);

					this.$bvToast.toast("Scenario created", {
						title: "Success ",
						variant: "success",
						solid: true,
						"auto-hide-delay": "2000",
						appendToast: true
					});
				} else {
					console.error(err);
					this.showErrorAlert("Error: " + err.data.error);
				}
			} else {
				console.error(err);
				this.showErrorAlert("Error: " + err.data.error);
			}

			this.isLoading = false;
		},

		scrollToNewItem (id) {
			setTimeout(() => {
				const element = this.$refs["div_category" + id][0];
				const elementRect = element.getBoundingClientRect();
				const absoluteElementTop = elementRect.bottom + window.pageYOffset;
				const middle = absoluteElementTop - window.innerHeight / 2;
				window.scrollTo(0, middle);
			}, 100);
		},
		topFunction () {
			document.body.scrollTop = 0; //For Safari
			document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera
		}
	}
};
</script>

<style lang="scss" scoped>
.categoryscenario-header {

	& > * {
		margin-right: .5rem;
	}

	&__generator-btn {
		margin-right: .8rem;
	}

	button:focus {
		outline: none;
	}
}
</style>
