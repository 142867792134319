var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.buttonClass,
      attrs: { id: "preview-button" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.clic($event, _vm.template)
        },
      },
    },
    [
      _c(
        "div",
        {},
        [
          !_vm.loading
            ? _c("font-awesome-icon", {
                attrs: { icon: _vm.icon_state, size: _vm.size },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("b-spinner", { attrs: { small: "", label: "Spinning" } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading && _vm.legend.length
            ? _c("span", { staticClass: "previews-multiplier" }, [
                _vm._v(_vm._s(_vm.legend)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }