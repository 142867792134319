<template>
	<tr>
		<td
			class="tree_viewer-border-b"
			:style="{ 'padding-left': node.level * indent + left_padding + 'px' }"
		>
			<a
				v-if="select_name == 'true'"
				href="#"
				@click.prevent="$emit('selectNarra', node.data)"
				>{{ node.name }}</a
			>
			<span v-else>{{ node.name }}</span>
		</td>
		<!--td v-if="!only_name_column" class="tree_viewer-border-b">
			{{ node.narras }}
		</td-->
		<td v-if="!only_name_column" class="tree_viewer-border-b">
			{{ node.template }}
		</td>
	</tr>
</template>
<script>
export default {
	name: "TableTreeRow",
	data () {
		return {
			indent: 20,
			left_padding: 5
		};
	},
	props: { node: Object, select_name: String, only_name_column: Boolean }
};
</script>
