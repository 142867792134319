<template>
  <div>
    <b-row>
      <b-card-body>
        <b-col cols="1"></b-col>
        <b-col>
          <b-card bg-variant="default">
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search scenario"
              v-model="searchNarras"
            ></b-form-input>
            <template v-for="narra in root_narras_filter">
              <b-card no-body bg-variant="default">
                <b-card-header class="p-1" header-tag="header" role="tab">
                  <h2>
                    <a
                      v-if="select_name == 'true'"
                      href="#"
                      @click="$emit('selectNarra', narra.versions[0])"
                    >
                      <b-badge variant="info">
                        {{ narra.versions[0].name }}
                      </b-badge>
                    </a>
                    <b-badge v-else variant="info">
                      {{ narra.versions[0].name }}
                    </b-badge>

                    <a
                      v-if="collapse_button != 'false'"
                      class="text-rigth"
                      href="#"
                      @click="toggle_root(narra.versions[0].name)"
                    >
                      <b-button
                        pill
                        variant="success"
                        v-b-popover.hover="'Collapse tree'"
                      >
                        <font-awesome-icon icon="angle-up" />
                      </b-button>
                    </a>
                    <a
                      v-if="tree_editor_button != 'false'"
                      class="text-rigth"
                      href="#"
                      @click="openDialog(narra)"
                    >
                      <b-button
                        pill
                        variant="info"
                        v-b-popover.hover="'Open tree editor'"
                      >
                        <font-awesome-icon icon="code-branch" />
                      </b-button>
                    </a>
                  </h2>
                </b-card-header>
                <b-collapse :id="narra.versions[0].name" visible>
                  <NrtTreeTable
                    :tree="rows[narra.id]"
                    :select_name="select_name"
                    :only_name_column="only_name_column"
                    :searchString="searchNarras"
                    @selectNarra="selectNarra"
                  />
                </b-collapse>
              </b-card>
            </template>
          </b-card>
        </b-col>
        <b-col cols="1"></b-col>
      </b-card-body>
    </b-row>
  </div>
</template>

<script>

import NrtTreeTable from "@/components/UI/TableTree/TableTree";

export default {
	name: "NarrasTreesViewer",
	components: {
		NrtTreeTable
	},
	props: {
		collapse_button: Boolean,
		tree_editor_button: String,
		select_name: String,
		only_name_column: Boolean,
		template_name_filter: { type: String, default: "" }
	},
	data () {
		return {
			searchNarras: "",
			root: [],
			columns: [
				{
					property: "name",
					title: "Node",
					collapseIcon: false
				},
				{
					title: "Narras",
					property: "narras"
				},
				{
					title: this.$templatesName,
					property: "template"
				}
			],
			narraIdEditor: 0
		};
	},
	computed: {
		rows () {
			return this.$store.getters.getRowsNarras;
		},
		root_narras () {
			return this.$store.getters.getRootNarras;
		},
		root_narras_filter () {
			if (this.template_name_filter == "") {
				return this.$store.getters.getRootNarras.nodes;
			} else {
				const roots = this.$store.getters.getRootNarras.nodes.filter((root) => {
					console.log("root", root);
					return root.versions[0].name == this.template_name_filter;
				});
				console.log("roots", roots);
				return roots;
			}
		}
	},
	methods: {
		selectNarra (tree_node) {
			console.log(tree_node);
			this.$emit("selectNarra", tree_node);
		},
		toggle_root (id) {
			this.$root.$emit("bv::toggle::collapse", id);
		},
		openDialog (narra) {
			console.log(narra);
			this.narraIdEditor = narra.id;
			//this.showDialog = true;
			this.$modal.show("NarraTreeEditorModal");
		}
	}
};
</script>
<style scoped>
.v--modal-overlay {
  z-index: 1030;
}
</style>
