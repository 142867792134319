import httpClient from "./config/httpClient";
import BaseClient from "./config/BaseApiClient";

class Scenarios extends BaseClient {
	constructor () {
		super(httpClient, "/scenarios/");
	}

	createScenario (scenario) {
		return this.post("", scenario);
	}

	deleteScenario (scenario_id) {
		return this.delete(scenario_id);
	}

	updateScenario (scenario) {
		return this.patch(scenario.id, scenario);
	}
}

export default new Scenarios();
