var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "segments-checker",
      staticClass: "segments-checker",
      attrs: {
        id: "segments-checker",
        size: "lg",
        centered: "",
        title: "Segments Checker",
      },
      on: { close: _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeModal.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.checkSegments.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Check Segments")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "segments-checker__body" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { class: _vm.messageCssClass }, [
                !_vm.checkPerformed
                  ? _c("span", [
                      _vm._v("Press in Check Segments to perform a check."),
                    ])
                  : _vm.hasErrors
                  ? _c(
                      "span",
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "exclamation-circle", size: "lg" },
                        }),
                        _c("strong", [
                          _vm._v(
                            "Found " +
                              _vm._s(_vm.searchResults.length) +
                              " segments with errors."
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm.checkPerformed
                  ? _c("span", [_vm._v("No errors found.")])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.searchingSegment
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "segments-checker__spinner flex flex-center-x flex-center-y",
                  },
                  [
                    _c("b-spinner", {
                      attrs: { variant: "primary", label: "Spinning" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.searchResults.length > 0
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "segments-checker__result-list" },
                      _vm._l(_vm.searchResults, function (template) {
                        return _c(
                          "div",
                          {
                            key: template.versions[0].id,
                            ref: "template" + template.id,
                            refInFor: true,
                            staticClass: "segments-checker__segment",
                          },
                          [
                            _c(
                              "div",
                              { class: _vm.getClassForTemplate(template) },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-header segments-checker__card-template-header template-header pt-0 pb-0",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-center-y flex-separate segments-checker__template-header template-row-header",
                                      },
                                      [
                                        _c("div", { staticClass: "pl-0" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "segments-checker__id badge template-id-number",
                                            },
                                            [_vm._v(_vm._s(template.id))]
                                          ),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass:
                                              "segments-checker__name template-history-date",
                                            attrs: { spellcheck: "false" },
                                            domProps: {
                                              textContent: _vm._s(
                                                template.versions[0].type
                                              ),
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "segments-checker__preview-wrap text-right align-middle",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "segments-checker__preview-icon template-preview-icon template-circle-button",
                                                attrs: {
                                                  href: "#",
                                                  title: "Set current version",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.navigateToTemplate(
                                                      template
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-arrow-alt-circle-right fa-lg",
                                                  }),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "segments-checker__template-text template-row-text",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "template-cell-text card-body",
                                          },
                                          [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  template.versions[0]
                                                    .text_for_editor
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "segments-checker__fail-causes",
                                      },
                                      [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v("Errors Found:"),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "segments-checker__error-list",
                                          },
                                          _vm._l(
                                            template.failures.cause,
                                            function (cause) {
                                              return _c(
                                                "li",
                                                { key: cause.type },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(cause.msg) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                  cause.type ==
                                                  "MALFORMED_LABELS"
                                                    ? _c("div", [
                                                        _c(
                                                          "ul",
                                                          _vm._l(
                                                            cause.labels,
                                                            function (label) {
                                                              return _c(
                                                                "li",
                                                                { key: label },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      label
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ])
                                                    : cause.type ==
                                                      "DUPLICATED_SEGMENT_TEXT"
                                                    ? _c("div", [
                                                        _c(
                                                          "ul",
                                                          _vm._l(
                                                            cause.repeated_segments_id,
                                                            function (
                                                              segment_id
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: segment_id,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      segment_id
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm.search.length > 0 && !_vm.searchingSegment
            ? [_c("p", [_vm._v("Nothing found")])]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }