<template>
	<div v-if="html_errors.length > 0">
		<pre v-for="error in html_errors" v-bind:key="error.id" class="preview_html_formated">
<code v-if="!error.is_error" class="code-style">{{ error.text }}</code><code v-else class="code-style-error">{{ error.text}}
{{ error.error_text }}</code>
		</pre>
	</div>
	<pre v-else class="preview_html_formated"><code class="code-style">{{html_formatted}}
		</code>
	</pre>
</template>

<script>
import html_validator_W3C_api from "../../api/html_validator.js";
import _ from "lodash";

export default {
	name: "HTMLValidator",
	props: ["html_text"],
	data() {
		return {
			html_errors: [],
		};
	},
	watch: {
		html_formatted() {
			console.debug("watch html_formatted");
			// this.validateHTML();
		},
	},
	computed: {
		html_formatted() {
			console.debug("computed html_formatted");
			return this.formatHtml(this.html_text.trim());
		},
		get_html_errors() {
			console.debug("get_html_errors");
			this.validateHTML();
		},
	},
	methods: {
		getIndent(level) {
			var result = '', i = level * 4;
			if (level < 0) {
					// throw "Level is below 0";
					return "";
			}
			while (i--) {
					result += ' ';
			}
			return result;
		},
		formatHtml(html) {

			html = html.trim();
    		let result = '',
			indentLevel = 0,
			tokens = html.split(/</);
			for (var i = 0, l = tokens.length; i < l; i++) {
				var parts = tokens[i].split(/>/);
				if (parts.length === 2) {
					if (tokens[i][0] === '/') {
						indentLevel--;
					}
					result += this.getIndent(indentLevel);
					if (tokens[i][0] !== '/') {
						indentLevel++;
					}

					if (i > 0) {
						result += '<';
					}

					result += parts[0].trim() + ">\n";
					if (parts[1].trim() !== '') {
						result += this.getIndent(indentLevel) + parts[1].trim().replace(/\s+/g, ' ') + "\n";
					}

					if (parts[0].match(/^(img|hr|br)/)) {
						indentLevel--;
					}
				} else {
						result += this.getIndent(indentLevel) + parts[0] + "\n";
				}
			}

			this.validateHTML(result);
					// console.debug("html_formatted", html_formatted);

			// const prettify = require('html-prettify');
			// const indentedHtml = prettify(result);
			// return indentedHtml;


			return result;
		},
		async getHtmlErrors() {
			const errors = await html_validator_W3C_api.validate_w3c(html_formatted);
		},
		validateHTML(html_formatted) {
			console.log("validateHTML");
			let self = this;

			const ignore_errors = [
				"Start tag seen without seeing a doctype first. Expected “<!DOCTYPE html>”.",
				"Element “head” is missing a required instance of child element “title”.",
				"Non-space characters found without seeing a doctype first. Expected “<!DOCTYPE html>”.",
			];

			// const html_formatted = this.formatHtml(this.html_formatted);
			console.log("validate html request");
			// const html_formatted = this.html_formatted;
			html_validator_W3C_api
				.validate_w3c(html_formatted)
				.then(function (response) {
					console.debug("################### response", response);

					const lines = html_formatted.split("\n");
					let parts = [];
					let start_line = 0;

					for (const error of response.data.messages) {
						if (error.type == "error") {
							if (ignore_errors.includes(error.message)) continue;

							const start_lines = lines.slice(start_line, error.lastLine - 1);
							const start_text = start_lines.join("\n");
							start_line = error.lastLine - 1;
							parts.push({
								id: _.uniqueId(),
								is_error: false,
								text: start_text,
							});

							const error_lines = lines.slice(start_line, start_line + 1);
							const error_text = error_lines.join("\n");
							start_line = error.lastLine;
							parts.push({
								id: _.uniqueId(),
								is_error: true,
								text: error_text,
								error_text: error.message,
							});
						}
						self.html_errors = parts;
					}
					if (parts.length === 0) {
						self.html_errors = [{
							id: _.uniqueId(),
							is_error: false,
							text: html_formatted,
						}];
					} else {
						const start_lines = lines.slice(start_line);
						const start_text = start_lines.join("\n");
						parts.push({
							id: _.uniqueId(),
							is_error: false,
							text: start_text,
						});
					}
			});
		}
	},
};
</script>

<style scoped>
.preview_html_formated {
	white-space: pre-wrap; /* Since CSS 2.1 */
}

.code-style {
	font-size: 15px;
	line-height: 28px;
	/* background-color: lightblue; */
	/* color: darkblue; */
}

.code-style-error {
	font-size: 15px;
	line-height: 28px;
	/* background-color: lightblue; */
	color: red;
}
</style>
